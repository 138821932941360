import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { Logout } from "@/pages/Logout/Logout";
import { Private } from "@/pages/Private/Private";
import { authSelector } from "@/pages/Public/redux/auth.slice";
import { useLazyFetchMeQuery } from "@/pages/Public/redux/auth.api";
import { Public } from "@/pages/Public/Public";
import { LoadingOverlay } from "@/components";
import { useAppSelector } from "@/app/redux/hooks";
import { PrivacyNotes } from "@/pages/Legal/PrivacyNotes/PrivacyNotes";
import { Imprint } from "@/pages/Legal/Imprint/Imprint";
import { CameraStatus } from "@/pages/CameraStatus/CameraStatus";

import { ROUTE_CONFIG } from "./config";

const { LOGIN, LOGOUT, EVENTS } = ROUTE_CONFIG;

export const Routing = () => {
  const { pathname } = useLocation();
  const { token, user } = useAppSelector(authSelector);
  const [fetchMe] = useLazyFetchMeQuery();
  const onLogoutRoute = pathname === LOGOUT;
  const [isLoading, setIsLoading] = useState(!onLogoutRoute);

  useEffect(() => {
    if (token && !user && isLoading) {
      fetchMe(token);
    }
  }, [token, user, isLoading, fetchMe]);

  useEffect(() => {
    if ((!token || !!user) && isLoading) {
      setTimeout(() => setIsLoading(false), 1000);
    }
  }, [user, token, isLoading]);

  const getFallbackRoute = () => {
    if (user) {
      return EVENTS;
    } else {
      return LOGIN;
    }
  };

  return isLoading ? (
    <LoadingOverlay />
  ) : (
    <Routes>
      <Route element={<Logout />} path={LOGOUT} />
      <Route element={<Private />} path="/app/*" />
      <Route element={<Navigate to={getFallbackRoute()} />} path="/" />
      <Route element={<Public />} path="*" />
      <Route element={<PrivacyNotes />} path="privacy-notes" />
      <Route element={<Imprint />} path="imprint" />
      <Route element={<CameraStatus />} path="monitor" />
    </Routes>
  );
};
