import { FunctionComponent, SyntheticEvent } from "react";
import ReactCrop, { centerCrop, Crop } from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";

interface ImageCropperProps {
  crop: Crop | undefined;
  setCrop: (c: Crop) => void;
  image: string;
  setImage: (img: HTMLImageElement) => void;
}

export const ImageCropper: FunctionComponent<ImageCropperProps> = ({
  crop,
  setCrop,
  image,
  setImage,
}) => {
  const onImageLoad = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    setImage(e.currentTarget);
    const { width, height } = e.currentTarget;

    const smallestVal = width < height ? width : height;

    const crop = centerCrop(
      {
        // You don't need to pass a complete crop into
        // makeAspectCrop or centerCrop.
        unit: "px",
        height: smallestVal,
        width: smallestVal,
      },
      width,
      height
    );

    setCrop(crop);
  };

  return (
    <ReactCrop
      aspect={1}
      crop={crop}
      style={{ width: "100%", height: "100%" }}
      onChange={(c) => setCrop(c)}
    >
      <img
        alt=""
        crossOrigin="anonymous"
        src={image}
        style={{
          borderRadius: 8,
          width: "100%",
          height: "100%",
        }}
        onLoad={(e) => onImageLoad(e)}
      />
    </ReactCrop>
  );
};
