import { toast } from "react-toastify";

import { Notification } from "@/components";

interface notifyProps {
  text: string;
  type?: "error" | "warning" | "info" | "success";
}

export const notify = ({ text, type }: notifyProps) => {
  toast(<Notification text={text} type={type} />);
};
