import { FunctionComponent } from "react";
import { default as Chart } from "react-apexcharts";

export interface StackedColumnChartSeries {
  name: string;
  data: number[];
}
interface ColumnBarChartProps {
  series: StackedColumnChartSeries[];
  categories: string[];
  colors?: string[];
  stacked?: boolean;
}

export const ColumnBarChart: FunctionComponent<ColumnBarChartProps> = ({
  series,
  categories,
  colors,
  stacked,
}) => {
  return (
    <Chart
      height={360}
      options={{
        chart: {
          type: "bar",
          height: 800,
          stacked: stacked,
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
            autoSelected: "zoom",
          },
          zoom: {
            enabled: true,
            type: "x",
            autoScaleYaxis: true,
            zoomedArea: {
              fill: {
                color: "#B9F1D8",
              },
            },
          },
          redrawOnParentResize: true,
        },
        colors: colors,
        plotOptions: {
          bar: {
            horizontal: false,
            // borderRadius: 8,
            // borderRadiusApplication: "end",
            // borderRadiusWhenStacked: "all",
            barHeight: "40%",
            columnWidth: "50px",
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: 3,
          style: {
            fontSize: "12px",
            colors: ["white"],
          },
        },
        grid: {
          show: true,
          borderColor: "#919EAB3D",
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        legend: {
          show: true,
          fontSize: "10px",
        },
        xaxis: {
          tickPlacement: "on",
          type: "category",
          categories,
          labels: {
            show: true,
            hideOverlappingLabels: false,
            style: {
              colors: "#373D3F",
              fontSize: series.length > 6 ? "10px" : "12px",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          tickAmount: 4,
          forceNiceScale: true,
          labels: {
            show: true,
            offsetX: 0,
            style: {
              colors: "#373D3F",
              fontSize: "11px",
              cssClass: "padding-right: 5px;",
            },
          },
        },
      }}
      series={series}
      type="bar"
      width="100%"
    />
  );
};
