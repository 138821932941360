import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { DonutChart, Loader } from "@/components";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";

interface ActivationsByTypeDonutChartProps {
  isFetching: boolean;
  isFiltration?: boolean;
  kpis: KpiType[];
}
export const ActivationsByTypeDonutChart: FunctionComponent<ActivationsByTypeDonutChartProps> = ({
  isFetching,
  isFiltration,
  kpis,
}) => {
  const { t } = useTranslation();

  const activationsTypesLabels = useMemo(() => {
    const activationsKPIs = kpis.find((kpi) => kpi.name === KpiTypeEnum.ACTIVATIONS)?.data ?? [];
    const activationsTypes = activationsKPIs.map((d) => d.specific).filter((el) => el);

    return activationsTypes?.map(
      (type) => activationsKPIs.find((kpi) => kpi.specific === type)?.specific ?? ""
    );
  }, [kpis]);

  const activationsByTypeData = useMemo(() => {
    const activationsKPIs = kpis.find((kpi) => kpi.name === KpiTypeEnum.ACTIVATIONS)?.data ?? [];

    return activationsTypesLabels.map((type) => {
      const recordData = activationsKPIs?.find((kpi) => kpi.specific === type);

      if (recordData) {
        if (isFiltration) {
          return Math.round(recordData.date?.[0]?.percent ?? 0);
        } else {
          return Math.round(recordData.overall?.percent ?? 0);
        }
      } else {
        return 0;
      }
    });
  }, [kpis, isFiltration, activationsTypesLabels]);

  return (
    <Box>
      <Typography component="p" mb={1} variant="mainBold">
        {t("events.donutActivationsByType")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : (
        <DonutChart
          kpi={t("events.donutActivationsByType")}
          labels={activationsTypesLabels}
          series={activationsByTypeData}
        />
      )}
    </Box>
  );
};
