export enum ActiveBarEnum {
  MENU = "MENU",
  EVENTS_FILTER = "EVENTS_FILTER",
  MODULES_FILTER = "MODULES_FILTER",
}

export enum ProductVersion {
  BSURE = "BSURE",
  JTI = "JTI",
}
