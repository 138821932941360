import { Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, DialogActions, DialogContent, Typography } from "@mui/material";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { InputField, Button, ImageUploader, Modal } from "@/components";

import { notify } from "@/app/utils/notify";
import { ERROR_TYPE, translateError } from "@/app/utils";
import { ServerError } from "@/types/error";
import { useCreateBrandMutation, useUpdateBrandMutation } from "@/pages/Private/redux/brands.api";
import { useUploadImage } from "@/pages/Private/helpers/useUploadImage";

import {
  CreateOrEditRequestSchema,
  CreateOrEditBrandType,
  BrandType,
} from "../schema/brand.schema";

interface CreateOrEditBrandModalIf {
  brand?: BrandType | null;
  isOpen: boolean;
  handleClose?: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isEditing: boolean;
}

export const CreateOrEditBrandModal: FunctionComponent<CreateOrEditBrandModalIf> = ({
  brand,
  isOpen,
  setIsOpen,
  isEditing,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`brands.modals.${key}`);

  const [createBrand, { isLoading }] = useCreateBrandMutation();
  const [updateBrand, { isSuccess, isLoading: isUpdateLoading }] = useUpdateBrandMutation();
  const { uploadImage, isLoading: isImageLoading } = useUploadImage();

  const initialValues: CreateOrEditBrandType = {
    name: brand?.name ?? "",
    image: brand?.image ?? "",
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm<CreateOrEditBrandType>({
    defaultValues: initialValues,
    resolver: zodResolver(CreateOrEditRequestSchema),
  });

  const handleClose = useCallback(() => {
    setIsOpen(false);
    reset();
  }, [setIsOpen, reset]);

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess, handleClose]);

  const onCreateSubmit = async (values: CreateOrEditBrandType) => {
    try {
      const { name, image } = values;
      const body: CreateOrEditBrandType = {
        name,
        image,
      };

      if (image) {
        const imageUrl = await uploadImage(image);

        body.image = imageUrl;
      }

      await createBrand(body).unwrap();
      setIsOpen(false);
      notify({ text: t("notifications.createBrand") });
    } catch (err) {
      notify({
        text: translateError[(err as ServerError).data.message as ERROR_TYPE],
        type: "error",
      });
    }
  };

  const onEditSubmit = async (values: CreateOrEditBrandType) => {
    try {
      const body: CreateOrEditBrandType = {};

      for (const key in values) {
        const initialValue = initialValues[key as keyof CreateOrEditBrandType];
        const currentValue = values[key as keyof CreateOrEditBrandType];

        if (currentValue !== initialValue) {
          body[key as keyof CreateOrEditBrandType] = currentValue;
        }
      }

      if (Object.entries(body).length > 0) {
        if (body.image) {
          const imageUrl = await uploadImage(body.image);

          body.image = imageUrl;
        }

        await updateBrand({ id: brand?.id ?? 0, body }).unwrap();
        notify({ text: t("notifications.updateBrand") });
      } else {
        handleClose();
      }
    } catch (err) {
      notify({
        text: translateError[(err as ServerError).data.message as ERROR_TYPE],
        type: "error",
      });
    }
  };

  return (
    <Modal
      setShow={handleClose}
      show={isOpen}
      title={isEditing ? ts("editBrand.title") : ts("createBrand.title")}
    >
      <DialogContent
        dividers
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          border: "none",
          padding: 1.5,
          paddingTop: 0,
        }}
      >
        <Typography component="p" mb={0.5} variant="main14">
          {isEditing ? ts("editBrand.cta") : ts("createBrand.cta")}
        </Typography>
        {
          <Box>
            <Box mb={1} width="100%">
              <InputField
                hasError={errors.name && !!errors.name}
                {...register("name")}
                placeholder={ts("createBrand.brandNamePlaceholder")}
              />
            </Box>
            <Box bgcolor="white" borderRadius={2} boxShadow={1} maxHeight={336} p={2}>
              <Box display={"flex"} height="calc(100% - 76px)" mb={1.5} minHeight={186}>
                <ImageUploader
                  currentImgUrl={getValues("image")}
                  setCurrentImgUrl={(img) => setValue("image", img, { shouldValidate: true })}
                />
              </Box>
              <Typography fontWeight={400} variant="main14">
                {t("basics.imageRatioNote")}
              </Typography>
            </Box>
          </Box>
        }
      </DialogContent>

      <DialogActions
        sx={{
          padding: 1.5,
        }}
      >
        <Button
          handleClick={isEditing ? handleSubmit(onEditSubmit) : handleSubmit(onCreateSubmit)}
          isDisabled={!isValid}
          isLoading={isLoading || isUpdateLoading || isImageLoading}
          title={isEditing ? t("basics.save") : t("create")}
          type="submit"
        />
        <Button handleClick={handleClose} title={t("basics.cancel")} variant="outlined" />
      </DialogActions>
    </Modal>
  );
};
