import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";

import { Button } from "@/components";
import { useSearch } from "@/app/utils";
import { useGetDataSourcesQuery } from "@/pages/Private/redux/dataSources.api";
import { useHasUserPermission } from "@/app/hooks/useHasUserPermission";
import { PermissionRoles } from "@/enum";

import { DataSourcesTable } from "./components/DataSourcesTable";
import { CreateDataSourceModal } from "./components/CreateDataSourceModal";

export const DataSourcesOverview: FunctionComponent = () => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`dataSources.${key}`);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const { page, setPage, limit } = useSearch();
  const { data: dataSources, isLoading } = useGetDataSourcesQuery({ page, limit });

  const canUserCreateDataSources = useHasUserPermission(PermissionRoles.CREATE_DATA_SOURCE);

  //Changes the browser tab title dynamically
  useEffect(() => {
    window.document.title = t("windowTitles.dataSources");

    return () => {
      window.document.title = t("windowTitles.default");
    };
  }, [t]);

  return (
    <>
      <Typography mb={1.5} mt={4} variant="h1">
        {ts("title")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { md: "space-between" },
          mb: 3,
        }}
      >
        <Typography
          component="p"
          mb={1.5}
          sx={{ maxWidth: { xs: "100%", md: 566 } }}
          variant="main"
        >
          {ts("description")}
        </Typography>
        <Box
          sx={{
            minWidth: { xs: "100%", md: "fit-content" },
          }}
        >
          {canUserCreateDataSources && (
            <Button
              handleClick={() => setIsCreateDialogOpen(true)}
              title={ts("create")}
              variant="contained"
            />
          )}
        </Box>
      </Box>
      <DataSourcesTable
        dataSources={dataSources?.data || []}
        handleFetchPage={setPage}
        isLoading={isLoading}
        meta={dataSources?.meta}
        page={page}
      />

      <CreateDataSourceModal isOpen={isCreateDialogOpen} setIsOpen={setIsCreateDialogOpen} />
    </>
  );
};
