import { forwardRef } from "react";
import styled from "@emotion/styled";
import { UseFormRegisterReturn } from "react-hook-form";
import { Box } from "@mui/system";

export type TextareaStylesProps = {
  value?: string | number;
  label?: string;
  placeholder?: string;
  readonly?: boolean;
} & Partial<UseFormRegisterReturn>;

const TextareaStyles = styled.textarea`
  width: calc(100% - 24px);
  height: calc(240px - 24px);
  padding: 12px;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  resize: none;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  &::placeholder {
    color: #919eab;
  }
  &:read-only {
    cursor: default;
  }
`;

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaStylesProps>(
  function CustomTextarea({ value, label, placeholder, readonly, ...rest }, ref) {
    return (
      <>
        {label && (
          <Box
            color="gray900.main"
            component="label"
            display="block"
            fontSize={16}
            fontWeight={600}
            htmlFor={rest.name}
            mb={1}
          >
            {label}
          </Box>
        )}
        <TextareaStyles
          ref={ref}
          id={rest.name}
          placeholder={placeholder}
          readOnly={readonly}
          value={value}
          {...rest}
        />
      </>
    );
  }
);
