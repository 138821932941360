import { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Pagination,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { Button, ButtonSize, InputField, LoadingOverlay, SelectField } from "@/components";
import { useAppSelector } from "@/app/redux/hooks";
import { eventsSelector } from "@/pages/Private/redux";
import {
  useAssignModuleMutation,
  useGetModulesQuery,
  useUpdateOpeningTimesMutation,
} from "@/pages/Private/redux/modules.api";
import { notify, useSearch } from "@/app/utils";
import {
  AssignModuleBodyType,
  AssignModulesType,
  OpeningHoursBodySchema,
  OpeningHoursBodyType,
  UpdateOpeningHoursRequestType,
} from "@/pages/Private/pages/Modules/schema/modules.schema";
import { useGetDataSourcesQuery } from "@/pages/Private/redux/dataSources.api";
import { EventModuleType } from "@/pages/Private/pages/Modules/types";
import { useHasUserPermission } from "@/app/hooks/useHasUserPermission";
import { PermissionRoles } from "@/enum";
import { defaultWeeklyOpeningTimes } from "@/app/constants";

import { EventTypes } from "../../../../types";

type WeeklyOpeningTimes = typeof defaultWeeklyOpeningTimes;

/**
 * The Modules component is responsible for managing and displaying event modules, including their assignment,
 * configuration, and opening times. It utilizes various hooks for fetching data, managing state, and form handling.
 */
export const Modules: FunctionComponent = () => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.settings.${key}`);

  // State variables to manage selected module, data sources, and weekly opening times
  const [selectedModule, setSelectedModule] = useState<number>(0);
  const [selectedDataSources, setSelectedDataSources] = useState<number[]>([]);
  const [isDataSourcesDiff, setIsDataSourcesDiff] = useState<boolean>(false);
  const [moduleOpenedWeekDays, setModuleOpenedWeekDays] =
    useState<WeeklyOpeningTimes>(defaultWeeklyOpeningTimes);

  const { page, limit } = useSearch(50);
  const { page: pageDS, limit: limitDS, setPage: setPageDS } = useSearch();
  const { eventDetails: event, regions } = useAppSelector(eventsSelector);

  const isStationaryEvent = event?.eventType === EventTypes.STATIONARY;
  const editGeneralSettingsPermission = useHasUserPermission(PermissionRoles.UPDATE_EVENT_GENERAL);

  // Fetch modules and data sources.
  const { data: modules } = useGetModulesQuery({ page, limit });
  const { data: dataSources } = useGetDataSourcesQuery({ page: pageDS, limit: limitDS });
  // Update modules, module opening times.
  const [assignModule] = useAssignModuleMutation();
  const [updateOpeningTimes] = useUpdateOpeningTimesMutation();

  // Determine the module type based on event type
  const moduleTypeForCurrEventType = useMemo(() => {
    switch (event?.eventType) {
      case EventTypes.FESTIVAL:
      case EventTypes.PLOOM_FESTIVAL:
        return EventModuleType.FESTIVAL_MODULE;
      case EventTypes.STATIONARY:
        return EventModuleType.STATIONARY_TOUCHPOINT;
      case EventTypes.MOBILE:
        return EventModuleType.MOBILE_TEAM;
    }
  }, [event?.eventType]);

  const regionOptions = useMemo(() => {
    return regions?.map((region: string) => {
      return { text: region, value: region };
    });
  }, [regions]);

  const currEventModuleIDs = event?.moduleConfigs.map((m) => m.moduleId);
  const selectedModuleName = modules?.data.find((module) => module.id === selectedModule)?.name;
  const currEventModules = modules?.data.filter((module) => {
    return currEventModuleIDs?.includes(module.id);
  });

  // Filter and map module options based on the current event type and already assigned modules.
  const modulesOptions = useMemo(() => {
    return modules?.data
      .filter((module) => {
        return (
          !currEventModuleIDs?.includes(module.id) && moduleTypeForCurrEventType === module.type
        );
      })
      .map((m) => {
        return { text: m.name, value: m.id };
      });
  }, [modules?.data, currEventModuleIDs, moduleTypeForCurrEventType]);

  // Initial values for form fields
  const initialValues = useMemo(() => {
    const selectedModuleEventConfig = event?.moduleEventConfigs.find(
      (moduleConfig) => moduleConfig.module.id === selectedModule
    );

    return {
      eventId: event?.id ?? 0,
      module: 0,
      address: {
        street: selectedModuleEventConfig?.address?.street ?? "",
        houseNumber: selectedModuleEventConfig?.address?.houseNumber ?? "",
        city: selectedModuleEventConfig?.address?.city ?? "",
        zip: selectedModuleEventConfig?.address?.zip ?? "",
        nielsen: selectedModuleEventConfig?.address?.nielsen ?? "",
      },
      startDate: selectedModuleEventConfig?.startDate?.slice(0, 10) ?? "",
      endDate: selectedModuleEventConfig?.endDate?.slice(0, 10) ?? "",
      weeklyOpeningTimes:
        selectedModuleEventConfig?.weeklyOpeningTimes ?? defaultWeeklyOpeningTimes,
    };
  }, [selectedModule, event]);

  type InitialValues = typeof initialValues;

  // Initialize react-hook-form with default values
  const {
    register,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors, touchedFields, isValid, isDirty },
  } = useForm<OpeningHoursBodyType>({
    defaultValues: initialValues,
    mode: "onChange",
    resolver: zodResolver(OpeningHoursBodySchema),
  });

  const inputValues = getValues();

  //Boolean value showing if there is difference between the initial and the updated state of the Weekly Opening Times used to enable/disable updating "Save" button
  const openingTimesInitialStateChanged = selectedModule
    ? Object.entries(inputValues.weeklyOpeningTimes)
        .filter(([_, openingTimes]) => !openingTimes.isOpened)
        .map(([day]) => day)
        .some((day) => Object.keys(initialValues.weeklyOpeningTimes).includes(day))
    : false;

  // Compute the difference between assigned modules for the event
  const assignModulesDiff = event?.moduleConfigs.reduce(
    (assignedEventModules: AssignModulesType[], m) => {
      const currModuleId = m.moduleId;
      const currModuleDataSources: number[] = [];

      for (const m of event?.moduleConfigs) {
        if (currModuleId === m.moduleId && m.dataSourceId !== null) {
          currModuleDataSources.push(m.dataSourceId);
        }
      }

      if (!assignedEventModules.some((m) => m.moduleId === currModuleId)) {
        assignedEventModules.push({ moduleId: currModuleId, dataSourceIds: currModuleDataSources });
      }

      return assignedEventModules;
    },
    []
  );

  /** Comparing if there is difference between the initial and current data sources selected items array.
   * @param currModuleDataSources An array with IDs of data sources for the current event fetched from the DB
   * @param updatedModuleDataSources An array with IDs of currently selected data sources from the input form.
   * @returns Boolean flag showing if there is difference between the compared array of numbers.
   */
  const compareDataSourcesArrDiff = (
    currModuleDataSources: number[],
    updatedModuleDataSources: number[]
  ) => {
    if (currModuleDataSources.length !== updatedModuleDataSources.length) return true;

    const currDS = [...currModuleDataSources].sort((a, b) => a - b);
    const updatedDS = [...updatedModuleDataSources].sort((a, b) => a - b);

    for (let i = 0; i < currDS.length; i++) {
      if (currDS[i] !== updatedDS[i]) return true;
    }

    return false;
  };

  const handleModuleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedModule(+e.target.value);

    const currModuleDataSources =
      event?.moduleEventConfigs
        .find((eventModule) => eventModule.module.id === +e.target.value)
        ?.dataSources.map((dataSource) => dataSource.id) ?? [];

    setSelectedDataSources(currModuleDataSources);
  };

  const handleDataSourceSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const index = selectedDataSources.indexOf(+e.target.value);

    index === -1
      ? setSelectedDataSources([...selectedDataSources, +e.target.value])
      : setSelectedDataSources(
          selectedDataSources.filter((dataSource) => dataSource !== +e.target.value)
        );
  };

  const handleOpeningHoursSelect = (day: string, isChecked: boolean) => {
    setValue(`weeklyOpeningTimes.${day as keyof WeeklyOpeningTimes}`, {
      fromTime: "",
      tillTime: "",
      isOpened: isChecked,
    });

    setModuleOpenedWeekDays((prev) => ({
      ...prev,
      [day]: {
        ...prev[day as keyof WeeklyOpeningTimes],
        isOpened: isChecked,
      },
    }));
  };

  //Assign selected module to the current event
  const handleAddModuleSubmit = async (moduleId: number) => {
    const assignModuleBody: AssignModuleBodyType = { modules: assignModulesDiff || [] };
    const moduleToAssign: AssignModulesType = {
      moduleId: moduleId,
      dataSourceIds: [],
    };

    assignModuleBody.modules.push(moduleToAssign);

    if (moduleId) {
      await assignModule({ id: event?.id ?? 0, body: assignModuleBody });
    }
  };

  const handleModuleAssignSubmit = async (moduleId: number) => {
    const assignModuleBody: AssignModuleBodyType = { modules: assignModulesDiff || [] };
    const moduleToAssign: AssignModulesType = {
      moduleId: moduleId,
      dataSourceIds: selectedDataSources,
    };

    const currModuleDataSources =
      event?.moduleEventConfigs
        ?.find((mEC) => mEC.module.id === selectedModule)
        ?.dataSources.map((dS) => dS.id) ?? [];

    assignModuleBody.modules?.push(moduleToAssign);

    if (compareDataSourcesArrDiff(currModuleDataSources, selectedDataSources)) {
      await assignModule({ id: event?.id ?? 0, body: assignModuleBody });
    }

    return;
  };

  const handleOpeningHoursSubmit = async (
    values: InitialValues,
    initialValues: InitialValues
  ): Promise<UpdateOpeningHoursRequestType | void> => {
    if (!isValid && Object.keys(errors).length > 0) {
      notify({ text: "Some inputs are not valid", type: "error" });

      return;
    }

    const body: Partial<OpeningHoursBodyType> = {};

    for (const key in values) {
      if (Object.hasOwnProperty.call(initialValues, key)) {
        const initialValue = (initialValues as Record<string, unknown>)[key];
        const currentValue = values[key as keyof InitialValues];

        if (currentValue !== initialValue) {
          (body as Record<string, unknown>)[key] = currentValue;
        } else if (key === "startDate" || key === "endDate") {
          (body as Record<string, unknown>)[key] = currentValue;
        }
      }
    }

    if (Object.entries(body).length > 0) {
      try {
        if (
          body.weeklyOpeningTimes &&
          Object.values(body.weeklyOpeningTimes).some((day) => day.isOpened)
        ) {
          await updateOpeningTimes({
            id: selectedModule ?? 0,
            body: { ...body, eventId: event?.id },
          }).unwrap();

          notify({ text: ts("openingTimes.notifications.openingTimesEdited") });
        } else {
          notify({ text: ts("openingTimes.notifications.incompleteData"), type: "info" });
        }
      } catch (error) {
        notify({ text: ts("openingTimes.notifications.errorMessage"), type: "error" });
      }
    } else {
      return;
    }
  };

  const onSubmit = async () => {
    await handleModuleAssignSubmit(selectedModule);
    if (isStationaryEvent) await handleOpeningHoursSubmit(inputValues, initialValues);
  };

  // Checks if there is difference between the initial and the current selected data sources of a module to set state of a boolean flag to enable/disable "Save" button
  useEffect(() => {
    const currModuleDataSources =
      event?.moduleEventConfigs
        .find((eventModule) => eventModule.module.id === selectedModule)
        ?.dataSources.map((dataSource) => dataSource.id) ?? [];

    setIsDataSourcesDiff(compareDataSourcesArrDiff(currModuleDataSources, selectedDataSources));
  }, [event?.moduleEventConfigs, selectedModule, selectedDataSources]);

  // Reset form when a new module is selected
  useEffect(() => {
    setModuleOpenedWeekDays(defaultWeeklyOpeningTimes);
    if (selectedModule) {
      reset(initialValues);
      for (const day in initialValues.weeklyOpeningTimes) {
        setModuleOpenedWeekDays((prev) => ({
          ...prev,
          [day]: { ...initialValues.weeklyOpeningTimes[day as keyof WeeklyOpeningTimes] },
        }));
      }
    }
  }, [initialValues, reset, selectedModule]);

  return !event ? (
    <LoadingOverlay />
  ) : (
    <Box
      sx={{
        alignItems: "flex-start",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: { xs: "column", md: "row" },
        mt: 4,
      }}
    >
      <Box>
        <Box bgcolor="white" borderRadius={2} boxShadow={1} flexGrow={1} mb={2} p={2}>
          <Box borderBottom="1px solid" borderColor="gray400.main" mb={2} pb={2}>
            <Typography color="gray900.main" fontSize={18} fontWeight={600} mb={1.5}>
              {ts("modules.title")}
            </Typography>
            <Typography color="gray900.main" fontSize={14} fontWeight={400}>
              {ts("modules.description")}
            </Typography>
          </Box>
          <Box borderBottom="1px solid" borderColor="gray400.main" display="flex" mb={2} pb={2}>
            <Box>
              <Typography display="block" mb={2} variant="mainBold">
                {isStationaryEvent ? ts("modules.pickTouchpoint") : ts("modules.pickModule")}
              </Typography>
              <Box alignItems="center" display="flex" justifyContent="space-between">
                <Box sx={{ flexGrow: 1, mr: 2, width: { xs: 200, md: 312 } }}>
                  <SelectField
                    border
                    currentValue={watch("module") || "DEFAULT"}
                    hasError={touchedFields.module && !!errors.module}
                    options={modulesOptions || []}
                    {...register("module")}
                    smallSize
                    placeholder={ts("modules.selectModule")}
                  />
                </Box>
                <Typography
                  display="inline-block"
                  fontWeight={700}
                  variant="link"
                  onClick={() => handleAddModuleSubmit(watch("module") ?? 0)}
                >
                  {ts("modules.addModule")}
                </Typography>
              </Box>
            </Box>
          </Box>

          <FormControl fullWidth>
            <FormLabel focused={false} sx={{ marginBottom: 2 }}>
              {ts("modules.selectModule")}
            </FormLabel>
            {currEventModules?.length ? (
              <RadioGroup
                defaultValue="none"
                name="modules"
                value={selectedModule}
                onChange={handleModuleChange}
              >
                {currEventModules?.map((module) => {
                  const matchingModuleConfig = event?.moduleEventConfigs?.find(
                    (m) => m.module.id === module.id
                  );
                  const dataSourceLength = matchingModuleConfig?.dataSources?.length || 0;

                  return (
                    <FormControlLabel
                      key={`module-${module.id}`}
                      control={<Radio size="small" />}
                      label={
                        <Typography fontWeight={400} variant="main14">
                          {module.name}{" "}
                          {dataSourceLength > 0 && (
                            <Typography
                              color="main"
                              variant="mainBold"
                            >{`(${dataSourceLength})`}</Typography>
                          )}
                        </Typography>
                      }
                      sx={{ height: "17px", marginBottom: 1, width: "fit-content" }}
                      value={module.id}
                    />
                  );
                })}
              </RadioGroup>
            ) : (
              <Typography textAlign="center" variant="gray14">
                {ts("modules.noModuleSelected")}
              </Typography>
            )}
          </FormControl>
        </Box>
        <Box maxWidth={128}>
          <Button
            handleClick={onSubmit}
            isDisabled={
              isStationaryEvent
                ? isDataSourcesDiff
                  ? false
                  : isDirty
                  ? false
                  : !openingTimesInitialStateChanged
                : false
            }
            size={ButtonSize.M}
            title={t("basics.save")}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: { xs: "100%", md: "50%" },
          gap: 1,
        }}
      >
        {isStationaryEvent && (
          <>
            <Box
              bgcolor="white"
              borderRadius={2}
              boxShadow={1}
              display="flex"
              flexDirection="column"
              minHeight={selectedModule !== 0 ? "fit-content" : "100px"}
              p={2}
            >
              <Typography display="block" mb={2} variant="mainBold">
                {ts("general.eventLocation")}
              </Typography>
              {selectedModule !== 0 ? (
                <>
                  <Box display="flex" justifyContent="space-between" mb={1.5}>
                    <Box mr={3.75} sx={{ minWidth: { xs: "unset", xl: 400 } }} width="auto">
                      <InputField
                        border
                        disabled={!editGeneralSettingsPermission}
                        hasError={touchedFields.address?.street && !!errors.address?.street}
                        label={
                          <Typography display="block" variant="mainBold">
                            {ts("general.street")}
                            <Typography color="red.main" component="sup">
                              *
                            </Typography>
                          </Typography>
                        }
                        {...register("address.street")}
                        smallSize
                        placeholder={ts("general.street")}
                      />
                    </Box>
                    <Box sx={{ minWidth: { xs: "unset", xl: 400 } }} width="auto">
                      <InputField
                        border
                        disabled={!editGeneralSettingsPermission}
                        hasError={
                          touchedFields.address?.houseNumber && !!errors.address?.houseNumber
                        }
                        label={
                          <Typography display="block" variant="mainBold">
                            {ts("general.streetNumber")}
                            <Typography color="red.main" component="sup">
                              *
                            </Typography>
                          </Typography>
                        }
                        {...register("address.houseNumber")}
                        smallSize
                        placeholder={ts("general.streetNumber")}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mb={1.5}>
                    <Box mr={3.75} sx={{ minWidth: { xs: "unset", xl: 400 } }} width="auto">
                      <InputField
                        border
                        disabled={!editGeneralSettingsPermission}
                        hasError={touchedFields.address?.city && !!errors.address?.city}
                        label={
                          <Typography display="block" variant="mainBold">
                            {ts("general.city")}
                            <Typography color="red.main" component="sup">
                              *
                            </Typography>
                          </Typography>
                        }
                        {...register("address.city")}
                        smallSize
                        placeholder={ts("general.city")}
                      />
                    </Box>
                    <Box sx={{ minWidth: { xs: "unset", xl: 400 } }} width="auto">
                      <InputField
                        border
                        disabled={!editGeneralSettingsPermission}
                        hasError={touchedFields.address?.zip && !!errors.address?.zip}
                        label={
                          <Typography display="block" variant="mainBold">
                            {ts("general.postalCode")}
                            <Typography color="red.main" component="sup">
                              *
                            </Typography>
                          </Typography>
                        }
                        {...register("address.zip")}
                        smallSize
                        placeholder={ts("general.postalCode")}
                      />
                    </Box>
                  </Box>
                  <Box width="auto">
                    <SelectField
                      border
                      smallSize
                      currentValue={watch("address.nielsen") || "DEFAULT"}
                      disabled={!editGeneralSettingsPermission}
                      hasError={touchedFields.address?.nielsen && !!errors.address?.nielsen}
                      label={
                        <Typography display="block" variant="mainBold">
                          {ts("general.nielsen")}
                          <Typography color="red.main" component="sup">
                            *
                          </Typography>
                        </Typography>
                      }
                      options={regionOptions || []}
                      {...register("address.nielsen")}
                    />
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexGrow: 1,
                  }}
                >
                  <Typography textAlign="center" variant="gray14">
                    {ts("dataSources.noModuleSelected")}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              bgcolor="white"
              borderRadius={2}
              boxShadow={1}
              display="flex"
              flexDirection="column"
              minHeight={selectedModule !== 0 ? "fit-content" : "300px"}
              p={2}
            >
              <Box borderBottom="1px solid" borderColor="gray400.main" mb={1} pb={2}>
                <Typography color="gray900.main" fontSize={18} fontWeight={600} mb={1.5}>
                  {ts("openingTimes.title")}
                </Typography>
                <Typography color="gray900.main" fontSize={14} fontWeight={400}>
                  {ts("openingTimes.description")}
                </Typography>
              </Box>
              <Typography color="gray900.main" fontSize={18} fontWeight={600} mb={1.5}>
                {ts("openingTimes.campaignDates.title")}
              </Typography>
              {selectedModule !== 0 ? (
                <>
                  <Box
                    sx={{
                      alignItems: { xs: "start", md: "center" },
                      borderBottom: "1px solid",
                      borderColor: "gray400.main",
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      justifyContent: "space-around",
                      mb: 1,
                      pb: 2,
                    }}
                  >
                    <Box alignItems="center" display="flex">
                      <Typography
                        alignItems="baseline"
                        display="flex"
                        fontWeight={600}
                        mr={2}
                        variant="main12"
                      >
                        {ts("openingTimes.campaignDates.startDate")}
                        <Typography color="red.main" component="sup">
                          *
                        </Typography>
                      </Typography>
                      <Box>
                        <InputField
                          {...register("startDate")}
                          border
                          smallSize
                          disabled={!editGeneralSettingsPermission}
                          hasError={touchedFields.startDate && !!errors.startDate}
                          type="date"
                        />
                      </Box>
                    </Box>
                    <Box alignItems="center" display="flex">
                      <Typography
                        alignItems="baseline"
                        display="flex"
                        fontWeight={600}
                        mr={2}
                        variant="main12"
                      >
                        {ts("openingTimes.campaignDates.endDate")}
                        <Typography color="red.main" component="sup">
                          *
                        </Typography>
                      </Typography>
                      <Box>
                        <InputField
                          {...register("endDate")}
                          border
                          smallSize
                          disabled={!editGeneralSettingsPermission}
                          hasError={touchedFields.endDate && !!errors.endDate}
                          type="date"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Typography color="gray900.main" fontSize={18} fontWeight={600} mb={1}>
                      {ts("openingTimes.weekDays.title")}
                      <Typography color="red.main" component="sup">
                        *
                      </Typography>
                      <Typography color="gray900.main" fontSize={12} mb={1.5}>
                        {ts("openingTimes.weekDays.tooltip")}
                      </Typography>
                    </Typography>
                    <FormControl component="fieldset">
                      <FormGroup aria-label="position">
                        {Object.keys(defaultWeeklyOpeningTimes).map((day) => (
                          <Box
                            key={day}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    moduleOpenedWeekDays[day as keyof WeeklyOpeningTimes]?.isOpened
                                  }
                                  size="small"
                                  sx={{ p: 1 }}
                                  onChange={(e) => handleOpeningHoursSelect(day, e.target.checked)}
                                />
                              }
                              label={ts(`openingTimes.weekDays.${day}`)}
                              labelPlacement="end"
                              sx={{
                                margin: 0,
                                minWidth: 150,
                                ".MuiFormControlLabel-label": {
                                  fontSize: 12,
                                  color: "#333333",
                                  fontWeight: 600,
                                },
                              }}
                            />

                            {moduleOpenedWeekDays[day as keyof WeeklyOpeningTimes]?.isOpened ? (
                              <Box display="flex" justifyContent="space-between" minWidth={400}>
                                <Box alignItems="center" display="flex">
                                  <Typography
                                    fontWeight={600}
                                    style={{
                                      whiteSpace: "nowrap",
                                      marginInlineEnd: "8px",
                                    }}
                                    variant="main12"
                                  >
                                    {ts("openingTimes.weekDays.startHour")}
                                  </Typography>
                                  <InputField
                                    {...register(
                                      `weeklyOpeningTimes.${
                                        day as keyof WeeklyOpeningTimes
                                      }.fromTime`
                                    )}
                                    border
                                    fixedWidth
                                    smallSize
                                    disabled={!editGeneralSettingsPermission}
                                    type="time"
                                  />
                                </Box>
                                <Box alignItems="center" display="flex">
                                  <Typography
                                    fontWeight={600}
                                    style={{ whiteSpace: "nowrap", marginInlineEnd: "8px" }}
                                    variant="main12"
                                  >
                                    {ts("openingTimes.weekDays.endHour")}
                                  </Typography>

                                  <InputField
                                    {...register(
                                      `weeklyOpeningTimes.${
                                        day as keyof WeeklyOpeningTimes
                                      }.tillTime`
                                    )}
                                    border
                                    fixedWidth
                                    smallSize
                                    disabled={!editGeneralSettingsPermission}
                                    type="time"
                                  />
                                </Box>
                              </Box>
                            ) : (
                              <Typography fontWeight={600} variant="main12">
                                {ts("openingTimes.weekDays.closed")}
                              </Typography>
                            )}
                          </Box>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexGrow: 1,
                  }}
                >
                  <Typography textAlign="center" variant="gray14">
                    {ts("dataSources.noModuleSelected")}
                  </Typography>
                </Box>
              )}
            </Box>
          </>
        )}
        <Box
          bgcolor="white"
          borderRadius={2}
          boxShadow={1}
          display="flex"
          flexDirection="column"
          minHeight={selectedModule !== 0 ? "fit-content" : "300px"}
          p={2}
        >
          <Box borderBottom="1px solid" borderColor="gray400.main" mb={1} pb={2}>
            <Typography color="gray900.main" fontSize={18} fontWeight={600} mb={1.5}>
              {ts("dataSources.title")}
            </Typography>
            <Typography color="gray900.main" fontSize={14} fontWeight={400}>
              {ts("dataSources.description")}
            </Typography>
          </Box>
          <FormControl sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <FormLabel sx={{ marginBottom: 2 }}>
              {selectedModule !== 0
                ? t(`events.settings.dataSources.selectedDataSources`, {
                    selectedModuleName,
                  })
                : ts("dataSources.noModuleSelectedTitle")}
            </FormLabel>

            {selectedModule !== 0 ? (
              <>
                {dataSources?.data.map((dataSource) => (
                  <FormControlLabel
                    key={`dataSource-${dataSource.id}`}
                    control={
                      <Checkbox
                        checked={selectedDataSources.includes(dataSource.id)}
                        size="small"
                        onChange={handleDataSourceSelect}
                      />
                    }
                    label={
                      <Typography fontWeight={400} variant="main14">
                        {dataSource.name}
                      </Typography>
                    }
                    sx={{ height: "17px", marginBottom: "8px", width: "fit-content" }}
                    value={dataSource.id}
                  />
                ))}
                {dataSources?.meta?.pageCount && dataSources?.meta?.pageCount > 1 && (
                  <Box>
                    <Pagination
                      count={dataSources?.meta?.pageCount}
                      page={pageDS}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      onChange={(_: unknown, page: number) => setPageDS(page)}
                    />
                  </Box>
                )}
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexGrow: 1,
                }}
              >
                <Typography textAlign="center" variant="gray14">
                  {ts("dataSources.noModuleSelected")}
                </Typography>
              </Box>
            )}
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};
