import { FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { DonutChart, Loader } from "@/components";
import { convertChartNumericData, toPercent } from "@/app/utils";
import { durationsArray, emotionsArray } from "@/app/constants";
import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";
import { Features } from "@/pages/Private/pages/Companies/enums";

import { EventDataType, KpiType } from "../../../../event.schema";
import { Duration, Emotions, KpiTypeEnum } from "../../../../types";

interface DonutsProps {
  kpis: KpiType[];
  isFiltration?: boolean;
  isLoading?: boolean;
}

export const Donuts: FunctionComponent<DonutsProps> = ({ kpis, isFiltration, isLoading }) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.${key}`);

  const convertData = useCallback(
    (data: EventDataType[], arr: Emotions[] | Duration[]) => {
      const values = convertChartNumericData({ enumValues: arr, data, isFiltration });

      const total = values.reduce((a, b) => a + b, 0);
      const percents = values.map((v) => Math.round(toPercent({ number: v, total })));

      return percents;
    },
    [isFiltration]
  );

  const isEmotionsChartActive = useIsFeatureActive(Features.TILE_EMOTIONS);
  const isResidenceTimeChartActive = useIsFeatureActive(Features.TILE_RESIDENCE_TIME);

  const durationValues = useMemo(() => {
    const duration = kpis.find((k) => k.name === KpiTypeEnum.LIVIA_DURATION);

    return convertData(duration?.data || [], durationsArray);
  }, [kpis, convertData]);

  const emotionsValues = useMemo(() => {
    const emotions = kpis.find((k) => k.name === KpiTypeEnum.EMOTIONS);

    return convertData(emotions?.data || [], emotionsArray);
  }, [kpis, convertData]);

  return (
    <Box
      alignSelf="self-start"
      bgcolor="white"
      borderRadius={2}
      boxShadow={1}
      p={3}
      sx={{
        width: { xs: "100%", md: "23%" },
        mr: { xs: 0, md: "30px" },
      }}
    >
      {isEmotionsChartActive && (
        <Box
          sx={
            isResidenceTimeChartActive
              ? {
                  borderBottom: "1px solid",
                  borderColor: "gray400.main",
                  mb: "3",
                  pb: "2",
                }
              : {}
          }
        >
          <Typography component="p" mb={1} variant="mainBold">
            {ts("emotions")}
          </Typography>
          {isLoading ? (
            <Loader />
          ) : (
            <DonutChart
              kpi={ts("emotions")}
              labels={[ts("positive"), ts("neutral"), ts("negative")]}
              series={emotionsValues}
            />
          )}
        </Box>
      )}
      {isResidenceTimeChartActive && (
        <Box>
          <Typography component="p" mb={1} variant="mainBold">
            {ts("residenceTime")}
          </Typography>
          {isLoading ? (
            <Loader />
          ) : (
            <DonutChart
              kpi={ts("residenceTime")}
              labels={[">20 min", "5-20 min", "1-5 min"]}
              series={durationValues}
            />
          )}
        </Box>
      )}
    </Box>
  );
};
