import { Role, UserStatus } from "@/enum";

import { TLoginResponse, User } from "../pages/Login/schema/login";

export const userMock: User = {
  id: 1,
  email: "mock@browserbite.io",
  firstName: "John",
  lastName: "Doe",
  isBlocked: false,
  status: UserStatus.ACTIVE,
  roles: [
    {
      id: 1,
      name: "SUPERADMIN",
      code: Role.COMPANY_ADMIN,
      description: "Test",
      permissions: [],
    },
  ],
  groups: [{ id: 1, name: "Test group", companyId: 1 }],
  createdAt: "1970-01-01T00:00:00Z",
  updatedAt: "1970-01-01T00:00:00Z",
};

export const loginResponseMock: TLoginResponse = {
  access_token: "abcd1234",
  user: userMock,
};
