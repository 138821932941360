import { api } from "@/app/redux/api";

import { UpdateCompanyBody } from "./../pages/Companies/types";
import {
  CompaniesResponse,
  CompaniesResponseSchema,
  CompanyType,
  CreateCompanyResponseSchema,
  CompanyRequestBodySchema,
  CompanyRequestBodyType,
  CompanySchema,
  DeleteCompany,
  DeleteCompanySchema,
} from "../pages/Companies/schema/companies.schema";

import { RequestPaginationParams } from "../../../types/request-params";

export const companiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query<CompaniesResponse, RequestPaginationParams>({
      query: (params) => ({
        url: "companies",
        params: {
          ...(params && { ...params }),
        },
        responseSchema: CompaniesResponseSchema,
      }),
      providesTags: ["Companies"],
    }),
    createCompany: builder.mutation<CompanyType, CompanyRequestBodyType>({
      query: (body) => ({
        url: "companies",
        method: "POST",
        body,
        requestSchema: CompanyRequestBodySchema,
        responseSchema: CreateCompanyResponseSchema,
      }),
      invalidatesTags: ["Companies"],
    }),
    getCompanyById: builder.query<CompanyType, number>({
      query: (id) => ({
        url: `companies/${id}`,
        responseSchema: CompanySchema,
      }),
      providesTags: [{ type: "Companies", id: "DETAIL" }],
    }),
    updateCompany: builder.mutation<CompanyType, UpdateCompanyBody>({
      query: ({ body, id }) => ({
        url: `companies/${id}`,
        method: "PATCH",
        body,
        responseSchema: CompaniesResponseSchema,
      }),
      invalidatesTags: ["Companies"],
    }),
    deleteCompany: builder.mutation<DeleteCompany, number>({
      query: (id) => ({
        url: `companies/${id}`,
        method: "DELETE",
        responseSchema: DeleteCompanySchema,
      }),
      invalidatesTags: ["Companies"],
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useCreateCompanyMutation,
  useGetCompanyByIdQuery,
  useDeleteCompanyMutation,
  useUpdateCompanyMutation,
} = companiesApi;
export const companiesApiReducer = companiesApi.reducer;
export const companiesApiMiddleware = companiesApi.middleware;
