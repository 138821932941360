import { FunctionComponent } from "react";
import { Box, CircularProgress } from "@mui/material";

export const LoadingOverlay: FunctionComponent = () => (
  <Box
    alignItems="center"
    display="flex"
    justifyContent="center"
    sx={{
      position: "fixed",
      inset: 0,
      zIndex: 9999,
    }}
  >
    <CircularProgress />
  </Box>
);
