import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CircularProgress, DialogActions, DialogContent, Box, Typography } from "@mui/material";

import { ServerError } from "@/types/error";
import { ERROR_TYPE, translateError, notify } from "@/app/utils";
import { Button, InputField, Modal, SelectField } from "@/components";
import { useInviteUserMutation } from "@/pages/Private/redux";
import { useRoleOptions } from "@/app/hooks/useRoleOptions";
import { useCompanyOptions } from "@/app/hooks/useCompanyOptions";
import { Features } from "@/pages/Private/pages/Companies/enums";
import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";
import { PermissionRoles } from "@/enum";
import { useHasUserPermission } from "@/app/hooks/useHasUserPermission";

import { InviteRequestSchema, InviteType } from "../../../schema/user";

interface InviteUserModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const InviteUserModal: FunctionComponent<InviteUserModalProps> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`users.inviteUser.modal.${key}`);

  const { companyOptions } = useCompanyOptions();
  const { roles, roleOptions } = useRoleOptions();
  const [invite, { isLoading }] = useInviteUserMutation();

  const isMultiTenancyActive = useIsFeatureActive(Features.MULTI_TENANCY);
  const isPermittedToGetCompanies = useHasUserPermission(PermissionRoles.GET_COMPANY);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    watch,
  } = useForm<InviteType>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      roles: [],
      companyId: 0,
    },
    resolver: zodResolver(InviteRequestSchema),
  });

  const onSubmit = async (values: InviteType) => {
    try {
      await invite(values).unwrap();
      setIsOpen(false);
      reset();
      notify({ text: t("notifications.invitedUser") });
    } catch (err) {
      notify({
        text: translateError[(err as ServerError).data.message as ERROR_TYPE],
        type: "error",
      });
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    reset();
  };

  return (
    <Modal setShow={handleClose} show={isOpen} title={ts("title")}>
      <DialogContent
        dividers
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          border: "none",
          padding: 1.5,
          paddingTop: 0,
        }}
      >
        <Typography component="p" mb={0.5} variant="main14">
          {ts("description")}
        </Typography>
        {!roles ? (
          <Box
            sx={{
              padding: "5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box mb={1} width="100%">
              <InputField
                hasError={errors.firstName && !!errors.firstName}
                {...register("firstName")}
                placeholder={ts("firstNamePlaceholder")}
              />
            </Box>
            <Box mb={1} width="100%">
              <InputField
                hasError={errors.lastName && !!errors.lastName}
                {...register("lastName")}
                placeholder={ts("lastNamePlaceholder")}
              />
            </Box>
            <Box mb={1} width="100%">
              <InputField
                hasError={errors.email && !!errors.email}
                {...register("email")}
                placeholder={ts("emailPlaceholder")}
              />
            </Box>

            <SelectField
              currentValue={watch("roles.0") || "DEFAULT"}
              hasError={!!errors.roles?.message}
              options={roleOptions || []}
              placeholder={ts("rolePlaceholder")}
              {...register("roles.0")}
            />
            {isMultiTenancyActive && isPermittedToGetCompanies && (
              <SelectField
                currentValue={watch("companyId") || "DEFAULT"}
                hasError={!!errors.companyId?.message}
                options={companyOptions || []}
                placeholder={ts("companyPlaceholder")}
                {...register("companyId")}
              />
            )}
          </Box>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          padding: 1.5,
        }}
      >
        <Button
          handleClick={handleSubmit(onSubmit)}
          isDisabled={!isValid}
          isLoading={isLoading}
          title={ts("cta")}
          type="submit"
        />
        <Button handleClick={handleClose} title={t("basics.cancel")} variant="outlined" />
      </DialogActions>
    </Modal>
  );
};
