import { useTranslation } from "react-i18next";
import { FunctionComponent, useMemo } from "react";
import { Box, Typography } from "@mui/material";

import { DonutChart, Loader } from "@/components";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";

interface SalesSplitByBrandProps {
  isFiltration?: boolean;
  isFetching: boolean;
  salesKPIs: KpiType[];
}

export const SalesByBrandDonutChart: FunctionComponent<SalesSplitByBrandProps> = ({
  isFiltration,
  isFetching,
  salesKPIs,
}) => {
  const { t } = useTranslation();

  const salesByBrandLabels = useMemo(() => {
    const salesByBrandKPIs =
      salesKPIs.find((kpi) => kpi.name === KpiTypeEnum.SALES_BY_BRAND)?.data ?? [];

    return salesByBrandKPIs.map((kpi) => kpi.specific);
  }, [salesKPIs]);

  const salesByBrandData = useMemo(() => {
    const salesByBrandKPIs =
      salesKPIs.find((kpi) => kpi.name === KpiTypeEnum.SALES_BY_BRAND)?.data ?? [];

    return salesByBrandLabels.map((type) => {
      const kpiData = salesByBrandKPIs.find((kpi) => kpi.specific === type);

      if (kpiData) {
        if (isFiltration) {
          return Math.round(kpiData.date?.[0]?.percent ?? 0);
        } else {
          return Math.round(kpiData.overall?.percent ?? 0);
        }
      } else {
        return 0;
      }
    });
  }, [salesKPIs, isFiltration, salesByBrandLabels]);

  return (
    <Box
      sx={{
        borderStyle: "solid",
        borderWidth: { xs: "0 0 1px 0", md: "0 1px 0 0" },
        borderColor: "gray400.main",
        paddingRight: { xs: 0, md: 1 },
        paddingBottom: { xs: 1, md: 0 },
      }}
    >
      <Typography component="p" mb={1} variant="mainBold">
        {t("events.donutSalesByBrand")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : (
        <DonutChart
          kpi={t("events.donutSalesByBrand")}
          labels={salesByBrandLabels}
          series={salesByBrandData}
        />
      )}
    </Box>
  );
};
