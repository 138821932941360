import { forwardRef, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControl, ListItemText, MenuItem, Select, Typography } from "@mui/material";
import { UseFormRegisterReturn } from "react-hook-form";

import { eventTypesArray } from "@/app/constants";
import { useAppSelector } from "@/app/redux/hooks";
import { sidebarSelector } from "@/pages/Private";
import { ActiveBarEnum } from "@/types";

type SelectFieldProps = {
  options: { text: string; value: string | number }[];
  currentValue: (string | number)[];
  placeholder?: string;
  border?: boolean;
  multiple?: boolean;
  smallSize?: boolean;
  withDefaultValue?: boolean;
  icon?: JSX.Element;
  onClose?: (event: SyntheticEvent<Element, Event>) => void;
} & UseFormRegisterReturn;

export const MultipleSelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  function CustomSelect(
    {
      options,
      currentValue,
      placeholder,
      border,
      multiple,
      smallSize,
      withDefaultValue,
      icon,
      onClose,
      ...rest
    },
    ref
  ) {
    const { t } = useTranslation();
    const { activeBar } = useAppSelector(sidebarSelector);

    return (
      <FormControl fullWidth variant="filled">
        <Select
          multiple
          {...rest}
          ref={ref}
          disableUnderline
          displayEmpty
          MenuProps={{
            sx: {
              "& .MuiMenu-paper": { boxShadow: 1 },
              "& .MuiMenu-list": {
                padding: 0,
                maxHeight: 300,
              },
            },
          }}
          inputProps={{ ...rest }}
          renderValue={(selected) => {
            if (!selected.length) {
              return <Typography fontSize={14}>{placeholder}</Typography>;
            } else if (typeof selected[0] === "number") {
              return selected
                .map((val) => {
                  return options.filter((opt) => opt.value === val).map((el) => el.text);
                })
                .join(", ");
            } else if (typeof selected.at(0) === "string") {
              return eventTypesArray
                .filter((type) => selected.includes(type.id))
                .map((type) => type.value)
                .join(", ");
            }
          }}
          sx={{
            height: smallSize ? 28 : 44,
            "& .MuiSelect-select": {
              textAlign: "left",
              fontSize: 14,
              color: !currentValue && !withDefaultValue ? "gray600.main" : "gray900.main",
              bgcolor: "white",
              boxShadow: border ? 0 : 1,
              borderRadius: 1,
              ...(border && { border: "1px solid", borderColor: "#E2E2E2" }),
              ...(smallSize ? { height: 18, p: "3px 12px;" } : { height: 20, p: 1.5 }),
            },
            "& .MuiSelect-select.MuiSelect-filled.MuiInputBase-input.MuiFilledInput-input": {
              ...(smallSize && { height: "20px", p: "3px 12px" }),
            },
          }}
          value={currentValue.at(-1) !== "DEFAULT" ? currentValue : []}
          onClose={onClose}
        >
          {activeBar !== ActiveBarEnum.EVENTS_FILTER && (
            <MenuItem selected disabled={!currentValue.length} value={"DEFAULT"}>
              {placeholder ?? t("basics.defaultSelectPlaceholder")}
            </MenuItem>
          )}
          {options.map((option, i) => (
            <MenuItem key={i} value={option.value}>
              <Checkbox
                checked={
                  currentValue.at(-1) !== "DEFAULT" ? currentValue.includes(option.value) : false
                }
              />
              <ListItemText primary={option.text} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);
