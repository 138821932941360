import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { DonutChart, Loader } from "@/components";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";

interface GiveawaysByModuleDonutChartProps {
  isFetching: boolean;
  isFiltration?: boolean;
  kpis: KpiType[];
}

export const GiveawaysByModuleDonutChart: FunctionComponent<GiveawaysByModuleDonutChartProps> = ({
  isFetching,
  isFiltration,
  kpis,
}) => {
  const { t } = useTranslation();

  const modulesByTypeLabels = useMemo(() => {
    return (kpis.find((kpi) => kpi.name === KpiTypeEnum.GIVEAWAY_BY_MODULE)?.data ?? []).map(
      (d) => d.specific
    );
  }, [kpis]);

  const modulesByTypeData = useMemo(() => {
    const giveawayKPIs =
      kpis.find((kpi) => kpi.name === KpiTypeEnum.GIVEAWAY_BY_MODULE)?.data ?? [];

    return modulesByTypeLabels.map((type) => {
      const recordData = giveawayKPIs?.find((kpi) => kpi.specific === type);

      return Math.round(recordData?.date?.[0]?.percent ?? 0);
    });
  }, [kpis, modulesByTypeLabels]);

  return (
    <Box>
      <Typography component="p" mb={1} variant="mainBold">
        {t("events.donutGiveawaysByModule")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : (
        <DonutChart
          kpi={t("events.donutGiveawaysByModule")}
          labels={modulesByTypeLabels}
          series={modulesByTypeData}
        />
      )}
    </Box>
  );
};
