import { Box, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";

import { useAppSelector } from "@/app/redux/hooks";
import { authSelector } from "@/pages/Public/redux/auth.slice";
import { eventsSelector } from "@/pages/Private/redux";
import { Role } from "@/enum";

import { routes } from "./config";

const navItemStyles = {
  textAlign: "center",
  fontWeight: 700,
  fontSize: "14px",
  width: "max-content",
};

const MenuItem: FunctionComponent<{
  text: ReactNode;
  path: string;
  isActive?: boolean;
  dashboardFeature: string;
  isVisibleForEventType?: boolean;
}> = ({ text, path, isActive, dashboardFeature, isVisibleForEventType }) => {
  const isDashboardActive = useIsFeatureActive(dashboardFeature);

  return (isDashboardActive || dashboardFeature === "default") && isVisibleForEventType ? (
    <ListItem key={text?.toString()} disablePadding>
      <ListItemButton
        component={Link}
        sx={{
          color: isActive ? "main" : "gray600.main",
          paddingX: { xs: 1, md: 2 },
          ...navItemStyles,
        }}
        to={path}
      >
        <ListItemText disableTypography primary={text} />
      </ListItemButton>
    </ListItem>
  ) : (
    <></>
  );
};
export const RibbonMenu: FunctionComponent<{ isProjectReporting?: boolean }> = ({
  isProjectReporting,
}) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    routes.userEventDetailsDashboards.at(0)?.path
  );

  const { dashboard } = useParams();
  const { user } = useAppSelector(authSelector);
  const { eventDetails } = useAppSelector(eventsSelector);
  const [userPermittedToSeePromoters, setUserPermittedToSeePromoters] = useState(false);
  const isUserBsureAdmin = user?.roles.some((role) => role.code === Role.BSURE_ADMIN);

  useEffect(() => {
    const groupIds = user?.groups.map((group) => group.id) || [];
    const isGroupOfUserAndEventSame = groupIds.includes(eventDetails?.groupId || 0);
    const isUserCompanyAdmin = user
      ? user.roles.some((role) => role.code === Role.COMPANY_ADMIN)
      : false;

    setUserPermittedToSeePromoters(isGroupOfUserAndEventSame && isUserCompanyAdmin);
  }, [user, eventDetails]);

  useEffect(() => {
    if (dashboard) {
      setSelectedMenuItem(dashboard);
    }
  }, [dashboard]);

  return (
    <Box display="flex">
      {routes.userEventDetailsDashboards.map((route, i) => (
        <MenuItem
          key={`event-dashboard-${i}`}
          dashboardFeature={route.dashboardFeature}
          isActive={route.path === selectedMenuItem}
          isVisibleForEventType={
            eventDetails?.eventType
              ? route.visibleForEventTypes.includes(eventDetails.eventType)
              : isProjectReporting
          }
          path={route.path}
          text={route.text}
        />
      ))}
      {(userPermittedToSeePromoters || isUserBsureAdmin) &&
        routes.adminEventDetailsDashboards.map((route, i) => (
          <MenuItem
            key={`event-dashboard-${i}`}
            dashboardFeature={route.dashboardFeature}
            isActive={route.path === selectedMenuItem}
            isVisibleForEventType={
              eventDetails?.eventType
                ? route.visibleForEventTypes.includes(eventDetails.eventType)
                : isProjectReporting
            }
            path={route.path}
            text={route.text}
          />
        ))}
    </Box>
  );
};
