import { FunctionComponent, useMemo } from "react";

import { formatNumbers } from "@/app/utils";

import { KpiTypeEnum, TopTenTableData } from "../../../../types";
import { KpiType } from "../../../../event.schema";
import { TopTenTable } from "../../SalesDashboard/components/TopTenTable";

interface ActivationsTopTenTableProps {
  isFetching: boolean;
  activationsKPIs: KpiType[];
  isFiltration?: boolean;
}

export const ActivationsTopTenTable: FunctionComponent<ActivationsTopTenTableProps> = ({
  isFetching,
  activationsKPIs,
  isFiltration,
}) => {
  const sortedActivationsKPI = useMemo(() => {
    const activationsKPI = activationsKPIs.find((k) => k.name === KpiTypeEnum.ACTIVATIONS)?.data;

    const labels: TopTenTableData[] = [];

    if (!activationsKPI) {
      return labels;
    }

    if (isFiltration && !activationsKPI.at(0)?.date) {
      return labels;
    }

    for (const activationType of activationsKPI) {
      const dates = isFiltration ? activationType.date : [activationType.overall];

      const item = {
        // Transform into quantity format
        name: activationType.specific ? activationType.specific : "",
        quantity: dates?.[0]?.value ? formatNumbers(dates?.[0]?.value.toFixed(0)) : 0,
        percentage: dates?.[0]?.percent ? dates?.[0]?.percent : 0,
      };

      if (item.name) {
        labels.push(item);
      }
    }

    labels.sort((a, b) => {
      if (a.percentage < b.percentage) {
        return 1;
      }

      if (a.percentage > b.percentage) {
        return -1;
      }

      return 0;
    });

    return labels;
  }, [activationsKPIs, isFiltration]);

  return (
    <TopTenTable
      dataToPass={sortedActivationsKPI}
      isFetching={isFetching}
      namingPath="activations"
    />
  );
};
