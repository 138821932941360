import { FunctionComponent } from "react";
import { default as Chart } from "react-apexcharts";

interface BarChartProps {
  name: string;
  values: number[];
  categories: string[];
  maxValue: number;
  color?: string;
  reversed?: boolean;
}

export const BarChart: FunctionComponent<BarChartProps> = ({
  name,
  values,
  categories,
  maxValue,
  color,
  reversed,
}) => {
  return (
    <Chart
      height={330}
      options={{
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
          redrawOnParentResize: true,
        },
        legend: {
          show: true,
        },
        colors: [color],
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 6,
            borderRadiusApplication: "end",
            barHeight: "40%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: true,
          borderColor: "#919EAB3D",
          strokeDashArray: 6,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        xaxis: {
          type: "category",
          tickAmount: 4,
          categories,
          max: maxValue,
          labels: {
            show: true,
            hideOverlappingLabels: true,
            style: {
              colors: "#919EAB",
              fontSize: "10px",
              cssClass: "margin-top: 10px;",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          reversed,
          labels: { show: false },
        },
      }}
      series={[
        {
          name,
          data: values,
        },
      ]}
      type="bar"
      width="100%"
    />
  );
};
