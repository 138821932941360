import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { formatNumbers } from "@/app/utils";
import { Loader, NoDataFound } from "@/components";

import { KpiTypeEnum, SalesPloomProductTypes } from "../../../../types";
import { KpiType } from "../../../../event.schema";

interface SalesTotalAndRealEvoPackagesProps {
  isFetching: boolean;
  ploomSalesKPIs: KpiType[];
}

export const SalesTotalAndRealEvoPackages: FunctionComponent<SalesTotalAndRealEvoPackagesProps> = ({
  isFetching,
  ploomSalesKPIs,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.salesKPIs.totalEvoPacksTile.${key}`);

  const evoPacksKpiData = ploomSalesKPIs
    .find((kpi) => kpi?.name === KpiTypeEnum.SALES_BY_PRODUCT_TYPE_BY_CATEGORY)
    ?.data.find((type) => type.specific === SalesPloomProductTypes.CIGARETTES);

  const evoPackages =
    evoPacksKpiData?.overall?.value?.toFixed(0) ||
    evoPacksKpiData?.date?.at(0)?.value?.toFixed(0) ||
    "0";

  const realEvoPackagesKpiData = ploomSalesKPIs
    .find((kpi) => kpi?.name === KpiTypeEnum.SALES_REAL_EVO_PACKAGES)
    ?.data?.at(0);

  const realEvoPackages =
    realEvoPackagesKpiData?.overall?.value || realEvoPackagesKpiData?.date?.at(0)?.value || "0";

  return (
    <Box
      bgcolor="white"
      borderRadius={2}
      boxShadow={1}
      px={1.5}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: { xs: 160, md: 140 },
        mb: { xs: 2, md: 0 },
        py: 1,
        width: "256px",
      }}
    >
      <Typography alignItems="center" display="flex" variant="gray14Bold">
        {ts("title")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : ploomSalesKPIs.length > 0 ? (
        <>
          <Box display="flex" flexDirection="column">
            <Typography variant="main42bold">{formatNumbers(evoPackages)}</Typography>
            <Typography variant="gray12">{ts("evoPackages")}</Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="main14">{formatNumbers(realEvoPackages)}</Typography>
            <Typography variant="gray12">{ts("real")}</Typography>
          </Box>
        </>
      ) : (
        <NoDataFound kpi={ts("evoPackages").toLocaleLowerCase()} />
      )}
    </Box>
  );
};
