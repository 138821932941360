import { Features } from "@/pages/Private/pages/Companies/enums";
import {
  Ages,
  Duration,
  Emotions,
  EventTypes,
  KpiTypeEnum,
} from "@/pages/Private/pages/Events/types";
import { EventModuleType } from "@/pages/Private/pages/Modules/types";

export const agesArray: Ages[] = [
  Ages.RANGE_18_19,
  Ages.RANGE_20_29,
  Ages.RANGE_30_39,
  Ages.RANGE_40_49,
  Ages.RANGE_50_59,
  Ages.RANGE_60,
];

export const emotionsArray: Emotions[] = [Emotions.POSITIVE, Emotions.NEUTRAL, Emotions.NEGATIVE];
export const eventTypesArray: { id: string; value: string }[] = [
  { id: EventTypes.FESTIVAL, value: "Festival" },
  { id: EventTypes.PLOOM_FESTIVAL, value: "Ploom Festival" },
  { id: EventTypes.MOBILE, value: "Mobile" },
  { id: EventTypes.STATIONARY, value: "Stationary" },
];

export const moduleTypesArray: { id: string; value: string }[] = [
  { id: EventModuleType.FESTIVAL_MODULE, value: "Festival Module" },
  { id: EventModuleType.STATIONARY_TOUCHPOINT, value: "Stationary Touchpoint" },
  // { id: EventModuleType.MOBILE_LOCATION, value: "Mobile Location" },
  // { id: EventModuleType.MOBILE_TEAM, value: "Mobile Team" },
];
export const durationsArray: Duration[] = [
  Duration.LIVIA_DURATION_20,
  Duration.LIVIA_DURATION_5_20,
  Duration.LIVIA_DURATION_1_5,
];
export const ageRanges = ["18-19", "20-29", "30-39", "40-49", "50-59", "60+"];

export const festivalKpiTiles: {
  name: KpiTypeEnum;
  showOverall: boolean;
  featureFlagName: Features;
}[] = [
  {
    name: KpiTypeEnum.NEWSLETTER_DATA,
    showOverall: true,
    featureFlagName: Features.KPI_TILE_DIO_NL,
  },
  { name: KpiTypeEnum.SALES, showOverall: false, featureFlagName: Features.KPI_TILE_SALES },
  {
    name: KpiTypeEnum.ACTIVATIONS,
    showOverall: false,
    featureFlagName: Features.KPI_TILE_ACTIVATIONS,
  },
  { name: KpiTypeEnum.SAMPLES, showOverall: false, featureFlagName: Features.KPI_TILE_SAMPLES },
  { name: KpiTypeEnum.GIVEAWAY, showOverall: false, featureFlagName: Features.KPI_TILE_GIVEAWAYS },
];

export const ploomFestivalKpiTiles: {
  name: KpiTypeEnum;
  showOverall: boolean;
}[] = [
  {
    name: KpiTypeEnum.NEWSLETTER_DATA,
    showOverall: true,
  },
  {
    name: KpiTypeEnum.SALES_BY_PRODUCT_BY_HEATERDEVICE,
    showOverall: false,
  },
  {
    name: KpiTypeEnum.SALES_BY_PRODUCT_BY_CIGARETTESTYPE_ECSCATEGORY,
    showOverall: false,
  },
  {
    name: KpiTypeEnum.ACTIVATIONS,
    showOverall: false,
  },
  { name: KpiTypeEnum.SAMPLES, showOverall: false },
  { name: KpiTypeEnum.GIVEAWAY, showOverall: false },
];

export const nonFestivalKpiTiles: {
  name: KpiTypeEnum;
  showOverall: boolean;
}[] = [
  {
    name: KpiTypeEnum.NEWSLETTER_DATA,
    showOverall: true,
  },
  {
    name: KpiTypeEnum.SALES_BY_PRODUCT_BY_HEATERDEVICE,
    showOverall: false,
  },
  {
    name: KpiTypeEnum.SALES_BY_PRODUCT_BY_CIGARETTESTYPE_ECSCATEGORY,
    showOverall: false,
  },
  { name: KpiTypeEnum.SAMPLES, showOverall: false },
];
