import { useTranslation } from "react-i18next";
import { FunctionComponent, useState } from "react";
import { EditOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";

import { LoadingOverlay, TableColumn, TableComponent } from "@/components";
import { MetaData } from "@/schemas/meta-data";

import { ProjectType } from "../../ProjectReporting/project.schema";
import { CreateOrEditProjectModal } from "./CreateOrEditProjectModal";

interface ProjectsTableProps {
  projects: ProjectType[];
  page: number;
  handleFetchPage: (page: number) => void;
  meta?: MetaData;
  isLoading?: boolean;
}

export const ProjectsTable: FunctionComponent<ProjectsTableProps> = ({
  projects,
  page,
  handleFetchPage,
  meta,
  isLoading,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`projects.${key}`);

  const [selectedProject, setSelectedProject] = useState<ProjectType | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleEditProject = (row: ProjectType) => {
    setShowEditModal(true);
    setSelectedProject(row);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedProject(null);
  };

  const columns: TableColumn<ProjectType>[] = [
    {
      title: ts("project"),
      render: (project) => <Typography variant="main14">{project?.name}</Typography>,
    },
    {
      title: ts("actions"),
      render: (row) => (
        <>
          {/* <DeleteOutline
            sx={{
              color: "gray600.main",
              marginRight: 1,
              "&:hover": {
                opacity: 0.5,
                cursor: "pointer",
              },
            }}
            // onClick={() => handleDeleteModal(row)}
          /> */}
          <EditOutlined
            sx={{
              color: "gray600.main",
              "&:hover": {
                opacity: 0.5,
                cursor: "pointer",
              },
            }}
            onClick={() => handleEditProject(row)}
          />
        </>
      ),
      width: 60,
    },
  ];

  return !projects ? (
    <LoadingOverlay />
  ) : (
    <>
      <TableComponent
        columns={columns}
        data={projects}
        handleFetchPage={handleFetchPage}
        isLoading={isLoading}
        page={page}
        totalPages={meta?.pageCount ?? 1}
      />

      {showEditModal && (
        <CreateOrEditProjectModal
          handleClose={handleCloseEditModal}
          isEditing={showEditModal}
          isOpen={showEditModal}
          project={selectedProject}
          setIsOpen={setShowEditModal}
        />
      )}
    </>
  );
};
