import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { ERROR_TYPE, renderErrorMessages } from "@/app/utils";
import { Button, PasswordInput } from "@/components";
import { TokenPurpose } from "@/enum";
import { ROUTE_CONFIG } from "@/app/routes/config";

import { useSetPasswordMutation } from "../../redux/auth.api";
import { SetPasswordSchema, SetPasswordType } from "../SetPassword/set-password.schema";

export const ResetPassword = () => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`resetPassword.${key}`);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [setPassword, { isLoading }] = useSetPasswordMutation();

  const token = searchParams.get("token") || "";

  const {
    handleSubmit,
    formState: { errors, touchedFields },
    register,
  } = useForm<SetPasswordType>({
    mode: "all",
    resolver: zodResolver(SetPasswordSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const [serverError, setServerError] = useState<ERROR_TYPE | null>(null);

  const handlePasswordSubmit = handleSubmit(async (values) => {
    try {
      await setPassword({
        token,
        purpose: TokenPurpose.RESET_PASSWORD,
        password: values.password,
        confirmPassword: values.confirmPassword,
      }).unwrap();
      navigate(ROUTE_CONFIG.LOGIN);
    } catch (err) {
      if (
        (err as Record<string, Record<string, string>>).data.message.includes(
          ERROR_TYPE.INVALID_TOKEN
        )
      ) {
        setServerError(ERROR_TYPE.INVALID_TOKEN);
      }
    }
  });

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
      onSubmit={handlePasswordSubmit}
    >
      <Typography mb={2} variant="h1">
        {ts("title")}
      </Typography>
      <Typography mb={3} textAlign="left">
        {ts("description")}
      </Typography>

      <Box mb={1} width="100%">
        <PasswordInput
          hasError={touchedFields.password && !!errors.password}
          placeholder={ts("passwordPlaceholder")}
          {...register("password")}
        />
        {touchedFields.password &&
        errors.password &&
        errors.password?.message !== ERROR_TYPE.PASSWORD_GUIDELINES
          ? renderErrorMessages([errors.password?.message ?? ""] as ERROR_TYPE[])
          : null}
      </Box>
      <Box mb={2} width="100%">
        <PasswordInput
          hasError={touchedFields.confirmPassword && !!errors.confirmPassword}
          placeholder={ts("confirmPasswordPlaceholder")}
          {...register("confirmPassword")}
        />
        {touchedFields.confirmPassword &&
        errors.confirmPassword &&
        errors.confirmPassword?.message !== ERROR_TYPE.PASSWORD_GUIDELINES
          ? renderErrorMessages([errors.confirmPassword?.message ?? ""] as ERROR_TYPE[])
          : null}
        {touchedFields.password && errors.password?.message === ERROR_TYPE.PASSWORD_GUIDELINES
          ? renderErrorMessages([errors.password?.message ?? ""] as ERROR_TYPE[])
          : null}
      </Box>
      {serverError && renderErrorMessages([serverError])}
      <Button
        isDisabled={isLoading}
        isLoading={isLoading}
        title={ts("setPasswordCta")}
        type="submit"
      />
    </Box>
  );
};
