import { List, ListItem } from "@mui/material";

import i18n from "@app/i18n";

export enum ERROR_TYPE {
  INVALID_EMAIL = "INVALID_EMAIL",
  PASSWORD_GUIDELINES = "PASSWORD_GUIDELINES",
  ACCEPT_TERMS = "ACCEPT_TERMS",
  INVALID_VALUE = "INVALID_VALUE",
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  NOT_CONFIRMED = "NOT_CONFIRMED.",
  EMAIL_ALREADY_TAKEN = "EMAIL_ALREADY_TAKEN",
  REQUIRED = "REQUIRED",
  PASSWORD_MISMATCH = "PASSWORD_MISMATCH",
  SOMETHING_WRONG = "SOMETHING_WRONG",
  INVALID_TOKEN = "INVALID_TOKEN",
  UNAUTHORIZED = "UNAUTHORIZED",
  USER_NOT_IN_GROUP = "USER_NOT_IN_GROUP",
}

export const translateError = {
  [ERROR_TYPE.INVALID_EMAIL]: i18n.t("errors.invalidEmail"),
  [ERROR_TYPE.PASSWORD_GUIDELINES]: i18n.t("errors.passwordGuidelinesNotMet"),
  [ERROR_TYPE.ACCEPT_TERMS]: i18n.t("errors.termsAcceptanceMissing"),
  [ERROR_TYPE.INVALID_VALUE]: i18n.t("errors.invalidValue"),
  [ERROR_TYPE.INVALID_CREDENTIALS]: i18n.t("login.invalidData"),
  [ERROR_TYPE.NOT_CONFIRMED]: i18n.t("login.userNotConfirmed"),
  [ERROR_TYPE.PASSWORD_MISMATCH]: i18n.t("errors.passwordMismatch"),
  [ERROR_TYPE.INVALID_TOKEN]: i18n.t("errors.invalidToken"),
  [ERROR_TYPE.EMAIL_ALREADY_TAKEN]: i18n.t("register.emailAlreadyTaken"),
  [ERROR_TYPE.REQUIRED]: i18n.t("errors.requiredFields"),
  [ERROR_TYPE.SOMETHING_WRONG]: i18n.t("errors.wentWrong"),
  [ERROR_TYPE.UNAUTHORIZED]: i18n.t("errors.unauthorized"),
  [ERROR_TYPE.USER_NOT_IN_GROUP]: i18n.t("errors.userNotInGroup"),
};

export const renderErrorMessages = (errors: ERROR_TYPE[]) => {
  const uniqueErrors: ERROR_TYPE[] = errors.filter((err, index, arr) => arr.indexOf(err) === index);

  return (
    <List sx={{ fontSize: 12 }}>
      {uniqueErrors.map((error, index) => (
        <ListItem key={index}>{translateError[error]}</ListItem>
      ))}
    </List>
  );
};
