import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { DialogActions, DialogContent, Typography } from "@mui/material";

import { Button, ConfirmationModal, InputField, Modal, SelectField } from "@/components";
import { translateError, ERROR_TYPE, getAllUserRoles, getUserRolesDiff, notify } from "@/app/utils";
import { User } from "@/pages/Public/pages/Login/schema/login";
import { ServerError } from "@/types/error";
import { Role } from "@/enum";
import {
  useAssignUserRolesMutation,
  useDeleteUserMutation,
  useResetUserPasswordMutation,
  useUnAssignUserRolesMutation,
  useUpdateUserMutation,
} from "@/pages/Private/redux";
import { useRoleOptions } from "@/app/hooks/useRoleOptions";

import { UpdateUser, UpdateUserSchema } from "../../../schema/user";

interface EditUserModalProps {
  user: User | null;
  handleClose: () => void;
  show: boolean;
}

export const EditUserModal: FunctionComponent<EditUserModalProps> = ({
  user,
  handleClose,
  show,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`users.modals.editUser.${key}`);
  const tk = (key: string) => t(`users.modals.deleteUser.${key}`);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { roles, roleOptions } = useRoleOptions();
  const [updateUser, { isLoading, isSuccess, reset }] = useUpdateUserMutation();
  const [deleteUser, { isLoading: isDeleting, isSuccess: isDeleted }] = useDeleteUserMutation();
  const [resetUserPassword, { isLoading: isResettingPassword, isSuccess: isPasswordReset }] =
    useResetUserPasswordMutation();
  const [assignRole] = useAssignUserRolesMutation();
  const [unAssignRole] = useUnAssignUserRolesMutation();

  const initialValues: UpdateUser = {
    firstName: user?.firstName ?? "",
    lastName: user?.lastName ?? "",
    email: user?.email ?? "",
    role: user?.roles?.["0"].code ?? "",
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<UpdateUser>({
    defaultValues: initialValues,
    resolver: zodResolver(UpdateUserSchema),
    values: initialValues,
  });

  const onSubmit = async (values: UpdateUser) => {
    const body = {} as UpdateUser;

    for (const key in values) {
      const initialValue = initialValues[key as keyof UpdateUser];
      const currentValue = values[key as keyof UpdateUser];

      if (currentValue !== initialValue) {
        body[key as keyof UpdateUser] = currentValue;
      }
    }

    if (Object.entries(body).length > 0) {
      try {
        await updateUser({ id: user?.id ?? 0, body });

        if (user && roles) {
          const userRolesDiff = getUserRolesDiff(
            getAllUserRoles(user.roles),
            [values["role"]] as Role[],
            roles.data
          );

          if (userRolesDiff) {
            await assignRole({
              id: user?.id ?? 0,
              body: { roles: [{ id: userRolesDiff.addedRoleId ?? 0 }] },
            }).unwrap();
            await unAssignRole({
              id: user?.id ?? 0,
              body: { roles: [{ id: userRolesDiff.removedRoleId ?? 0 }] },
            }).unwrap();
          }
        }

        notify({ text: t("notifications.updatedUser") });
      } catch (err) {
        notify({
          text: translateError[(err as ServerError).data.message as ERROR_TYPE],
          type: "error",
        });
      }
    } else {
      handleClose();
    }
  };

  const onBlock = async () => {
    try {
      await updateUser({
        id: user?.id ?? 0,
        body: { isBlocked: user?.isBlocked ? false : true },
      }).unwrap();

      notify({ text: t("notifications.updatedUser") });
    } catch (err) {
      notify({
        text: translateError[(err as ServerError).data.message as ERROR_TYPE],
        type: "error",
      });
    }
  };

  const onDelete = async () => {
    try {
      await deleteUser(user?.id ?? 0).unwrap();
      notify({ text: t("notifications.deletedUser") });
    } catch (err) {
      notify({
        text: translateError[(err as ServerError).data.message as ERROR_TYPE],
        type: "error",
      });
    }
  };

  const onPasswordReset = async () => {
    try {
      await resetUserPassword(user?.id ?? 0).unwrap();
      notify({ text: t("notifications.resetUserPassword") });
    } catch (err) {
      notify({
        text: translateError[(err as ServerError).data.message as ERROR_TYPE],
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (isSuccess || isDeleted || isPasswordReset) {
      handleClose();
      reset();
    }
  }, [isSuccess, isDeleted, isPasswordReset, handleClose, reset]);

  return (
    <>
      <Modal setShow={handleClose} show={show} title={ts("title")}>
        <DialogContent
          dividers
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            border: "none",
            padding: 1.5,
            paddingTop: 0,
          }}
        >
          <Typography component="p" sx={{ marginBottom: 0.5 }} variant="main14">
            {ts("description")}
          </Typography>
          <InputField
            hasError={errors.firstName && !!errors.firstName}
            {...register("firstName")}
            placeholder={t("basics.firstName")}
          />
          <InputField
            hasError={errors.lastName && !!errors.lastName}
            {...register("lastName")}
            placeholder={t("basics.lastName")}
          />

          <InputField
            hasError={errors.email && !!errors.email}
            {...register("email")}
            placeholder={t("basics.email")}
          />

          <SelectField
            currentValue={watch("role") || "DEFAULT"}
            hasError={!!errors.role?.message}
            options={roleOptions || []}
            placeholder={t("basics.role")}
            {...register("role")}
          />
          <Button
            handleClick={handleSubmit(onSubmit)}
            isDisabled={!isValid}
            isLoading={isLoading}
            title={t("basics.save")}
          />
        </DialogContent>

        <DialogActions
          sx={{
            padding: 1.5,
            display: "flex",
            flexWrap: "wrap",
            gap: 1.5,
            justifyContent: "center",
            "&>*:not(:first-of-type)": {
              marginLeft: 0,
            },
          }}
        >
          <Button
            handleClick={onPasswordReset}
            isLoading={isResettingPassword}
            title={t("basics.resetPassword")}
          />
          <Button
            handleClick={onBlock}
            isLoading={isLoading}
            title={user?.isBlocked ? t("basics.unblock") : t("basics.block")}
          />
          <Button
            bgcolor="red.main"
            handleClick={() => setShowConfirmationModal(true)}
            isLoading={isDeleting}
            title={t("basics.delete")}
          />
        </DialogActions>
      </Modal>

      <ConfirmationModal
        isDeletion
        confirmButton={t("basics.delete")}
        description={tk("description")}
        email={`${t("basics.email")}: ${user?.email}`}
        handleClose={() => setShowConfirmationModal(false)}
        handleConfirm={onDelete}
        isShown={showConfirmationModal}
        name={`${t("basics.name")}: ${user?.firstName} ${user?.lastName}`}
        role={`${t("basics.role")}: ${user?.roles.map((role) => role.name)}`}
        title={tk("title")}
      />
    </>
  );
};
