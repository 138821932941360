import { CompanyType } from "@/pages/Private/pages/Companies/schema/companies.schema";
import { useGetCompaniesQuery } from "@/pages/Private/redux/companies.api";

import { useSearch } from "../utils";

interface CompanyOption {
  text: string;
  value: number;
}
export const useCompanyOptions = () => {
  const { page, limit } = useSearch();
  const { data: companies, isLoading } = useGetCompaniesQuery({ page, limit });

  const companyOptions = companies?.data.reduce(
    (companyOptions: CompanyOption[], company: CompanyType) => {
      companyOptions.push({ text: company.name, value: company.id });

      return companyOptions;
    },
    []
  );

  return { companies, isLoading, companyOptions };
};
