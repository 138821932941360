import { FunctionComponent } from "react";
import { EditOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";

interface EditButtonProps {
  handleClick: () => void;
}

export const EditButton: FunctionComponent<EditButtonProps> = ({ handleClick }) => {
  return (
    <Button
      sx={{
        width: 36,
        minWidth: "unset",
        height: 36,
        bgcolor: "white",
        border: "1px solid",
        borderColor: "main",
        borderRadius: 1,
        boxShadow: 1,
      }}
      onClick={handleClick}
    >
      <EditOutlined sx={{ color: "main", height: 23, width: "auto" }} />
    </Button>
  );
};
