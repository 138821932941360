import { useTranslation } from "react-i18next";
import { FunctionComponent, useState } from "react";
import { Box, Typography } from "@mui/material";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";

import { MetaData } from "@/schemas/meta-data";
import { ConfirmationModal, LoadingOverlay, TableColumn, TableComponent } from "@/components";
import { ServerError } from "@/types/error";
import { useDeleteModuleMutation } from "@/pages/Private/redux/modules.api";
import { ERROR_TYPE, notify, translateError } from "@/app/utils";
import { useHasUserPermission } from "@/app/hooks/useHasUserPermission";
import { PermissionRoles } from "@/enum";
import { EventModuleTypeIcon } from "@/pages/Private/pages/Events/pages/EventsOverview/components";

import { ModuleType } from "../../../schema/modules.schema";
import { EditModuleModal } from "./EditModuleModal";

interface ModulesTableProps {
  modules: ModuleType[];
  page: number;
  handleFetchPage: (page: number) => void;
  meta?: MetaData;
  isLoading?: boolean;
}

export const ModulesTable: FunctionComponent<ModulesTableProps> = ({
  modules,
  page,
  handleFetchPage,
  meta,
  isLoading,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`modules.${key}`);

  const canUserDeleteModules = useHasUserPermission(PermissionRoles.DELETE_MODULE);

  const [selectedModule, setSelectedModule] = useState<ModuleType | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteModule] = useDeleteModuleMutation();

  const handleDeleteModal = (module: ModuleType) => {
    setSelectedModule(module);
    setShowConfirmationModal(true);
  };

  const handleEditModule = (module: ModuleType) => {
    setSelectedModule(module);
    setShowEditModal(true);
  };

  const handleDeleteModule = async () => {
    try {
      await deleteModule(selectedModule?.id ?? 0).unwrap();
      notify({ text: t("notifications.deletedModule") });

      setShowConfirmationModal(false);
    } catch (err) {
      notify({
        text: translateError[(err as ServerError).data.message as ERROR_TYPE],
        type: "error",
      });
    }
  };

  const handleCloseModals = () => {
    setSelectedModule(null);
    setShowEditModal(false);
    setShowConfirmationModal(false);
  };

  const columns: TableColumn<ModuleType>[] = [
    {
      title: ts("moduleInfo.moduleName"),
      render: (module) => (
        <Typography display="flex" variant="main14">
          <EventModuleTypeIcon smallSize type={module.type} />
          {module?.name}
        </Typography>
      ),
    },
    {
      title: ts("actions"),
      render: (module) => (
        <Box display="flex" justifyContent="center">
          {canUserDeleteModules && (
            <DeleteOutline
              sx={{
                color: "gray600.main",
                marginRight: 1,
                "&:hover": {
                  opacity: 0.5,
                  cursor: "pointer",
                },
              }}
              onClick={() => handleDeleteModal(module)}
            />
          )}
          <EditOutlined
            sx={{
              color: "gray600.main",
              "&:hover": {
                opacity: 0.5,
                cursor: "pointer",
              },
            }}
            onClick={() => handleEditModule(module)}
          />
        </Box>
      ),
      width: 60,
    },
  ];

  return modules ? (
    <>
      <TableComponent
        columns={columns}
        data={modules}
        handleFetchPage={handleFetchPage}
        isLoading={isLoading}
        page={page}
        totalPages={meta?.pageCount ?? 1}
      />
      <EditModuleModal
        handleClose={handleCloseModals}
        module={selectedModule}
        show={showEditModal}
      />
      <ConfirmationModal
        isDeletion
        confirmButton={t("basics.delete")}
        description={ts("deleteModule.description")}
        handleClose={handleCloseModals}
        handleConfirm={handleDeleteModule}
        isShown={showConfirmationModal}
        name={`${t("basics.name")}: ${selectedModule?.name}`}
        title={ts("deleteModule.title")}
      />
    </>
  ) : (
    <LoadingOverlay />
  );
};
