import { z } from "zod";

import { DataSourceFlags } from "@/pages/CameraStatus/enums";
import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/app/utils";

export const DataSourceSchema = z.object({
  id: z.number(),
  name: z.string(),
  externalId: z.string(),
  type: z.string(),
  moduleId: z.nullable(z.number()),
  hostname: z.nullable(z.string()),
  status: z.nullable(z.string()),
  lastStatusAt: z.string().datetime(),
  flag: z.nativeEnum(DataSourceFlags),
  companyId: z.nullable(z.number()),
});

export const DataSourcesResponseSchema = z.object({
  data: z.array(DataSourceSchema),
  meta: MetaDataSchema,
});

const { REQUIRED } = ERROR_TYPE;

export const CreateDataSourceRequestSchema = z.object({
  name: z.string().min(2, { message: REQUIRED }).trim(),
  externalId: z.string().min(2, { message: REQUIRED }).trim(),
  type: z.string().min(2, { message: REQUIRED }).trim(),
  companyId: z.number().optional(),
});

export const DataSourceRequestBodySchema = z
  .object({
    name: z.string().min(2, { message: REQUIRED }).trim(),
    externalId: z.string().min(2, { message: REQUIRED }).trim(),
    type: z.string().min(2, { message: REQUIRED }).trim(),
    companyId: z.number(),
  })
  .partial();

export const DeleteDataSourceSchema = z.object({
  dataSourceId: z.number().gt(0),
});

export const DataStatusResponseSchema = DataSourceSchema;
export type DataSourceType = z.infer<typeof DataSourceSchema>;
export type DataSourcesResponse = z.infer<typeof DataSourcesResponseSchema>;
export type CreateDataSourceType = z.infer<typeof CreateDataSourceRequestSchema>;
export type DataSourceRequestBodyType = z.infer<typeof DataSourceRequestBodySchema>;
export type DeleteDataSource = z.infer<typeof DeleteDataSourceSchema>;
