import { api } from "@/app/redux/api";

import { FeaturesResponse } from "../pages/Companies/schema/companies.schema";

export const featuresData = api.injectEndpoints({
  endpoints: (builder) => ({
    getFeatures: builder.query<FeaturesResponse, void>({
      query: () => ({
        url: "features",
      }),
    }),
  }),
});

export const { useGetFeaturesQuery } = featuresData;
export const featuresDataApiReducer = featuresData.reducer;
export const featuresDataApiMiddleware = featuresData.middleware;
