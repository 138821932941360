import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Box, CircularProgress, Typography } from "@mui/material";
import { CheckRounded, CloseRounded } from "@mui/icons-material";

import { EditButton, Textarea } from "@/components";
import { useUpdateEventMutation } from "@/pages/Private/redux";

export const EventNotes: FunctionComponent<{ eventId: number; notes: string | null }> = ({
  eventId,
  notes,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.${key}`);

  const [updateEvent, { isSuccess, isLoading }] = useUpdateEventMutation();
  const [editNotes, setEditNotes] = useState(false);
  const [eventNotes, setEventNotes] = useState("");

  const { handleSubmit, register, reset } = useForm({
    defaultValues: { notes: eventNotes },
  });

  const handleDelete = () => {
    reset();
    setEditNotes(false);
  };

  const onSubmit = handleSubmit(async ({ notes }: { notes: string }) => {
    await updateEvent({ id: eventId, body: { notes } });
  });

  useEffect(() => {
    if (!isLoading) {
      setEventNotes(notes ?? "");
      reset({ notes: notes ?? "" });
    }
  }, [notes, reset, isLoading]);

  useEffect(() => {
    if (isSuccess) {
      setEditNotes(false);
    }
  }, [isSuccess]);

  return (
    <Box
      bgcolor="white"
      borderRadius={2}
      boxShadow={1}
      mt="30px"
      p={3}
      position="relative"
      sx={{ width: { md: "50%" } }}
    >
      <Box display="flex" height={36} justifyContent="space-between" mb={2}>
        <Typography variant="mainBold">{ts("eventNotes")}</Typography>
        {!editNotes && <EditButton handleClick={() => setEditNotes(true)} />}
      </Box>
      {isLoading ? (
        <Box
          sx={{
            padding: "6rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Textarea
          placeholder={editNotes && !eventNotes ? ts("startTyping") : ""}
          readonly={!editNotes}
          {...register("notes")}
        />
      )}
      {!editNotes && !notes && !isLoading && (
        <Box
          left="50%"
          position="absolute"
          sx={{ transform: "translateX(-50%) translateY(100%)" }}
          top="50%"
        >
          <Typography fontWeight={400} variant="gray14">
            {ts("noNotes")}
          </Typography>
        </Box>
      )}
      {editNotes && (
        <Box color="white" display="flex" justifyContent="flex-end" mt={2}>
          <Box
            bgcolor="green.main"
            borderRadius={1}
            boxShadow={1}
            height={24}
            mr={1}
            p={0.75}
            sx={{ cursor: "pointer" }}
            onClick={onSubmit}
          >
            <CheckRounded />
          </Box>
          <Box
            bgcolor="#E03C31"
            borderRadius={1}
            boxShadow={1}
            height={24}
            p={0.75}
            sx={{ cursor: "pointer" }}
            onClick={handleDelete}
          >
            <CloseRounded />
          </Box>
        </Box>
      )}
    </Box>
  );
};
