import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { EventDetails, EventsOverview, EventSettings, CreateEvent } from "./pages";

export const Events: FunctionComponent = () => {
  return (
    <Routes>
      <Route element={<EventsOverview />} path="" />
      <Route element={<CreateEvent />} path="/create" />
      <Route element={<EventDetails />} path="/:id">
        <Route element={<EventDetails />} path="/:id/:dashboard" />
      </Route>
      <Route element={<EventSettings />} path="/:id/settings/*" />
    </Routes>
  );
};
