import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { useSearch } from "@/app/utils";

import { DataStatusTable } from "./components/DataStatusTable";
import { useGetDataSourceStatusQuery } from "../Private/redux/dataSources.api";

export const CameraStatus: FunctionComponent = () => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`dataStatus.${key}`);

  const { page, setPage, limit } = useSearch();
  const { data: dataStatus, isLoading } = useGetDataSourceStatusQuery({ page, limit });

  return (
    <Box m={1.5}>
      <Typography mb={1.5} mt={4} variant="h1">
        {ts("title")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { md: "space-between" },
          mb: 3,
        }}
      >
        <Typography
          component="p"
          mb={1.5}
          sx={{ maxWidth: { xs: "100%", md: 566 } }}
          variant="main"
        >
          {ts("description")}
        </Typography>
      </Box>
      <DataStatusTable
        dataStatus={dataStatus?.data || []}
        handleFetchPage={setPage}
        isLoading={isLoading}
        meta={dataStatus?.meta}
        page={page}
      />
    </Box>
  );
};
