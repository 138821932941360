import { LoadingButton } from "@mui/lab";

export enum ButtonSize {
  S = "S",
  M = "M",
  L = "L",
}

interface ButtonProps {
  title: string;
  handleClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  type?: "button" | "submit" | "reset";
  variant?: "contained" | "outlined" | "text";
  bgcolor?: string;
  size?: ButtonSize;
}

export const Button = ({
  title,
  handleClick,
  type = "button",
  variant = "contained",
  isDisabled = false,
  isLoading = false,
  bgcolor = "main-green",
  size = ButtonSize.L,
}: ButtonProps) => {
  const renderButtonSize = () => {
    switch (size) {
      case ButtonSize.S:
        return { height: 28, fontSize: 14 };
      case ButtonSize.M:
        return { height: 36, fontSize: 14 };
      default:
        return { height: 44, fontSize: 16 };
    }
  };

  return (
    <LoadingButton
      fullWidth
      disabled={isDisabled}
      loading={isLoading}
      sx={{
        bgcolor,
        color: "main-green",
        boxShadow: 1,
        borderRadius: 2,
        textTransform: "capitalize",
        ...renderButtonSize(),
        ":disabled": {
          bgcolor: "gray200.main",
          color: "main",
        },
      }}
      type={type}
      variant={variant}
      onClick={handleClick}
    >
      {title}
    </LoadingButton>
  );
};
