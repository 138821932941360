import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { DialogActions, DialogContent, Typography } from "@mui/material";

import { Button, ConfirmationModal, InputField, Modal, SelectField } from "@/components";
import {
  useDeleteModuleMutation,
  useUpdateModuleMutation,
} from "@/pages/Private/redux/modules.api";
import { ERROR_TYPE, notify, translateError } from "@/app/utils";
import { ServerError } from "@/types/error";
import { useCompanyOptions } from "@/app/hooks/useCompanyOptions";
import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";
import { Features } from "@/pages/Private/pages/Companies/enums";
import { moduleTypesArray } from "@/app/constants";

import {
  ModuleType,
  UpdateModuleBodyType,
  UpdateRequestModuleBodySchema,
} from "../../../schema/modules.schema";

interface EditModuleModalProps {
  module: ModuleType | null;
  handleClose: () => void;
  show: boolean;
}
export const EditModuleModal: FunctionComponent<EditModuleModalProps> = ({
  module,
  handleClose,
  show,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`modules.editModule.${key}`);
  const tk = (key: string) => t(`modules.${key}`);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { companyOptions } = useCompanyOptions();

  const [updateModule, { isLoading, isSuccess, reset }] = useUpdateModuleMutation();
  const [deleteModule, { isLoading: isDeleting, isSuccess: isDeleted }] = useDeleteModuleMutation();

  const isMutlitenancyActive = useIsFeatureActive(Features.MULTI_TENANCY);

  const initialValues: UpdateModuleBodyType = {
    name: module?.name ?? "",
    type: module?.type ?? "",
    companyId: module?.companyId ?? 0,
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<UpdateModuleBodyType>({
    defaultValues: initialValues,
    resolver: zodResolver(UpdateRequestModuleBodySchema),
    values: initialValues,
  });

  const moduleTypeOptions = useMemo(() => {
    return moduleTypesArray?.map((type) => {
      return { text: type.value, value: type.id };
    });
  }, []);

  const onSubmit = async (values: UpdateModuleBodyType) => {
    try {
      const body: UpdateModuleBodyType = values;

      const valuesDoNotDiffer =
        Object.keys(body).length === Object.keys(initialValues).length &&
        (Object.keys(body) as (keyof typeof body)[]).every((key) => {
          return (
            Object.prototype.hasOwnProperty.call(initialValues, key) &&
            body[key] === initialValues[key]
          );
        });

      if (Object.entries(body).length > 0 && !valuesDoNotDiffer) {
        await updateModule({ id: module?.id ?? 0, body }).unwrap();
        notify({ text: t("notifications.updatedModule") });
      }
    } catch (err) {
      notify({
        text: translateError[(err as ServerError).data.message as ERROR_TYPE],
        type: "error",
      });
    }
  };

  const onDelete = async () => {
    try {
      await deleteModule(module?.id ?? 0).unwrap();
      notify({ text: t("notifications.deletedUser") });
    } catch (err) {
      notify({
        text: translateError[(err as ServerError).data.message as ERROR_TYPE],
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (isSuccess || isDeleted) {
      handleClose();
      reset();
    }
  }, [isSuccess, isDeleted, handleClose, reset]);

  return (
    <>
      <Modal setShow={handleClose} show={show} title={ts("title")}>
        <DialogContent
          dividers
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            border: "none",
            padding: 1.5,
            paddingTop: 0,
            overflow: "hidden",
          }}
        >
          <Typography component="p" sx={{ marginBottom: 0.5 }} variant="main14">
            {ts("description")}
          </Typography>
          <InputField
            hasError={errors.name && !!errors.name}
            {...register("name")}
            placeholder={t("basics.name")}
          />
          <SelectField
            currentValue={watch("type") || "DEFAULT"}
            hasError={!!errors.type?.message}
            options={moduleTypeOptions || []}
            placeholder={t("terms.selectType")}
            {...register("type")}
          />
          {isMutlitenancyActive && (
            <SelectField
              currentValue={watch("companyId") || "DEFAULT"}
              hasError={!!errors.companyId?.message}
              options={companyOptions || []}
              placeholder={tk("createModal.companyPlaceholder")}
              {...register("companyId")}
            />
          )}
          <Button
            handleClick={handleSubmit(onSubmit)}
            isDisabled={!isValid}
            isLoading={isLoading}
            title={t("basics.save")}
          />
        </DialogContent>

        <DialogActions
          sx={{
            padding: 1.5,
            display: "flex",
            flexWrap: "wrap",
            gap: 1.5,
            justifyContent: "center",
            "&>*:not(:first-of-type)": {
              marginLeft: 0,
            },
          }}
        >
          <Button
            bgcolor="red.main"
            handleClick={() => setShowConfirmationModal(true)}
            isLoading={isDeleting}
            title={t("basics.delete")}
          />
        </DialogActions>
      </Modal>

      <ConfirmationModal
        isDeletion
        confirmButton={t("basics.delete")}
        description={tk("deleteModule.description")}
        handleClose={() => setShowConfirmationModal(false)}
        handleConfirm={onDelete}
        isShown={showConfirmationModal}
        name={`${t("basics.name")}: ${module?.name}`}
        title={tk("deleteModule.title")}
      />
    </>
  );
};
