import { useTranslation } from "react-i18next";
import { FunctionComponent, useMemo } from "react";
import { Box, Typography } from "@mui/material";

import { DonutChart, Loader } from "@/components";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";

interface SalesByCategoryProps {
  isFiltration?: boolean;
  isFetching: boolean;
  salesKPIs: KpiType[];
}

export const SalesByCategoryDonutChart: FunctionComponent<SalesByCategoryProps> = ({
  isFiltration,
  isFetching,
  salesKPIs,
}) => {
  const { t } = useTranslation();

  const salesByCategoryLabels = useMemo(() => {
    const salesByCategoryKPIs =
      salesKPIs.find((kpi) => kpi.name === KpiTypeEnum.SALES_BY_CATEGORY)?.data ?? [];

    return salesByCategoryKPIs.map((kpi) => kpi.specific);
  }, [salesKPIs]);

  const salesByCategoryData = useMemo(() => {
    const salesByCategoryKPIs =
      salesKPIs.find((kpi) => kpi.name === KpiTypeEnum.SALES_BY_CATEGORY)?.data ?? [];

    return salesByCategoryLabels.map((type) => {
      const kpiData = salesByCategoryKPIs.find((kpi) => kpi.specific === type);

      if (kpiData) {
        return Math.round(kpiData.date?.[0]?.percent ?? 0);
      } else {
        return 0;
      }
    });
  }, [salesKPIs, salesByCategoryLabels]);

  return (
    <Box
      sx={{
        paddingLeft: { xs: 0, md: 1 },
        paddingY: { xs: 1, md: 0 },
      }}
    >
      <Typography component="p" mb={1} variant="mainBold">
        {t("events.donutSalesByCategory")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : (
        <DonutChart
          kpi={t("events.donutSalesByCategory")}
          labels={salesByCategoryLabels}
          series={salesByCategoryData}
        />
      )}
    </Box>
  );
};
