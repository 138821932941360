import { Tooltip } from "@mui/material";

import InfoIcon from "@mui/icons-material/InfoOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useMemo } from "react";

import { useWindowSize } from "@/app/hooks/useWindowSize";

export enum TooltipType {
  INFO = "INFO",
  HELP = "HELP",
}
interface TooltipProps {
  leaveDelay?: number;
  isOpen: boolean;
  title: string;
  type: TooltipType;
  onClose: () => void;
  onOpen: () => void;
}
export const ContextTooltip = ({ isOpen, title, type, onClose, onOpen }: TooltipProps) => {
  const { width } = useWindowSize();

  const tooltipIcon = useMemo(() => {
    switch (type) {
      case TooltipType.INFO:
        return <InfoIcon sx={{ color: "gray600.main", cursor: "pointer" }} onClick={onOpen} />;

      case TooltipType.HELP:
        return (
          <HelpOutlineIcon sx={{ color: "gray600.main", cursor: "pointer" }} onClick={onOpen} />
        );
    }
  }, [type, onOpen]);

  return (
    <Tooltip
      leaveDelay={2000}
      open={isOpen}
      placement={width < 600 ? "bottom" : "right"}
      title={title}
      onClose={onClose}
    >
      {tooltipIcon}
    </Tooltip>
  );
};
