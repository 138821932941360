import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { formatEventDates } from "@/app/utils/helpers";

interface EventDurationProps {
  startDate: string;
  endDate: string;
  dayArrCount: number;
}

export const EventDuration: FunctionComponent<EventDurationProps> = ({
  startDate,
  endDate,
  dayArrCount,
}) => {
  const { t } = useTranslation();
  const isOneDayEvent = dayArrCount === 1;
  const formattedDuration = useMemo(() => {
    if (!dayArrCount || dayArrCount <= 0) {
      return "-";
    }

    if (dayArrCount && dayArrCount > 1) {
      return t("events.duration", { days: `${dayArrCount}` });
    } else {
      return t("events.oneDay", { days: "1" });
    }
  }, [t, dayArrCount]);

  return (
    <Box>
      <Typography component="p" sx={{ marginBottom: 0.5 }} variant="gray14">
        {formatEventDates({ startDate, endDate, isOneDayEvent })}
      </Typography>
      <Typography component="p" variant="gray12">
        {formattedDuration}
      </Typography>
    </Box>
  );
};
