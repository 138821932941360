import { useTranslation } from "react-i18next";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";

import { MetaData } from "@/schemas/meta-data";
import { ConfirmationModal, LoadingOverlay, TableColumn, TableComponent } from "@/components";
import { ServerError } from "@/types/error";
import { useDeleteCompanyMutation } from "@/pages/Private/redux/companies.api";
import { ERROR_TYPE, notify, translateError } from "@/app/utils";

import { CompanyType } from "../../../schema/companies.schema";

interface CompaniesTableProps {
  companies: CompanyType[];
  page: number;
  handleFetchPage: (page: number) => void;
  meta?: MetaData;
  isLoading?: boolean;
}

export const CompaniesTable: FunctionComponent<CompaniesTableProps> = ({
  companies,
  page,
  handleFetchPage,
  meta,
  isLoading,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const ts = (key: string) => t(`companies.${key}`);
  const tk = (key: string) => t(`companies.modals.${key}`);

  const [selectedCompany, setSelectedCompany] = useState<CompanyType | null>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteCompany] = useDeleteCompanyMutation();

  const handleDeleteModal = (company: CompanyType) => {
    setSelectedCompany(company);
    setShowConfirmationModal(true);
  };

  const handleDeleteCompany = async () => {
    try {
      await deleteCompany(selectedCompany?.id ?? 0).unwrap();
      notify({ text: ts("notifications.companyDeleted") });

      setShowConfirmationModal(false);
    } catch (err) {
      notify({
        text: translateError[(err as ServerError).data.message as ERROR_TYPE],
        type: "error",
      });
    }
  };

  const handleCloseModals = () => {
    setSelectedCompany(null);
    setShowConfirmationModal(false);
  };

  const columns: TableColumn<CompanyType>[] = [
    {
      title: ts("company"),
      render: (company) => (
        <>
          <Box alignItems="center" display="flex">
            {company.logo ? (
              <Box height={28} mr={1} width={28}>
                <img
                  alt="#"
                  src={company.logo}
                  style={{ width: "100%", height: "100%", borderRadius: "100%" }}
                />
              </Box>
            ) : (
              <Box bgcolor="main" borderRadius="100%" height={28} mr={1} width={28}></Box>
            )}
            <Typography variant="main14">{company?.name}</Typography>
          </Box>
        </>
      ),
    },
    {
      title: ts("actions"),
      render: (row) => (
        <>
          <DeleteOutline
            sx={{
              color: "gray600.main",
              marginRight: 1,
              "&:hover": {
                opacity: 0.5,
                cursor: "pointer",
              },
            }}
            onClick={() => handleDeleteModal(row)}
          />
          <EditOutlined
            sx={{
              color: "gray600.main",
              "&:hover": {
                opacity: 0.5,
                cursor: "pointer",
              },
            }}
            onClick={() => navigate(`${row.id}/settings`)}
          />
        </>
      ),
      width: 60,
    },
  ];

  return !companies ? (
    <LoadingOverlay />
  ) : (
    <>
      <TableComponent
        columns={columns}
        data={companies}
        handleFetchPage={handleFetchPage}
        isLoading={isLoading}
        page={page}
        totalPages={meta?.pageCount ?? 1}
      />
      <ConfirmationModal
        isDeletion
        confirmButton={t("basics.delete")}
        description={tk("deleteCompany.description")}
        handleClose={handleCloseModals}
        handleConfirm={handleDeleteCompany}
        isShown={showConfirmationModal}
        name={`${t("basics.name")}: ${selectedCompany?.name}`}
        title={tk("deleteCompany.title")}
      />
    </>
  );
};
