import { FunctionComponent } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ArrowBackIosNewRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

export const BackLink: FunctionComponent<{ text: string; destination?: string }> = ({
  text,
  destination,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <Box
      alignItems="center"
      display="inline-flex"
      sx={{ cursor: "pointer" }}
      onClick={() =>
        destination
          ? navigate({ pathname: destination, search: searchParams.toString() })
          : navigate(-1)
      }
    >
      <ArrowBackIosNewRounded sx={{ color: "gray500.main" }} />
      <Typography fontWeight={400} ml={1.5} variant="main14">
        {text}
      </Typography>
    </Box>
  );
};
