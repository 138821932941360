export enum Features {
  MULTI_TENANCY = "MULTI_TENANCY",
  BUDGETS_TAB = "BUDGETS_TAB",
  KPI_TAB = "KPI_TAB",
  KPI_TILE_DIO_NL = "KPI_TILE_DIO_NL",
  KPI_TILE_SALES = "KPI_TILE_SALES",
  KPI_TILE_ACTIVATIONS = "KPI_TILE_ACTIVATIONS",
  KPI_TILE_SAMPLES = "KPI_TILE_SAMPLES",
  KPI_TILE_GIVEAWAYS = "KPI_TILE_GIVEAWAYS",
  GRAPH_DIO_NL = "GRAPH_DIO_NL",
  GRAPH_ACTIVATIONS = "GRAPH_ACTIVATIONS",
  GRAPH_SALES = "GRAPH_SALES",
  TILE_AGE_DISTRIBUTION = "TILE_AGE_DISTRIBUTION",
  TILE_EMOTIONS = "TILE_EMOTIONS",
  TILE_RESIDENCE_TIME = "TILE_RESIDENCE_TIME",
  GRAPH_FOOTFALL = "GRAPH_FOOTFALL",
  GRAPH_LIVIA_ONLY = "GRAPH_LIVIA_ONLY",
  EVENT_OVERVIEW_SETTINGS = "EVENT_OVERVIEW_SETTINGS",
  EVENT_OVERVIEW_KPI = "EVENT_OVERVIEW_KPI",
  DOI_DASHBOARD = "DOI_DASHBOARD",
  SALES_DASHBOARD = "SALES_DASHBOARD",
  ACTIVATIONS_DASHBOARD = "ACTIVATIONS_DASHBOARD",
  GIVEAWAYS_DASHBOARD = "GIVEAWAYS_DASHBOARD",
  PROMOTERS_DASHBOARD = "PROMOTERS_DASHBOARD",
  SECONDARY_PLANNED_KPI = "SECONDARY_PLANNED_KPI", // TECH DEBT - BA-706
  PROJECT_REPORTING = "PROJECT_REPORTING",
}
