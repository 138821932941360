import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/app/utils";

import { BudgetTypeEnum } from "../../Events/types";

export const BrandCompanyConfigSchema = z
  .object({
    budgets: z.nativeEnum(BudgetTypeEnum),
    kpiEditableGoals: z.string().array(),
    kpiGoals: z.string().array(),
    kpiHiddenPlanned: z.string().array(),
    kpiTiles: z.string().array(),
  })
  .optional();
export const BrandCompanySchema = z.object({
  id: z.number(),
  code: z.string(),
  name: z.string(),
  logo: z.null(),
  config: BrandCompanyConfigSchema,
  subusers: z.null().optional(),
  admins: z.null().optional(),
  brands: z.string().optional(),
  updatedAt: z.string().datetime().optional(),
  createdAt: z.string().datetime().optional(),
});
export const BrandSchema = z.object({
  id: z.number(),
  code: z.string(),
  name: z.string(),
  companyId: z.number(),
  company: BrandCompanySchema,
  image: z.string(),
});

export const BrandsResponseSchema = z.object({
  data: z.array(BrandSchema),
  meta: MetaDataSchema,
});

const { REQUIRED } = ERROR_TYPE;
export const CreateOrEditRequestSchema = z
  .object({
    name: z.string().min(1, { message: REQUIRED }).trim(),
    image: z.string().min(1, { message: REQUIRED }).trim(),
  })
  .partial();
export type CreateOrEditBrandType = z.infer<typeof CreateOrEditRequestSchema>;
export const CreateResponseSchema = BrandSchema.partial();
export type BrandType = z.infer<typeof BrandSchema>;
export type BrandsResponse = z.infer<typeof BrandsResponseSchema>;
