import { FunctionComponent } from "react";
import { Alert } from "@mui/material";

interface NotificationProps {
  text: string;
  type?: "error" | "warning" | "info" | "success";
}

export const Notification: FunctionComponent<NotificationProps> = ({ text, type = "success" }) => {
  return (
    <Alert severity={type} sx={{ width: "100%", padding: 0 }}>
      {text}
    </Alert>
  );
};
