import { FunctionComponent } from "react";
import { FilterListRounded } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

interface FilterButtonProps {
  handleClick: () => void;
  activeFilters?: number;
}

export const FilterButton: FunctionComponent<FilterButtonProps> = ({
  handleClick,
  activeFilters,
}) => {
  return (
    <Button
      sx={{
        width: 44,
        minWidth: "unset",
        height: 44,
        bgcolor: "main",
        borderRadius: 2,
        boxShadow: 1,
        display: { md: "none" },
        position: "relative",
        "&.MuiButtonBase-root:hover": {
          bgcolor: "main",
        },
      }}
      onClick={handleClick}
    >
      <FilterListRounded sx={{ color: "#fff", height: 28, width: "auto" }} />
      {!!activeFilters && (
        <Box
          alignItems="center"
          bgcolor="white"
          border="1px solid"
          borderColor="main"
          borderRadius="100%"
          bottom={-10}
          boxShadow={1}
          display="flex"
          height={20}
          justifyContent="center"
          position="absolute"
          right={-10}
          width={20}
        >
          <Typography variant="green10">{activeFilters}</Typography>
        </Box>
      )}
    </Button>
  );
};
