import { FunctionComponent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { Bullets, LineChart, LineChartSeries, Loader, Tabs } from "@/components";
import { useProductVersion } from "@/app/hooks/useProductVersion";
import { ProductVersion } from "@/types";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum, NewsletterWithoutDeviceSpecifics } from "../../../../types";

interface TimelineProps {
  kpis: KpiType[];
  dates: { start: Date; end: Date };
  isFiltration?: boolean;
  isLoading?: boolean;
  isProjectReporting?: boolean;
}

export const GiveawaysTimelineChart: FunctionComponent<TimelineProps> = ({
  kpis,
  dates,
  isLoading,
  isProjectReporting,
}) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState<KpiTypeEnum>(KpiTypeEnum.GIVEAWAY);
  const currentProductVersion = useProductVersion();

  const tabs = [
    {
      text: t(`events.kpis.${KpiTypeEnum.GIVEAWAY}`),
      value: KpiTypeEnum.GIVEAWAY,
    },
  ];

  const seriesData = useMemo(() => {
    const categories: number[] = [];
    const data: LineChartSeries[] = [
      {
        name: selectedTab,
        type: "line",
        data: [],
        color: currentProductVersion === ProductVersion.BSURE ? "#D6B60E" : "#00B2A9",
      },
      {
        name: KpiTypeEnum.PROMOTER_HOURS,
        type: "area",
        data: [],
        color: currentProductVersion === ProductVersion.BSURE ? "#ABCCCC" : "#8031A7",
      },
      {
        name: KpiTypeEnum.VISITORS,
        type: "line",
        data: [],
        color: currentProductVersion === ProductVersion.BSURE ? "#969696" : "#919EAB",
      },
    ];

    const firstDate = dates.start.getTime();
    const lastDate = dates.end.getTime();

    if (isProjectReporting) {
      const setOfUnixTimestamps = Array.from(
        new Set(
          data.flatMap((row) => {
            const kpiData = kpis.find((k) => k.name === row.name)?.data ?? [];
            const existedTimes =
              kpiData.find((d) =>
                row.name === KpiTypeEnum.NEWSLETTER_DATA
                  ? d.specific === NewsletterWithoutDeviceSpecifics.DOI_NL
                  : d.specific === null
              )?.time ?? [];

            return existedTimes.map((o) => new Date(o.time || "").getTime());
          })
        )
      ).sort((a, b) => a - b);

      setOfUnixTimestamps.forEach((unixMilisec) => categories.push(unixMilisec));
    } else {
      for (let i = firstDate; i <= lastDate; i += 15 * 60 * 1000) {
        categories.push(i);
      }
    }

    const series = data.map((row) => {
      const kpiData = kpis.find((k) => k.name === row.name)?.data ?? [];
      const existedTimes =
        kpiData.find((d) =>
          row.name === KpiTypeEnum.NEWSLETTER_DATA
            ? d.specific === NewsletterWithoutDeviceSpecifics.DOI_NL
            : d.specific === null
        )?.time ?? [];

      row.data = categories.map(
        (unixTime) =>
          existedTimes.find((time) => unixTime === new Date(time.time || "").getTime())?.value ?? 0
      );

      return { ...row, name: t(`events.kpis.${row.name}`) };
    });

    return { series, categories };
  }, [kpis, t, selectedTab, dates, currentProductVersion, isProjectReporting]);

  return (
    <>
      <Box
        sx={{
          mb: { xs: 3, md: 0 },
          mr: { xs: 0, md: 1 },
          bgcolor: { md: "white" },
          borderRadius: { md: 2 },
          boxShadow: { md: 1 },
          p: { md: 2 },
          width: { md: "70%" },
        }}
      >
        <Box
          sx={{
            display: { md: "flex" },
            justifyContent: "space-between",
          }}
        >
          <Typography component="h3" mb={2} variant="mainBold">
            {t("events.performanceTimeline")}
          </Typography>
          <Tabs
            excludeFromSearch
            handleSelect={(value) => setSelectedTab(value as KpiTypeEnum)}
            selectedTab={selectedTab}
            tabs={tabs}
          />
        </Box>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Box
              height={274}
              px={1.5}
              py={2}
              sx={{
                bgcolor: { xs: "white", md: "transparent" },
                borderRadius: { xs: 2, md: 0 },
                boxShadow: { xs: 1, md: 0 },
                mt: { xs: 2, md: 4 },
              }}
            >
              <LineChart categories={seriesData.categories} series={seriesData.series} />
            </Box>
            <Box sx={{ display: { md: "none" } }}>
              <Bullets
                count={tabs.length}
                selectedIndex={tabs.findIndex((t) => t.value === selectedTab)}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
