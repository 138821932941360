import { useGetRolesQuery } from "@/pages/Private/redux";
import { UserRole } from "@/pages/Public/pages/Login/schema/login";
import { Role } from "@/enum";

interface RoleOption {
  text: string;
  value: Role;
}

// use role options hook
export const useRoleOptions = () => {
  const { data: roles } = useGetRolesQuery();

  const roleOptions = roles?.data.reduce((roleOptions: RoleOption[], role: UserRole) => {
    roleOptions.push({ text: role.name, value: role.code });

    return roleOptions;
  }, []);

  return { roles, roleOptions };
};
