import { api } from "@app/redux/api";

import { UpdateBrandBody } from "../pages/Brands/types";

import { RequestPaginationParams } from "./../../../types/request-params";
import {
  BrandsResponse,
  BrandsResponseSchema,
  BrandType,
  CreateOrEditBrandType,
  CreateOrEditRequestSchema,
  CreateResponseSchema,
} from "./../pages/Brands/schema/brand.schema";

export const brandsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBrands: builder.query<BrandsResponse, RequestPaginationParams>({
      query: (params) => ({
        url: "brands",
        params: {
          ...(params && { ...params }),
        },
        responseSchema: BrandsResponseSchema,
      }),
      providesTags: ["Brands"],
    }),
    getBrandsList: builder.query<{ data: BrandType[] }, { companyId?: number }>({
      query: (params) => ({
        url: "brands/list",
        params: {
          ...(params && { ...params }),
        },
      }),
    }),
    createBrand: builder.mutation<BrandType, CreateOrEditBrandType>({
      query: (body) => ({
        url: "brands",
        method: "POST",
        body,
        requestSchema: CreateOrEditRequestSchema,
        responseSchema: CreateResponseSchema,
      }),
      invalidatesTags: ["Brands"],
    }),
    updateBrand: builder.mutation<BrandType, UpdateBrandBody>({
      query: ({ body, id }) => ({
        url: `brands/${id}`,
        method: "PATCH",
        body,
        responseSchema: BrandsResponseSchema,
      }),
      invalidatesTags: ["Brands"],
    }),
  }),
});

export const {
  useGetBrandsQuery,
  useCreateBrandMutation,
  useUpdateBrandMutation,
  useGetBrandsListQuery,
  useLazyGetBrandsListQuery,
} = brandsApi;
export const brandsApiReducer = brandsApi.reducer;
export const brandsApiMiddleware = brandsApi.middleware;
