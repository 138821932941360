import { FunctionComponent, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { TargetIcon } from "@/assets";
import { formatNumbers } from "@/app/utils/helpers";

interface KpiSingleCardProps {
  kpi: string;
  sum: number;
  goal?: number;
}

export const KpiSingleCard: FunctionComponent<KpiSingleCardProps> = ({ kpi, sum, goal }) => {
  const { t } = useTranslation();
  const ts = useCallback((key: string) => t(`events.kpis.${key}`), [t]);

  return (
    <Box
      bgcolor="white"
      borderRadius={2}
      boxShadow={1}
      mb={1}
      mr={1}
      pt={1.5}
      px={1.5}
      sx={{
        display: { xs: "inline-block" },
        height: { xs: "auto" },
        pb: { xs: 1.5, md: 1 },
        width: "280px",
      }}
    >
      <>
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ lineHeight: "23px" }} variant="gray14Bold">
            {ts(`${kpi}`)}
          </Typography>
          <Box alignItems="center" color={"main"} display="flex">
            <Box alignItems={"center"} display="flex" ml={3}>
              <Box display="inline-flex" height="23px" pt={0.5}>
                <TargetIcon height={19} width={19} />
              </Box>
              <Typography fontSize={18} ml={1} variant="main28">
                {formatNumbers(sum)}
                {goal ? <Typography variant="gray14Bold">/{formatNumbers(goal)}</Typography> : null}
              </Typography>
            </Box>
          </Box>
        </Box>
      </>
    </Box>
  );
};
