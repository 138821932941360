import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/app/redux";

import { BrandsState } from "../pages/Brands/types";
import { brandsApi } from "./brands.api";

const initialState: BrandsState = {
  brands: [],
};

export const brandsSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addMatcher(brandsApi.endpoints.getBrandsList.matchFulfilled, (state, { payload }) => {
      state.brands = payload.data;
    }),
});

export const brandsReducer = brandsSlice.reducer;
export const brandsSelector = (state: RootState) => state.brands;
