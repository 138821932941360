import { MobileEventModuleType, ModuleType } from "./schema/modules.schema";

export enum EventModuleType {
  FESTIVAL_MODULE = "FESTIVAL_MODULE",
  STATIONARY_TOUCHPOINT = "STATIONARY_TOUCHPOINT",
  MOBILE_LOCATION = "MOBILE_LOCATION",
  MOBILE_TEAM = "MOBILE_TEAM",
}
export interface ModuleState {
  modules: ModuleType[] | null;
  mobileEventModules: MobileEventModuleType[] | null;
  moduleDetails: ModuleType | null;
  filters: { moduleType: string | null; locationIds: string[] | null; teamId: string | null };
}
