import { ForgotPasswordValues } from "@/types/auth.types";

import { api } from "@/app/redux/api";

import { ForgotPasswordRequestSchema } from "../pages/ForgotPassword/forgot-password.schema";
import {
  LoginResponseSchema,
  FetchMeResponseSchema,
  LoginRequestSchema,
  TLoginResponse,
  TLoginRequest,
  UserTable,
} from "../pages/Login/schema/login";
import {
  SetPasswordBody,
  SetPasswordRequestSchema,
} from "../pages/SetPassword/set-password.schema";

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchMe: builder.query<UserTable, string>({
      query: (token) => ({
        url: "users/me",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseSchema: FetchMeResponseSchema,
      }),
    }),
    login: builder.mutation<TLoginResponse, TLoginRequest>({
      query: (body) => ({
        url: "users/login",
        method: "POST",
        body,
        requestSchema: LoginRequestSchema,
        responseSchema: LoginResponseSchema,
      }),
      invalidatesTags: [
        "Brands",
        "Events",
        "Users",
        "Companies",
        "Modules",
        "Projects",
        "DataSources",
      ],
    }),

    resetPassword: builder.mutation<void, ForgotPasswordValues>({
      query: (body) => ({
        url: "users/reset-password",
        method: "POST",
        body,
        requestSchema: ForgotPasswordRequestSchema,
      }),
    }),
    setPassword: builder.mutation<void, SetPasswordBody>({
      query: (body) => ({
        url: "users/set-password",
        method: "POST",
        body,
        requestSchema: SetPasswordRequestSchema,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useResetPasswordMutation,
  useSetPasswordMutation,
  useLazyFetchMeQuery,
} = authApi;
export const authApiReducer = authApi.reducer;
export const authApiMiddleware = authApi.middleware;
