import { Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, DialogActions, DialogContent, Typography } from "@mui/material";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { InputField, Button, Modal } from "@/components";

import { notify } from "@/app/utils/notify";
import { ERROR_TYPE, translateError } from "@/app/utils";
import { ServerError } from "@/types/error";
import {
  useCreateProjectMutation,
  useUpdateProjectMutation,
} from "@/pages/Private/redux/projects.api";

import {
  CreateOrEditProjectType,
  CreateOrEditRequestSchema,
  ProjectType,
} from "../../ProjectReporting/project.schema";

interface CreateOrEditProjectModalIf {
  project?: ProjectType | null;
  isOpen: boolean;
  handleClose?: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isEditing: boolean;
}

export const CreateOrEditProjectModal: FunctionComponent<CreateOrEditProjectModalIf> = ({
  project,
  isOpen,
  setIsOpen,
  isEditing,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`projects.modals.${key}`);

  const [createProject, { isLoading }] = useCreateProjectMutation();
  const [updateProject, { isSuccess, isLoading: isUpdateLoading }] = useUpdateProjectMutation();

  const initialValues: CreateOrEditProjectType = {
    name: project?.name ?? "",
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<CreateOrEditProjectType>({
    defaultValues: initialValues,
    resolver: zodResolver(CreateOrEditRequestSchema),
  });

  const handleClose = useCallback(() => {
    setIsOpen(false);
    reset();
  }, [setIsOpen, reset]);

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess, handleClose]);

  const onCreateSubmit = async (values: CreateOrEditProjectType) => {
    try {
      const { name } = values;
      const body: CreateOrEditProjectType = {
        name,
      };

      await createProject(body).unwrap();
      setIsOpen(false);
      notify({ text: t("notifications.createProject") });
    } catch (err) {
      notify({
        text: translateError[(err as ServerError).data.message as ERROR_TYPE],
        type: "error",
      });
    }
  };

  const onEditSubmit = async (values: CreateOrEditProjectType) => {
    try {
      const body: CreateOrEditProjectType = {};

      for (const key in values) {
        const initialValue = initialValues[key as keyof CreateOrEditProjectType];
        const currentValue = values[key as keyof CreateOrEditProjectType];

        if (currentValue !== initialValue) {
          body[key as keyof CreateOrEditProjectType] = currentValue;
        }
      }

      if (Object.entries(body).length > 0) {
        await updateProject({ id: project?.id ?? 0, body }).unwrap();
        notify({ text: t("notifications.updateProject") });
      } else {
        handleClose();
      }
    } catch (err) {
      notify({
        text: translateError[(err as ServerError).data.message as ERROR_TYPE],
        type: "error",
      });
    }
  };

  return (
    <Modal
      setShow={handleClose}
      show={isOpen}
      title={isEditing ? ts("editProject.title") : ts("createProject.title")}
    >
      <DialogContent
        dividers
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          border: "none",
          padding: 1.5,
          paddingTop: 0,
        }}
      >
        <Typography component="p" mb={0.5} variant="main14">
          {isEditing ? ts("editProject.cta") : ts("createProject.cta")}
        </Typography>
        {
          <Box>
            <Box mb={1} width="100%">
              <InputField
                hasError={errors.name && !!errors.name}
                {...register("name")}
                placeholder={ts("createProject.projectNamePlaceholder")}
              />
            </Box>
          </Box>
        }
      </DialogContent>

      <DialogActions
        sx={{
          padding: 1.5,
        }}
      >
        <Button
          handleClick={isEditing ? handleSubmit(onEditSubmit) : handleSubmit(onCreateSubmit)}
          isDisabled={!isValid}
          isLoading={isLoading || isUpdateLoading}
          title={isEditing ? t("basics.save") : t("create")}
          type="submit"
        />
        <Button handleClick={handleClose} title={t("basics.cancel")} variant="outlined" />
      </DialogActions>
    </Modal>
  );
};
