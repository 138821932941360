import { z } from "zod";

import { ERROR_TYPE } from "@/app/utils/errors";
import { TokenPurpose } from "@/enum";

const { PASSWORD_GUIDELINES, PASSWORD_MISMATCH, REQUIRED } = ERROR_TYPE;

export const regEx =
  /(?=[\p{L}\p{N}~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]+$)^(?=.*[\p{Ll}])(?=.*[\p{Lu}])(?=.*[\p{N}])(?=.*[~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/])(?=.{8,}).*$/u;

export const SetPasswordSchema = z
  .object({
    password: z.string().min(1, { message: REQUIRED }).regex(regEx, PASSWORD_GUIDELINES).trim(),
    confirmPassword: z.string().min(1, { message: REQUIRED }).trim(),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: "custom",
        path: ["confirmPassword"],
        message: PASSWORD_MISMATCH,
      });
    }
  });

export type SetPasswordType = z.infer<typeof SetPasswordSchema>;

export const SetPasswordRequestSchema = z.object({
  password: z.string().min(8),
  confirmPassword: z.string().min(8),
  token: z.string().min(1, { message: REQUIRED }),
  purpose: z.nativeEnum(TokenPurpose),
});
export type SetPasswordBody = z.infer<typeof SetPasswordRequestSchema>;
