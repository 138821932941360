import { FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import { DonutChart, Loader } from "@/components";
import { Dashboard } from "@/enum";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";

interface DoiByGenderDonutChartProps {
  isFetching: boolean;
  isFiltration?: boolean;
  kpis: KpiType[];
}

export const DoiByGenderDonutChart: FunctionComponent<DoiByGenderDonutChartProps> = ({
  isFetching,
  isFiltration,
  kpis,
}) => {
  const { t } = useTranslation();
  const { dashboard } = useParams();

  const isDoiChart = dashboard === Dashboard.DOI;
  const ts = useCallback(
    (key: string) => {
      return t(`events.doiKPIs.doiSplitByGender.states.${key}`);
    },
    [t]
  );

  const registrationsByGenderLabels = useMemo(() => {
    const doiKPIs =
      kpis.find((kpi) => kpi.name === KpiTypeEnum.NEWSLETTER_DATA_BY_GENDER)?.data ?? [];
    const registrationGenders = doiKPIs.map((d) => d.specific);

    return registrationGenders.map((type) => {
      const recordData = doiKPIs.find((kpi) => kpi.specific === type);

      return recordData ? ts(recordData.specific) ?? "" : "";
    });
  }, [kpis, ts]);

  const registrationsByGendersData = useMemo(() => {
    const doiKPIs =
      kpis.find((kpi) => kpi.name === KpiTypeEnum.NEWSLETTER_DATA_BY_GENDER)?.data ?? [];
    const registrationGenders = doiKPIs.map((d) => d.specific);

    return registrationGenders.map((type) => {
      const recordData = doiKPIs.find((kpi) => kpi.specific === type);

      if (recordData) {
        if (isFiltration) {
          return Math.round(recordData.date?.[0].percent ?? 0);
        } else {
          return Math.round(recordData.overall?.percent ?? 0);
        }
      } else {
        return 0;
      }
    });
  }, [kpis, isFiltration]);

  return (
    <Box
      sx={{
        paddingX: { xs: 0, md: 1 },
        paddingY: { xs: 1, md: 0 },
      }}
    >
      <Typography component="p" mb={1} variant="mainBold">
        {t("events.doiKPIs.doiSplitByGender.title")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : (
        <DonutChart
          isDoiChart={isDoiChart}
          kpi={t("events.doiKPIs.doiSplitByGender.title")}
          labels={registrationsByGenderLabels}
          series={registrationsByGendersData}
        />
      )}
    </Box>
  );
};
