import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { HeatmapChart, Loader, Tabs } from "@/components";
import { formatInTimeZone } from "@/app/utils";
import { useAppSelector } from "@/app/redux/hooks";
import { eventsSelector } from "@/pages/Private/redux";

import { KpiTypeEnum } from "../../../../types";
import { KpiType } from "../../../../event.schema";

interface HeatmapProps {
  kpis: KpiType[];
  isFiltration?: boolean;
  isLoading?: boolean;
  defaultTab: KpiTypeEnum;
}

export const SalesByDayHeatmapChart: FunctionComponent<HeatmapProps> = ({
  kpis,
  isLoading,
  defaultTab,
}) => {
  const { t } = useTranslation();
  const ts = useCallback((key: string) => t(`events.salesKPIs.byCategoryType.${key}`), [t]);

  const [selectedTab, setSelectedTab] = useState<KpiTypeEnum>(defaultTab);

  const { eventDetails } = useAppSelector(eventsSelector);

  //Tab menu items
  const tabs = useMemo(() => {
    return [
      {
        text: ts(`evoPacks`),
        value: KpiTypeEnum.SALES_BY_PRODUCT_BY_CIGARETTESTYPE_ECSCATEGORY,
      },
      {
        text: ts(`heaters`),
        value: KpiTypeEnum.SALES_BY_PRODUCT_BY_HEATERDEVICE,
      },
    ];
  }, [ts]);

  const heatmapLabels = useMemo(() => {
    return kpis.find((k) => k.name === selectedTab)?.data.map((product) => product.specific) || [];
  }, [kpis, selectedTab]);

  const heatmapData = useMemo(() => {
    const eventDates =
      eventDetails?.eventDates.map((date) =>
        formatInTimeZone(new Date(date.fromDateTime), "yyyy-MM-dd")
      ) || [];

    const heatmapValues: {
      name: string;
      data: {
        x: string;
        y: number;
      }[];
    }[] = [];

    const salesByProductCategory = kpis.find((k) => k.name === selectedTab)?.data;

    if (!salesByProductCategory) {
      return heatmapValues;
    }

    for (let i = 0; i < eventDates.length; i++) {
      const stringEventDate = eventDates[i];
      const heatmapData = [];

      for (let k = 0; k < salesByProductCategory.length; k++) {
        const product = salesByProductCategory[k];

        const value = product.date?.find((o) => o.date === stringEventDate)?.value || 0;

        heatmapData.push({ x: product.specific, y: +value.toFixed(2) });
      }

      heatmapValues.push({
        name: stringEventDate,
        data: heatmapData,
      });
    }

    return [...heatmapValues].sort((a, b) => b.name.localeCompare(a.name));
  }, [eventDetails?.eventDates, kpis, selectedTab]);

  return (
    <Box
      sx={{
        p: 3,
        flexGrow: 1,
        bgcolor: "white",
        borderRadius: 2,
        boxShadow: 1,
        position: "relative",
        display: { xs: "none", md: "block" },
      }}
    >
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Typography component="h3" variant="mainBold">
          {t("events.heatmapSalesByDays")}
        </Typography>
        <Tabs
          excludeFromSearch
          handleSelect={(value) => setSelectedTab(value as KpiTypeEnum)}
          selectedTab={selectedTab}
          tabs={tabs}
        />
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <HeatmapChart
          kpi={t("events.heatmapSalesByDays").toLowerCase()}
          labels={heatmapLabels}
          series={heatmapData}
        />
      )}
    </Box>
  );
};
