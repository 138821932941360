import { FunctionComponent, useMemo } from "react";
import { Box } from "@mui/material";
import { utcToZonedTime } from "date-fns-tz";

import { timeZone } from "@/app/utils";
import { LoadingOverlay } from "@/components";
import { useAppSelector } from "@/app/redux/hooks";
import { eventsSelector } from "@/pages/Private/redux";

import { GiveawaysTotalTile } from "./components/GiveawaysTotalTile";
import { GiveawaysByDayDonutChart } from "./components/GiveawaysByDayDonutChart";
import { GiveawaysTimelineChart } from "./components/GiveawaysTimelineChart";
import { GiveawaysTopTenTable } from "./components/GiveawaysTopTenTable";
import { GiveawaysByModuleHeatmap } from "./components/GiveawaysByModuleHeatmap";
import { GiveawaysByModuleDonutChart } from "./components/GiveawaysByModuleDonutChart";

interface EventGiveawaysDashboardProps {
  eventDateId: number | null;
  isFetching: boolean;
}
export const EventGiveawaysDashboard: FunctionComponent<EventGiveawaysDashboardProps> = ({
  eventDateId,
  isFetching,
}) => {
  const { selectedEvent: event } = useAppSelector(eventsSelector);

  const timeLineDates = useMemo(() => {
    if (eventDateId) {
      const eventDate = event?.eventDates.find((date) => date.id === eventDateId);

      return {
        // start: eventDate?.fromDateTime ?? "",
        // end: eventDate?.tillDateTime ?? "",
        start: utcToZonedTime(new Date(eventDate?.fromDateTime ?? ""), timeZone),
        end: utcToZonedTime(new Date(eventDate?.tillDateTime ?? ""), timeZone),
      };
    }

    const startDate = event?.eventDates.map((date) => date.fromDateTime)[0] ?? "";
    const endDate =
      event?.eventDates.map((date) => date.tillDateTime)[event?.eventDates.length - 1] ?? "";
    const start = utcToZonedTime(new Date(startDate), timeZone);
    const end = utcToZonedTime(new Date(endDate), timeZone);

    return { start, end };
  }, [event, eventDateId]);

  return !event ? (
    <LoadingOverlay />
  ) : (
    <>
      <Box
        sx={{
          mb: { xs: 2, md: 1 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
        }}
      >
        <GiveawaysTotalTile isFetching={isFetching} kpis={event?.data} />
        <Box
          bgcolor="white"
          borderRadius={2}
          boxShadow={1}
          p={1.5}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
            pb: { xs: 1.5, md: 1 },
          }}
        >
          <GiveawaysByModuleDonutChart
            isFetching={isFetching}
            isFiltration={!!eventDateId}
            kpis={event?.data}
          />
          {!!!eventDateId && (
            <GiveawaysByDayDonutChart isFetching={isFetching} kpis={event?.data} />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: { md: "flex" },
          mb: { xs: 2, md: 1 },
        }}
      >
        <GiveawaysTimelineChart dates={timeLineDates} isLoading={isFetching} kpis={event?.data} />
        <GiveawaysTopTenTable
          giveawaysKPIs={event?.data}
          isFetching={isFetching}
          isFiltration={!!eventDateId}
        />
      </Box>
      <GiveawaysByModuleHeatmap isFiltration={!!eventDateId} kpis={event?.data} />
    </>
  );
};
