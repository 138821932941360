import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/app/redux";

import { CompanyState } from "../pages/Companies/types";
import { companiesApi } from "./companies.api";

const initialState: CompanyState = {
  companies: [],
  selectedCompany: null,
  companyDetails: null,
};

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addMatcher(companiesApi.endpoints.getCompanies.matchFulfilled, (state, { payload }) => {
        state.companies = payload.data;
      })
      .addMatcher(companiesApi.endpoints.getCompanyById.matchFulfilled, (state, { payload }) => {
        state.companyDetails = payload;
      })
      .addMatcher(companiesApi.endpoints.updateCompany.matchFulfilled, (state, { payload }) => {
        state.companyDetails = payload;
        if (state.selectedCompany) {
          state.selectedCompany = {
            ...state.selectedCompany,
          };
        }
      }),
});

export const companiesReducer = companiesSlice.reducer;
export const companiesSelector = (state: RootState) => state.companies;
