import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useSearch = (limitResults?: number) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const paramPageValue = searchParams.get("page") || 1;

  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(+paramPageValue);
  const [limit, setLimit] = useState(limitResults ?? 35);

  const handleSetPage = (page: number) => {
    setPage(page);
    setSearchParams({ ...searchParams, ...{ page: String(page) } }, { replace: true });
  };

  const searchTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }

    searchTimer.current = setTimeout(() => {
      setSearchValue(searchValue);
    }, 500);
  }, [searchValue]);

  return { searchValue, setSearchValue, page, setPage: handleSetPage, limit, setLimit };
};
