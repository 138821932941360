import { authSelector } from "@/pages/Public/redux/auth.slice";
import { PermissionRoles } from "@/enum";

import { useAppSelector } from "../redux/hooks";
import { getAllUserPermissions } from "../utils";

export const useHasUserPermission = (permission: PermissionRoles): boolean => {
  const { user } = useAppSelector(authSelector);

  if (!user) {
    return false;
  }

  const userPermissions = getAllUserPermissions(user.roles);

  return userPermissions.includes(permission);
};
