import { rest } from "msw";

import { mockUrl } from "@/app/constants";

import { loginResponseMock, userMock } from "./auth.mocks";

export const authHandlers = [
  rest.post(mockUrl("api/login"), (_, res, ctx) => {
    return res(ctx.json(loginResponseMock), ctx.status(200));
  }),
  rest.get(mockUrl("api/me"), (_, res, ctx) => {
    return res(ctx.json(userMock), ctx.status(200));
  }),
  rest.post(mockUrl("api/reset-password"), (_, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.post(mockUrl("api/set-password"), (_, res, ctx) => {
    return res(ctx.status(200));
  }),
];
