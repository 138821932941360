import { FunctionComponent, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";

import { Button, ButtonSize, InputField } from "@/components";
import { useAppSelector } from "@/app/redux/hooks";
import { eventsSelector } from "@/pages/Private";
import { useUpdateEventMutation } from "@/pages/Private/redux";

import { formatNumbers, notify, removeThousandsSeparator } from "@/app/utils";
import { PermissionRoles } from "@/enum";
import { useHasUserPermission } from "@/app/hooks/useHasUserPermission";

import { EventBudgetType } from "../../../../event.schema";

export const Budget: FunctionComponent = () => {
  const { t } = useTranslation();
  const ts = useCallback((key: string) => t(`events.settings.budget.${key}`), [t]);

  const [updateEvent, { isSuccess, isError, isLoading }] = useUpdateEventMutation();

  const { eventDetails } = useAppSelector(eventsSelector);

  const isEditable = useMemo(
    () =>
      eventDetails?.budgets?.reduce((flag, budget) => {
        flag = !budget.completed;

        return flag;
      }, true),
    [eventDetails]
  );

  const editBudgetPermission = useHasUserPermission(PermissionRoles.UPDATE_EVENT_BUDGET);

  const initialValues = {
    budgets: eventDetails?.budgets || [],
  };
  const { control, handleSubmit, getValues, register, watch } = useForm({
    defaultValues: initialValues,
  });

  const { fields } = useFieldArray({
    control,
    name: "budgets",
  });

  const inputData = watch();
  const plannedSum = useMemo(
    () =>
      inputData.budgets?.reduce(
        (sumAmount, currBudget) =>
          sumAmount +
          (isNaN(removeThousandsSeparator(currBudget.planned))
            ? 0
            : removeThousandsSeparator(currBudget.planned)),
        0
      ),
    [inputData]
  );
  const spentSum = useMemo(
    () =>
      inputData.budgets?.reduce(
        (sumAmount, currBudget) =>
          sumAmount +
          (isNaN(removeThousandsSeparator(currBudget.actual))
            ? 0
            : removeThousandsSeparator(currBudget.actual)),
        0
      ),
    [inputData]
  );
  const balanceAmount = plannedSum - spentSum;

  const finalize = async (values: { budgets: EventBudgetType[] }) => {
    const disabledBudgets = values.budgets.reduce(
      (newBudget: EventBudgetType[], currBudget: EventBudgetType) => {
        currBudget.planned = removeThousandsSeparator(currBudget.planned);
        currBudget.actual = removeThousandsSeparator(currBudget.actual);
        currBudget.completed = !currBudget.completed;
        newBudget.push(currBudget);

        return newBudget;
      },
      []
    );

    const body = { budgets: disabledBudgets };

    await updateEvent({ id: eventDetails!.id, body });
  };

  const updateBudgets = async (values: { budgets: EventBudgetType[] }) => {
    const budgets = values.budgets.reduce((arr: EventBudgetType[], currBudget: EventBudgetType) => {
      currBudget.planned = removeThousandsSeparator(currBudget.planned);
      currBudget.actual = removeThousandsSeparator(currBudget.actual);

      const oldBudgetValues = eventDetails?.budgets.find((b) => b.type === currBudget.type);

      if (
        oldBudgetValues?.planned !== currBudget.planned ||
        oldBudgetValues?.actual !== currBudget.actual
      ) {
        arr.push(currBudget);
      }

      return arr;
    }, []);

    if (budgets.length) {
      const body = { budgets };

      await updateEvent({ id: eventDetails!.id, body });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      notify({ text: t("notifications.eventUpdated") });
    }

    if (isError) {
      notify({ text: t("notifications.wentWrong"), type: "error" });
    }
  }, [isSuccess, isError, t]);

  return (
    <Box
      bgcolor="white"
      borderRadius={2}
      boxShadow={1}
      maxWidth="844px"
      mt={4}
      p={2}
      width="fit-content"
    >
      <Box borderBottom="1px solid" borderColor="gray400.main" mb={2} pb={2}>
        <Typography color="gray900.main" fontSize={18} fontWeight={600} mb={1.5}>
          {ts("title")}
        </Typography>
        <Typography color="gray900.main" fontSize={14} fontWeight={400}>
          {ts("description")}
        </Typography>
      </Box>
      <Box component="form">
        <Box borderBottom="1px solid" borderColor="gray400.main" display="flex" mb={2} pb={2}>
          <Grid container rowGap={1}>
            <Grid container item columns={4} gap={5}>
              <Grid item xs={1.1}>
                <Typography variant="mainBold">{ts("budgetPosition")}</Typography>
              </Grid>
              <Grid item xs={1.1}>
                <Typography variant="mainBold">{ts("planned")}</Typography>
              </Grid>
              <Grid item xs={1.1}>
                <Typography variant="mainBold">{ts("spent")}</Typography>
              </Grid>
            </Grid>
            {fields?.map((budget, i) => (
              <Grid key={budget.id} container item columns={4} gap={5}>
                <Grid item xs={1.1}>
                  <InputField
                    border
                    disabled
                    smallSize
                    value={t(`events.budgets.${budget.type}`)}
                  />
                </Grid>
                <Grid item xs={1.1}>
                  <InputField
                    smallSize
                    {...register(`budgets.${i}.planned`)}
                    border
                    disabled={budget.completed || !editBudgetPermission}
                    value={formatNumbers(getValues(`budgets.${i}.planned`))}
                  />
                </Grid>
                <Grid item xs={1.1}>
                  <InputField
                    smallSize
                    {...register(`budgets.${i}.actual`)}
                    border
                    disabled={budget.completed || !editBudgetPermission}
                    value={formatNumbers(getValues(`budgets.${i}.actual`))}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box borderBottom="1px solid" borderColor="gray400.main" display="flex" mb={2} pb={2}>
          <Grid container rowGap={1}>
            <Grid container item columns={4} gap={5}>
              <Grid item xs={1.1}></Grid>
              <Grid item xs={1.1}>
                <Typography variant="mainBold">
                  {ts("planned")} ({ts("sum")})
                </Typography>
              </Grid>
              <Grid item xs={1.1}>
                <Typography variant="mainBold">
                  {ts("spent")} ({ts("sum")})
                </Typography>
              </Grid>
            </Grid>

            <Grid container item columns={4} gap={5}>
              <Grid item xs={1.1}></Grid>
              <Grid item xs={1.1}>
                <InputField border disabled smallSize value={`Σ €${formatNumbers(plannedSum)}`} />
              </Grid>
              <Grid item xs={1.1}>
                <InputField border disabled smallSize value={`Σ €${formatNumbers(spentSum)}`} />
              </Grid>
            </Grid>

            <Grid container item columns={4} gap={5}>
              <Grid item xs={1.1}></Grid>
              <Grid item xs={1.1}></Grid>
              <Grid item xs={1.1}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="mainBold">{ts("balance")}</Typography>
                  <Typography
                    color={balanceAmount < 0 ? "red.main" : "green.main"}
                    fontWeight={600}
                  >
                    {balanceAmount < 0 ? "-" : "+"}
                    <Typography component="span" fontWeight={400}>
                      €
                    </Typography>
                    {formatNumbers(Math.abs(balanceAmount))}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Typography display="block" mb={1.5} variant="mainBold">
            {ts("finalizeBudget")}
          </Typography>
          <Typography color="gray900.main" fontSize={14} fontWeight={400} mb={3}>
            {ts("finalizeBudgetDescription")}
          </Typography>
          <Box display="flex" justifyContent="space-between" maxWidth={266}>
            <Box minWidth={128}>
              <Button
                handleClick={handleSubmit(updateBudgets)}
                isDisabled={!editBudgetPermission}
                isLoading={isLoading}
                size={ButtonSize.M}
                title={t("basics.save")}
              />
            </Box>
            <Box minWidth={128}>
              <Button
                handleClick={handleSubmit(finalize)}
                isDisabled={!editBudgetPermission}
                isLoading={isLoading}
                size={ButtonSize.M}
                title={t(isEditable ? "basics.finalize" : "basics.unlock")}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
