import { FunctionComponent, useMemo } from "react";
//import { useTranslation } from "react-i18next";

import { formatNumbers } from "@/app/utils";

import { KpiTypeEnum, TopTenTableData } from "../../../../types";
import { KpiType } from "../../../../event.schema";
import { TopTenTable } from "./TopTenTable";

interface TopTenTableModuleProps {
  isFetching: boolean;
  salesKPIs: KpiType[];
  isFiltration?: boolean;
  isPloomEvent?: boolean;
}

export const TopTenModuleTable: FunctionComponent<TopTenTableModuleProps> = ({
  isFetching,
  salesKPIs,
  isFiltration,
  isPloomEvent,
}) => {
  const filteredSalesModuleKPI = useMemo(() => {
    const salesByProductProducts = salesKPIs.find(
      (k) => k.name === KpiTypeEnum.SALES_BY_MODULE
    )?.data;

    const dataArray: TopTenTableData[] = [];

    if (!salesByProductProducts) {
      return dataArray;
    }

    for (const i of salesByProductProducts) {
      const dates = isFiltration ? i.date : [i.overall];

      const item = {
        // Transform into SalesQuantity format
        name: i.specific ? i.specific : "",
        quantity: dates?.[0]?.value ? formatNumbers(dates?.[0].value.toFixed(0)) : 0,
        percentage: dates?.[0]?.percent ? dates?.[0].percent : 0,
      };

      dataArray.push(item);
    }

    dataArray.sort((a, b) => {
      if (a.percentage < b.percentage) {
        return 1;
      }

      if (a.percentage > b.percentage) {
        return -1;
      }

      return 0;
    });

    return dataArray;
  }, [isFiltration, salesKPIs]);

  return (
    <TopTenTable
      dataToPass={filteredSalesModuleKPI}
      isFetching={isFetching}
      namingPath={isPloomEvent ? "ploomModule" : "module"}
    />
  );
};
