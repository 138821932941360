import { FunctionComponent, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

import { useAppSelector } from "@/app/redux/hooks";
import { Button, SelectField } from "@/components";
import { setActiveBar } from "@/pages/Private";
import {
  clearModulesFilter,
  modulesSelector,
  setModulesFilter,
} from "@/pages/Private/redux/modules.slice";
import { moduleTypesArray } from "@/app/constants";

export const ModulesFilter: FunctionComponent<{ isForMobile?: boolean }> = ({ isForMobile }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { filters } = useAppSelector(modulesSelector);

  const moduleTypeOptions = useMemo(() => {
    return moduleTypesArray?.map((type) => {
      return { text: type.value, value: type.id };
    });
  }, []);

  const { register, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      moduleType: filters.moduleType ?? "DEFAULT",
    },
  });

  const moduleType = watch("moduleType");

  const onSubmit = handleSubmit((values) => {
    dispatch(
      setModulesFilter({
        moduleType: values.moduleType !== "DEFAULT" ? values.moduleType : null,
      })
    );
    dispatch(setActiveBar(null));
  });

  const clearFilters = () => {
    dispatch(clearModulesFilter());
    reset({
      moduleType: "DEFAULT",
    });
  };

  useEffect(() => {
    if (!isForMobile) {
      dispatch(
        setModulesFilter({
          moduleType: moduleType === "DEFAULT" ? null : moduleType,
        })
      );
    }

    return () => {
      dispatch(clearModulesFilter());
    };
  }, [moduleType, dispatch, isForMobile]);

  return (
    <Box
      alignItems="center"
      display="flex"
      flexGrow={1}
      height="100%"
      sx={{
        justifyContent: { xs: "space-between", md: "unset" },
        flexDirection: { xs: "column", md: "row" },
      }}
      width="100%"
    >
      <Box
        sx={{
          mr: { xs: 0, md: 2 },
          mb: { xs: 2, md: 0 },
          maxWidth: { md: 220 },
        }}
        width="100%"
      >
        <SelectField
          withDefaultValue
          currentValue={moduleType}
          options={moduleTypeOptions || []}
          placeholder={t("terms.selectType")}
          {...register("moduleType")}
        />
      </Box>
      {isForMobile && (
        <Box pb={3} width="100%">
          {!!Object.values(filters).filter((v) => v !== null).length && (
            <Typography
              color="red.main"
              component="p"
              fontSize={16}
              fontWeight={700}
              mb={3}
              textAlign="center"
              variant="link"
              onClick={clearFilters}
            >
              {t("basics.clearFilter")}
            </Typography>
          )}
          <Button handleClick={onSubmit} title={t("basics.search")} type="submit" />
        </Box>
      )}
    </Box>
  );
};
