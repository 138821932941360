import { forwardRef, useState } from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";

import { UseFormRegisterReturn } from "react-hook-form";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

interface PasswordInputStyledProps {
  label?: string;
  classes?: string;
  hasError?: boolean;
}

export type PasswordInputProps = {
  value?: string | number;
  placeholder?: string;
  autocompleteValue?: string;
} & UseFormRegisterReturn &
  PasswordInputStyledProps;

const Input = styled.input<PasswordInputStyledProps>`
  width: 100%;
  height: 18px;
  padding: 13px 16px;
  padding-right: 46px;
  font-size: 14px;
  outline: none;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  ${(props) => (props.label ? "padding-top: 28px; padding-bottom: 10px" : "")}
  ${(props) => props.classes},
	${(props) => (props.hasError ? "border: 1px solid red;" : "")}
  &::placeholder {
    color: #919eab;
  }
`;

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  function CustomPasswordInput(
    { value, label, hasError, classes, autocompleteValue, placeholder, ...rest },
    ref
  ) {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <Box sx={{ position: "relative", display: "flex", alignItems: "center", width: "100%" }}>
        {label && (
          <Box
            component="label"
            htmlFor={rest.name}
            sx={{
              position: "absolute",
              top: 10,
              left: 16,
              fontSize: 14,
              color: "",
            }}
          >
            {label}
          </Box>
        )}
        <Input
          autoComplete={autocompleteValue}
          id={rest.name}
          placeholder={placeholder}
          type={showPassword ? "text" : "password"}
          {...rest}
          ref={ref}
          classes={classes}
          hasError={hasError}
          label={label}
        />

        <Box
          sx={{ position: "absolute", cursor: "pointer", top: 12, right: 16 }}
          onClick={() => setShowPassword(!showPassword)}
        >
          {!showPassword ? (
            <VisibilityOffOutlined sx={{ width: "22px", height: "auto", color: "gray600.main" }} />
          ) : (
            <VisibilityOutlined sx={{ width: "22px", height: "auto", color: "gray600.main" }} />
          )}
        </Box>
      </Box>
    );
  }
);
