import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { HeatmapChart, Loader, Tabs } from "@/components";
import { useAppSelector } from "@/app/redux/hooks";
import { eventsSelector } from "@/pages/Private/redux";
import { filterEventDates, formatInTimeZone, timeZone } from "@/app/utils";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum, NewsletterWithDeviceSpecifics } from "../../../../types";

interface HeatmapProps {
  kpis: KpiType[];
  isFiltration?: boolean;
  isLoading?: boolean;
  defaultTab: KpiTypeEnum;
}

export const HeatmapByDayAndHour: FunctionComponent<HeatmapProps> = ({
  kpis,
  isLoading,
  defaultTab,
}) => {
  const { t } = useTranslation();
  const ts = useCallback((key: string) => t(`events.kpis.${key}`), [t]);

  const [selectedTab, setSelectedTab] = useState<KpiTypeEnum>(defaultTab);

  const { eventDetails, selectedEvent } = useAppSelector(eventsSelector);

  //Tab menu items
  const tabs = useMemo(() => {
    return [
      {
        text: ts(`NEWSLETTER_DATA_WITH_DEVICE_ID`),
        value: KpiTypeEnum.NEWSLETTER_DATA,
      },
      {
        text: ts(`SALES_BY_DEVICE`),
        value: KpiTypeEnum.SALES_BY_DEVICE,
      },
      {
        text: ts(`SALES_BY_CIGARETTESTYPE_ECSCATEGORY`),
        value: KpiTypeEnum.SALES_BY_CIGARETTESTYPE_ECSCATEGORY,
      },
      {
        text: ts(`SALES`),
        value: KpiTypeEnum.SALES,
      },
    ].filter((tab) => kpis.some((kpi) => kpi.name === tab.value));
  }, [kpis, ts]);

  const heatmapLabels = useMemo(() => {
    const selectedTabKpiData = kpis.find((k) => k.name === selectedTab)?.data;
    const labels: string[] = [];

    if (!selectedTabKpiData) {
      return labels;
    }

    //Heatmap requires 24 columns to show data for every hour of a day.
    //Used to show the xaxis labels on the heatmap
    for (let i = 0; i < 24; i++) {
      labels.push(i < 10 ? `0${i}:00` : `${i}:00`);
    }

    return labels;
  }, [kpis, selectedTab]);

  const heatmapData = useMemo(() => {
    const eventDates = filterEventDates({
      eventDates: eventDetails?.eventDates ?? [],
      startDate: selectedEvent?.startDate ?? "",
      endDate: selectedEvent?.endDate ?? "",
      isAlsoFilterByDataAvailability: true,
    });

    //Used to show the yaxis labels on the heatmap
    const datesLabels = eventDates.map((date) =>
      formatInTimeZone(new Date(date.fromDateTime), "yyyy.MM.dd")
    );

    const heatmapValues: {
      /** Event date on the left of the heatmap e.g 2024.05.17 */
      name: string;
      data: {
        x: string;
        y: number;
      }[];
    }[] = [];

    const isNewsletterData = selectedTab === KpiTypeEnum.NEWSLETTER_DATA;

    const kpiDataByHour = isNewsletterData
      ? kpis
          .find((kpi) => kpi.name === selectedTab)
          ?.data.find(
            (newsletterDataType) =>
              newsletterDataType.specific === NewsletterWithDeviceSpecifics.DOI_NL_WITH_DEVICE_ID
          )?.hour
      : kpis.find((kpi) => kpi.name === selectedTab)?.data[0].hour;

    if (!kpiDataByHour) {
      return heatmapValues;
    }

    for (let i = 0; i < eventDates.length; i++) {
      const dayData = kpiDataByHour
        .map((d) => {
          const zonedTime = utcToZonedTime(new Date(d.time), timeZone);
          const formattedTime = format(zonedTime, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");

          return {
            ...d,
            time: formattedTime,
          };
        })
        .filter((hourData) => {
          return hourData.time.substring(0, 10) === eventDates[i].fromDateTime.substring(0, 10);
        });

      const heatmapData = [];

      for (const hour of heatmapLabels) {
        const hourValue =
          dayData.find((hourData) => hourData.time.substring(11, 16) === hour)?.value || 0;

        heatmapData.push({ x: hour, y: +hourValue.toFixed(2) });
      }

      heatmapValues.push({
        name: datesLabels[i],
        data: heatmapData,
      });
    }

    return [...heatmapValues].sort((a, b) => b.name.localeCompare(a.name));
  }, [heatmapLabels, eventDetails, selectedTab, kpis, selectedEvent]);

  return (
    <Box
      sx={{
        p: 3,
        flexGrow: 1,
        bgcolor: "white",
        borderRadius: 2,
        boxShadow: 1,
        position: "relative",
        display: { xs: "none", md: "block" },
      }}
    >
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Typography component="h3" variant="mainBold">
          {t("events.heatmapByDayAndHour")}
        </Typography>
        <Tabs
          excludeFromSearch
          handleSelect={(value) => setSelectedTab(value as KpiTypeEnum)}
          selectedTab={selectedTab}
          tabs={tabs}
        />
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <HeatmapChart
          kpi={t("events.heatmapByDayAndHour").toLowerCase()}
          labels={heatmapLabels}
          series={heatmapData}
        />
      )}
    </Box>
  );
};
