import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "@/app/redux";
import { ActiveBarEnum } from "@/types";

const initialState: { activeBar: ActiveBarEnum | null } = {
  activeBar: null,
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setActiveBar: (state, action: PayloadAction<ActiveBarEnum | null>) => {
      state.activeBar = action.payload;
    },
  },
});

export const { setActiveBar } = sidebarSlice.actions;
export const sidebarReducer = sidebarSlice.reducer;
export const sidebarSelector = (state: RootState) => state[sidebarSlice.name];
