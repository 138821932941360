import { useTranslation } from "react-i18next";
import { FunctionComponent, useCallback, useMemo } from "react";
import { Box, Typography } from "@mui/material";

import { DonutChart, Loader } from "@/components";
import { toPercent } from "@/app/utils";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum, SalesPloomProductCategories } from "../../../../types";

interface SalesByAccessoriesProps {
  isFiltration?: boolean;
  isFetching: boolean;
  ploomSalesKPIs: KpiType[];
}

export const SalesByAccessoriesDonutChart: FunctionComponent<SalesByAccessoriesProps> = ({
  isFiltration,
  isFetching,
  ploomSalesKPIs,
}) => {
  const { t } = useTranslation();

  /** Additional function to calculate the overall of sales by category
   * @param kpi KpiType with products
   * @returns The total sum of the kpi products overall values
   */
  const salesByProductCategory = useCallback(
    (kpi: KpiType) => {
      return kpi.data.reduce((totalKpiSales, product) => {
        totalKpiSales += (isFiltration ? product.date?.at(0)?.value : product.overall?.value) ?? 0;

        return totalKpiSales;
      }, 0);
    },
    [isFiltration]
  );

  // Sales split by Device & Packs donut chart labels names
  const salesByAccessoriesLabels = useMemo(() => {
    return ploomSalesKPIs.reduce((chartLabels: string[], kpi) => {
      if (kpi.name === KpiTypeEnum.SALES_BY_ACCESSORIES_FUNCTIONAL_BY_PRODUCT) {
        chartLabels.push(SalesPloomProductCategories.FUNCTIONAL);
      } else if (kpi.name === KpiTypeEnum.SALES_BY_ACCESSORIES_DEVICE_BY_PRODUCT) {
        chartLabels.push(SalesPloomProductCategories.DEVICE);
      }

      return chartLabels;
    }, []);
  }, [ploomSalesKPIs]);

  // Sales split by Device & Packs donut chart data
  const salesByAccessoriesData = useMemo(() => {
    const deviceAndPacksKpis = ploomSalesKPIs.filter(
      (kpi) =>
        kpi.name === KpiTypeEnum.SALES_BY_ACCESSORIES_FUNCTIONAL_BY_PRODUCT ||
        kpi.name === KpiTypeEnum.SALES_BY_ACCESSORIES_DEVICE_BY_PRODUCT
    );

    const totalSales = deviceAndPacksKpis.reduce((kpisOverallSum, kpi) => {
      kpisOverallSum += salesByProductCategory(kpi);

      return kpisOverallSum;
    }, 0);

    return deviceAndPacksKpis.map(
      (kpi) =>
        Math.round(toPercent({ number: salesByProductCategory(kpi), total: totalSales })) ?? 0
    );
  }, [ploomSalesKPIs, salesByProductCategory]);

  return (
    <Box
      sx={{
        borderStyle: "solid",
        borderWidth: { xs: "0 0 1px 0", md: "0 1px 0 0" },
        borderColor: "gray400.main",
        px: { xs: 0, md: 1 },
        pt: { xs: 1, md: 0 },
      }}
    >
      <Typography component="p" mb={1} variant="mainBold">
        {t("events.donutSalesByAccessories")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : (
        <DonutChart
          kpi={t("events.donutSalesByAccessories")}
          labels={salesByAccessoriesLabels}
          series={salesByAccessoriesData}
        />
      )}
    </Box>
  );
};
