import { FunctionComponent, useMemo, useState } from "react";
import { Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import { formatInTimeZone } from "date-fns-tz";

import { convertHourToMinutes } from "@/app/utils";

import { TableColumn, TableComponent } from "@/components";

import { KpiTypeEnum } from "../../../../types";
import { KpiType } from "../../../../event.schema";
import { NewsletterDataType } from "../EventPromoterDashboard";

interface PromoterOverallPerformanceProps {
  isFetching: boolean;
  isFiltration?: boolean;
  promoterKPIs: KpiType[];
  timeFilterStartingMinutes: number | null;
}

interface PromoterTableContent {
  id: number;
  email: string;
  DOI: number;
  DOI_NL: number;
  WITHOUT_DOI: number;
  DOI_WITH_DEVICE_ID: number;
  DOI_NL_WITH_DEVICE_ID: number;
  PENDING_WITH_DEVICE_ID: number;
  sum: number;
}

export const PromoterOverallPerformance: FunctionComponent<PromoterOverallPerformanceProps> = ({
  isFetching,
  isFiltration,
  promoterKPIs,
  timeFilterStartingMinutes,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.topTenTables.promoter.promoterOverViewtable.${key}`);

  const [sortColumn, setSortColumn] = useState<keyof PromoterTableContent | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const handleSort = (columnName: keyof PromoterTableContent) => {
    const isAsc = sortColumn === columnName && sortDirection === "asc";

    setSortDirection(isAsc ? "desc" : "asc");
    setSortColumn(columnName);
  };

  const data = useMemo(() => {
    if (!promoterKPIs) return [];

    let idCounter = 1; // Start the ID counter

    const promoterNLDataByMailKPIs =
      promoterKPIs.find((kpi) => kpi.name === KpiTypeEnum.NEWSLETTER_DATA_BY_PROMOTEREMAIL)?.data ||
      [];

    const promoterTableData: PromoterTableContent[] = promoterNLDataByMailKPIs.flatMap((kpi) => {
      const dataToMap = timeFilterStartingMinutes
        ? kpi.hour?.filter((el) => {
            const promoterStartingMins = convertHourToMinutes(
              formatInTimeZone(new Date(el.time), "UTC", "HH:mm")
            );

            return (
              promoterStartingMins >= timeFilterStartingMinutes &&
              promoterStartingMins < timeFilterStartingMinutes + 60
            );
          }) || []
        : kpi.date.filter((kpi) => (isFiltration ? kpi.date : !kpi.date)) || [];

      return dataToMap.map((val) => {
        const result = {
          id: idCounter++,
          email: val.dim2 ?? "", // Handle missing email case here
          DOI: 0,
          DOI_WITH_DEVICE_ID: 0,
          DOI_NL: 0,
          DOI_NL_WITH_DEVICE_ID: 0,
          WITHOUT_DOI: 0,
          PENDING_WITH_DEVICE_ID: 0,
          sum: val.value,
        };

        switch (kpi.specific) {
          case NewsletterDataType.DOI:
            result.DOI = val.value;
            break;
          case NewsletterDataType.DOI_WITH_DEVICE_ID:
            result.DOI_WITH_DEVICE_ID = val.value;
            break;
          case NewsletterDataType.DOI_NL:
            result.DOI_NL = val.value;
            break;
          case NewsletterDataType.DOI_NL_WITH_DEVICE_ID:
            result.DOI_NL_WITH_DEVICE_ID = val.value;
            break;
          case NewsletterDataType.WITHOUT_DOI:
            result.WITHOUT_DOI = val.value;
            break;
          case NewsletterDataType.PENDING_WITH_DEVICE_ID:
            result.PENDING_WITH_DEVICE_ID = val.value;
            break;
        }

        return result;
      });
    });

    const promoterTableRowData = promoterTableData.reduce<Record<string, PromoterTableContent>>(
      (
        acc,
        {
          id,
          email,
          DOI,
          DOI_WITH_DEVICE_ID,
          DOI_NL,
          DOI_NL_WITH_DEVICE_ID,
          WITHOUT_DOI,
          PENDING_WITH_DEVICE_ID,
          sum,
        }
      ) => {
        if (!acc[email]) {
          acc[email] = {
            id: id,
            email,
            DOI: 0,
            DOI_WITH_DEVICE_ID: 0,
            DOI_NL_WITH_DEVICE_ID: 0,
            PENDING_WITH_DEVICE_ID: 0,
            DOI_NL: 0,
            WITHOUT_DOI: 0,
            sum: 0,
          };
        }

        acc[email].DOI += DOI;
        acc[email].DOI_WITH_DEVICE_ID += DOI_WITH_DEVICE_ID;
        acc[email].DOI_NL += DOI_NL;
        acc[email].DOI_NL_WITH_DEVICE_ID += DOI_NL_WITH_DEVICE_ID;
        acc[email].WITHOUT_DOI += WITHOUT_DOI;
        acc[email].PENDING_WITH_DEVICE_ID += PENDING_WITH_DEVICE_ID;
        acc[email].sum += sum;

        return acc;
      },
      {}
    );

    return Object.values(promoterTableRowData);
  }, [promoterKPIs, timeFilterStartingMinutes, isFiltration]);

  const sortedData = useMemo(() => {
    if (!sortColumn) return data; // No sort applied

    return [...data].sort((a, b) => {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];

      if (valueA < valueB) {
        return sortDirection === "asc" ? -1 : 1;
      }

      if (valueA > valueB) {
        return sortDirection === "asc" ? 1 : -1;
      }

      return 0;
    });
  }, [data, sortColumn, sortDirection]);

  // Define the columns for the table
  const columns: TableColumn<PromoterTableContent>[] = [
    {
      title: ts("email"),
      render: (item) => <span>{item.email}</span>,
      sortable: sortColumn === "email",
      onClick: () => handleSort("email"),
    },
    {
      title: ts("doiNL"),
      render: (item) => <Typography>{item.DOI_NL}</Typography>,
      sortable: sortColumn === "DOI_NL",
      onClick: () => handleSort("DOI_NL"),
      align: "center",
    },
    {
      title: ts("doiNLWithDeviceId"),
      render: (item) => <Typography>{item.DOI_NL_WITH_DEVICE_ID}</Typography>,
      sortable: sortColumn === "DOI_NL_WITH_DEVICE_ID",
      onClick: () => handleSort("DOI_NL_WITH_DEVICE_ID"),
      align: "center",
    },
    {
      title: ts("doi"),
      render: (item) => <Typography>{item.DOI}</Typography>,
      sortable: sortColumn === "DOI",
      onClick: () => handleSort("DOI"),
      align: "center",
    },
    {
      title: ts("doiWithDeviceId"),
      render: (item) => <Typography>{item.DOI_WITH_DEVICE_ID}</Typography>,
      sortable: sortColumn === "DOI_WITH_DEVICE_ID",
      onClick: () => handleSort("DOI_WITH_DEVICE_ID"),
      align: "center",
    },
    {
      title: ts("withoutDOI"),
      render: (item) => <Typography>{item.WITHOUT_DOI}</Typography>,
      sortable: sortColumn === "WITHOUT_DOI",
      onClick: () => handleSort("WITHOUT_DOI"),
      align: "center",
    },
    {
      title: ts("pendingWithDeviceId"),
      render: (item) => <Typography>{item.PENDING_WITH_DEVICE_ID}</Typography>,
      sortable: sortColumn === "PENDING_WITH_DEVICE_ID",
      onClick: () => handleSort("PENDING_WITH_DEVICE_ID"),
      align: "center",
    },
    {
      title: ts("sum"),
      render: (item) => <Typography>{item.sum}</Typography>,
      sortable: sortColumn === "sum",
      onClick: () => handleSort("sum"),
      align: "center",
    },
  ];

  return (
    <TableComponent
      allowNavigation={false}
      columns={columns}
      data={sortedData}
      handleFetchPage={function (page: number): void {
        throw new Error("Function not implemented.");
      }}
      isLoading={isFetching}
      noDataText="No items found"
      page={0}
      sortOrder={sortDirection} // This should dynamically change based on the actual sort state
      totalPages={0}
    />
  );
};
