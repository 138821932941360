import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { Button } from "@/components";
import { useGetUsersQuery } from "@/pages/Private/redux";
import { useSearch } from "@/app/utils";

import { UsersTable } from "./components/UsersTable";
import { InviteUserModal } from "./components/InviteUserModal";

export const UsersOverview: FunctionComponent = () => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`users.${key}`);
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);

  const { page, setPage, limit } = useSearch();
  const { data: users, isLoading } = useGetUsersQuery({ page, limit });

  //Changes the browser tab title dynamically
  useEffect(() => {
    window.document.title = t("windowTitles.users");

    return () => {
      window.document.title = t("windowTitles.default");
    };
  }, [t]);

  return (
    <>
      <Typography mb={1.5} mt={4} variant="h1">
        {ts("title")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { md: "space-between" },
          mb: 3,
        }}
      >
        <Typography
          component="p"
          mb={1.5}
          sx={{ maxWidth: { xs: "100%", md: 566 } }}
          variant="main"
        >
          {ts("description")}
        </Typography>
        <Box
          sx={{
            minWidth: { xs: "100%", md: "fit-content" },
          }}
        >
          <Button
            handleClick={() => setIsInviteDialogOpen(true)}
            title={ts("create")}
            variant="contained"
          />
        </Box>
      </Box>
      <UsersTable
        handleFetchPage={setPage}
        isLoading={isLoading}
        meta={users?.meta}
        page={page}
        users={users?.data || []}
      />

      <InviteUserModal isOpen={isInviteDialogOpen} setIsOpen={setIsInviteDialogOpen} />
    </>
  );
};
