import { FunctionComponent, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { compareAsc } from "date-fns";

import { eventsSelector, useUpdateEventMutation } from "@/pages/Private/redux";
import { useAppSelector } from "@/app/redux/hooks";
import { LoadingOverlay } from "@/components";
import { convertEventDates, formatInTimeZone, notify } from "@/app/utils";
import { useUploadImage } from "@/pages/Private/helpers/useUploadImage";
import { defaultEventDate } from "@/app/constants";

import { EventSettingForm } from "../../../components";
import {
  EventRequestBodyType,
  EventRequestType,
  NoAddressEventRequestBodyType,
} from "../../../../event.schema";
import { EventTypes } from "../../../../types";

const hourFormat = "HH:mm";
const yearFormat = "yyyy-MM-dd";

export const isFestivalEvent = (eventType: string | EventTypes) => {
  return eventType === EventTypes.FESTIVAL || eventType === EventTypes.PLOOM_FESTIVAL;
};

export const General: FunctionComponent = () => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.settings.general.${key}`);
  const { eventDetails: event } = useAppSelector(eventsSelector);

  const [updateEvent, { isSuccess, isError, isLoading }] = useUpdateEventMutation();
  const { uploadImage, isLoading: isImageLoading } = useUploadImage();

  const eventDates = useMemo(() => {
    const dates = event?.eventDates.map((date) => {
      const endOnTheNextDay = compareAsc(
        new Date(formatInTimeZone(new Date(date.fromDateTime), yearFormat)),
        new Date(formatInTimeZone(new Date(date.tillDateTime), yearFormat))
      );

      return {
        date: formatInTimeZone(new Date(date.fromDateTime), yearFormat),
        fromDateTime: formatInTimeZone(new Date(date.fromDateTime), hourFormat),
        tillDateTime: formatInTimeZone(new Date(date.tillDateTime), hourFormat),
        isAfterMidnight: endOnTheNextDay < 0,
      };
    });

    return dates;
  }, [event?.eventDates]);

  const initialValuesWithoutAddress: NoAddressEventRequestBodyType = {
    eventType: event?.eventType ?? "",
    name: event?.name ?? "",
    externalId: event?.externalId ?? "",
    projectId: event?.projectId ?? 0,
    brandId: event?.brandId ?? 0,
    companyId: event?.companyId ?? 0,
    image: event?.image ?? "",
  };

  const initialValues: EventRequestBodyType = {
    eventType: event?.eventType ?? "",
    name: event?.name ?? "",
    externalId: event?.externalId ?? "",
    projectId: event?.projectId ?? 0,
    brandId: event?.brandId ?? 0,
    companyId: event?.companyId ?? 0,
    eventDates: eventDates ?? [defaultEventDate],
    street: event?.street ?? "",
    houseNumber: event?.houseNumber ?? "",
    city: event?.city ?? "",
    zip: event?.zip ?? "",
    nielsen: event?.nielsen ?? "",
    image: event?.image ?? "",
  };

  type GenericEventRequestBodyType = EventRequestBodyType | NoAddressEventRequestBodyType;
  type InitialValuesType = typeof initialValues | typeof initialValuesWithoutAddress;

  /** Handles submission of event data, updating based on changes between initial and current values.
   * @param values The current event data to be submitted.
   * @param initialValues The initial event data used as a baseline for comparison.
   * @param isFestivalEvent Indicates whether the event is a festival event.
   * @returns A Promise that resolves once the event data is successfully submitted and updated.
   */
  const handleSubmit = async <T extends GenericEventRequestBodyType>(
    values: T,
    initialValues: InitialValuesType,
    isFestivalEvent: boolean
  ): Promise<void> => {
    const body = isFestivalEvent
      ? { eventDates: convertEventDates((values as EventRequestBodyType).eventDates!) }
      : ({} as Partial<EventRequestType>);

    for (const key in values) {
      if (Object.hasOwnProperty.call(initialValues, key)) {
        const initialValue = (initialValues as Record<string, unknown>)[key];
        const currentValue = values[key];

        if (currentValue !== initialValue && key !== "eventDates") {
          (body as Record<string, unknown>)[key] = currentValue;
        }
      }
    }

    if (Object.entries(body).length > 0) {
      if (body.image) {
        const image = await uploadImage(body.image);

        body.image = image;
      }

      await updateEvent({ id: event!.id, body });
    } else {
      return;
    }
  };

  const onSubmit = isFestivalEvent(event?.eventType ?? "")
    ? async (values: EventRequestBodyType) =>
        handleSubmit(values, initialValues, isFestivalEvent(event?.eventType ?? ""))
    : async (values: NoAddressEventRequestBodyType) =>
        handleSubmit(values, initialValuesWithoutAddress, isFestivalEvent(event?.eventType ?? ""));

  useEffect(() => {
    if (isSuccess) {
      notify({ text: t("notifications.eventUpdated") });
    }

    if (isError) {
      notify({ text: t("notifications.wentWrong"), type: "error" });
    }
  }, [isSuccess, isError, t]);

  return !event ? (
    <LoadingOverlay />
  ) : (
    <EventSettingForm
      isEditing
      description={ts("description")}
      initialValues={initialValues}
      isLoading={isLoading || isImageLoading}
      onSubmit={onSubmit}
    />
  );
};
