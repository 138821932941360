import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

declare global {
  interface Window {
    __APP_VERSION__: string;
  }
}

const useVersionCheck = () => {
  const { t } = useTranslation();
  const versionUpdateNotificationShown = useRef(false);

  useEffect(() => {
    const checkVersion = async () => {
      try {
        const response = await fetch("/version.txt", { cache: "no-store" });

        if (!response.ok) {
          throw new Error(`Error fetching version: ${response.statusText}`);
        }

        const fetchedVersion = await response.text();
        const localVersion = window.__APP_VERSION__;

        if (localVersion !== fetchedVersion && !versionUpdateNotificationShown.current) {
          versionUpdateNotificationShown.current = true;

          toast.info(t("notifications.versionUpdate"), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
            onClick: () => {
              window.location.reload();
              versionUpdateNotificationShown.current = false;
            },
          });
        }
      } catch (error) {
        console.error("Error checking version:", error);
      }
    };

    const interval = setInterval(checkVersion, 60000); // Set the checking interval to 1 minute

    return () => clearInterval(interval);
  }, [t]);
};

export default useVersionCheck;
