import { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { InputField, Button } from "@/components";
import { useResetPasswordMutation } from "@/pages/Public/redux/auth.api";
import { ERROR_TYPE, renderErrorMessages } from "@/app/utils/errors";
import { ROUTE_CONFIG } from "@/app/routes/config";

import { ForgotPasswordSchema, ForgotPasswordType } from "./forgot-password.schema";

export const ForgotPassword: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [resetPassword, { isLoading, isError, isSuccess }] = useResetPasswordMutation();
  const [showError, setShowError] = useState(false);

  const initialValues: ForgotPasswordType = {
    email: "",
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isValid, isSubmitting },
  } = useForm<ForgotPasswordType>({
    defaultValues: initialValues,
    resolver: zodResolver(ForgotPasswordSchema),
  });

  useEffect(() => {
    if (isSubmitting && !isValid) {
      setShowError(true);
    }
  }, [isSubmitting, isValid]);

  const onSubmit = handleSubmit(async (values) => {
    try {
      await resetPassword(values);
    } catch (err) {
      console.error(err);
    }
  });

  const hasSubmitted = !!isError || !!isSuccess;

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
      onSubmit={onSubmit}
    >
      <Typography mb={2} variant="h1">
        {t("forgotPassword.title")}
      </Typography>
      <Typography mb={3} textAlign="left">
        {t("forgotPassword.description")}
      </Typography>

      <Box mb={1} width="100%">
        <InputField
          autocompleteValue="username"
          hasError={!!errors.email}
          placeholder={t("forgotPassword.emailPlaceholder")}
          {...register("email")}
        />
      </Box>
      <Box mb={4.5} width="100%">
        <Typography
          component="p"
          textAlign="right"
          variant="link"
          onClick={() => navigate(ROUTE_CONFIG.LOGIN)}
        >
          {t("basics.backToLogin")}
        </Typography>
      </Box>
      {showError && Object.keys(errors).length
        ? renderErrorMessages(Object.values(errors).map((error) => error.message) as ERROR_TYPE[])
        : null}
      {hasSubmitted ? (
        <p>{t("forgotPassword.submittedFeedbackMessage")}</p>
      ) : (
        <Button isLoading={isLoading} title={t("forgotPassword.forgotPasswordCta")} type="submit" />
      )}
    </Box>
  );
};
