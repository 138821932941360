import { FunctionComponent } from "react";
import { default as Chart } from "react-apexcharts";

import { useProductVersion } from "@/app/hooks/useProductVersion";
import { ProductVersion } from "@/types";
import { useAppSelector } from "@/app/redux/hooks";
import { eventsSelector } from "@/pages/Private";
import { EventTypes } from "@/pages/Private/pages/Events/types";

import { NoDataFound } from "../NoDataFound/NoDataFound";

interface DonutChartProps {
  labels: string[];
  series: number[];
  kpi: string;
  isDoiChart?: boolean;
}

export const DonutChart: FunctionComponent<DonutChartProps> = ({
  labels,
  series,
  kpi,
  isDoiChart,
}) => {
  const isWithoutData = !series.filter((s) => !!s).length;
  const currentProductVersion = useProductVersion();
  const { eventDetails } = useAppSelector(eventsSelector);

  const isPloomFestival = eventDetails?.eventType === EventTypes.PLOOM_FESTIVAL;

  return isWithoutData ? (
    <NoDataFound height="100px" kpi={kpi.toLowerCase()} />
  ) : (
    <Chart
      options={{
        noData: {
          text: "No data found",
        },
        series,
        chart: {
          offsetY: 0,
          type: "donut",
          redrawOnParentResize: true,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "70%",
            },
          },
        },
        stroke: {
          width: 0,
        },
        labels: isWithoutData ? [] : labels,
        colors:
          currentProductVersion === ProductVersion.BSURE
            ? ["#D6B60E", "#ABCCCC", "#969696", "#5E7580", "#2B3845"]
            : ["#8031A7", "#00B2A9", "#b298dc", "#919EAB", "#B9F1D8"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          offsetY: -20,
          position: "right",
          fontSize: "12px",
          fontWeight: 500,
          itemMargin: {
            vertical: 0,
            horizontal: 0,
          },
          width: 140,
          formatter(legendName, opts) {
            return `<div style="margin-left: 5px; display: inline-flex; justify-content: space-between; width: 84%;">
							<span>${legendName}</span>
							<span style="color: #919EAB;">${opts.w.globals.series[opts.seriesIndex]}%</span>
						</div>`;
          },
        },
      }}
      series={isWithoutData ? [] : series}
      type="donut"
      width={isPloomFestival && isDoiChart ? 350 : 310}
    />
  );
};
