import { Features } from "@/pages/Private/pages/Companies/enums";
import { EventTypes } from "@/pages/Private/pages/Events/types";
import i18n from "@app/i18n";

export const routes = {
  userEventDetailsDashboards: [
    {
      path: "overview",
      text: i18n.t("events.overview"),
      dashboardFeature: "default",
      visibleForEventTypes: [
        EventTypes.FESTIVAL,
        EventTypes.PLOOM_FESTIVAL,
        EventTypes.MOBILE,
        EventTypes.STATIONARY,
      ],
    },
    {
      path: "sales",
      text: i18n.t("events.sales"),
      dashboardFeature: Features.SALES_DASHBOARD,
      visibleForEventTypes: [
        EventTypes.FESTIVAL,
        EventTypes.PLOOM_FESTIVAL,
        EventTypes.MOBILE,
        EventTypes.STATIONARY,
      ],
    },
    {
      path: "doi",
      text: i18n.t("events.doi"),
      dashboardFeature: Features.DOI_DASHBOARD,
      visibleForEventTypes: [
        EventTypes.FESTIVAL,
        EventTypes.PLOOM_FESTIVAL,
        EventTypes.MOBILE,
        EventTypes.STATIONARY,
      ],
    },
    {
      path: "activations",
      text: i18n.t("events.activations"),
      dashboardFeature: Features.ACTIVATIONS_DASHBOARD,
      visibleForEventTypes: [EventTypes.FESTIVAL, EventTypes.PLOOM_FESTIVAL],
    },
    {
      path: "giveaways",
      text: i18n.t("events.giveaways"),
      dashboardFeature: Features.GIVEAWAYS_DASHBOARD,
      visibleForEventTypes: [EventTypes.FESTIVAL, EventTypes.PLOOM_FESTIVAL],
    },
  ],
  adminEventDetailsDashboards: [
    {
      path: "promoter",
      text: i18n.t("events.promoters"),
      dashboardFeature: Features.PROMOTERS_DASHBOARD,
      visibleForEventTypes: [
        EventTypes.FESTIVAL,
        EventTypes.PLOOM_FESTIVAL,
        EventTypes.MOBILE,
        EventTypes.STATIONARY,
      ],
    },
  ],
};
