export enum PermissionRoles {
  CREATE_ROLE = "CREATE_ROLE",
  READ_ROLES = "READ_ROLES",
  UPDATE_ROLE = "UPDATE_ROLE",
  DELETE_ROLE = "DELETE_ROLE",
  INVITE_USER = "INVITE_USER",
  GET_USER = "GET_USER",
  GET_ME = "GET_ME",
  DELETE_USER = "DELETE_USER",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  UPDATE_ME = "UPDATE_ME",
  UPDATE_USER = "UPDATE_USER",
  UPDATE_USER_ROLE = "UPDATE_USER_ROLE",
  GENERATE_2FA = "GENERATE_2FA",
  GET_PERMISSION = "GET_PERMISSION",
  UPDATE_PERMISSION = "UPDATE_PERMISSION",
  MANAGE_CONFIGURATION = "MANAGE_CONFIGURATION",
  CREATE_BRAND = "CREATE_BRAND",
  GET_BRANDS = "GET_BRANDS",
  UPDATE_BRANDS = "UPDATE_BRANDS",
  UPLOAD_FILES = "UPLOAD_FILES",
  GET_EVENTS = "GET_EVENTS",
  CREATE_EVENTS = "CREATE_EVENTS",
  UPDATE_EVENTS = "UPDATE_EVENTS",
  CREATE_EVENT_DATES = "CREATE_EVENT_DATES",
  GET_EVENT_DATES = "GET_EVENT_DATES",
  UPDATE_EVENT_DATES = "UPDATE_EVENT_DATES",
  CREATE_DATA_SOURCE = "CREATE_DATA_SOURCE",
  GET_DATA_SOURCE = "GET_DATA_SOURCES",
  UPDATE_DATA_SOURCE = "UPDATE_DATA_SOURCE",
  DELETE_DATA_SOURCE = "DELETE_DATA_SOURCE",
  GET_KPIS = "GET_KPIS",
  UNLOCK_KPI = "UNLOCK_KPI",
  CREATE_MODULE = "CREATE_MODULE",
  GET_MODULES = "GET_MODULES",
  UPDATE_MODULE = "UPDATE_MODULE",
  DELETE_MODULE = "DELETE_MODULE",
  GET_PROJECTS = "GET_PROJECTS",
  CREATE_PROJECT = "CREATE_PROJECT",
  UPDATE_PROJECT = "UPDATE_PROJECT",
  DELETE_PROJECT = "DELETE_PROJECT",
  CREATE_COMPANY = "CREATE_COMPANY",
  GET_COMPANY = "GET_COMPANY",
  UPDATE_COMPANY = "UPDATE_COMPANY",
  SELECT_BRANDS = "SELECT_BRANDS",
  UPDATE_EVENT_GENERAL = "UPDATE_EVENT_GENERAL",
  UPDATE_EVENT_BUDGET = "UPDATE_EVENT_BUDGET",
  UPDATE_EVENT_KPI = "UPDATE_EVENT_KPI",
  CREATE_GROUP = "CREATE_GROUP",
  GET_GROUP = "GET_GROUP",
  UPDATE_GROUP = "UPDATE_GROUP",
  DELETE_GROUP = "DELETE_GROUP",
  UPDATE_USER_GROUP = "UPDATE_USER_GROUP",
  GET_DATA_SOURCE_STATUS = "GET_DATA_SOURCE_STATUS",
  DELETE_COMPANY = "DELETE_COMPANY",
  LIST_PROJECTS = "LIST_PROJECTS",
  EDIT_2ND_PLANNED_KPI = "EDIT_2ND_PLANNED_KPI", //TECH DEBT - BA-706
}
