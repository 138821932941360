import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

import { useSearch } from "@/app/utils";
import { Button, FilterButton } from "@/components";
import { useGetModulesQuery } from "@/pages/Private/redux/modules.api";
import { ActiveBarEnum } from "@/types";
import { modulesSelector } from "@/pages/Private/redux/modules.slice";
import { useAppSelector } from "@/app/redux/hooks";
import { setActiveBar } from "@/pages/Private/redux";

import { ModulesFilter } from "./components/ModulesFilter";
import { ModulesTable } from "./components/ModulesTable";
import { CreateModuleModal } from "./components/CreateModuleModal";

export const ModulesOverview: FunctionComponent = () => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`modules.${key}`);
  const dispatch = useDispatch();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const { filters } = useAppSelector(modulesSelector);

  const { page, setPage, limit } = useSearch();
  const { data: modules, isLoading } = useGetModulesQuery({
    page,
    limit,
    types: filters.moduleType ?? undefined,
  });

  //Changes the browser tab title dynamically
  useEffect(() => {
    window.document.title = t("windowTitles.modules");

    return () => {
      window.document.title = t("windowTitles.default");
    };
  }, [t]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 4 }}>
        <Box>
          <Typography mb={1.5} variant="h1">
            {ts("title")}
          </Typography>
          <Typography
            component="p"
            mb={1.5}
            sx={{ maxWidth: { xs: "100%", md: "fit-content" } }}
            variant="main"
          >
            {ts("description")}
          </Typography>
        </Box>
        <FilterButton
          activeFilters={
            Object.values(filters).filter(
              (v) => v !== null && (Array.isArray(v) ? v.length > 0 : true)
            ).length
          }
          handleClick={() => dispatch(setActiveBar(ActiveBarEnum.MODULES_FILTER))}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          mb: 3,
        }}
      >
        <Box sx={{ display: { xs: "none", md: "block" }, minWidth: 300 }}>
          <ModulesFilter />
        </Box>
        <Box
          sx={{
            minWidth: { xs: "100%", md: 156 },
          }}
        >
          <Button
            handleClick={() => setCreateDialogOpen(true)}
            title={ts("create")}
            variant="contained"
          />
        </Box>
      </Box>
      <ModulesTable
        handleFetchPage={setPage}
        isLoading={isLoading}
        meta={modules?.meta}
        modules={modules?.data || []}
        page={page}
      />

      <CreateModuleModal isOpen={createDialogOpen} setIsOpen={setCreateDialogOpen} />
    </>
  );
};
