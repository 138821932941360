import { Dispatch, FunctionComponent, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, CircularProgress, DialogActions, DialogContent, Typography } from "@mui/material";

import { Button, InputField, Modal, SelectField } from "@/components";
import { ERROR_TYPE, notify, translateError } from "@/app/utils";
import { ServerError } from "@/types/error";
import { useCompanyOptions } from "@/app/hooks/useCompanyOptions";
import { useCreateModuleMutation } from "@/pages/Private/redux/modules.api";
import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";
import { Features } from "@/pages/Private/pages/Companies/enums";
import { moduleTypesArray } from "@/app/constants";

import { CreateRequestModuleType, CreateRequestSchema } from "../../../schema/modules.schema";

interface CreateModuleModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
export const CreateModuleModal: FunctionComponent<CreateModuleModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`modules.createModal.${key}`);

  const { companyOptions } = useCompanyOptions();
  const [create, { isLoading }] = useCreateModuleMutation();

  const isMultiTenancyActive = useIsFeatureActive(Features.MULTI_TENANCY);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    watch,
  } = useForm<CreateRequestModuleType>({
    defaultValues: {
      name: "",
      type: "",
      companyId: isMultiTenancyActive ? 0 : 1, //TODO Supposed to be adjusted when there are more companies/customers
    },
    resolver: zodResolver(CreateRequestSchema),
  });

  const moduleTypeOptions = useMemo(() => {
    return moduleTypesArray?.map((type) => {
      return { text: type.value, value: type.id };
    });
  }, []);

  const onSubmit = async (values: CreateRequestModuleType) => {
    try {
      await create(values).unwrap();
      setIsOpen(false);
      reset();
      notify({ text: t("notifications.createdModule") });
    } catch (err) {
      notify({
        text: translateError[(err as ServerError).data.message as ERROR_TYPE],
        type: "error",
      });
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    reset();
  };

  return (
    <Modal setShow={handleClose} show={isOpen} title={ts("title")}>
      <DialogContent
        dividers
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          border: "none",
          padding: 1.5,
          paddingTop: 0,
        }}
      >
        <Typography component="p" mb={0.5} variant="main14">
          {ts("description")}
        </Typography>
        {!companyOptions && isMultiTenancyActive ? (
          <Box
            sx={{
              padding: "5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box mb={1} width="100%">
              <InputField
                hasError={errors.name && !!errors.name}
                {...register("name")}
                placeholder={ts("namePlaceholder")}
              />
            </Box>
            <SelectField
              currentValue={watch("type") || "DEFAULT"}
              hasError={!!errors.type?.message}
              options={moduleTypeOptions || []}
              placeholder={t("terms.selectType")}
              {...register("type")}
            />
            {isMultiTenancyActive && (
              <SelectField
                currentValue={watch("companyId") || "DEFAULT"}
                hasError={!!errors.companyId?.message}
                options={companyOptions || []}
                placeholder={ts("companyPlaceholder")}
                {...register("companyId")}
              />
            )}
          </Box>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          padding: 1.5,
        }}
      >
        <Button
          handleClick={handleSubmit(onSubmit)}
          isDisabled={!isValid}
          isLoading={isLoading}
          title={ts("cta")}
          type="submit"
        />
        <Button handleClick={handleClose} title={t("basics.cancel")} variant="outlined" />
      </DialogActions>
    </Modal>
  );
};
