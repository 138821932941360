import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/app/redux";

import { EventState } from "../pages/Events/types";
import { eventsApi } from "./events.api";

const initialState: EventState = {
  events: [],
  selectedEvent: null,
  eventDetails: null,
  regions: null,
  filters: {
    brandId: null,
    eventTypeIds: [],
    projectId: null,
  },
  kpiTarget2TypeSwitchedOn: false, // TECH DEBT - BA-706
};

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setEventsFilter: (action, { payload }) => {
      action.filters = {
        brandId: payload.brandId ?? initialState.filters.brandId,
        eventTypeIds: payload.eventTypeIds ?? initialState.filters.eventTypeIds,
        projectId: payload.projectId ?? initialState.filters.projectId,
      };
    },
    // TECH DEBT - BA-706
    switchEventsKpiTarget2Type: (state) => {
      state.kpiTarget2TypeSwitchedOn = state.kpiTarget2TypeSwitchedOn ? false : true;
    },
    clearEventsFilter: (action) => {
      action.filters.brandId = initialState.filters.brandId;
      action.filters.eventTypeIds = initialState.filters.eventTypeIds;
      action.filters.projectId = initialState.filters.projectId;
      // action.filters = initialState.filters;
    },
    clearEventDetails: (state) => {
      state.eventDetails = null;
    },
    clearSelectedEvent: (state) => {
      state.selectedEvent = null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(eventsApi.endpoints.getEvents.matchFulfilled, (state, { payload }) => {
        state.events = payload.data;
      })
      .addMatcher(eventsApi.endpoints.getEventKpi.matchFulfilled, (state, { payload }) => {
        state.selectedEvent = payload;
      })
      .addMatcher(eventsApi.endpoints.getEventById.matchFulfilled, (state, { payload }) => {
        state.eventDetails = payload;
      })
      .addMatcher(eventsApi.endpoints.updateEvent.matchFulfilled, (state, { payload }) => {
        state.eventDetails = payload;
        if (state.selectedEvent) {
          state.selectedEvent = {
            ...state.selectedEvent,
            notes: payload.notes,
          };
        }
      })
      .addMatcher(eventsApi.endpoints.getRegions.matchFulfilled, (state, { payload }) => {
        state.regions = payload.data;
      }),
});

export const {
  setEventsFilter,
  clearEventsFilter,
  clearSelectedEvent,
  clearEventDetails,
  switchEventsKpiTarget2Type, // TECH DEBT - BA-706
} = eventsSlice.actions;
export const eventsReducer = eventsSlice.reducer;
export const eventsSelector = (state: RootState) => state.events;
