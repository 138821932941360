import {
  DataStatusResponse,
  DataStatusResponseSchema,
} from "@/pages/CameraStatus/schema/dataStatus.schema";
import { RequestPaginationParams } from "@/types/request-params";
import { api } from "@app/redux/api";

import {
  CreateDataSourceRequestSchema,
  CreateDataSourceType,
  DataSourceType,
  DataSourcesResponse,
  DataSourcesResponseSchema,
  DeleteDataSource,
  DeleteDataSourceSchema,
} from "../pages/DataSources/schema/dataSource.schema";
import { UpdateDataSourceBody } from "../pages/DataSources/types";

export const dataSourcesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDataSourceStatus: builder.query<DataStatusResponse, RequestPaginationParams>({
      query: (params) => ({
        url: "data-sources/status",
        params: {
          ...(params && { ...params }),
        },
        responseSchema: DataStatusResponseSchema,
      }),
    }),
    getDataSources: builder.query<DataSourcesResponse, RequestPaginationParams>({
      query: (params) => ({
        url: "data-sources",
        params: {
          ...(params && { ...params }),
        },
        responseSchema: DataSourcesResponseSchema,
      }),
      providesTags: ["DataSources"],
    }),
    createDataSource: builder.mutation<DataSourceType, CreateDataSourceType>({
      query: (body) => ({
        url: "data-sources",
        method: "POST",
        body,
        requestSchema: CreateDataSourceRequestSchema,
        responseSchema: CreateDataSourceRequestSchema,
      }),
      invalidatesTags: ["DataSources"],
    }),
    deleteDataSource: builder.mutation<DeleteDataSource, number>({
      query: (id) => ({
        url: `data-sources/${id}`,
        method: "DELETE",
        responseSchema: DeleteDataSourceSchema,
      }),
      invalidatesTags: ["DataSources"],
    }),
    updateDataSource: builder.mutation<DataSourceType, UpdateDataSourceBody>({
      query: ({ body, id }) => ({
        url: `data-sources/${id}`,
        method: "PATCH",
        body,
        responseSchema: DataSourcesResponseSchema,
      }),
      invalidatesTags: ["DataSources"],
    }),
  }),
});

export const {
  useGetDataSourceStatusQuery,
  useGetDataSourcesQuery,
  useCreateDataSourceMutation,
  useDeleteDataSourceMutation,
  useUpdateDataSourceMutation,
} = dataSourcesApi;
export const dataSourcesApiReducer = dataSourcesApi.reducer;
export const dataSourcesApiMiddleware = dataSourcesApi.middleware;
