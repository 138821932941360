import { FunctionComponent, useMemo } from "react";
import { Box } from "@mui/material";
import { utcToZonedTime } from "date-fns-tz";
import { DateRange } from "mui-daterange-picker-plus";

import { eventsSelector } from "@/pages/Private/redux";
import { useAppSelector } from "@/app/redux/hooks";
import { LoadingOverlay } from "@/components";
import { timeZone } from "@/app/utils";
import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";

import { Features } from "../../../../Companies/enums";

import {
  AgeDistribution,
  Donuts,
  KpiSwiper,
  Timeline,
  EventNotes,
  Heatmap,
  // RetrievedInfo,
  // ScoreImpactItem,
} from "..";
import { EventTypes, KpiTypeEnum } from "../../../types";
import { HeatmapByDayAndHour } from "./components/HeatmapByDayAndHour";
import { EventDatesActivityMonitor } from "./components/EventDatesActivityMonitor";

interface EventOverviewDashboardProps {
  eventDateId: number | null;
  isFetching: boolean;
  selectedDateRange: DateRange | null;
}

export const EventOverviewDashboard: FunctionComponent<EventOverviewDashboardProps> = ({
  eventDateId,
  selectedDateRange,
  isFetching,
}) => {
  const { selectedEvent: event, eventDetails } = useAppSelector(eventsSelector);

  const isEmotionsActive = useIsFeatureActive(Features.TILE_EMOTIONS);
  const isResidenceTimeActive = useIsFeatureActive(Features.TILE_RESIDENCE_TIME);
  const isAgeDistributionActive = useIsFeatureActive(Features.TILE_AGE_DISTRIBUTION);
  const isFootfallActive = useIsFeatureActive(Features.GRAPH_FOOTFALL);
  const isPloomEvent = eventDetails?.eventType !== EventTypes.FESTIVAL;
  const isPloomFestivalEvent = eventDetails?.eventType === EventTypes.PLOOM_FESTIVAL;

  const timeLineDates = useMemo(() => {
    if (eventDateId) {
      const eventDate = event?.eventDates.find((date) => date.id === eventDateId);

      return {
        start: utcToZonedTime(new Date(eventDate?.fromDateTime ?? ""), timeZone),
        end: utcToZonedTime(new Date(eventDate?.tillDateTime ?? ""), timeZone),
      };
    }

    const startDate = event?.eventDates.map((date) => date.fromDateTime)[0] ?? "";
    const endDate =
      event?.eventDates.map((date) => date.tillDateTime)[event?.eventDates.length - 1] ?? "";
    const start = utcToZonedTime(new Date(startDate), timeZone);
    const end = utcToZonedTime(new Date(endDate), timeZone);

    return { start, end };
  }, [event, eventDateId]);

  return !event ? (
    <LoadingOverlay />
  ) : (
    <Box>
      <KpiSwiper isFiltration={!!eventDateId} isLoading={isFetching} kpis={event.data} />
      <Box
        sx={{
          display: { md: "flex" },
          mb: { md: "30px" },
          gap: 1,
        }}
      >
        {isPloomEvent ? (
          <HeatmapByDayAndHour
            defaultTab={KpiTypeEnum.NEWSLETTER_DATA}
            isFiltration={!!eventDateId}
            isLoading={isFetching}
            kpis={event.data}
          />
        ) : (
          <Timeline
            dates={timeLineDates}
            isFiltration={!!selectedDateRange}
            isLoading={isFetching}
            kpis={event.data}
          />
        )}
        {isPloomEvent ? (
          isPloomFestivalEvent ? (
            <></>
          ) : (
            <EventDatesActivityMonitor isFetching={isFetching} />
          )
        ) : (
          isAgeDistributionActive && (
            <AgeDistribution
              isFiltration={!!eventDateId}
              isLoading={isFetching}
              kpis={event.data}
            />
          )
        )}
      </Box>
      {isPloomEvent ? (
        isPloomFestivalEvent ? (
          <EventNotes eventId={event.id} notes={event.notes} />
        ) : (
          <></>
        )
      ) : (
        <>
          <Box display="flex">
            {(isEmotionsActive || isResidenceTimeActive) && (
              <Donuts isFiltration={!!eventDateId} isLoading={isFetching} kpis={event.data} />
            )}
            {isFootfallActive && (
              <Heatmap isFiltration={!!eventDateId} isLoading={isFetching} kpis={event.data} />
            )}
          </Box>
          <EventNotes eventId={event.id} notes={event.notes} />
        </>
      )}
    </Box>
  );
};
