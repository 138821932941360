import { FunctionComponent } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";

import { ROUTE_CONFIG } from "@/app/routes/config";
import { useAppSelector } from "@/app/redux/hooks";
import { MainLayout } from "@/components";
import { theme } from "@/theme/theme";
import { bSureTheme } from "@/theme/bSureTheme";

import { authSelector } from "../Public/redux/auth.slice";
import { NotFound } from "../NotFound/NotFound";
import { Brands, Companies, Events, Users, Modules, DataSources } from "./pages";
import { AdminAuth } from "./pages/AdminAuth";
import { ProjectReporting } from "./pages/ProjectReporting/ProjectReporting";
import { Projects } from "./pages/Projects/Projects";

const { LOGIN } = ROUTE_CONFIG;

export const Private: FunctionComponent = () => {
  const { user } = useAppSelector(authSelector);

  if (!user) {
    return <Navigate to={LOGIN} />;
  }

  return (
    <ThemeProvider theme={user.company?.id === 1 ? theme : bSureTheme}>
      <MainLayout>
        <Routes>
          <Route element={<Events />} path="events/*" />
          <Route element={<ProjectReporting />} path="project-reporting/*" />
          <Route element={<AdminAuth />}>
            <Route element={<Users />} path="users" />
            <Route element={<Brands />} path="brands" />
            <Route element={<Modules />} path="modules/*" />
            <Route element={<Projects />} path="projects/*" />
            <Route element={<Companies />} path="companies/*" />
            <Route element={<DataSources />} path="data-sources" />
          </Route>
          <Route element={<NotFound />} path="*" />
        </Routes>
      </MainLayout>
    </ThemeProvider>
  );
};
