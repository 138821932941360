import { EventDataType } from "@/pages/Private/pages/Events/event.schema";

export const convertChartNumericData = ({
  enumValues,
  data,
  isFiltration,
}: {
  enumValues: string[];
  data: EventDataType[];
  isFiltration?: boolean;
}) => {
  const values: number[] = [];

  for (const record of enumValues) {
    const recordData = data.find((d) => d.specific === record);

    if (recordData) {
      if (isFiltration) {
        values.push(recordData.date?.[0].value ?? 0);
      } else {
        values.push(recordData.overall?.value ?? 0);
      }
    } else {
      values.push(0);
    }
  }

  return values;
};
export const convertChartStringData = ({
  enumValues,
  data,
  isFiltration,
}: {
  enumValues: string[];
  data: EventDataType[];
  isFiltration?: boolean;
}) => {
  const values: string[] = [];

  for (const record of enumValues) {
    const recordData = data.find((d) => d.specific === record);

    if (recordData) {
      if (isFiltration) {
        values.push(recordData.specific ?? "");
      } else {
        values.push(recordData.specific ?? "");
      }
    } else {
      values.push("");
    }
  }

  return values;
};
