import { ChangeEventHandler, FocusEventHandler, FunctionComponent } from "react";
import { SearchRounded } from "@mui/icons-material";
import { Box } from "@mui/material";
import styled from "@emotion/styled";

interface SearchInputStyledProps {
  hideIcon?: boolean;
  border?: boolean;
}

interface InputProps extends SearchInputStyledProps {
  value: string | number;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  handleBlur?: FocusEventHandler;
  name: string;
  onClick?: () => void;
  icon?: JSX.Element;
  disabled?: boolean;
  isNumber?: boolean;
}

const Input = styled.input<SearchInputStyledProps>`
  display: block;
  padding: 0 2px;
  ${(props) => (props.hideIcon ? "padding-left: 24px;" : "padding-left: 48px;")}
  width: 100%;
  height: 44px;
  outline: none;
  color: black;
  background: white;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  ${(props) => props.border && "border: 1px solid gray-lighter;"}
  box-sizing: border-box;
  &::placeholder {
    color: #919eab;
  }
  &:focus {
    border: none;
  }
`;

export const SearchField: FunctionComponent<InputProps> = ({
  value,
  handleChange,
  placeholder,
  handleBlur,
  name,
  onClick,
  disabled,
  isNumber,
  hideIcon = false,
  border,
}) => {
  return (
    <Box position="relative" sx={{ maxWidth: 320 }} width="100%" onClick={onClick}>
      <Input
        border={border}
        disabled={disabled}
        hideIcon={hideIcon}
        name={name}
        placeholder={placeholder}
        type={isNumber ? "number" : "text"}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      {!hideIcon && (
        <Box color="gray500.main" left={12} position="absolute" top={10}>
          <SearchRounded width={28} />
        </Box>
      )}
    </Box>
  );
};
