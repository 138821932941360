import { Typography } from "@mui/material";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";

import { MetaData } from "@/schemas/meta-data";
import { ConfirmationModal, LoadingOverlay, TableColumn, TableComponent } from "@/components";
import { ServerError } from "@/types/error";
import { useDeleteDataSourceMutation } from "@/pages/Private/redux/dataSources.api";
import { ERROR_TYPE, notify, translateError } from "@/app/utils";
import { PermissionRoles } from "@/enum";
import { useHasUserPermission } from "@/app/hooks/useHasUserPermission";

import { DataSourceType } from "../../../schema/dataSource.schema";
import { EditDataSourceModal } from "./EditDataSourceModal";

interface DataSourcesTableProps {
  dataSources: DataSourceType[];
  page: number;
  handleFetchPage: (page: number) => void;
  meta?: MetaData;
  isLoading?: boolean;
}

export const DataSourcesTable: FunctionComponent<DataSourcesTableProps> = ({
  dataSources,
  page,
  handleFetchPage,
  meta,
  isLoading,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`dataSources.${key}`);
  const tk = (key: string) => t(`dataSources.modals.deleteDataSource.${key}`);

  const [dataSource, setDataSource] = useState<DataSourceType | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const userCanEditDataSource = useHasUserPermission(PermissionRoles.UPDATE_DATA_SOURCE);
  const userCanDeleteDataSource = useHasUserPermission(PermissionRoles.DELETE_DATA_SOURCE);

  const [deleteDataSource] = useDeleteDataSourceMutation();

  const handleEditDataSource = (dataSource: DataSourceType) => {
    setDataSource(dataSource);
    setShowEditModal(true);
  };

  const handleDeleteModal = (dataSource: DataSourceType) => {
    setDataSource(dataSource);
    setShowConfirmationModal(true);
  };

  const handleDeleteDataSource = async () => {
    try {
      await deleteDataSource(dataSource?.id ?? 0).unwrap();
      notify({ text: t("notifications.deleteDataSource") });

      setShowConfirmationModal(false);
    } catch (err) {
      notify({
        text: translateError[(err as ServerError).data.message as ERROR_TYPE],
        type: "error",
      });
    }
  };

  const handleCloseModals = () => {
    setDataSource(null);
    setShowEditModal(false);
    setShowConfirmationModal(false);
  };

  const columns: TableColumn<DataSourceType>[] = [
    {
      title: ts("dataSourcesName"),
      render: (dataSource) => (
        <>
          <Typography
            sx={{ display: "inline-flex", color: "gray900.main", fontWeight: 500 }}
            variant="main14"
          >
            {dataSource.name}
          </Typography>{" "}
        </>
      ),
    },
    {
      title: ts("type"),
      render: (dataSource) => (
        <>
          <Typography
            sx={{ display: "inline-flex", color: "gray900.main", fontWeight: 500 }}
            variant="main14"
          >
            {dataSource.type}
          </Typography>{" "}
        </>
      ),
    },
    {
      title: ts("externalId"),
      render: (dataSource) => (
        <>
          <Typography
            sx={{ display: "inline-flex", color: "gray900.main", fontWeight: 500 }}
            variant="main14"
          >
            {dataSource.externalId}
          </Typography>{" "}
        </>
      ),
    },
    ...(userCanEditDataSource || userCanDeleteDataSource
      ? [
          {
            title: ts("actions"),
            width: 60,
            render: (dataSource: DataSourceType) => (
              <>
                {userCanDeleteDataSource && (
                  <DeleteOutline
                    sx={{
                      color: "gray600.main",
                      marginRight: 1,
                      "&:hover": {
                        opacity: 0.5,
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => handleDeleteModal(dataSource)}
                  />
                )}
                {userCanEditDataSource && (
                  <EditOutlined
                    sx={{
                      color: "gray600.main",
                      "&:hover": {
                        opacity: 0.5,
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => handleEditDataSource(dataSource)}
                  />
                )}
              </>
            ),
          },
        ]
      : []),
  ];

  return !dataSources ? (
    <LoadingOverlay />
  ) : (
    <>
      <TableComponent
        columns={columns}
        data={dataSources}
        handleFetchPage={handleFetchPage}
        isLoading={isLoading}
        page={page}
        totalPages={meta?.pageCount ?? 1}
      />

      <EditDataSourceModal
        dataSource={dataSource}
        handleClose={handleCloseModals}
        show={showEditModal}
      />
      <ConfirmationModal
        isDeletion
        confirmButton={t("basics.delete")}
        description={tk("description")}
        handleClose={handleCloseModals}
        handleConfirm={handleDeleteDataSource}
        isShown={showConfirmationModal}
        name={`${t("basics.name")}: ${dataSource?.name}`}
        title={tk("title")}
      />
    </>
  );
};
