import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { ModulesOverview } from "./pages/ModulesOverview/ModulesOverview";

export const Modules: FunctionComponent = () => {
  return (
    <Routes>
      <Route element={<ModulesOverview />} path="" />
    </Routes>
  );
};
