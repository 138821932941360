import { Close, CloseRounded } from "@mui/icons-material";
import { Dialog, DialogTitle } from "@mui/material";
import { FunctionComponent } from "react";

interface ModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  children: JSX.Element[] | JSX.Element;
  title?: string;
  testId?: string;
}

export const Modal: FunctionComponent<ModalProps> = ({ show, setShow, children, title }) => {
  return show ? (
    <Dialog fullWidth PaperProps={{ style: { borderRadius: 8 } }} open={show} onClose={setShow}>
      <DialogTitle sx={{ fontSize: { xs: "16px", md: "20px" }, padding: 1.5 }}>
        {title}

        <CloseRounded
          aria-label="close"
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            width: 32,
            height: "auto",
            cursor: "pointer",
          }}
          onClick={() => setShow(false)}
        >
          <Close />
        </CloseRounded>
      </DialogTitle>
      {children}
    </Dialog>
  ) : null;
};
