import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/app/redux";

import { projectsApi } from "./projects.api";
import { ProjectState } from "../pages/ProjectReporting/types";

const initialState: ProjectState = {
  projects: [],
  selectedProjectByBrand: null,
  projectDetails: null,
};
export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    clearProjectDetails: (state) => {
      state.projectDetails = null;
    },
    clearSelectedProjectByBrand: (state) => {
      state.selectedProjectByBrand = null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(projectsApi.endpoints.getProjectsList.matchFulfilled, (state, { payload }) => {
        state.projects = payload.data;
      })
      .addMatcher(
        projectsApi.endpoints.getProjectByBrandKpi.matchFulfilled,
        (state, { payload }) => {
          state.selectedProjectByBrand = payload;
        }
      ),
});

export const { clearProjectDetails, clearSelectedProjectByBrand } = projectsSlice.actions;
export const projectsReducer = projectsSlice.reducer;
export const projectsSelector = (state: RootState) => state.projects;
