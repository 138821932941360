import { FunctionComponent, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { Button, ButtonSize, ImageUploader, InputField, LoadingOverlay } from "@/components";

import { CompanyRequestBodySchema, CompanyRequestBodyType } from "../../schema/companies.schema";

interface CompanySettingsFormProps {
  onSubmit: (values: CompanyRequestBodyType) => void;
  initialValues: CompanyRequestBodyType;
  isLoading: boolean;
  isEditing?: boolean;
}
export const CompanySettingsOverview: FunctionComponent<CompanySettingsFormProps> = ({
  onSubmit,
  initialValues,
  isLoading,
  isEditing,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`companies.${key}`);

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    reset,
    formState: { errors, touchedFields, isValid },
  } = useForm({
    defaultValues: initialValues,
    resolver: zodResolver(CompanyRequestBodySchema),
  });

  useEffect(() => {
    if (!isLoading) {
      reset(initialValues);
    }
  }, [initialValues, reset, isLoading]);

  return isLoading ? (
    <LoadingOverlay />
  ) : (
    <Box alignItems="flex-start" display="flex" mt={4}>
      <Box
        bgcolor="white"
        borderRadius={2}
        boxShadow={1}
        flexGrow={1}
        maxWidth="50%"
        mr={3.75}
        p={2}
      >
        <Typography color="gray900.main" fontSize={14} fontWeight={400} mb={1.5}>
          {isEditing ? ts("edit.description") : ts("create.description")}
        </Typography>
        <Box mr={3.75} width="40%">
          <Typography display="block" mb={2} variant="mainBold">
            {ts("companyName")}
            <Typography color="red.main" component="span">
              *
            </Typography>
          </Typography>
          <Box mb={2} width="100%">
            <InputField
              border
              hasError={touchedFields.name && !!errors.name}
              {...register("name")}
              smallSize
              placeholder={t("basics.name")}
            />
          </Box>
          <Typography display="block" mb={2} variant="mainBold">
            {ts("companyCode")}
            <Typography color="red.main" component="span">
              *
            </Typography>
          </Typography>
          <Box mb={2} width="100%">
            <InputField
              border
              hasError={touchedFields.name && !!errors.name}
              {...register("code")}
              smallSize
              placeholder={ts("companyCode")}
            />
          </Box>
        </Box>

        <Box maxWidth={128}>
          <Button
            handleClick={handleSubmit(onSubmit)}
            isDisabled={!isValid}
            isLoading={isLoading}
            size={ButtonSize.M}
            title={isEditing ? t("basics.save") : t("create")}
          />
        </Box>
      </Box>
      <Box bgcolor="white" borderRadius={2} boxShadow={1} p={2} width="calc(100% - 874px)">
        <Typography color="gray900.main" fontSize={18} fontWeight={600} mb={2}>
          {ts("companyLogo")}
          <Typography color="red.main" component="span">
            *
          </Typography>
        </Typography>
        <Box display="flex" mb={1.5} minHeight={240}>
          <ImageUploader
            border
            currentImgUrl={getValues("logo")}
            setCurrentImgUrl={(img) => setValue("logo", img, { shouldValidate: true })}
          />
        </Box>
        <Typography fontWeight={400} variant="main14">
          {t("basics.imageRatioNote")}
        </Typography>
      </Box>
    </Box>
  );
};
