import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";

import { ERROR_TYPE } from "@/app/utils";
import { FetchMeResponseSchema } from "@/pages/Public/pages/Login/schema/login";

export const UsersResponseSchema = z.object({
  data: z.array(FetchMeResponseSchema),
  meta: MetaDataSchema,
});

const { REQUIRED, INVALID_EMAIL } = ERROR_TYPE;

export const InviteRequestSchema = z.object({
  firstName: z.string().min(2, { message: REQUIRED }).trim(),
  lastName: z.string().min(2, { message: REQUIRED }).trim(),
  email: z.string().email(INVALID_EMAIL),
  roles: z.string().min(1, { message: REQUIRED }).or(z.string().array()),
  companyId: z.number().optional(),
});

export type InviteType = z.infer<typeof InviteRequestSchema>;

export const InviteResponseSchema = FetchMeResponseSchema.partial();

export const UpdateUserSchema = z
  .object({
    firstName: z.string().min(2, { message: ERROR_TYPE.REQUIRED }).trim(),
    lastName: z.string().min(2, { message: ERROR_TYPE.REQUIRED }).trim(),
    email: z.string().email(),
    role: z.string().min(4, { message: REQUIRED }),
  })
  .partial();

export type UpdateUser = z.infer<typeof UpdateUserSchema>;

export const DeleteUserSchema = z.object({
  userId: z.number().gt(0),
});

export type DeletedUser = z.infer<typeof DeleteUserSchema>;

export const UserRolesUpdateSchema = z.object({
  roles: z.array(z.object({ id: z.number().gt(0) })),
});

export type UserRolesUpdate = z.infer<typeof UserRolesUpdateSchema>;
export type UsersResponse = z.infer<typeof UsersResponseSchema>;
