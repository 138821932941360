import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";

import { Button } from "@/components";
import { useGetBrandsQuery } from "@/pages/Private/redux/brands.api";
import { useSearch } from "@/app/utils";

import { BrandsTable } from "./components/BrandsTable";
import { CreateOrEditBrandModal } from "./components/CreateOrEditBrandModal";

export const Brands: FunctionComponent = () => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`brands.${key}`);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const { page, setPage, limit } = useSearch();
  const { data: brands, isLoading } = useGetBrandsQuery({ page, limit });

  //Changes the browser tab title dynamically
  useEffect(() => {
    window.document.title = t("windowTitles.brands");

    return () => {
      window.document.title = t("windowTitles.default");
    };
  }, [t]);

  return (
    <>
      <Typography mb={1.5} mt={4} variant="h1">
        {ts("title")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { md: "space-between" },
          mb: 3,
        }}
      >
        <Typography
          component="p"
          mb={1.5}
          sx={{ maxWidth: { xs: "100%", md: 566 } }}
          variant="main"
        >
          {ts("description")}
        </Typography>
        <Box
          sx={{
            minWidth: { xs: "100%", md: 156 },
          }}
        >
          <Button
            handleClick={() => setIsCreateDialogOpen(true)}
            title={ts("create")}
            variant="contained"
          />
        </Box>
      </Box>
      <BrandsTable
        brands={brands?.data || []}
        handleFetchPage={setPage}
        isLoading={isLoading}
        meta={brands?.meta}
        page={page}
      />

      <CreateOrEditBrandModal
        isEditing={false}
        isOpen={isCreateDialogOpen}
        setIsOpen={setIsCreateDialogOpen}
      />
    </>
  );
};
