import { FunctionComponent } from "react";
import { SettingsOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const SettingsButton: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <Button
      sx={{
        width: 44,
        minWidth: "unset",
        height: 44,
        bgcolor: "main",
        borderRadius: 2,
        boxShadow: 1,
        display: { xs: "none", md: "flex" },
        "&.MuiButtonBase-root:hover": {
          bgcolor: "main",
        },
      }}
      onClick={() => navigate("settings")}
    >
      <SettingsOutlined sx={{ color: "#fff", height: 26, width: "auto", m: "auto" }} />
    </Button>
  );
};
