import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { DataSourcesOverview } from "./pages/DataSourcesOverview/DataSourcesOverview";

export const DataSources: FunctionComponent = () => {
  return (
    <Routes>
      <Route element={<DataSourcesOverview />} path="" />
      <Route element={<DataSourcesOverview />} path="/:id" />
    </Routes>
  );
};
