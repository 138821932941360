import { rest } from "msw";

import { mockUrl } from "@/app/constants";

import { liveEventsMock, upcomingEventsMock, finishedEventsMock, meta } from "./events.mocks";

export const eventsHandlers = [
  rest.get(mockUrl("api/events"), (req, res, ctx) => {
    const eventType = req.url.searchParams.get("type");
    const query = req.url.searchParams.get("query");

    const filteredLiveEvents = liveEventsMock.filter((e) =>
      e.name.toLowerCase().includes(query?.toLowerCase() || "")
    );
    const filteredUpcomingEvents = upcomingEventsMock.filter((e) =>
      e.name.toLowerCase().includes(query?.toLowerCase() || "")
    );
    const filteredFinishedEvents = finishedEventsMock.filter((e) =>
      e.name.toLowerCase().includes(query?.toLowerCase() || "")
    );

    if (eventType === "live") {
      return res(ctx.json({ meta, events: filteredLiveEvents }), ctx.status(200));
    }

    if (eventType === "upcoming") {
      return res(ctx.json({ meta, events: filteredUpcomingEvents }), ctx.status(200));
    }

    if (eventType === "finished") {
      return res(ctx.json({ meta, events: filteredFinishedEvents }), ctx.status(200));
    }
  }),
  // rest.get(mockUrl("api/events/:id"), (req, res, ctx) => {
  // 	const { id } = req.params;

  // 	const allEvents = [...liveEventsMock, ...upcomingEventsMock, ...finishedEventsMock];
  // 	const searchedEvent = allEvents.find((e) => e.id === +id);

  // 	return res(ctx.json(searchedEvent), ctx.status(200));
  // }),
];
