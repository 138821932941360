import { FunctionComponent } from "react";
import { Box } from "@mui/material";

import { LoadingOverlay } from "@/components";
import { useAppSelector } from "@/app/redux/hooks";
import { projectsSelector } from "@/pages/Private/redux/projects.slice";
import { PromotersTopThirtyTable } from "@/pages/Private/pages/Events/pages/EventDetails/PromoterDashboard/components/PromotersTopThirtyTable";

export enum NewsletterDataType {
  DOI = "DOI",
  DOI_NL = "DOI_NL",
  WITHOUT_DOI = "WITHOUT_DOI",
}
export const ProjectByBrandPromoterDashboard: FunctionComponent = () => {
  const { selectedProjectByBrand: project } = useAppSelector(projectsSelector);

  return !project ? (
    <LoadingOverlay />
  ) : (
    <>
      <Box
        sx={{
          width: "100%",
          display: { md: "flex" },
          justifyContent: "space-between",
          mb: { xs: 2, md: 1 },
        }}
      >
        <PromotersTopThirtyTable
          isFetching={!project}
          newsletterDataType={NewsletterDataType.DOI}
          promoterKPIs={project?.data}
        />
        <PromotersTopThirtyTable
          isFetching={!project}
          newsletterDataType={NewsletterDataType.DOI_NL}
          promoterKPIs={project?.data}
        />
        <PromotersTopThirtyTable
          isFetching={!project}
          newsletterDataType={NewsletterDataType.WITHOUT_DOI}
          promoterKPIs={project?.data}
        />
      </Box>
    </>
  );
};
