import { z } from "zod";

export const MetaDataSchema = z.object({
  page: z.number(),
  limit: z.number(),
  itemCount: z.number(),
  pageCount: z.number(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
});

export type MetaData = z.infer<typeof MetaDataSchema>;
