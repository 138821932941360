import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/app/redux";

import { DataSourceState } from "../pages/DataSources/types";
import { dataSourcesApi } from "./dataSources.api";

const initialState: DataSourceState = {
  dataSources: [],
};

export const dataSourcesSlice = createSlice({
  name: "dataSources",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addMatcher(
      dataSourcesApi.endpoints.getDataSources.matchFulfilled,
      (state, { payload }) => {
        state.dataSources = payload.data;
      }
    ),
});

export const dataSourcesReducer = dataSourcesSlice.reducer;
export const dataSourcesSelector = (state: RootState) => state.dataSources;
