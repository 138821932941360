import { FunctionComponent } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// import { KpiTypeEnum } from "../../../types";
import { EventType } from "../../../event.schema";
import { EventStatusBox } from "../../components";

interface EventHeaderProps {
  event: EventType;
  alignCenter?: boolean;
  withStatus?: boolean;
}

export const EventHeader: FunctionComponent<EventHeaderProps> = ({
  event,
  alignCenter,
  withStatus,
}) => {
  const { t } = useTranslation();
  const { image, name, isComplete } = event;

  return (
    <Box display="flex" flexGrow={1} sx={{ ...(alignCenter && { alignItems: "center" }) }}>
      <Box height={54} minWidth={54} mr={1.5} sx={{ position: withStatus ? "relative" : "unset" }}>
        <img
          alt="#"
          src={
            image ??
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRw1RzLMgo3wke8keENIrang5SPOGC8D20L9A&usqp=CAU"
          }
          style={{ width: 54, height: 54, borderRadius: 8, objectFit: "cover" }}
        />
        <Box
          bottom={-10}
          position="absolute"
          right={-10}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <EventStatusBox status={event.status} />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
        <Typography component="p" sx={{ marginBottom: 0.5 }} variant="main">
          {name}
        </Typography>
        {!isComplete && (
          <Typography color="warning.main" component="p" variant="h6">
            {t("events.missingProductsTooltipWarning")}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
