import { PermissionRoles, Role } from "@/enum";
import { UserRole } from "@/pages/Public/pages/Login/schema/login";

export function getAllUserPermissions(roles: UserRole[]): PermissionRoles[] {
  const permissions: PermissionRoles[] = [];

  roles.forEach((role) => {
    role.permissions.forEach((permission) => permissions.push(permission.code));
  });

  return permissions;
}

export function getAllUserRoles(roles: UserRole[]): Role[] {
  return roles.map((role) => role.code);
}

type UserRoleDiff = {
  addedRoleId: number | undefined;
  removedRoleId: number | undefined;
};

export function getUserRolesDiff(
  userRoles: Role[],
  selectedRoles: Role[],
  allRoles: UserRole[]
): UserRoleDiff {
  const addedRoles = selectedRoles.filter((role) => !userRoles.includes(role));
  const removedRoles = userRoles.filter((role) => !selectedRoles.includes(role));

  return {
    addedRoleId: allRoles.find((role) => addedRoles.find((added) => added === role.code))?.id,
    removedRoleId: allRoles.find((role) => removedRoles.find((removed) => removed === role.code))
      ?.id,
  };
}
