import { Typography, Box } from "@mui/material";
import { FunctionComponent } from "react";

export const Imprint: FunctionComponent = () => {
  return (
    <Box padding={1.5}>
      <Typography display="flex" justifyContent="center" mb={2} variant="h1">
        Impressum
      </Typography>

      <Typography my={1} variant="h2">
        Angaben gem&auml;&szlig; &sect; 5 TMG
      </Typography>
      <Typography>
        b.sure GmbH
        <br />
        K&ouml;rnerstra&szlig;e 32
        <br />
        04107 Leipzig
      </Typography>

      <Typography>
        <strong>Vertreten durch:</strong>
        <br />
        Finn Jensen, Marc L&uuml;tkemeier
      </Typography>

      <Typography my={1} variant="h2">
        Kontakt
      </Typography>
      <Typography>
        Telefon: +49 (0) 341-97854207
        <br />
        E-Mail: fj@jensen-collegen.de
      </Typography>

      <Typography my={1} variant="h2">
        Umsatzsteuer-ID
      </Typography>
      <Typography>
        Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:
        <br />
        DE357352032
      </Typography>

      <Typography my={1} variant="h2">
        Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
      </Typography>
      <Typography>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle teilzunehmen.
      </Typography>
    </Box>
  );
};
