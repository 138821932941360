import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/app/utils";

import { Features } from "../enums";
import { BudgetTypeEnum } from "../../Events/types";

export const FeatureSchema = z.object({
  feature: z.nativeEnum(Features),
  active: z.boolean(),
});

export const FeaturesResponseSchema = FeatureSchema.array();

export const CompanyConfigSchema = z
  .object({
    budgets: z.nativeEnum(BudgetTypeEnum),
    kpiEditableGoals: z.string().array(),
    kpiGoals: z.string().array(),
    kpiHiddenPlanned: z.string().array(),
    kpiTiles: z.string().array(),
  })
  .optional();

export const CompanyBrandSchema = z.object({
  id: z.number(),
  name: z.string(),
  companyId: z.number(),
  company: z.string(),
  image: z.string(),
});

export const CompanyGroupSchema = z.object({
  id: z.number(),
  name: z.string(),
  companyId: z.number(),
  company: z.string(),
  users: z.null().array(),
  events: z.string().array(),
});

export const CompanyUserSchema = z.object({
  id: z.number(),
  name: z.string(),
  code: z.string(),
  logo: z.string(),
  config: CompanyConfigSchema,
});

export const CompanySchema = z.object({
  id: z.number(),
  name: z.string(),
  code: z.string(),
  logo: z.string(),
  config: CompanyConfigSchema,
  subusers: z.null().array(),
  admins: z.null().array(),
  brands: CompanyBrandSchema.array(),
  groups: CompanyGroupSchema.array(),
  updatedAt: z.string().datetime().optional(),
  createdAt: z.string().datetime().optional(),
});

export const CompaniesResponseSchema = z.object({
  data: CompanySchema.array(),
  meta: MetaDataSchema,
});

const { REQUIRED } = ERROR_TYPE;
export const CompanyRequestBodySchema = z
  .object({
    name: z.string().min(1, { message: REQUIRED }),
    logo: z.string().min(1, { message: REQUIRED }),
    code: z.string().min(1, { message: REQUIRED }),
  })
  .partial();
export const CompanyRequestSchema = z
  .object({
    name: z.string(),
    logo: z.string(),
    code: z.string(),
  })
  .partial();

export const DeleteCompanySchema = z.object({
  companyId: z.number().gt(0),
});

export type CompanyRequestType = z.infer<typeof CompanyRequestSchema>;
export type CompanyRequestBodyType = z.infer<typeof CompanyRequestBodySchema>;
export const CreateCompanyResponseSchema = CompanySchema.partial();
export type CompanyType = z.infer<typeof CompanySchema>;
export type CompaniesResponse = z.infer<typeof CompaniesResponseSchema>;
export type FeatureType = z.infer<typeof FeatureSchema>;
export type FeaturesResponse = z.infer<typeof FeaturesResponseSchema>;
export type DeleteCompany = z.infer<typeof DeleteCompanySchema>;
