import { RequestPaginationParams } from "@/types/request-params";
import { api } from "@/app/redux/api";

import {
  ModuleType,
  ModulesResponse,
  ModulesResponseSchema,
  CreateModuleResponseSchema,
  CreateRequestModuleType,
  CreateRequestSchema,
  DeleteModuleType,
  DeleteModuleSchema,
  UpdateModuleRequestType,
  AssignModuleRequestType,
  ModuleSchema,
  OpeningHoursType,
  OpeningHoursBodyType,
  MobileEventModuleSchema,
  MobileEventModuleType,
} from "./../pages/Modules/schema/modules.schema";

export const modulesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getModules: builder.query<ModulesResponse, RequestPaginationParams & { types?: string }>({
      query: ({ page, limit, types }) => ({
        url: "modules",
        params: {
          page,
          limit,
          ...(types && { types }),
        },
        responseSchema: ModulesResponseSchema,
      }),
      providesTags: ["Modules"],
    }),
    getModuleById: builder.query<ModuleType, number>({
      query: (id) => ({
        url: `modules/${id}`,
        responseSchema: ModuleSchema,
      }),
      providesTags: [{ type: "Modules", id: "DETAIL" }],
    }),
    getModulesByMobileEventId: builder.query<MobileEventModuleType[], number>({
      query: (id) => ({
        url: `modules/mobile-events/${id}`,
        responseSchema: MobileEventModuleSchema,
      }),
      providesTags: [{ type: "Modules", id: "DETAIL" }],
    }),
    createModule: builder.mutation<ModuleType, CreateRequestModuleType>({
      query: (body) => ({
        url: "modules",
        method: "POST",
        body,
        requestSchema: CreateRequestSchema,
        responseSchema: CreateModuleResponseSchema,
      }),
      invalidatesTags: ["Modules"],
    }),
    deleteModule: builder.mutation<DeleteModuleType, number>({
      query: (id) => ({
        url: `modules/${id}`,
        method: "DELETE",
        responseSchema: DeleteModuleSchema,
      }),
      invalidatesTags: ["Modules"],
    }),
    updateModule: builder.mutation<ModuleType, UpdateModuleRequestType>({
      query: ({ id, body }) => ({
        url: `modules/${id}`,
        method: "PATCH",
        body,
        responseSchema: ModulesResponseSchema,
      }),
      invalidatesTags: ["Modules"],
    }),
    assignModule: builder.mutation<ModuleType, AssignModuleRequestType>({
      query: ({ id, body }) => ({
        url: `modules/assign/${id}`,
        method: "PATCH",
        body,
        responseSchema: ModulesResponseSchema,
      }),
      invalidatesTags: ["Events"],
    }),
    updateOpeningTimes: builder.mutation<
      OpeningHoursType,
      { id: number; body: Partial<OpeningHoursBodyType> }
    >({
      query: ({ id, body }) => ({
        url: `modules/opening-hours/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Events"],
    }),
  }),
});

export const {
  useGetModulesQuery,
  useGetModuleByIdQuery,
  useGetModulesByMobileEventIdQuery,
  useCreateModuleMutation,
  useDeleteModuleMutation,
  useUpdateModuleMutation,
  useAssignModuleMutation,
  useUpdateOpeningTimesMutation,
} = modulesApi;
