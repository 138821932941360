import { ReactNode } from "react";

import { api } from "@/app/redux/api";
import { RequestPaginationParams } from "@/types/request-params";

import {
  CreateOrEditProjectType,
  CreateOrEditRequestSchema,
  CreateResponseSchema,
  ProjectByBrandKPIsResponseSchema,
  ProjectByBrandKPIsResponseType,
  ProjectsResponse,
  ProjectsResponseSchema,
  ProjectType,
} from "../pages/ProjectReporting/project.schema";
import { UpdateProjectBody } from "../pages/Projects/types";

export const projectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<ProjectsResponse, RequestPaginationParams>({
      query: (params) => ({
        url: "projects",
        params: {
          ...(params && { ...params }),
        },
      }),
      providesTags: ["Projects"],
    }),
    getProjectsList: builder.query<
      { data: ProjectType[] },
      { companyId?: number; additionalData?: string }
    >({
      query: (params) => ({
        url: "projects/list",
        params: {
          ...(params && { ...params }),
        },
      }),
    }),
    getProjectByBrandKpi: builder.query<
      ProjectByBrandKPIsResponseType,
      { brandId: number; projectId: number; dashboard: ReactNode }
    >({
      query: ({ brandId, projectId, dashboard }) => ({
        url: "kpi",
        params: { brandId, projectId, dashboard },
        responseSchema: ProjectByBrandKPIsResponseSchema,
      }),
    }),
    createProject: builder.mutation<ProjectType, CreateOrEditProjectType>({
      query: (body) => ({
        url: "projects",
        method: "POST",
        body,
        requestSchema: CreateOrEditRequestSchema,
        responseSchema: CreateResponseSchema,
      }),
      invalidatesTags: ["Projects"],
    }),
    updateProject: builder.mutation<ProjectType, UpdateProjectBody>({
      query: ({ body, id }) => ({
        url: `projects/${id}`,
        method: "PATCH",
        body,
        responseSchema: ProjectsResponseSchema,
      }),
      invalidatesTags: ["Projects"],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectsListQuery,
  useGetProjectByBrandKpiQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
} = projectsApi;
