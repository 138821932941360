import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { useSearch } from "@/app/utils";
import { Button } from "@/components";
import { ROUTE_CONFIG } from "@/app/routes/config";
import { useGetCompaniesQuery } from "@/pages/Private/redux/companies.api";

import { CompaniesTable } from "./components/CompaniesTable";

export const CompaniesOverview: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const ts = (key: string) => t(`companies.${key}`);

  const { page, setPage, limit } = useSearch();
  const { data: companies, isLoading } = useGetCompaniesQuery({ page, limit });

  return (
    <>
      <Typography mb={1.5} mt={4} variant="h1">
        {ts("title")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { md: "space-between" },
          mb: 3,
        }}
      >
        <Typography
          component="p"
          mb={1.5}
          sx={{ maxWidth: { xs: "100%", md: 566 } }}
          variant="main"
        >
          {ts("description")}
        </Typography>
        <Box
          sx={{
            minWidth: { xs: "100%", md: 156 },
          }}
        >
          <Button
            handleClick={() => navigate(ROUTE_CONFIG.COMPANY_CREATE)}
            title={ts("createCompany")}
            variant="contained"
          />
        </Box>
      </Box>
      <CompaniesTable
        companies={companies?.data || []}
        handleFetchPage={setPage}
        isLoading={isLoading}
        meta={companies?.meta}
        page={page}
      />
    </>
  );
};
