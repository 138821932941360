import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { ProjectsListOverview } from "./pages/ProjectsOverview/ProjectsListOverview";
import { ProjectDetails } from "./pages/ProjectDetails/ProjectDetails";

export const ProjectReporting: FunctionComponent = () => {
  return (
    <Routes>
      <Route element={<ProjectsListOverview />} path="" />
      <Route element={<ProjectDetails />} path="/:projectId/:brandId">
        <Route element={<ProjectDetails />} path="/:projectId/:brandId/:dashboard" />
      </Route>
    </Routes>
  );
};
