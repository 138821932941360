const en = {
  translation: {
    basics: {
      title: "Event Analytics",
      poweredBy: "powered by",
      email: "E-Mail",
      password: "Password",
      save: "Save",
      backToLogin: "Back to login",
      male: "Male",
      female: "Female",
      firstName: "First name",
      lastName: "Last name",
      cancel: "Cancel",
      delete: "Delete",
      home: "Home",
      logout: "Logout",
      name: "Name",
      noData: "No data found",
      notApplicable: "N/A",
      resetPassword: "Reset password",
      role: "Role",
      status: "Status",
      block: "Block",
      brand: "Brand",
      remove: "Remove",
      confirm: "Confirm ",
      allBrands: "All brands",
      kpi: "KPI",
      data: "Data",
      day: "Day",
      defaultSelectPlaceholder: "Please select",
      completed: "Completed!",
      finalize: "Finalize",
      unlock: "Unlock",
      dragAndDropImage: "Please drag and drop or select an image from your device",
      dragAndDropFile: "Click to upload or drag and drop",
      selectFile: "Please select a file",
      imageRatioNote: "Note: Images with aspect ratio of 1:1 will work best",
      settings: "Settings",
      search: "Search",
      clearFilter: "Clear filters",
      beta: "BETA",
    },
    windowTitles: {
      default: "FRET Dashboard",
      eventListing: "FRET Event Listing",
      modules: "FRET Modules",
      projects: "FRET Projects",
      brands: "FRET Brands",
      users: "FRET Users",
      projectReporting: "FRET Project Reporting",
      dataSources: "FRET Data Sources",
      eventDetails: "FRET {{eventName}} Dashboard",
      eventSettings: "FRET {{eventName}} Settings",
    },
    errors: {
      invalidEmail: "Please enter a valid email address.",
      passwordGuidelinesNotMet:
        "Your password should be at least 8 symbols, including one uppercase, one lowercase letter, a number and a special symbol",
      termsAcceptanceMissing: "Please accept the terms and conditions to proceed.",
      invalidValue: "Please enter a valid value.",
      passwordMismatch: "Passwords do not match.",
      invalidToken: "Invalid token.",
      requiredFields: "Please fill out the required fields.",
      wentWrong: "Something went wrong. Please try again later.",
      unauthorized: "Not authorized. Please log in.",
      userNotInGroup: "The user is not part of a group.",
    },
    terms: {
      brand: "Brand",
      project: "Project",
      selectBrand: "Select Brand",
      selectTeam: "Select Team",
      selectLocation: "Select Location",
      selectType: "Select Type",
      selectProject: "Select Project",
    },
    weekDays: {
      0: "Sunday",
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
    },
    login: {
      title: {
        bsure: "Welcome to b.sure",
        jti: "Welcome to FRET",
      },
      description: {
        bsure: "Event analytics tool",
        jti: "Festival Reporting Tool",
      },
      loginCta: "Login",
      emailPlaceholder: "Your E-mail address",
      forgotPasswordCta: "Forgot password?",
      passwordPlaceholder: "Your password",
      invalidData: "The email or password you entered is incorrect. Please try again.",
      userNotConfirmed: "Account not confirmed yet. Please check your email.",
    },
    forgotPassword: {
      title: "Forgotten password?",
      description:
        "Enter the E-mail address associated with your account and we’ll send you a link to set your new password",
      emailPlaceholder: "Your e-mail address",
      forgotPasswordCta: "Send link",
      submittedFeedbackMessage:
        "Check your inbox. We send an e-mail with instructions on how to reset your password!",
      toLoginCta: "To Login",
    },
    setPassword: {
      title: "Please set your password",
      description:
        "Your password should be at least 8 symbols, including one uppercase, one lowercase letter, a number and a special symbol",
      confirmPasswordPlaceholder: "Confirm password",
      passwordPlaceholder: "Set password",
      setPasswordCta: "Set password",
      confirmPassword: "Confirm Password",
    },
    resetPassword: {
      title: "Reset your password",
      description:
        "Your password should be at least 8 symbols, including one uppercase, one lowercase letter, a number and a special symbol",
      confirmPasswordPlaceholder: "Confirm password",
      passwordPlaceholder: "Password",
      setPasswordCta: "Reset password",
      confirmPassword: "Confirm Password",
    },
    register: {
      title: "Please set your password",
      confirmPassword: "Confirm Password",
      confirmPasswordPlaceholder: "Repeat your Password",
      passwordPlaceholder: "Enter desired password",
      setPasswordCta: "Set password",
      emailAlreadyTaken: "Email already registered.",
    },
    events: {
      createEvent: "Create event",
      eventList: "Event List",
      eventType: "Type",
      searchEvent: "Search Events...",
      visitors: "Visitors",
      visitorsExpected: "Visitors expected",
      duration: "{{days}} Days Festival",
      oneDay: "{{days}} Day Festival",
      allEvents: "All events",
      live: "Live",
      upcoming: "Upcoming",
      finished: "Finished",
      liveDataSources: "{{count}} Data sources live",
      bScore: "BScore",
      bImpact: "BImpact",
      module: "Module",
      dataSource: "Data sources",
      mt: "MT",
      audienceEstimate: "Audience Estimate",
      emotions: "Emotions",
      reach: "Reach",
      performanceTimeline: "Performance Timeline",
      footfallFrequency: "Footfall Frequency",
      DOI_NL: "DOI+NL",
      activations: "Activations",
      sales: "Sales",
      kpiScore: "KPI Score",
      deviceSales: "Device Sales",
      samples: "Samples",
      ageDistribution: "Age distribution",
      residenceTime: "Residence time",
      timeFrame: "Time frame",
      finalKPIs: "Final KPIs",
      sampling: "Sampling",
      noData: "Currently, no information is available for {{kpi}}",
      promoterHours: "Promoter hours",
      totalPeriod: "Total period",
      selectHour: "Select hour",
      positive: "Positive",
      neutral: "Neutral",
      negative: "Negative",
      eventNotes: "Event notes",
      noNotes: "Currently there are no notes added",
      startTyping: "Start typing...",
      thisKPI: "this KPI",
      kpiSwitch: "KPI Switch",
      plannedKPI: "Planned",
      jtiKPI: "JTI Planned",
      overview: "Overview",
      heatmapSalesByDays: "Sales by Days",
      datesActivityMonitor: "Dates Activity Monitor",
      heatmapByDayAndHour: "Heatmap by days and hours",
      heatmapActivationsByDays: "Activations by Days",
      heatmapSalesByModules: "Sales by Modules",
      heatmapGiveawaysByModules: "Giveaways by Modules",
      donutSalesByBrand: "Sales Split by Brand",
      donutSalesByDay: "Sales Split by Day",
      donutDoiByDay: "DOI Split by Day",
      donutSalesByCategory: "Sales Split by Category",
      donutSalesByDeviceAndPacks: "Sales Split by Device and Packs",
      donutSalesByAccessories: "Sales Split by Accessories",
      donutSalesByType: "Sales Split by Type",
      donutGiveawaysByDay: "Giveaways Split by Day",
      donutGiveawaysByModule: "Giveaways Split by Module",
      donutActivationsByType: "Activations Split by Type",
      donutActivationsByDay: "Activations Split by Day",
      moduleInsights: "Module Insights",
      twentySticks: "20-stick packages",
      missingProductsWarning: "Incomplete Data. Please, maintain all data in the Cockpit!",
      missingProductsTooltipWarning: "Incomplete Data",
      missingModulesWarning: "Please configure at least one module.",
      dashboardsTooltipTitle:
        "The data may be up to 30 min late. Data imports occur every 15 minutes during a festival. The data transport happens also every 15 minutes.",
      doi: "DOI",
      giveaways: "Giveaways",
      promoters: "Promoters",
      electronicCigaretteSticks: "ECS",
      withDeviceId: "with Device ID",
      create: {
        backToEvents: "Back to events",
        title: "Create Event",
        description: "Edit the basic data of your event here. Required fields are highlighted.",
      },
      settings: {
        backToEvent: "Back to event",
        title: "Event Settings",
        general: {
          title: "General Settings",
          description:
            "Manage your event here. Add details like the name, address, event days the brand and other information.",
          eventImage: "Event Image",
          eventName: "Event name",
          externalId: "External ID",
          eventCompany: "Company",
          eventCategory: "Event Category",
          eventTime: "Event time",
          audience: "Audience",
          startHour: "Start hour",
          endHour: "End hour",
          eventContinue: "Event will continue after the end of the day",
          addDay: "Add day",
          expectedAudience: "Expected Audience",
          actualAudience: "Actual Audience",
          eventLocation: "Event Location",
          street: "Street",
          streetNumber: "Street Number",
          city: "City",
          postalCode: "Postal Code",
          nielsen: "Nielsen",
        },
        openingTimes: {
          title: "Event Time Settings",
          description:
            "Here you can manage the campaign working days and hours for the particular module. Please, select start/end date for the module and select the working hours for each day.",
          campaignDates: {
            title: "Campaign Dates",
            startDate: "Start Date",
            endDate: "End Date",
          },
          weekDays: {
            title: "Weekly Opening Times",
            tooltip: "(Please select at least one of the days and set opening hours)",
            startHour: "Start hour",
            endHour: "End hour",
            closed: "-closed-",
            monday: "Mondays",
            tuesday: "Tuesdays",
            wednesday: "Wednesdays",
            thursday: "Thursdays",
            friday: "Fridays",
            saturday: "Saturdays",
            sunday: "Sundays",
          },
          notifications: {
            openingTimesEdited: "Module details updated!",
            incompleteData: "Incomplete data! Please fill out all of the required fields.",
            errorMessage: "Something went wrong! Please try again later",
          },
        },
        tourPlan: {
          title: "Tour Plan",
          description: "Upload tour plan in .xlsx file",
          filesRestriction: "Only .xlsx files",
          importData: "Import Data",
        },
        modules: {
          title: "Module Settings ",
          description:
            "Here you can define which module is present on this festival and which data sources are available on each module.",
          pickModule: "Pick Module",
          pickTouchpoint: "Pick Touchpoint",
          selectModule: "Select Module",
          selectTouchpoint: "Select Touchpoint",
          addModule: "Add Module",
          selectedModules: "Selected Modules",
          noModuleSelected: "Currently this event has no modules added. Please add module first.",
          deleteModal: {
            title: "Remove Module from Event",
            description: "Are you sure you want to remove the Module:",
          },
        },
        dataSources: {
          title: "Data source Settings",
          description:
            "You can add one or more Data sources to a module, but one data source can only be added to one module within one event.",
          noModuleSelectedTitle: "Available Data sources",
          selectedDataSources: "Available Data sources for the module {{selectedModuleName}}",
          noModuleSelected: "Currently no module is selected. Please select a module first.",
        },
        budget: {
          title: "Budget Settings",
          description: "Manage budgets here.",
          budgetPosition: "Budget position",
          planned: "Planned",
          spent: "Spent",
          wkz: "WKZ",
          merch: "Merch",
          freeBeer: "Free beer",
          products: "Products",
          food: "Food",
          sum: "sum",
          balance: "Balance:",
          finalizeBudget: "Finalize budget",
          finalizeBudgetDescription:
            "Save the added data by using the save button. As soon as all numbers are confirmed you may finalize them. After finalizing you would not be able to change them again.",
        },
        kpis: {
          title: "KPI Settings",
          kpi: "KPI",
          active: "Active",
          planned: "Planned",
          planned2: "JTI Planned", // TECH DEBT - BA-706
          actual: "Actual",
          description:
            "Once the KPIs for the festival are reconciled and correct, users can lock the fields. If you want to make further changes later, please contact your manager who can unlock the fields again.",
        },
        tabs: {
          general: "General",
          modules: "Modules",
          tourPlanUpload: "Tour Plan Upload",
          budget: "Budget",
          kpis: "KPIs",
        },
      },
      kpis: {
        SALES: "Sales",
        SAMPLES: "Samples",
        GIVEAWAY: "Give-Away",
        VISITORS: "Visitors",
        ACTIVATIONS: "Activations",
        PROMOTER_HOURS: "Promoter Hours",
        NEWSLETTER_DATA: "DOI+NL",
        NEWSLETTER_DATA_WITH_DEVICE_ID: "DOI+NL with Device ID",
        EMOTIONS: "EMOTIONS",
        LIVIA_DURATION: "LIVIA_DURATION",
        MALE: "MALE",
        FEMALE: "FEMALE",
        SALES_BY_MODULE: "Sales per Module",
        SALES_BY_PRODUCT_BY_HEATERDEVICE: "Device Sales",
        SALES_BY_PRODUCT_BY_CIGARETTESTYPE_ECSCATEGORY: "Evo Sales",
        SALES_BY_DEVICE: "Device Sales",
        SALES_BY_CIGARETTESTYPE_ECSCATEGORY: "Evo Sales",
        specific: {
          DOI_NL: "DOI+NL",
          DOI_NL_WITH_DEVICE_ID: "DOI+NL with Device ID",
          DOI: "DOI",
          DOI_WITH_DEVICE_ID: "DOI with Device ID",
          PENDING: "Pending",
          PENDING_WITH_DEVICE_ID: "Pending with Device ID",
          ANONYMOUS: "Anonymous",
          ALL: "All",
        },
      },
      topTenTables: {
        product: {
          viewAll: "view all",
          totals: "Totals",
          title: "Top 10 most sold products",
          popupTitle: "All product sales",
          type: "products",
        },
        module: {
          viewAll: "view all",
          totals: "Totals",
          title: "Top 10 sales by module",
          popupTitle: "All sales by module",
          type: "modules",
        },
        cities: {
          title: "Top 10 Registrations by Cities",
          viewAll: "view all",
          popupTitle: "All registrations by cities",
          totals: "Totals",
          type: "cities",
        },
        activations: {
          title: "Top 10 Activations",
          viewAll: "view all",
          popupTitle: "All Activations",
          totals: "Totals",
          type: "types of activations",
        },
        giveaways: {
          title: "Top 10 Giveaways",
          viewAll: "view all",
          popupTitle: "All Giveaways",
          totals: "Totals",
          type: "types of giveaways",
        },
        ploomModule: {
          viewAll: "view all",
          totals: "Totals",
          title: "Location/ Touchpoint Sales (Top 10)",
          popupTitle: "All sales by Location/ Touchpoint",
          type: "modules",
        },
        promoter: {
          doi: {
            title: {
              withoutDevice: "Top 30 Promoters List for DOI Registrations",
              withDevice: "Top 30 Promoters List for DOI Registrations with Device ID",
            },
            viewAll: "view all",
            popupTitle: "All Promoters",
            totals: "Totals",
            type: "promoters",
          },
          doiNL: {
            title: {
              withoutDevice: "Top 30 Promoters List for DOI+NL Registrations",
              withDevice: "Top 30 Promoters List for DOI+NL Registrations with Device ID",
            },
            viewAll: "view all",
            popupTitle: "All Promoters",
            totals: "Totals",
            type: "promoters",
          },
          withoutDOI: {
            title: {
              withoutDevice: "Top 30 Promoters List for Registrations without DOI",
              withDevice: "Top 30 Promoters List for Registrations without DOI with Device ID",
            },
            viewAll: "view all",
            popupTitle: "All Promoters",
            totals: "Totals",
            type: "promoters",
          },
          promoterOverViewtable: {
            email: "email",
            doi: "DOI",
            doiWithDeviceId: "DOI with Device ID",
            doiNL: "DOI + NL",
            doiNLWithDeviceId: "DOI + NL with Device ID",
            withoutDOI: "Without DOI",
            pendingWithDeviceId: "Pending with Device ID",
            sum: "Sum",
          },
        },
      },
      salesKPIs: {
        description:
          "The data may be up to 30 min late. Data imports occur every 15 minutes during a festival. The data transport happens also every 15 minutes. All amounts are normalized to 20-stick packages. Pouches do not have any normalization.",
        totalPacksTile: {
          title: "Total packages sales",
          real: "real packages",
        },
        totalEvoPacksTile: {
          title: "Total Evo Packs Sales",
          evoPackages: "Evo Packages",
          real: "real packages",
        },
        totalProducts: {
          title: "Total Product Sales",
          allTypeProducts: "Packs, Devices & Accessories",
        },
        totalDevice: {
          title: "Total Device Sales",
        },
        totalAccessories: {
          title: "Total Accessories Sales",
        },
        totalDeviceAndAccessories: {
          title: "Total Device and Accessories Sales",
        },
        byProductByBrand: {
          title: "Sold Products",
        },
        byDeviceAndPacks: {
          title: "Sales Split by Device & Packs",
        },
        byCategoryType: {
          title: "Sold Products",
          evoPacks: "Evo Packs",
          heaters: "Heaters",
          functionalAcc: "Functional Acc.",
          heaterAcc: "Heater Acc.",
        },
      },
      doiKPIs: {
        description:
          "The data may be up to 30 min late. Data imports occur every 15 minutes during a festival. The data transport happens also every 15 minutes. Information shown on tiles is only for DOI+NL.",
        totalRegistrations: {
          title: "DOI Registrations",
          total: "DOI+NL registrations",
          totalWithoutNL: "DOI without NL registrations",
        },
        totalRegistrationsWithDeviceId: {
          title: "DOI Registrations with Device ID",
          total: "DOI+NL with Device ID",
          totalWithoutNL: "DOI with Device ID",
        },
        doiSplitByState: {
          description: "Information shown on tiles is only for DOI+NL.",
          title: "DOI Split by State",
          states: {
            DOI_NL: "DOI+NL",
            DOI_NL_WITH_DEVICE_ID: "DOI+NL with Device ID",
            DOI: "DOI without NL",
            DOI_WITH_DEVICE_ID: "DOI with Device ID",
            PENDING: "Pending",
            PENDING_WITH_DEVICE_ID: "Pending with Device ID",
            ANONYMOUS: "Anonymous",
          },
        },
        doiSplitByGender: {
          title: "DOI Split by Gender",
          states: {
            male: "Male",
            female: "Female",
            notspecified: "Not specified",
            divers: "Divers",
          },
        },
        doiSplitByAgeGroups: {
          title: "DOI Split by Age",
          states: {
            notspecified: "Other",
          },
        },
        doiByAgegroupByGender: {
          title: "DOI by Age Groups by Gender",
          genders: {
            male: "Male",
            female: "Female",
            notspecified: "N/A",
            divers: "Divers",
          },
        },
      },
      activationsKPIs: {
        totalTile: {
          title: "Total Activations",
        },
      },
      giveawaysKPIs: {
        totalTile: {
          title: "Total Giveaways",
        },
      },
      budgets: {
        WKZ: "WKZ",
        EXECUTIONS: "Executions",
        MODULAUFBAUKOSTEN: "Modulaufbaukosten",
        FILM_AND_FOTO: "Film & Foto",
      },
    },
    dataStatus: {
      title: "b.sure live sensor status",
      description: "Here you can view the online status of b.sure sensors.",
      name: "Camera name",
      hostname: "Hostname",
      deviceId: "Camera ID",
      type: "Type",
      status: "Status",
      lastStatusAt: "Last status at",
    },
    projectReporting: {
      title: "Project Reporting",
      description: "Here you can select a brand from a project to see detailed information.",
    },
    brands: {
      create: "Create Brand",
      title: "Brands",
      description: "Create and manage your brands here.",
      searchBrands: "Search Brand",
      actions: "Actions",
      brand: "Brand",
      modals: {
        createBrand: {
          title: "Create brand",
          cta: "Create a brand",
          brandNamePlaceholder: "Brand name",
        },
        editBrand: {
          title: "Edit brand",
          cta: "Edit a brand",
        },
      },
    },
    projects: {
      create: "Create Project",
      title: "Projects",
      description: "Create and manage your projects here.",
      searchProjects: "Search Project",
      actions: "Actions",
      project: "Project",
      modals: {
        createProject: {
          title: "Create project",
          cta: "Create a project",
          projectNamePlaceholder: "Project name",
        },
        editProject: {
          title: "Edit project",
          cta: "Edit a project",
        },
      },
    },
    companies: {
      createCompany: "Create Company",
      title: "Companies list",
      description: "Create and manage your companies here.",
      searchCompanies: "Search Company",
      actions: "Actions",
      company: "Company",
      backToCompanies: "Back to companies",
      companyName: "Company name",
      companyCode: "Company code",
      companyLogo: "Company logo",
      admin: {
        firstName: "First name",
        lastName: "Last name",
        email: "E-mail",
      },
      create: {
        title: "Create Company",
        description: "Add the basic data of your company here. Required fields are highlighted.",
      },
      edit: {
        title: "Edit company details",
        description: "Edit the basic data of your company here. Required fields are highlighted.",
      },
      notifications: {
        companyCreated: "Company created",
        companyUpdated: "Company updated",
        companyDeleted: "Company deleted",
        wentWrong: "Something went wrong! Please try again later",
      },
      modals: {
        deleteCompany: {
          confirmButton: "Yes, delete",
          description: "Are you sure you want to delete this company?",
          title: "Delete a company",
        },
      },
    },
    users: {
      create: "Create user",
      title: "Team members",
      description:
        "Manage your team members and their permissions here. You can add, change role and delete users.",
      searchUsers: "Search Users",
      userInfo: {
        userName: "User name",
        email: "E-mail address",
        role: "Role",
        company: "Company",
      },
      details: "User Details",
      actions: "Actions",
      inviteUser: {
        button: "Create user",
        modal: {
          title: "Create user",
          cta: "Create user",
          description: "Add an user to your organization.",
          firstNamePlaceholder: "User First Name",
          lastNamePlaceholder: "User Last name",
          emailPlaceholder: "User E-Mail Address",
          rolePlaceholder: "Select User Role",
          companyPlaceholder: "Select User Company",
        },
      },
      modals: {
        deleteUser: {
          confirmButton: "Yes, delete",
          description: "Are you sure you want to delete this user?",
          title: "Delete a user",
        },
        editUser: {
          description: "Edit the information about the user",
          title: "Edit user",
        },
      },
    },
    modules: {
      create: "Create module",
      title: "Modules",
      description: "Create and edit modules here.",
      searchModules: "Search modules",
      moduleInfo: {
        moduleType: "Type",
        moduleName: "Name",
      },
      details: "Module Details",
      actions: "Actions",
      createModal: {
        title: "Create module",
        cta: "Create module",
        description: "Add information for new module",
        namePlaceholder: "Module name",
        companyPlaceholder: "Select module company",
      },
      editModule: {
        description: "Edit the information about the module",
        title: "Edit module",
      },
      deleteModule: {
        confirmButton: "Yes, delete",
        description: "Are you sure you want to delete this module?",
        title: "Delete a module",
      },
    },
    dataSources: {
      create: "Create data source",
      title: "Data Sources",
      description: "Manage your data sources and their details here.",
      searchDataSources: "Search data sources",
      dataSourcesName: "Data source name",
      details: "Data source details",
      actions: "Actions",
      type: "Type",
      externalId: "External ID",
      createDataSource: {
        button: "Create data source",
        modal: {
          title: "Create data source",
          cta: "Create data source",
          description: "Add a data source details.",
          namePlaceholder: "Name",
          externalIdPlaceholder: "External ID",
          typePlaceholder: "Select Type",
          companyPlaceholder: "Select Company",
        },
      },
      modals: {
        deleteDataSource: {
          confirmButton: "Yes, delete",
          description: "Are you sure you want to delete this data source?",
          title: "Delete a data source",
        },
        editDataSource: {
          description: "Edit the information about the data source",
          title: "Edit data source",
          namePlaceholder: "Name",
          externalIdPlaceholder: "External ID",
          typePlaceholder: "Select Type",
          companyPlaceholder: "Select Company",
        },
      },
    },
    navigation: {
      brands: "Brands",
      events: "Events",
      projectReporting: "Project Reporting",
      users: "Users",
      cockpit: "Cockpit",
      companies: "Companies",
      modules: "Modules",
      projects: "Projects",
      dataSources: "Data Sources",
      settings: "Settings",
      logout: "Log out",
    },
    notifications: {
      updatedUser: "User updated",
      invitedUser: "User invited",
      deletedUser: "User deleted",
      updatedModule: "Module updated",
      createdModule: "Module created",
      deletedModule: "Module deleted",
      eventCreated: "Event was created successfully!",
      eventUpdated: "Event was updated successfully!",
      createBrand: "Brand created",
      updateBrand: "Brand updated",
      createProject: "Project created",
      updateProject: "Project updated",
      createDataSource: "Data source created",
      updateDataSource: "Data source updated",
      deleteDataSource: "Data source deleted",
      wentWrong: "Something went wrong! Please try again later",
      resetUserPassword: "Email for password reset sent",
      versionUpdate: "Application is not up to date! Please click here to refresh.",
      moduleFilterInfo: "This filter is integrated: It can take 10-20 seconds...",
    },
  },
};

export default en;
