import { FunctionComponent, useEffect } from "react";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { LoadingOverlay } from "@/components";

import { useGetProjectsListQuery } from "../../../../redux/projects.api";

export const ProjectsListOverview: FunctionComponent = () => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`projectReporting.${key}`);
  const navigate = useNavigate();

  const { data: projects, isFetching } = useGetProjectsListQuery({ additionalData: "brands" });

  //Changes the browser tab title dynamically
  useEffect(() => {
    window.document.title = t("windowTitles.projectReporting");

    return () => {
      window.document.title = t("windowTitles.default");
    };
  }, [t]);

  return isFetching ? (
    <LoadingOverlay />
  ) : (
    <>
      <Typography mb={1.5} mt={4} variant="h1">
        {ts("title")}
      </Typography>
      <Typography component="p" mb={1.5} sx={{ maxWidth: { xs: "100%", md: 566 } }} variant="main">
        {ts("description")}
      </Typography>
      {projects?.data.map((project) => (
        <Card key={project.id}>
          <CardContent>
            <Typography component="p" sx={{ marginBottom: 2 }} variant="mainBold">
              {project.name}
            </Typography>
            <Grid
              container
              alignContent="center"
              justifyContent="space-around"
              sx={{
                flexDirection: { xs: "column", lg: "row" },
              }}
            >
              {project?.brands.map((brand) => (
                <Grid key={brand.brandId} item alignSelf="center" display="flex">
                  <Button
                    sx={{ display: "flex", flexDirection: "column" }}
                    onClick={() =>
                      navigate({
                        pathname: `${project.id}/${brand.brandId}`,
                        //TODO Discuss and add searchParams if preferable.
                        // search: `?project=${project.name}&brand=${brand.name}`,
                      })
                    }
                  >
                    <img alt="#" src={brand.image} style={{ width: 100, height: 100 }} />
                    <Typography sx={{ marginTop: 1 }} variant="green">
                      {brand.name}
                    </Typography>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      ))}
    </>
  );
};
