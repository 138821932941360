import { FunctionComponent, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { formatNumbers, toPercent } from "@/app/utils";

interface NumbersComparesProps {
  actual: number;
  planned: number;
  isKpiFeatureActive?: boolean;
  isPloomValue?: boolean;
  isKpiSettingActive?: boolean;
  titleLabel?: string;
}

export const NumbersCompares: FunctionComponent<NumbersComparesProps> = ({
  actual,
  planned,
  isKpiFeatureActive,
  isPloomValue,
  isKpiSettingActive,
  titleLabel,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.${key}`);

  const percentReached = useMemo(() => {
    const percent = toPercent({ number: actual, total: planned });

    return Math.round(percent) !== percent ? percent.toFixed(2) : percent;
  }, [actual, planned]);

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex">
        <Typography variant="gray14">{formatNumbers(actual)}</Typography>
        {isKpiFeatureActive && (
          <>
            {isKpiSettingActive && (
              <Typography variant="gray14">/{formatNumbers(planned)}</Typography>
            )}
            {isPloomValue && (
              <Typography marginLeft={0.3} variant="green10">
                {titleLabel}
              </Typography>
            )}
          </>
        )}
      </Box>
      {isKpiFeatureActive && (
        <Box>
          <Typography variant="gray12">
            {actual ? percentReached : 0}% {ts("reach")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
