import { FetchMeResponseSchema, User } from "@/pages/Public/pages/Login/schema/login";
import { api } from "@/app/redux/api";
import { StatusResponse, StatusResponseSchema } from "@/schemas/status-response";
import { RequestPaginationParams } from "@/types/request-params";

import {
  DeletedUser,
  DeleteUserSchema,
  InviteRequestSchema,
  InviteResponseSchema,
  InviteType,
  UserRolesUpdateSchema,
  UsersResponse,
  UsersResponseSchema,
} from "../pages/Users/schema/user";
import { GetAllRolesResponse, UpdateUserBody, UpdateUserRoleBody } from "../pages/Users/types";

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<UsersResponse, RequestPaginationParams>({
      query: (params) => ({
        url: "users",
        params: {
          ...(params && { ...params }),
        },
        responseSchema: UsersResponseSchema,
      }),
      providesTags: ["Users"],
    }),
    inviteUser: builder.mutation<User, InviteType>({
      query: (body) => ({
        url: "users/invite",
        method: "POST",
        body,
        requestSchema: InviteRequestSchema,
        responseSchema: InviteResponseSchema,
      }),
      invalidatesTags: ["Users"],
    }),
    getRoles: builder.query<GetAllRolesResponse, void>({
      query: () => ({
        url: "/roles",
      }),
    }),
    updateUser: builder.mutation<User, UpdateUserBody>({
      query: ({ body, id }) => ({
        url: `users/${id}`,
        method: "PATCH",
        body,
        responseSchema: FetchMeResponseSchema,
      }),
      invalidatesTags: ["Users"],
    }),
    deleteUser: builder.mutation<DeletedUser, number>({
      query: (id) => ({
        url: `users/${id}`,
        method: "DELETE",
        responseSchema: DeleteUserSchema,
      }),
      invalidatesTags: ["Users"],
    }),
    resetUserPassword: builder.mutation<StatusResponse, number>({
      query: (id) => ({
        url: `users/${id}/reset-password`,
        method: "POST",
        responseSchema: StatusResponseSchema,
      }),
    }),
    assignUserRoles: builder.mutation<User, UpdateUserRoleBody>({
      query: ({ id, body }) => ({
        url: `users/${id}/roles`,
        method: "POST",
        body,
        requestSchema: UserRolesUpdateSchema,
        responseSchema: FetchMeResponseSchema,
      }),
    }),
    unAssignUserRoles: builder.mutation<User, UpdateUserRoleBody>({
      query: ({ id, body }) => ({
        url: `users/${id}/roles`,
        method: "DELETE",
        body,
        requestSchema: UserRolesUpdateSchema,
        responseSchema: FetchMeResponseSchema,
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useInviteUserMutation,
  useGetRolesQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useResetUserPasswordMutation,
  useAssignUserRolesMutation,
  useUnAssignUserRolesMutation,
} = usersApi;
export const usersApiReducer = usersApi.reducer;
export const usersApiMiddleware = usersApi.middleware;
