import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/app/redux";
import { AuthState } from "@/types/auth.types";

import { authApi } from "./auth.api";

const initialState: AuthState = {
  token: localStorage.getItem("token") || null,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: (state) => {
      localStorage.clear();
      state.user = null;
      state.token = null;
    },
    setUser: (state, { payload: { access_token, user } }) => {
      localStorage.setItem("token", access_token);
      state.token = access_token;
      state.user = user;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
        const { access_token, user } = payload;

        localStorage.setItem("token", access_token);
        state.token = access_token;
        state.user = user;
      })
      .addMatcher(authApi.endpoints.fetchMe.matchFulfilled, (state, { payload }) => {
        state.user = payload;
      });
  },
});

export const { resetAuth, setUser } = authSlice.actions;
export const authReducer = authSlice.reducer;
export const authSelector = (state: RootState) => state[authSlice.name];
