import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { CreateCompany } from "./pages/CreateCompany/CreateCompany";
import { CompaniesOverview } from "./pages/CompaniesOverview/CompaniesOverview";
import { EditCompanyDetails } from "./pages/CompanySettings/EditCompanyDetails";

export const Companies: FunctionComponent = () => {
  return (
    <Routes>
      <Route element={<CompaniesOverview />} path="" />
      <Route element={<CreateCompany />} path="/create" />
      <Route element={<EditCompanyDetails />} path="/:id/settings" />
    </Routes>
  );
};
