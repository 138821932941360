import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, DialogActions, DialogContent, Typography } from "@mui/material";

import { ERROR_TYPE, notify, translateError } from "@/app/utils";
import { useCreateDataSourceMutation } from "@/pages/Private/redux/dataSources.api";
import { ServerError } from "@/types/error";
import { Button, InputField, Modal, SelectField } from "@/components";
import { useCompanyOptions } from "@/app/hooks/useCompanyOptions";

import { Features } from "@/pages/Private/pages/Companies/enums";
import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";

import {
  CreateDataSourceRequestSchema,
  CreateDataSourceType,
} from "../../../schema/dataSource.schema";

interface CreateDataSourceModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const CreateDataSourceModal: FunctionComponent<CreateDataSourceModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`dataSources.createDataSource.modal.${key}`);

  const [create, { isLoading }] = useCreateDataSourceMutation();
  const { companyOptions } = useCompanyOptions();
  const isMultiTenancyActive = useIsFeatureActive(Features.MULTI_TENANCY);

  const dataSourceTypeOptions = [
    { text: "Livia", value: "LIVIA" },
    { text: "Sum Up", value: "SUM_UP" },
  ]; // to be adapted when data source types are received from BE

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<CreateDataSourceType>({
    defaultValues: {
      name: "",
      externalId: "",
      type: "",
      companyId: 0,
    },
    resolver: zodResolver(CreateDataSourceRequestSchema),
  });

  const onSubmit = async (values: CreateDataSourceType) => {
    try {
      await create(values).unwrap();
      setIsOpen(false);
      reset();
      notify({ text: t("notifications.createDataSource") });
    } catch (err) {
      notify({
        text: translateError[(err as ServerError).data.message as ERROR_TYPE],
        type: "error",
      });
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    reset();
  };

  return (
    <Modal setShow={handleClose} show={isOpen} title={ts("title")}>
      <DialogContent
        dividers
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          border: "none",
          padding: 1.5,
          paddingTop: 0,
        }}
      >
        <Typography component="p" mb={0.5} variant="main14">
          {ts("description")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Box mb={1} width="100%">
            <InputField
              hasError={errors.name && !!errors.name}
              {...register("name")}
              placeholder={ts("namePlaceholder")}
            />
          </Box>
          <Box mb={1} width="100%">
            <InputField
              hasError={errors.externalId && !!errors.externalId}
              {...register("externalId")}
              placeholder={ts("externalIdPlaceholder")}
            />
          </Box>
          <SelectField
            currentValue={watch("type") || "DEFAULT"}
            hasError={!!errors.companyId?.message}
            options={dataSourceTypeOptions || []}
            placeholder={ts("typePlaceholder")}
            {...register("type")}
          />
          {isMultiTenancyActive && (
            <SelectField
              currentValue={watch("companyId") || "DEFAULT"}
              hasError={!!errors.companyId?.message}
              options={companyOptions || []}
              placeholder={ts("companyPlaceholder")}
              {...register("companyId")}
            />
          )}
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          padding: 1.5,
        }}
      >
        <Button
          handleClick={handleSubmit(onSubmit)}
          isDisabled={!isValid}
          isLoading={isLoading}
          title={ts("cta")}
          type="submit"
        />
        <Button handleClick={handleClose} title={t("basics.cancel")} variant="outlined" />
      </DialogActions>
    </Modal>
  );
};
