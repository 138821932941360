import { FunctionComponent } from "react";
import { Box } from "@mui/material";

import { LoadingOverlay } from "@/components";
import { eventsSelector } from "@/pages/Private/redux";
import { useAppSelector } from "@/app/redux/hooks";

import { PromotersTopThirtyTable } from "./components/PromotersTopThirtyTable";
import { PromoterOverallPerformance } from "./components/PromoterOverallPerformance";
import { EventTypes } from "../../../types";

export enum NewsletterDataType {
  DOI_NL = "DOI_NL",
  DOI_NL_WITH_DEVICE_ID = "DOI_NL_WITH_DEVICE_ID",
  DOI = "DOI",
  DOI_WITH_DEVICE_ID = "DOI_WITH_DEVICE_ID",
  PENDING_WITH_DEVICE_ID = "PENDING_WITH_DEVICE_ID",
  WITHOUT_DOI = "WITHOUT_DOI",
}
interface EventPromotersDashboardProps {
  isFiltration?: boolean;
  isFetching: boolean;
  timeFilterStartingMinutes: number | null;
}
export const EventPromotersDashboard: FunctionComponent<EventPromotersDashboardProps> = ({
  isFiltration,
  isFetching,
  timeFilterStartingMinutes,
}) => {
  const { eventDetails, selectedEvent: event } = useAppSelector(eventsSelector);
  const isPloomEvent = eventDetails?.eventType !== EventTypes.FESTIVAL;

  return !event ? (
    <LoadingOverlay />
  ) : (
    <>
      <Box
        sx={{
          width: "100%",
          display: { md: "flex" },
          justifyContent: "space-between",
          mb: { xs: 2, md: 1 },
        }}
      >
        <PromotersTopThirtyTable
          isFetching={isFetching}
          isFiltration={isFiltration}
          newsletterDataType={
            isPloomEvent ? NewsletterDataType.DOI_NL_WITH_DEVICE_ID : NewsletterDataType.DOI_NL
          }
          promoterKPIs={event?.data}
          timeFilterStartingMinutes={timeFilterStartingMinutes}
        />
        <PromotersTopThirtyTable
          isFetching={isFetching}
          isFiltration={isFiltration}
          newsletterDataType={
            isPloomEvent ? NewsletterDataType.DOI_WITH_DEVICE_ID : NewsletterDataType.DOI
          }
          promoterKPIs={event?.data}
          timeFilterStartingMinutes={timeFilterStartingMinutes}
        />
        <PromotersTopThirtyTable
          isFetching={isFetching}
          isFiltration={isFiltration}
          newsletterDataType={
            isPloomEvent
              ? NewsletterDataType.PENDING_WITH_DEVICE_ID
              : NewsletterDataType.WITHOUT_DOI
          }
          promoterKPIs={event?.data}
          timeFilterStartingMinutes={timeFilterStartingMinutes}
        />
      </Box>
      <PromoterOverallPerformance
        isFetching={isFetching}
        isFiltration={isFiltration}
        promoterKPIs={event?.data}
        timeFilterStartingMinutes={timeFilterStartingMinutes}
      />
    </>
  );
};
