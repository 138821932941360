import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/app/utils";

import { EventDatesSchema, KpiSchema } from "./../Events/event.schema";

const ProjectsListBrandSchema = z.object({
  brandId: z.number(),
  projectId: z.number(),
  name: z.string(),
  image: z.string(),
  companyId: z.number(),
});

const ProjectSchema = z.object({
  id: z.number(),
  name: z.string(),
  events: z.string().array(),
  brands: z.array(ProjectsListBrandSchema),
  companyId: z.number(),
});

export const ProjectsResponseSchema = z.object({
  data: z.array(ProjectSchema),
  meta: MetaDataSchema,
});

export const ProjectByBrandKPIsResponseSchema = z.object({
  projectId: z.number(),
  brandId: z.number(),
  projectName: z.string(),
  name: z.string(),
  eventDates: z.array(EventDatesSchema),
  expectedVisitors: z.number(),
  actualVisitors: z.number(),
  image: z.string(),
  data: z.array(KpiSchema),
});

const { REQUIRED } = ERROR_TYPE;
export const CreateOrEditRequestSchema = z
  .object({
    name: z.string().min(1, { message: REQUIRED }).trim(),
  })
  .partial();

export type ProjectBrandType = z.infer<typeof ProjectsListBrandSchema>;

export const CreateResponseSchema = ProjectSchema.partial();
export type ProjectType = z.infer<typeof ProjectSchema>;
export type ProjectsResponse = z.infer<typeof ProjectsResponseSchema>;
export type ProjectByBrandKPIsResponseType = z.infer<typeof ProjectByBrandKPIsResponseSchema>;
export type CreateOrEditProjectType = z.infer<typeof CreateOrEditRequestSchema>;
