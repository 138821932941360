import { FunctionComponent } from "react";
import { Box, CircularProgress } from "@mui/material";

export const Loader: FunctionComponent = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      minHeight: "110px",
    }}
  >
    <CircularProgress />
  </Box>
);
