import { FunctionComponent, useMemo } from "react";
//import { useTranslation } from "react-i18next";

import { formatNumbers } from "@/app/utils";

import { KpiTypeEnum, TopTenTableData } from "../../../../types";
import { KpiType } from "../../../../event.schema";
import { TopTenTable } from "../../SalesDashboard/components/TopTenTable";

interface DoiByCityTableProps {
  isFetching: boolean;
  doiKPIs: KpiType[];
  isFiltration?: boolean;
}

export const DoiDistributionByCityTable: FunctionComponent<DoiByCityTableProps> = ({
  isFetching,
  doiKPIs,
  isFiltration,
}) => {
  const filteredDoiByCityKPI = useMemo(() => {
    const doiByCityKPI = doiKPIs.find((k) => k.name === KpiTypeEnum.NEWSLETTER_DATA_BY_CITY)?.data;

    const labels: TopTenTableData[] = [];

    if (!doiByCityKPI) {
      return labels;
    }

    if (isFiltration && !doiByCityKPI?.at(0)?.date) {
      return labels;
    }

    for (const city of doiByCityKPI) {
      const dates = isFiltration ? city.date : [city.overall];
      const item = {
        // Transform into DoiQuantity format
        name: city.specific ? city.specific : "",
        quantity: dates?.[0]?.value ? formatNumbers(dates?.[0]?.value.toFixed(0)) : 0,
        percentage: dates?.[0]?.percent ? dates?.[0]?.percent : 0,
      };

      labels.push(item);
    }

    labels.sort((a, b) => {
      if (a.percentage < b.percentage) {
        return 1;
      }

      if (a.percentage > b.percentage) {
        return -1;
      }

      return 0;
    });

    return labels;
  }, [doiKPIs, isFiltration]);

  return (
    <TopTenTable dataToPass={filteredDoiByCityKPI} isFetching={isFetching} namingPath="cities" />
  );
};
