import { FunctionComponent, useMemo } from "react";

import { formatNumbers } from "@/app/utils";

import { KpiTypeEnum, TopTenTableData } from "../../../../types";
import { KpiType } from "../../../../event.schema";
import { TopTenTable } from "../../SalesDashboard/components/TopTenTable";

interface GiveawaysTopTenTableProps {
  isFetching: boolean;
  giveawaysKPIs: KpiType[];
  isFiltration?: boolean;
}

export const GiveawaysTopTenTable: FunctionComponent<GiveawaysTopTenTableProps> = ({
  isFetching,
  giveawaysKPIs,
  isFiltration,
}) => {
  const sortedGiveawaysKPI = useMemo(() => {
    const giveawaysKPI = giveawaysKPIs.find((k) => k.name === KpiTypeEnum.GIVEAWAY)?.data;

    const labels: TopTenTableData[] = [];

    if (!giveawaysKPI) {
      return labels;
    }

    if (isFiltration && !giveawaysKPI.at(0)?.date) {
      return labels;
    }

    for (const giveaway of giveawaysKPI) {
      const dates = isFiltration ? giveaway.date : [giveaway.overall];

      const item = {
        // Transform into quantity format
        name: giveaway.specific ? giveaway.specific : "",
        quantity: dates?.[0].value ? formatNumbers(dates?.[0].value.toFixed(0)) : 0,
        percentage: dates?.[0].percent ? dates?.[0].percent : 0,
      };

      if (item.name) {
        labels.push(item);
      }
    }

    labels.sort((a, b) => {
      if (a.percentage < b.percentage) {
        return 1;
      }

      if (a.percentage > b.percentage) {
        return -1;
      }

      return 0;
    });

    return labels;
  }, [giveawaysKPIs, isFiltration]);

  return (
    <TopTenTable dataToPass={sortedGiveawaysKPI} isFetching={isFetching} namingPath="giveaways" />
  );
};
