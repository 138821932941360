import { zodResolver } from "@hookform/resolvers/zod";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { DialogActions, DialogContent, Typography } from "@mui/material";

import {
  useDeleteDataSourceMutation,
  useUpdateDataSourceMutation,
} from "@/pages/Private/redux/dataSources.api";
import { ERROR_TYPE, notify, translateError } from "@/app/utils";
import { ServerError } from "@/types/error";
import { Button, ConfirmationModal, InputField, Modal, SelectField } from "@/components";
import { useCompanyOptions } from "@/app/hooks/useCompanyOptions";

import {
  DataSourceRequestBodySchema,
  DataSourceRequestBodyType,
  DataSourceType,
} from "../../../schema/dataSource.schema";

interface EditDataSourceModalProps {
  dataSource: DataSourceType | null;
  handleClose: () => void;
  show: boolean;
}
export const EditDataSourceModal: FunctionComponent<EditDataSourceModalProps> = ({
  dataSource,
  handleClose,
  show,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`dataSources.modals.editDataSource.${key}`);
  const tk = (key: string) => t(`dataSources.modals.deleteDataSource.${key}`);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [updateDataSource, { isLoading, isSuccess, reset }] = useUpdateDataSourceMutation();
  const [deleteDataSource, { isLoading: isDeleting, isSuccess: isDeleted }] =
    useDeleteDataSourceMutation();

  const { companyOptions } = useCompanyOptions();
  const dataSourceTypeOptions = [{ text: "Livia", value: "LIVIA" }]; // to be adapted when data source types are received from BE

  const initialValues: DataSourceRequestBodyType = {
    name: dataSource?.name ?? "",
    externalId: dataSource?.externalId ?? "",
    type: dataSource?.type ?? "",
    companyId: dataSource?.companyId ?? 0,
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<DataSourceRequestBodyType>({
    defaultValues: initialValues,
    resolver: zodResolver(DataSourceRequestBodySchema),
    values: initialValues,
  });

  const onSubmit = async (values: DataSourceRequestBodyType) => {
    try {
      const body: DataSourceRequestBodyType = values;

      const valuesDoNotDiffer =
        Object.keys(body).length === Object.keys(initialValues).length &&
        (Object.keys(body) as (keyof typeof body)[]).every((key) => {
          return (
            Object.prototype.hasOwnProperty.call(initialValues, key) &&
            body[key] === initialValues[key]
          );
        });

      if (Object.entries(body).length > 0 && !valuesDoNotDiffer) {
        await updateDataSource({ id: dataSource?.id ?? 0, body }).unwrap();
        notify({ text: t("notifications.updateDataSource") });
      }
    } catch (err) {
      notify({
        text: translateError[(err as ServerError).data.message as ERROR_TYPE],
        type: "error",
      });
    }
  };

  const onDelete = async () => {
    try {
      await deleteDataSource(dataSource?.id ?? 0).unwrap();
      notify({ text: t("notifications.deletedDataSource") });
    } catch (err) {
      notify({
        text: translateError[(err as ServerError).data.message as ERROR_TYPE],
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (isSuccess || isDeleted) {
      handleClose();
      reset();
    }
  }, [isSuccess, isDeleted, handleClose, reset]);

  return (
    <>
      <Modal setShow={handleClose} show={show} title={ts("title")}>
        <DialogContent
          dividers
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            border: "none",
            padding: 1.5,
            paddingTop: 0,
            overflow: "hidden",
          }}
        >
          <Typography component="p" sx={{ marginBottom: 0.5 }} variant="main14">
            {ts("description")}
          </Typography>
          <InputField
            hasError={errors.name && !!errors.name}
            {...register("name")}
            placeholder={ts("namePlaceholder")}
          />
          <InputField
            hasError={errors.externalId && !!errors.externalId}
            {...register("externalId")}
            placeholder={ts("externalIdPlaceholder")}
          />
          <SelectField
            currentValue={watch("type") || "DEFAULT"}
            hasError={!!errors.type?.message}
            options={dataSourceTypeOptions || []}
            placeholder={ts("typePlaceholder")}
            {...register("type")}
          />
          <SelectField
            currentValue={watch("companyId") || "DEFAULT"}
            hasError={!!errors.companyId?.message}
            options={companyOptions || []}
            placeholder={ts("companyPlaceholder")}
            {...register("companyId")}
          />
          <Button
            handleClick={handleSubmit(onSubmit)}
            isDisabled={!isValid}
            isLoading={isLoading}
            title={t("basics.save")}
          />
        </DialogContent>

        <DialogActions
          sx={{
            padding: 1.5,
            display: "flex",
            flexWrap: "wrap",
            gap: 1.5,
            justifyContent: "center",
            "&>*:not(:first-of-type)": {
              marginLeft: 0,
            },
          }}
        >
          <Button
            bgcolor="red.main"
            handleClick={() => setShowConfirmationModal(true)}
            isLoading={isDeleting}
            title={t("basics.delete")}
          />
        </DialogActions>
      </Modal>

      <ConfirmationModal
        isDeletion
        confirmButton={t("basics.delete")}
        description={tk("description")}
        handleClose={() => setShowConfirmationModal(false)}
        handleConfirm={onDelete}
        isShown={showConfirmationModal}
        name={`${t("basics.name")}: ${dataSource?.name}`}
        title={tk("title")}
      />
    </>
  );
};
