import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FunctionComponent } from "react";

import { Loader, NoDataFound } from "@/components";
import { formatNumbers } from "@/app/utils";
import { useAppSelector } from "@/app/redux/hooks";
import { eventsSelector } from "@/pages/Private/redux";

import { KpiType } from "../../../../event.schema";
import {
  EventTypes,
  KpiTypeEnum,
  NewsletterWithoutDeviceSpecifics,
  NewsletterWithDeviceSpecifics,
} from "../../../../types";

interface DoiTotalRegistrationsProps {
  isFetching: boolean;
  kpis: KpiType[];
}
export const DoiTotalRegistrations: FunctionComponent<DoiTotalRegistrationsProps> = ({
  isFetching,
  kpis,
}) => {
  const { eventDetails } = useAppSelector(eventsSelector);
  const isPloomEvent = eventDetails?.eventType !== EventTypes.FESTIVAL;
  const { t } = useTranslation();
  const ts = (key: string) =>
    t(
      `events.doiKPIs.${
        isPloomEvent ? "totalRegistrationsWithDeviceId" : "totalRegistrations"
      }.${key}`
    );

  const newsletterData = kpis.find((kpi) => kpi.name === KpiTypeEnum.NEWSLETTER_DATA);

  const totalDoiNL =
    newsletterData?.data.find(
      (data) =>
        data.specific ===
        (isPloomEvent
          ? NewsletterWithDeviceSpecifics.DOI_NL_WITH_DEVICE_ID
          : NewsletterWithoutDeviceSpecifics.DOI_NL)
    )?.overall?.value ||
    newsletterData?.data
      .find(
        (data) =>
          data.specific ===
          (isPloomEvent
            ? NewsletterWithDeviceSpecifics.DOI_NL_WITH_DEVICE_ID
            : NewsletterWithoutDeviceSpecifics.DOI_NL)
      )
      ?.date?.at(0)?.value ||
    0;

  const totalDoi =
    newsletterData?.data.find(
      (data) =>
        data.specific ===
        (isPloomEvent
          ? NewsletterWithDeviceSpecifics.DOI_WITH_DEVICE_ID
          : NewsletterWithoutDeviceSpecifics.DOI)
    )?.overall?.value ||
    newsletterData?.data
      .find(
        (data) =>
          data.specific ===
          (isPloomEvent
            ? NewsletterWithDeviceSpecifics.DOI_WITH_DEVICE_ID
            : NewsletterWithoutDeviceSpecifics.DOI)
      )
      ?.date?.at(0)?.value ||
    0;

  return (
    <Box
      sx={{
        borderStyle: "solid",
        borderWidth: { xs: "0 0 1px 0 ", md: "0 1px 0 0" },
        borderColor: "gray400.main",
        paddingRight: { xs: 0, md: 1 },
        paddingBottom: { xs: 1, md: 0 },
        width: "100%",
      }}
    >
      <Typography alignItems="center" paddingTop={1.5} variant="gray14Bold">
        {ts("title")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : kpis.length > 0 ? (
        <Box display="flex" flexDirection="column" height="90%" justifyContent="space-around">
          <Box display="flex" flexDirection="column">
            <Typography variant="main42bold">{formatNumbers(totalDoiNL)}</Typography>
            <Typography variant="gray12">{ts("total")}</Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="main14">{formatNumbers(totalDoi)}</Typography>
            <Typography variant="gray12">{ts("totalWithoutNL")}</Typography>
          </Box>
        </Box>
      ) : (
        <NoDataFound height={140} kpi={ts("title")} />
      )}
    </Box>
  );
};
