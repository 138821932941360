import { Box, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { Loader, NoDataFound } from "@/components";
import { formatNumbers } from "@/app/utils";

import { KpiTypeEnum } from "../../../../types";
import { KpiType } from "../../../../event.schema";

interface GiveawaysTotalTileProps {
  isFetching: boolean;
  kpis: KpiType[];
}
export const GiveawaysTotalTile: FunctionComponent<GiveawaysTotalTileProps> = ({
  isFetching,
  kpis,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.giveawaysKPIs.${key}`);

  const totalGiveaways =
    kpis.find((kpi) => kpi?.name === KpiTypeEnum.GIVEAWAY)?.data?.at(0)?.overall?.value ||
    kpis
      .find((kpi) => kpi?.name === KpiTypeEnum.GIVEAWAY)
      ?.data?.at(0)
      ?.date?.at(0)?.value ||
    0;

  return (
    <Box
      bgcolor="white"
      borderRadius={2}
      boxShadow={1}
      px={1.5}
      sx={{
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-between",
        // height: { xs: 160, md: 140 },
        height: totalGiveaways ? "fit-content" : 140,
        mb: { xs: 2, md: 0 },
        py: 1,
        // width: "256px",
      }}
    >
      <Typography alignItems="center" display="flex" variant="gray14Bold">
        {ts("totalTile.title")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : kpis.length > 0 ? (
        <>
          <Box display="flex" flexDirection="column">
            <Typography variant="main42bold">{formatNumbers(totalGiveaways)}</Typography>
          </Box>
        </>
      ) : (
        <NoDataFound kpi={ts("totalTile.title").toLocaleLowerCase()} />
      )}
    </Box>
  );
};
