import { FunctionComponent, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

import { useAppSelector } from "@/app/redux/hooks";
import { brandsSelector } from "@/pages/Private/redux/brands.slice";
import { BrandType } from "@/pages/Private/pages/Brands/schema/brand.schema";
import { Button, SelectField } from "@/components";
import { useGetBrandsListQuery } from "@/pages/Private/redux/brands.api";
import { clearEventsFilter, eventsSelector, setActiveBar, setEventsFilter } from "@/pages/Private";
import { eventTypesArray } from "@/app/constants";
import { MultipleSelectField } from "@/components/MultipleSelectField/MultipleSelectField";
import { projectsSelector } from "@/pages/Private/redux/projects.slice";
import { useGetProjectsListQuery } from "@/pages/Private/redux/projects.api";

export const EventFilter: FunctionComponent<{ isForMobile?: boolean }> = ({ isForMobile }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { filters } = useAppSelector(eventsSelector);
  const { brands } = useAppSelector(brandsSelector);
  const { projects } = useAppSelector(projectsSelector);

  useGetBrandsListQuery({});
  useGetProjectsListQuery({});

  const brandsOptions = useMemo(() => {
    return brands?.map((brands: BrandType) => {
      return { text: brands.name, value: brands.id };
    });
  }, [brands]);

  const eventTypeOptions = useMemo(() => {
    return eventTypesArray?.map((type) => {
      return { text: type.value, value: type.id };
    });
  }, []);

  const projectsOptions = useMemo(() => {
    return projects?.map((project: { id: number; name: string }) => {
      return { text: project.name, value: project.id };
    });
  }, [projects]);

  const { register, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      brandId: filters.brandId ?? "DEFAULT",
      eventTypeIds: filters.eventTypeIds.length ? filters.eventTypeIds : [],
      projectId: filters.projectId ?? "DEFAULT",
    },
  });

  const { brandId, eventTypeIds, projectId } = watch();

  const onSubmit = handleSubmit((values) => {
    dispatch(
      setEventsFilter({
        brandId: values.brandId !== "DEFAULT" ? values.brandId : null,
        eventTypeIds: eventTypeIds.at(-1) === "DEFAULT" ? [] : eventTypeIds,
        projectId: values.projectId !== "DEFAULT" ? values.projectId : null,
      })
    );
    dispatch(setActiveBar(null));
  });

  const clearFilters = () => {
    dispatch(clearEventsFilter());
    reset({
      brandId: "DEFAULT",
      eventTypeIds: [],
      projectId: "DEFAULT",
    });
  };

  useEffect(() => {
    if (!isForMobile) {
      dispatch(
        setEventsFilter({
          brandId: brandId === "DEFAULT" ? null : Number(brandId),
          eventTypeIds: eventTypeIds.at(-1) === "DEFAULT" ? [] : eventTypeIds,
          projectId: projectId === "DEFAULT" ? null : Number(projectId),
        })
      );
      if (eventTypeIds.at(-1) === "DEFAULT") {
        reset({ eventTypeIds: [] });
      }
    }
  }, [brandId, eventTypeIds, projectId, reset, dispatch, isForMobile]);

  return (
    <Box
      alignItems="center"
      display="flex"
      flexGrow={1}
      height="100%"
      sx={{
        flexDirection: { xs: "column", md: "row" },
        maxWidth: { sx: "100%", md: 540 },
        gap: 1,
      }}
    >
      <SelectField
        withDefaultValue
        currentValue={brandId}
        options={brandsOptions || []}
        placeholder={t("terms.selectBrand")}
        {...register("brandId")}
      />

      <MultipleSelectField
        multiple
        currentValue={eventTypeIds}
        options={eventTypeOptions || []}
        placeholder={t("terms.selectType")}
        {...register("eventTypeIds")}
      />

      <SelectField
        withDefaultValue
        currentValue={projectId}
        options={projectsOptions || []}
        placeholder={t("terms.selectProject")}
        {...register("projectId")}
      />
      {isForMobile && (
        <>
          {!!Object.values(filters).filter((v) => v !== null).length && (
            <Typography
              color="red.main"
              component="p"
              fontSize={16}
              fontWeight={700}
              mb={3}
              textAlign="center"
              variant="link"
              onClick={clearFilters}
            >
              {t("basics.clearFilter")}
            </Typography>
          )}
          <Button handleClick={onSubmit} title={t("basics.search")} type="submit" />
        </>
      )}
    </Box>
  );
};
