import { FunctionComponent, useMemo } from "react";
import { Box } from "@mui/material";
import { utcToZonedTime } from "date-fns-tz";

import { LoadingOverlay } from "@/components";
import { GiveawaysTotalTile } from "@/pages/Private/pages/Events/pages/EventDetails/GiveawaysDashboard/components/GiveawaysTotalTile";
import { GiveawaysByModuleDonutChart } from "@/pages/Private/pages/Events/pages/EventDetails/GiveawaysDashboard/components/GiveawaysByModuleDonutChart";
import { GiveawaysByDayDonutChart } from "@/pages/Private/pages/Events/pages/EventDetails/GiveawaysDashboard/components/GiveawaysByDayDonutChart";
import { GiveawaysTopTenTable } from "@/pages/Private/pages/Events/pages/EventDetails/GiveawaysDashboard/components/GiveawaysTopTenTable";
import { GiveawaysByModuleHeatmap } from "@/pages/Private/pages/Events/pages/EventDetails/GiveawaysDashboard/components/GiveawaysByModuleHeatmap";
import { useAppSelector } from "@/app/redux/hooks";
import { projectsSelector } from "@/pages/Private/redux/projects.slice";
import { timeZone } from "@/app/utils";
import { GiveawaysTimelineChart } from "@/pages/Private/pages/Events/pages/EventDetails/GiveawaysDashboard/components/GiveawaysTimelineChart";

export const ProjectByBrandGiveawaysDashboard: FunctionComponent = () => {
  const { selectedProjectByBrand: project } = useAppSelector(projectsSelector);

  const timeLineDates = useMemo(() => {
    const startDate = project?.eventDates[0]?.fromDateTime ?? "";
    const endDate = project?.eventDates[project.eventDates.length - 1]?.tillDateTime ?? "";
    const start = utcToZonedTime(new Date(startDate), timeZone);
    const end = utcToZonedTime(new Date(endDate), timeZone);

    return { start, end };
  }, [project]);

  return !project ? (
    <LoadingOverlay />
  ) : (
    <>
      <Box
        sx={{
          mb: { xs: 2, md: 1 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
        }}
      >
        <GiveawaysTotalTile isFetching={!project} kpis={project?.data} />
        <Box
          bgcolor="white"
          borderRadius={2}
          boxShadow={1}
          p={1.5}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
            pb: { xs: 1.5, md: 1 },
          }}
        >
          <GiveawaysByModuleDonutChart isFetching={!project} kpis={project?.data} />
          <GiveawaysByDayDonutChart isFetching={!project} kpis={project?.data} />
        </Box>
      </Box>
      <Box
        sx={{
          display: { md: "flex" },
          mb: { xs: 2, md: 1 },
        }}
      >
        <GiveawaysTimelineChart
          dates={timeLineDates}
          isLoading={!project}
          isProjectReporting={true}
          kpis={project?.data}
        />
        <GiveawaysTopTenTable giveawaysKPIs={project?.data} isFetching={!project} />
      </Box>
      <GiveawaysByModuleHeatmap kpis={project?.data} />
    </>
  );
};
