import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./translations/en";
import de from "./translations/de";

i18n.use(initReactI18next).init({
  returnNull: false,
  fallbackLng: "en",
  resources: {
    en,
    de,
  },
});

export default i18n;
