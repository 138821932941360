import { FunctionComponent, useMemo } from "react";
import { formatInTimeZone } from "date-fns-tz";

import { convertHourToMinutes, formatNumbers } from "@/app/utils";

import { KpiTypeEnum, TopTenTableData } from "../../../../types";
import { KpiType } from "../../../../event.schema";
import { TopTenTable } from "../../SalesDashboard/components/TopTenTable";
import { NewsletterDataType } from "../EventPromoterDashboard";

interface PromotersTopThirtyTableProps {
  isFetching: boolean;
  promoterKPIs: KpiType[];
  isFiltration?: boolean;
  newsletterDataType?: string;
  timeFilterStartingMinutes?: number | null;
}

export const PromotersTopThirtyTable: FunctionComponent<PromotersTopThirtyTableProps> = ({
  isFetching,
  promoterKPIs,
  isFiltration,
  newsletterDataType,
  timeFilterStartingMinutes,
}) => {
  const tableNamingPathByNLType = () => {
    switch (newsletterDataType) {
      case NewsletterDataType.DOI:
      case NewsletterDataType.DOI_WITH_DEVICE_ID:
        return "promoter.doi";
      case NewsletterDataType.DOI_NL:
      case NewsletterDataType.DOI_NL_WITH_DEVICE_ID:
        return "promoter.doiNL";
      case NewsletterDataType.WITHOUT_DOI:
      case NewsletterDataType.PENDING_WITH_DEVICE_ID:
        return "promoter.withoutDOI";
      default:
        return "";
    }
  };

  const sortedPromoterKPIs = useMemo(() => {
    const promoterNLDataByMailKPIs = promoterKPIs
      .find((kpi) => kpi.name === KpiTypeEnum.NEWSLETTER_DATA_BY_PROMOTEREMAIL)
      ?.data.find((d) => d.specific === newsletterDataType)
      ?.date.filter((promoterData) => (isFiltration ? promoterData.date : !promoterData.date));

    const filteredPromoterDataByPickedTime = promoterKPIs
      .find((kpi) => kpi.name === KpiTypeEnum.NEWSLETTER_DATA_BY_PROMOTEREMAIL)
      ?.data?.find((d) => d.specific === newsletterDataType)
      ?.hour?.filter((el) => {
        const promoterStartingMins = convertHourToMinutes(
          formatInTimeZone(new Date(el.time), "UTC", "HH:mm")
        );

        return (
          timeFilterStartingMinutes &&
          promoterStartingMins >= timeFilterStartingMinutes &&
          promoterStartingMins < timeFilterStartingMinutes + 60
        );
      });

    const labels: TopTenTableData[] = [];

    if (!promoterNLDataByMailKPIs) {
      return labels;
    }

    if (isFiltration && !promoterNLDataByMailKPIs) {
      return labels;
    }

    if (timeFilterStartingMinutes && filteredPromoterDataByPickedTime) {
      for (const promoterNewsletterData of filteredPromoterDataByPickedTime) {
        const item = {
          name: promoterNewsletterData.dim2 ? promoterNewsletterData.dim2 : "",
          quantity: promoterNewsletterData.value
            ? formatNumbers(promoterNewsletterData.value.toFixed(0))
            : 0,
          percentage: promoterNewsletterData.percent ? promoterNewsletterData.percent : 0,
        };

        if (item.name) {
          labels.push(item);
        }
      }
    } else {
      for (const promoterNewsletterData of promoterNLDataByMailKPIs) {
        const item = {
          name: promoterNewsletterData.dim2 ? promoterNewsletterData.dim2 : "",
          quantity: promoterNewsletterData.value
            ? formatNumbers(promoterNewsletterData.value.toFixed(0))
            : 0,
          percentage: promoterNewsletterData.percent ? promoterNewsletterData.percent : 0,
        };

        if (item.name) {
          labels.push(item);
        }
      }
    }

    labels.sort((a, b) => {
      if (a.percentage < b.percentage) {
        return 1;
      }

      if (a.percentage > b.percentage) {
        return -1;
      }

      return 0;
    });

    return labels;
  }, [promoterKPIs, isFiltration, newsletterDataType, timeFilterStartingMinutes]);

  return (
    <TopTenTable
      dataToPass={sortedPromoterKPIs}
      isFetching={isFetching}
      namingPath={tableNamingPathByNLType()}
    />
  );
};
