import { FunctionComponent, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";

import { EventStatus, KpiTypeEnum } from "@/pages/Private/pages/Events/types";
import { SettingTabs } from "@/pages/Private/pages/Events/pages";

type TabValue = EventStatus | SettingTabs | KpiTypeEnum | string;

interface TabProps {
  text: string;
  value: TabValue;
}

interface TabsProps {
  tabs: TabProps[];
  selectedTab: string;
  handleSelect: (value: TabValue) => void;
  excludeFromSearch?: boolean;
}

export const Tabs: FunctionComponent<TabsProps> = ({
  tabs,
  selectedTab,
  handleSelect,
  excludeFromSearch,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSelectValue = (tab: TabProps) => {
    handleSelect(tab.value);

    if (!excludeFromSearch) {
      setSearchParams({ tab: tab.text });
    }
  };

  useEffect(() => {
    if (searchParams) {
      const tab = searchParams.get("tab");
      const selectedTabParam = tabs.find((t) => t.text === tab)?.value;

      if (selectedTabParam) {
        handleSelect(selectedTabParam);
      }
    }
  }, [searchParams, handleSelect, tabs]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{ width: { sx: "100%", md: "70%", lg: tabs.length < 4 ? "40%" : "60%" } }}
    >
      {tabs.map((tab, i) => (
        <Box
          key={`events-tab-${tab.value}-${i}`}
          sx={{
            padding: 0.5,
            width: "100%",
            borderBottom: "2px solid",
            borderColor: tab.value === selectedTab ? "main" : "transparent",
            textAlign: "center",
            color: tab.value === selectedTab ? "main" : "gray600.main",
            fontWeight: 600,
            transitionDuration: "200ms",
            transform: "border",
            cursor: "pointer",
          }}
          onClick={() => handleSelectValue(tab)}
        >
          {tab.text}
        </Box>
      ))}
    </Box>
  );
};
