import { FunctionComponent, useMemo } from "react";
import { default as Chart } from "react-apexcharts";
import { useTranslation } from "react-i18next";

import { useWindowSize } from "@/app/hooks/useWindowSize";

import { NoDataFound } from "../NoDataFound/NoDataFound";

export interface LineChartSeries {
  name: string;
  type: "line" | "area" | "column";
  data: number[];
  color?: string;
}

interface LineChartProps {
  series: LineChartSeries[];
  categories: number[];
  stroke?: number[];
  daysOnly?: boolean;
}

export const LineChart: FunctionComponent<LineChartProps> = ({
  series,
  categories,
  stroke,
  daysOnly,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isWithoutData = series.every((s) => s.data.every((v) => v === 0));

  const mainData = useMemo(
    () => series.find((s) => s.name !== t("events.visitors") && s.type === "line"),
    [series, t]
  );

  const mainDataLength = mainData?.data.length ?? 1;

  return isWithoutData ? (
    <NoDataFound kpi={t("events.performanceTimeline").toLowerCase()} />
  ) : (
    <Chart
      height="100%"
      options={{
        chart: {
          type: "line",
          toolbar: {
            show: false,
          },
          redrawOnParentResize: true,
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: width < 900,
          },
        },
        legend: {
          show: true,
        },
        annotations: {
          xaxis: [
            {
              ...(mainDataLength !== categories.length && {
                x: categories[mainDataLength - 1],
                borderColor: "#54D62C",
                strokeDashArray: 4,
              }),
            },
            // {
            // 	// x: 46800000, // adjust
            // 	// x2: 68400000, // adjust
            // 	fillColor: "#00B2A9",
            // 	label: {
            // 		text: t("events.promoterHours"),
            // 		style: {
            // 			color: "white",
            // 			background: "#00B2A9",
            // 		},
            // 	},
            // },
          ],
        },
        stroke: {
          width: stroke || [2, 2, 2],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            columnWidth: width > 900 ? "20px" : "30px",
          },
        },
        forecastDataPoints: {
          count: 0,
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: true,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: width > 900,
            },
          },
        },
        xaxis: {
          type: "datetime",
          categories,
          tickAmount: 3,
          tooltip: {
            enabled: true,
          },
          labels: {
            show: true,
            datetimeUTC: false,
            datetimeFormatter: {
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
          axisBorder: {
            show: true,
            color: "#919EAB",
            offsetX: 0,
            offsetY: -1,
          },
        },
        tooltip: {
          x: {
            format: daysOnly ? "dd.MM.yyyy" : "dd.MM.yyyy HH:mm",
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: width > 900,
          },
          axisBorder: {
            show: width <= 900,
            color: "#919EAB",
            offsetX: width > 900 ? 2 : -9,
            offsetY: 0,
          },
        },
        fill: {
          opacity: 0.7,
        },
      }}
      series={series}
      type="line"
      width="100%"
    />
  );
};
