import { Box } from "@mui/system";

import { Role } from "@/enum";
import { UserRole } from "@/pages/Public/pages/Login/schema/login";
import { useProductVersion } from "@/app/hooks/useProductVersion";
import { ProductVersion } from "@/types";

interface TextBadgeProps {
  role: UserRole;
}

export const TextBadge = ({ role }: TextBadgeProps) => {
  const currentProductVersion = useProductVersion();

  const badgeColor = (role: UserRole) => {
    switch (role.code) {
      case Role.COMPANY_USER:
        return "badge.gray";
      case Role.BSURE_ADMIN:
        return currentProductVersion === ProductVersion.BSURE ? "badge.yellow" : "badge.green";
      case Role.COMPANY_ADMIN:
        return "badge.gray";
      case Role.KPI_ADMIN:
        return "badge.gray";

      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        bgcolor: badgeColor(role),
        boxShadow: 1,
        borderRadius: 1,
        fontSize: 10,
        fontWeight: 500,
        textTransform: "uppercase",
        padding: "2px 4px",
        marginRight: "4px",
      }}
    >
      {role.name}
    </Box>
  );
};
