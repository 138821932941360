import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";

import { DataSourceFlags } from "../enums";

export const CameraStatusSchema = z.object({
  id: z.number(),
  name: z.string(),
  externalId: z.string(),
  type: z.string(),
  moduleId: z.number(),
  hostname: z.nullable(z.string()),
  status: z.nullable(z.string()),
  lastStatusAt: z.string().datetime(),
  flag: z.nativeEnum(DataSourceFlags),
});

export const CameraStatusResponseSchema = z.object({
  data: z.array(CameraStatusSchema),
  meta: MetaDataSchema,
});

export const DataStatusResponseSchema = CameraStatusSchema;
export type DataStatusType = z.infer<typeof CameraStatusSchema>;
export type DataStatusResponse = z.infer<typeof CameraStatusResponseSchema>;
