import { FunctionComponent, useMemo } from "react";
import { Box } from "@mui/material";
import { utcToZonedTime } from "date-fns-tz";

import { useAppSelector } from "@/app/redux/hooks";
import { LoadingOverlay } from "@/components";
import {
  AgeDistribution,
  Donuts,
  Heatmap,
  KpiSwiper,
  Timeline,
} from "@/pages/Private/pages/Events/pages/EventDetails";
import { projectsSelector } from "@/pages/Private/redux/projects.slice";
import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";
import { Features } from "@/pages/Private/pages/Companies/enums";
import { timeZone } from "@/app/utils";

export const ProjectBrandOverviewDashboard: FunctionComponent = () => {
  const { selectedProjectByBrand: project } = useAppSelector(projectsSelector);

  const isEmotionsActive = useIsFeatureActive(Features.TILE_EMOTIONS);
  const isResidenceTimeActive = useIsFeatureActive(Features.TILE_RESIDENCE_TIME);
  const isAgeDistributionActive = useIsFeatureActive(Features.TILE_AGE_DISTRIBUTION);
  const isFootfallActive = useIsFeatureActive(Features.GRAPH_FOOTFALL);

  const timeLineDates = useMemo(() => {
    const startDate = project?.eventDates[0]?.fromDateTime ?? "";
    const endDate = project?.eventDates[project.eventDates.length - 1]?.tillDateTime ?? "";
    const start = utcToZonedTime(new Date(startDate), timeZone);
    const end = utcToZonedTime(new Date(endDate), timeZone);

    return { start, end };
  }, [project]);

  return !project ? (
    <LoadingOverlay />
  ) : (
    <Box>
      <KpiSwiper isLoading={!project} kpis={project.data} />
      <Box
        sx={{
          display: { md: "flex" },
          mb: { md: "30px" },
        }}
      >
        <Timeline
          dates={timeLineDates}
          isLoading={!project}
          isProjectReporting={true}
          kpis={project.data}
        />
        {isAgeDistributionActive && <AgeDistribution isLoading={!project} kpis={project?.data} />}
      </Box>
      <Box display="flex">
        {!isEmotionsActive && !isResidenceTimeActive ? (
          <></>
        ) : (
          <Donuts isLoading={!project} kpis={project?.data} />
        )}
        {isFootfallActive && <Heatmap isLoading={!project} kpis={project?.data} />}
      </Box>
    </Box>
  );
};
