import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { DateRange } from "mui-daterange-picker-plus";

import {
  clearEventDetails,
  clearSelectedEvent,
  eventsSelector,
  useGetEventByIdQuery,
  useGetEventKpiQuery,
} from "@/pages/Private/redux";
import { eventPlaceholder } from "@/app/constants";
import { useAppSelector } from "@/app/redux/hooks";
import { LoadingOverlay } from "@/components";
import { Dashboard } from "@/enum";
import { parseDateToISODateFormat } from "@/app/utils";
import { useGetModulesByMobileEventIdQuery } from "@/pages/Private/redux/modules.api";
import { modulesSelector } from "@/pages/Private/redux/modules.slice";

import { EventSalesDashboard } from "./SalesDashboard/EventSalesDashboard";
import { EventOverviewDashboard } from "./OverviewDashboard/EventOverviewDashboard";
import { EventDoiDashboard } from "./DOIDashboard/EventDoiDashboard";
import { EventActivationsDashboard } from "./ActivationsDashboard/EventActivationsDashboard";
import { EventGiveawaysDashboard } from "./GiveawaysDashboard/EventGiveawaysDashboard";
import { EventPromotersDashboard } from "./PromoterDashboard/EventPromoterDashboard";
import { EventTypes } from "../../types";

import {
  EventDetailHeader,
  SettingsSection,
  // RetrievedInfo,
  // ScoreImpactItem,
} from ".";

export const EventDetails: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id = 0, dashboard = Dashboard.DEFAULT } = useParams();
  const { selectedEvent: event, eventDetails } = useAppSelector(eventsSelector);
  const { filters } = useAppSelector(modulesSelector);

  const [eventDateId, setEventDateId] = useState<number | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange | null>(null);
  const [eventTimeBracket, setEventTimeBracket] = useState<number | null>(null);

  const isPloomEvent = eventDetails?.eventType !== EventTypes.FESTIVAL;

  const moduleIds = [filters.teamId, ...(filters.locationIds ?? [])].filter((id) => id).join(",");

  useGetModulesByMobileEventIdQuery(Number(id));
  useGetEventByIdQuery(Number(id), { refetchOnMountOrArgChange: true });
  const {
    data: eventKPIs,
    isLoading,
    isFetching,
  } = useGetEventKpiQuery(
    {
      eventId: Number(id),
      eventDateId,
      dashboard:
        dashboard === "overview"
          ? isPloomEvent
            ? `ploom_${Dashboard.DEFAULT}`
            : Dashboard.DEFAULT
          : isPloomEvent
          ? `ploom_${dashboard}`
          : dashboard,
      dateRangeStart: parseDateToISODateFormat(selectedDateRange?.startDate),
      dateRangeEnd: parseDateToISODateFormat(selectedDateRange?.endDate),
      moduleIds: moduleIds,
    },
    { refetchOnMountOrArgChange: true }
  );

  const renderElement = useMemo(() => {
    switch (dashboard) {
      case Dashboard.SALES:
        return (
          <EventSalesDashboard
            eventDateId={eventDateId}
            isFetching={isFetching}
            selectedDateRange={!!selectedDateRange}
          />
        );
      case Dashboard.DOI:
        return (
          <EventDoiDashboard
            eventDateId={eventDateId}
            isFetching={isFetching}
            selectedDashboard={dashboard}
          />
        );
      case Dashboard.ACTIVATIONS:
        return <EventActivationsDashboard eventDateId={eventDateId} isFetching={isFetching} />;
      case Dashboard.GIVEAWAYS:
        return <EventGiveawaysDashboard eventDateId={eventDateId} isFetching={isFetching} />;
      case Dashboard.PROMOTER:
        return (
          <EventPromotersDashboard
            isFetching={isFetching}
            isFiltration={!!eventDateId || !!selectedDateRange}
            timeFilterStartingMinutes={eventTimeBracket}
          />
        );

      default:
        return (
          <EventOverviewDashboard
            eventDateId={eventDateId}
            isFetching={isFetching}
            selectedDateRange={selectedDateRange}
          />
        );
    }
  }, [dashboard, eventDateId, eventTimeBracket, selectedDateRange, isFetching]);

  //Changes the browser tab title dynamically
  useEffect(() => {
    window.document.title = t("windowTitles.eventDetails", { eventName: event?.name });

    return () => {
      window.document.title = t("windowTitles.default");
    };
  }, [t, event?.name]);

  //Cleanup state properties
  useEffect(() => {
    return () => {
      dispatch(clearEventDetails());
      dispatch(clearSelectedEvent());
    };
  }, [dispatch]);

  return !event ? (
    <LoadingOverlay />
  ) : (
    <Box sx={{ pt: { md: 1 } }}>
      <EventDetailHeader event={event || eventPlaceholder} />
      <SettingsSection
        dates={event.eventDates}
        handleEventDateChange={setEventDateId}
        handleEventDateRangeChange={setSelectedDateRange}
        handleEventTimeBracketChange={setEventTimeBracket}
        selectedDashboard={dashboard}
      />
      <Box sx={{ display: { xs: "unset", md: "flex" } }}>
        {!eventKPIs?.isComplete && !isLoading && (
          <Alert
            severity="warning"
            sx={{
              marginRight: { xs: 0, md: 1.5 },
              marginBottom: 1,
            }}
          >
            {t("events.missingProductsWarning")}
          </Alert>
        )}
        {event.modules === 0 && !isLoading && (
          <Alert
            severity="warning"
            sx={{
              marginBottom: 1,
            }}
          >
            {t("events.missingModulesWarning")}
          </Alert>
        )}
      </Box>
      {renderElement}
    </Box>
  );
};
