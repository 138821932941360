import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Button, DialogActions, DialogContent, DialogContentText } from "@mui/material";

import { Modal } from "..";

interface ConfirmationModalProps {
  title: string;
  description: string;
  name: string;
  email?: string;
  role?: string;
  confirmButton: string;
  isShown: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  isDeletion?: boolean;
}

export const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  title,
  description,
  name,
  email,
  role,
  confirmButton,
  isShown,
  handleClose,
  handleConfirm,
  isDeletion,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`basics.${key}`);

  return (
    <Modal setShow={handleClose} show={isShown} title={title}>
      <DialogContent>
        {description}
        <DialogContentText>
          <br />
          {name}
          <br />
          {email}
          <br />
          {role}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ bgcolor: isDeletion ? "red.main" : "main" }}
          variant="contained"
          onClick={handleConfirm}
        >
          {confirmButton}
        </Button>
        <Button autoFocus onClick={handleClose}>
          {ts("cancel")}
        </Button>
      </DialogActions>
    </Modal>
  );
};
