import { FunctionComponent, useMemo } from "react";
import { utcToZonedTime } from "date-fns-tz";
import { Box } from "@mui/material";

import { timeZone } from "@/app/utils";
import { LoadingOverlay } from "@/components";
import { eventsSelector } from "@/pages/Private/redux";
import { useAppSelector } from "@/app/redux/hooks";

import { ActivationsTotalTile } from "./components/ActivationsTotalTile";
import { ActivationsTopTenTable } from "./components/ActivationsTopTenTable";
import { ActivationsByTypeDonutChart } from "./components/ActivationsByTypeDonutChart";
import { ActivationsByDayDonutChart } from "./components/ActivationsByDayDonutChart";
import { ActivationsByTypeHeatmap } from "./components/ActivationsByTypeHeatmap";
import { ActivationsTimelineChart } from "./components/ActivationsTimeline";

interface EventActivationsDashboardProps {
  eventDateId: number | null;
  isFetching: boolean;
}
export const EventActivationsDashboard: FunctionComponent<EventActivationsDashboardProps> = ({
  eventDateId,
  isFetching,
}) => {
  const { selectedEvent: event } = useAppSelector(eventsSelector);

  const timeLineDates = useMemo(() => {
    if (eventDateId) {
      const eventDate = event?.eventDates.find((date) => date.id === eventDateId);

      return {
        // start: eventDate?.fromDateTime ?? "",
        // end: eventDate?.tillDateTime ?? "",
        start: utcToZonedTime(new Date(eventDate?.fromDateTime ?? ""), timeZone),
        end: utcToZonedTime(new Date(eventDate?.tillDateTime ?? ""), timeZone),
      };
    }

    const startDate = event?.eventDates.map((date) => date.fromDateTime)[0] ?? "";
    const endDate =
      event?.eventDates.map((date) => date.tillDateTime)[event?.eventDates.length - 1] ?? "";
    const start = utcToZonedTime(new Date(startDate), timeZone);
    const end = utcToZonedTime(new Date(endDate), timeZone);

    return { start, end };
  }, [event, eventDateId]);

  return !event ? (
    <LoadingOverlay />
  ) : (
    <>
      <Box
        sx={{
          mb: { xs: 2, md: 1 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
        }}
      >
        <ActivationsTotalTile isFetching={isFetching} kpis={event?.data} />
        <Box
          bgcolor="white"
          borderRadius={2}
          boxShadow={1}
          p={1.5}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
            pb: { xs: 1.5, md: 1 },
          }}
        >
          <ActivationsByTypeDonutChart
            isFetching={isFetching}
            isFiltration={!!eventDateId}
            kpis={event?.data}
          />
          {!!!eventDateId && (
            <ActivationsByDayDonutChart isFetching={isFetching} kpis={event?.data} />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: { md: "flex" },
          mb: { xs: 2, md: 1 },
        }}
      >
        <ActivationsTimelineChart
          dates={timeLineDates}
          isFiltration={!!eventDateId}
          isLoading={isFetching}
          kpis={event?.data}
        />
        <ActivationsTopTenTable
          activationsKPIs={event?.data}
          isFetching={isFetching}
          isFiltration={!!eventDateId}
        />
      </Box>
      <ActivationsByTypeHeatmap
        isFiltration={!!eventDateId}
        isLoading={isFetching}
        kpis={event.data}
      />
    </>
  );
};
