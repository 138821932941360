import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { formatNumbers } from "@/app/utils";
import { Loader, NoDataFound } from "@/components";

import { KpiTypeEnum } from "../../../../types";
import { KpiType } from "../../../../event.schema";

interface SalesTotalSoldProductsProps {
  isFetching: boolean;
  ploomSalesKPIs: KpiType[];
}

export const SalesTotalSoldProducts: FunctionComponent<SalesTotalSoldProductsProps> = ({
  isFetching,
  ploomSalesKPIs,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.salesKPIs.totalProducts.${key}`);

  const ploomSalesKpiData = ploomSalesKPIs.find((kpi) => kpi?.name === KpiTypeEnum.SALES);

  const totalSoldProducts =
    ploomSalesKpiData?.data?.at(0)?.overall?.value?.toFixed(0) ||
    ploomSalesKpiData?.data?.at(0)?.date?.at(0)?.value?.toFixed(0) ||
    "0";

  return (
    <Box
      bgcolor="white"
      borderRadius={2}
      boxShadow={1}
      px={1.5}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: { xs: "auto", md: 140 },
        mb: { xs: 2, md: 0 },
        py: 1,
        width: "256px",
      }}
    >
      <Typography alignItems="center" display="flex" variant="gray14Bold">
        {ts("title")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : ploomSalesKPIs.length > 0 ? (
        <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
          <Typography variant="main60bold">{formatNumbers(totalSoldProducts)}</Typography>
          <Typography variant="gray12">{ts("allTypeProducts")}</Typography>
        </Box>
      ) : (
        <NoDataFound kpi={ts("title").toLocaleLowerCase()} />
      )}
    </Box>
  );
};
