import { FunctionComponent } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { ArrowBackIosNewRounded } from "@mui/icons-material";

import { formatEventDates } from "@/app/utils/helpers";
import { ROUTE_CONFIG } from "@/app/routes/config";

import { EventType } from "../../../../event.schema";
import { EventStatusBox } from "../../../components/EventStatus";

interface EventHeaderProps {
  event: EventType;
}

export const EventDetailHeader: FunctionComponent<EventHeaderProps> = ({ event }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { name, startDate, endDate, image, status, brand } = event;

  return (
    <Box
      bgcolor="white"
      borderRadius="0 0 8px 8px"
      boxShadow={1}
      height={54}
      m={-2}
      mb={3}
      p="12px 16px"
      sx={{
        display: { xs: "flex", md: "none" },
      }}
    >
      <Box
        mr={2}
        mt={0.6}
        onClick={() => navigate({ pathname: ROUTE_CONFIG.EVENTS, search: searchParams.toString() })}
      >
        <ArrowBackIosNewRounded />
      </Box>
      <Box alignItems="flex-end" display="flex" flexGrow={1} justifyContent="space-between">
        <Box display="flex">
          <img
            alt="#"
            src={image}
            style={{ width: 54, height: 54, borderRadius: 8, marginRight: 8, objectFit: "cover" }}
          />
          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
            <Box alignItems="center" display="flex" sx={{ marginBottom: 0.5 }}>
              <Typography component="p" sx={{ marginRight: 0.5 }} variant="main">
                {name}
              </Typography>
              <img
                alt="#"
                src={brand?.image}
                style={{
                  width: 18,
                  height: 18,
                  borderRadius: 8,
                }}
              />
            </Box>
            <Typography component="p" sx={{ marginBottom: 0.5 }} variant="gray12">
              {formatEventDates({ startDate, endDate })}
            </Typography>
          </Box>
        </Box>
        <EventStatusBox status={status} />
      </Box>
    </Box>
  );
};
