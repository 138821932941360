import { forwardRef } from "react";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { UseFormRegisterReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { EventModuleTypeIcon } from "@/pages/Private/pages/Events/pages/EventsOverview/components";

type SelectFieldProps = {
  options: { text: string; value: string | number }[];
  currentValue: string | number | string[];
  label?: string | React.ReactNode;
  placeholder?: string;
  hasError?: boolean;
  border?: boolean;
  smallSize?: boolean;
  withDefaultValue?: boolean;
  hasIcon?: boolean;
  icon?: JSX.Element;
} & UseFormRegisterReturn;

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(function CustomSelect(
  {
    options,
    currentValue,
    label,
    placeholder,
    hasError,
    border,
    smallSize,
    withDefaultValue,
    hasIcon,
    icon,
    ...rest
  },
  ref
) {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth variant="filled">
      {label && (
        <Box
          color="gray900.main"
          component="label"
          display="block"
          fontSize={16}
          fontWeight={600}
          htmlFor={rest.name}
          mb={1}
        >
          {label}
        </Box>
      )}
      <Select
        label={label}
        {...rest}
        ref={ref}
        disableUnderline
        MenuProps={{
          sx: {
            "& .MuiMenu-paper": { boxShadow: 1 },
            "& .MuiMenuItem-root": { display: "flex", alignItems: "start" },
            "& .MuiMenu-list": {
              padding: 0,
            },
          },
        }}
        defaultValue="DEFAULT"
        error={hasError}
        inputProps={{ ...rest }}
        sx={{
          height: smallSize ? 28 : 44,
          "& .MuiSelect-select": {
            display: "flex",
            textAlign: "left",
            fontSize: 14,
            color:
              currentValue === "DEFAULT" && !withDefaultValue ? "gray600.main" : "gray900.main",
            bgcolor: "white",
            boxShadow: border ? 0 : 1,
            borderRadius: 1,
            ...(border && { border: "1px solid", borderColor: "#E2E2E2" }),
            ...(smallSize ? { height: 18, p: "3px 12px;" } : { height: 20, p: 1.5 }),
          },
          "& .MuiSelect-select.MuiSelect-filled.MuiInputBase-input.MuiFilledInput-input": {
            ...(smallSize && { height: "20px", p: "3px 12px" }),
          },
        }}
        value={currentValue}
      >
        <MenuItem selected disabled={!withDefaultValue} value="DEFAULT">
          {placeholder ?? t("basics.defaultSelectPlaceholder")}
        </MenuItem>
        {options.map((option) => (
          <MenuItem
            key={`option-${option.value}`}
            sx={{ alignItems: "center", display: "flex" }}
            value={option.value}
          >
            {hasIcon && <EventModuleTypeIcon smallSize type={option.value} />}
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});
