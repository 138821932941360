import { useUploadFileMutation } from "../redux/files.api";

export const useUploadImage = () => {
  const [uploadFile, { isLoading }] = useUploadFileMutation();

  const uploadImage = async (image: string) => {
    const formData = new FormData();
    const res: Response = await fetch(image);
    const blob: Blob = await res.blob();
    const file = new File([blob], "img.jpeg");

    formData.append("file", file);
    formData.append("type", "image");
    const imgRes = await uploadFile(formData).unwrap();

    return imgRes.file.replaceAll("%2F", "/");
  };

  return { uploadImage, isLoading };
};
