import { useTranslation } from "react-i18next";
import { FunctionComponent, useMemo } from "react";
import { Box, Typography } from "@mui/material";

import { DonutChart, Loader } from "@/components";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum, SalesPloomProductCategories } from "../../../../types";

interface SalesByTypeProps {
  isFiltration?: boolean;
  isFetching: boolean;
  ploomSalesKPIs: KpiType[];
}

export const SalesByTypeDonutChart: FunctionComponent<SalesByTypeProps> = ({
  isFiltration,
  isFetching,
  ploomSalesKPIs,
}) => {
  const { t } = useTranslation();

  // Sales split by Type donut chart data
  const salesByTypeLabels = useMemo(() => {
    return (
      ploomSalesKPIs
        .find((kpi) => kpi.name === KpiTypeEnum.SALES_BY_PRODUCT_TYPE_BY_CATEGORY)
        ?.data.map(
          (kpi) =>
            SalesPloomProductCategories[
              kpi.overall?.dim2 as keyof typeof SalesPloomProductCategories
            ] || kpi.specific
        ) || []
    );
  }, [ploomSalesKPIs]);

  const salesByTypeData = useMemo(() => {
    return (
      ploomSalesKPIs
        .find((kpi) => kpi.name === KpiTypeEnum.SALES_BY_PRODUCT_TYPE_BY_CATEGORY)
        ?.data.map((kpi) =>
          Math.round(isFiltration ? kpi.date.at(0)?.percent ?? 0 : kpi.overall?.percent ?? 0)
        ) || []
    );
  }, [ploomSalesKPIs, isFiltration]);

  return (
    <Box
      sx={{
        borderStyle: "solid",
        borderWidth: { xs: "0 0 1px 0", md: "0 1px 0 0" },
        borderColor: "gray400.main",
        paddingRight: { xs: 0, md: 1 },
        paddingBottom: { xs: 1, md: 0 },
      }}
    >
      <Typography component="p" mb={1} variant="mainBold">
        {t("events.donutSalesByType")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : (
        <DonutChart
          kpi={t("events.donutSalesByType")}
          labels={salesByTypeLabels}
          series={salesByTypeData}
        />
      )}
    </Box>
  );
};
