import { EventDatesType, EventType, KpiType } from "@/pages/Private/pages/Events/event.schema";
import {
  EventStatus,
  EventTypes,
  KpiTileData,
  KpiTypeEnum,
} from "@/pages/Private/pages/Events/types";

export const kpiPlaceholder: KpiType = {
  name: KpiTypeEnum.DEFAULT,
  target: 0,
  target2: 0, // TECH DEBT - BA-706
  isActive: false,
  data: [],
};

export const eventDatesPlaceholder: EventDatesType = {
  id: 0,
  eventId: 0,
  fromDateTime: "",
  tillDateTime: "",
  dataAvailabilityCount: 0,
};

export const eventPlaceholder: EventType = {
  id: 0,
  name: "",
  externalId: null,
  brand: null,
  project: null,
  companyId: null,
  brandId: null,
  projectId: null,
  eventType: EventTypes.FESTIVAL,
  startDate: "2000-01-01",
  endDate: "200-01-01",
  status: EventStatus.LIVE,
  image: "",
  expectedVisitors: 0,
  visitorsReach: 0,
  dataSources: 0,
  modules: 0,
  moduleConfigs: [],
  moduleEventConfigs: [],
  mt: 0,
  bimpact: 0,
  bscore: 0,
  street: "",
  houseNumber: "",
  city: "",
  zip: "",
  nielsen: "",
  kpiScore: { plannedKpiScore: 0, planned2KpiScore: 0 },
  eventDates: [],
  data: [],
  goals: [],
  groupId: 0,
  budgets: [],
  notes: null,
};

export const defaultEventDate = {
  date: "",
  fromDateTime: "",
  tillDateTime: "",
  isAfterMidnight: false,
};

export const defaultWeeklyOpeningTimes = {
  monday: {
    fromTime: "",
    tillTime: "",
    isOpened: false,
  },
  tuesday: {
    fromTime: "",
    tillTime: "",
    isOpened: false,
  },
  wednesday: {
    fromTime: "",
    tillTime: "",
    isOpened: false,
  },
  thursday: {
    fromTime: "",
    tillTime: "",
    isOpened: false,
  },
  friday: {
    fromTime: "",
    tillTime: "",
    isOpened: false,
  },
  saturday: {
    fromTime: "",
    tillTime: "",
    isOpened: false,
  },
  sunday: {
    fromTime: "",
    tillTime: "",
    isOpened: false,
  },
};

export const kpiSpecificPlaceholder: KpiTileData = {
  name: "",
  value: "",
  percent: "",
};
