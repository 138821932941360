import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { BarChart, Loader, NoDataFound } from "@/components";
import { convertChartNumericData, toPercent } from "@/app/utils";
import { ageRanges, agesArray } from "@/app/constants";
import { useProductVersion } from "@/app/hooks/useProductVersion";
import { ProductVersion } from "@/types";

import { EventDataType, KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";

interface IAgeDistributionProps {
  kpis: KpiType[];
  isFiltration?: boolean;
  isLoading?: boolean;
}

export const AgeDistribution: FunctionComponent<IAgeDistributionProps> = ({
  kpis,
  isFiltration,
  isLoading,
}) => {
  const { t } = useTranslation();

  const [isWithoutData, setIsWithoutData] = useState(false);
  const [totalAudience, setTotalAudience] = useState(0);

  const currentProductVersion = useProductVersion();

  const convertData = useCallback(
    (data: EventDataType[]) => {
      const values = convertChartNumericData({ enumValues: agesArray, data, isFiltration });

      const total = values.reduce((a, b) => a + b, 0);

      return { values, total };
    },
    [isFiltration]
  );

  const maleValues = useMemo(() => {
    const male = kpis.find((k) => k.name === KpiTypeEnum.MALE);

    return convertData(male?.data || []);
  }, [kpis, convertData]);

  const femaleValues = useMemo(() => {
    const female = kpis.find((k) => k.name === KpiTypeEnum.FEMALE);

    return convertData(female?.data || []);
  }, [kpis, convertData]);

  const getHighestNumber = useMemo(() => {
    const allValues = [...maleValues.values, ...femaleValues.values];
    const highestNumber = Math.max(...allValues);

    return highestNumber;
  }, [femaleValues, maleValues]);

  useEffect(() => {
    if (
      !!maleValues.values.filter((s) => !!s).length ||
      !!femaleValues.values.filter((s) => !!s).length
    ) {
      setIsWithoutData(false);
      setTotalAudience(maleValues.total + femaleValues.total);
    } else {
      setIsWithoutData(true);
    }
  }, [maleValues, femaleValues]);

  return (
    <Box
      bgcolor="white"
      borderRadius={2}
      boxShadow={1}
      display="flex"
      flexDirection="column"
      minHeight={350}
      px={1.5}
      py={2}
      sx={{ width: { md: "30%" }, mb: { xs: 3, md: 0 } }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography variant="mainBold" width="47%">
          {t("events.ageDistribution")}
        </Typography>
        <Box flexGrow={1}>
          <Box
            alignItems="center"
            display="flex"
            flexGrow={1}
            justifyContent="space-between"
            mb={1}
          >
            <Box alignItems="center" display="flex">
              <Box bgcolor="secondary.main" borderRadius={50} height={12} mr={1} width={12}></Box>
              <Typography variant="main14">{t("basics.male")}</Typography>
            </Box>
            <Typography variant="main14">
              {!isWithoutData
                ? `${Math.round(toPercent({ number: maleValues.total, total: totalAudience }))}%`
                : "-"}
            </Typography>
          </Box>
          <Box alignItems="center" display="flex" flexGrow={1} justifyContent="space-between">
            <Box alignItems="center" display="flex">
              <Box bgcolor="main" borderRadius={50} height={12} mr={1} width={12}></Box>
              <Typography variant="main14">{t("basics.female")}</Typography>
            </Box>
            <Typography variant="main14">
              {!isWithoutData
                ? `${Math.round(toPercent({ number: femaleValues.total, total: totalAudience }))}%`
                : "-"}
            </Typography>
          </Box>
        </Box>
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isWithoutData ? (
            <NoDataFound kpi={t("events.ageDistribution").toLowerCase()} />
          ) : (
            <Box display="flex" justifyContent="space-between">
              <Box width="44%">
                <BarChart
                  reversed
                  categories={ageRanges}
                  color={currentProductVersion === ProductVersion.BSURE ? "#ABCCCC" : "#8031A7"}
                  maxValue={getHighestNumber}
                  name={t("basics.male")}
                  values={maleValues.values}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                maxHeight={266}
                ml={0.25}
                mt={3.8}
                textAlign="center"
              >
                {ageRanges.map((range) => (
                  <Typography key={`${range}`} variant="main12">
                    {range}
                  </Typography>
                ))}
              </Box>
              <Box width="44%">
                <BarChart
                  categories={ageRanges}
                  color={currentProductVersion === ProductVersion.BSURE ? "#D6B60E" : "#00B2A9"}
                  maxValue={getHighestNumber}
                  name={t("basics.female")}
                  values={femaleValues.values}
                />
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
