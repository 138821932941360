import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

import { UsersOverview } from "./pages";

export const Users: FunctionComponent = () => {
  return (
    <Routes>
      <Route element={<UsersOverview />} path="" />
      <Route element={<UsersOverview />} path="/:id" />
    </Routes>
  );
};
