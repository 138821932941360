import { FunctionComponent, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import { MetaData } from "@/schemas/meta-data";
import { LoadingOverlay } from "@/components";
import { formatInTimeZone } from "@/app/utils";

import { TableColumn, TableComponent } from "../../../components/TableComponent/TableComponent";
import { DataStatusType } from "../schema/dataStatus.schema";
import { DataSourceFlags } from "../enums";

interface DataStatusTableProps {
  dataStatus: DataStatusType[];
  page: number;
  handleFetchPage: (page: number) => void;
  meta?: MetaData;
  isLoading?: boolean;
}
export const DataStatusTable: FunctionComponent<DataStatusTableProps> = ({
  dataStatus,
  page,
  handleFetchPage,
  meta,
  isLoading,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`dataStatus.${key}`);

  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);

  const sortDataStatus = (data: DataStatusType[], order: "asc" | "desc" | null) => {
    const sortedData = [...data].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (order === "asc") {
        return nameA.localeCompare(nameB);
      } else if (order === "desc") {
        return nameB.localeCompare(nameA);
      } else {
        return 0;
      }
    });

    return sortedData;
  };

  const handleSort = () => {
    if (sortOrder === "asc") {
      setSortOrder("desc");
    } else if (sortOrder === "desc") {
      setSortOrder(null);
    } else if (sortOrder === null) {
      setSortOrder("asc");
    }
  };

  const indicatorColor = (flag: DataSourceFlags) => {
    if (flag === DataSourceFlags.GREEN) {
      return "#4caf50";
    } else if (flag === DataSourceFlags.ORANGE) {
      return "#ff9100";
    } else if (flag === DataSourceFlags.RED) {
      return "#d9182e";
    }
  };

  const columns: TableColumn<DataStatusType>[] = [
    {
      title: ts("name"),
      width: "12.5%",
      render: (deviceData) => (
        <>
          <Typography
            sx={{ display: "inline-flex", color: "gray900.main", fontWeight: 500 }}
            variant="main14"
          >
            {deviceData.name}
          </Typography>{" "}
        </>
      ),
      sortable: true,
      onClick: () => {
        handleSort();
      },
    },
    {
      title: ts("hostname"),
      width: "20%",
      render: (deviceData) => (
        <>
          <Typography
            sx={{ display: "inline-flex", color: "gray900.main", fontWeight: 500 }}
            variant="main14"
          >
            {deviceData.hostname}
          </Typography>{" "}
        </>
      ),
    },
    {
      title: ts("deviceId"),
      width: "30%",
      render: (deviceData) => (
        <>
          <Typography
            sx={{ display: "inline-flex", color: "gray900.main", fontWeight: 500 }}
            variant="main14"
          >
            {deviceData.externalId}
          </Typography>{" "}
        </>
      ),
    },
    {
      title: ts("type"),
      width: "12.5%",
      render: (deviceData) => (
        <>
          <Typography
            sx={{ display: "inline-flex", color: "gray900.main", fontWeight: 500 }}
            variant="main14"
          >
            {deviceData.type}
          </Typography>{" "}
        </>
      ),
    },
    {
      title: ts("lastStatusAt"),
      width: "20%",
      render: (deviceData) => (
        <>
          <Typography
            sx={{ display: "inline-flex", color: "gray900.main", fontWeight: 500 }}
            variant="main14"
          >
            {formatInTimeZone(new Date(deviceData.lastStatusAt), "dd/MM/yyyy")} -{" "}
            {formatInTimeZone(new Date(deviceData.lastStatusAt), "HH:mm:ss")}
          </Typography>{" "}
        </>
      ),
    },
    {
      title: ts("status"),
      width: "5%",
      render: (deviceData) => (
        <Box sx={{ p: 1, display: "flex" }}>
          <FiberManualRecordIcon
            fontSize="small"
            sx={{
              mr: 1,
              color: indicatorColor(deviceData.flag),
            }}
          />
        </Box>
      ),
    },
  ];

  return !dataStatus ? (
    <LoadingOverlay />
  ) : (
    <TableComponent
      columns={columns}
      data={sortDataStatus(dataStatus, sortOrder)}
      handleFetchPage={handleFetchPage}
      isLoading={isLoading}
      page={page}
      sortOrder={sortOrder}
      totalPages={meta?.pageCount ?? 1}
    />
  );
};
