import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { formatNumbers } from "@/app/utils";
import { Loader, NoDataFound } from "@/components";

import { KpiTypeEnum, SalesPloomProductTypes } from "../../../../types";
import { KpiType } from "../../../../event.schema";

interface SalesTotalDeviceAndAccessoriesProps {
  isFetching: boolean;
  ploomSalesKPIs: KpiType[];
}

export const SalesTotalDeviceAndAccessoriesTile: FunctionComponent<
  SalesTotalDeviceAndAccessoriesProps
> = ({ isFetching, ploomSalesKPIs }) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.salesKPIs.${key}`);

  const deviceSalesKpiData = ploomSalesKPIs
    .find((kpi) => kpi?.name === KpiTypeEnum.SALES_BY_PRODUCT_TYPE_BY_CATEGORY)
    ?.data.find((type) => type.specific === SalesPloomProductTypes.DEVICE);

  const totalDeviceSales =
    deviceSalesKpiData?.overall?.value?.toFixed(0) ||
    deviceSalesKpiData?.date.at(0)?.value?.toFixed(0) ||
    "0";

  const accessoriesSalesKpiData = ploomSalesKPIs
    .find((kpi) => kpi?.name === KpiTypeEnum.SALES_BY_PRODUCT_TYPE_BY_CATEGORY)
    ?.data.find((type) => type.specific === SalesPloomProductTypes.ACCESSORIES);

  const totalAccessoriesSales =
    accessoriesSalesKpiData?.overall?.value?.toFixed(0) ||
    accessoriesSalesKpiData?.date.at(0)?.value?.toFixed(0) ||
    "0";

  return (
    <Box
      bgcolor="white"
      borderRadius={2}
      boxShadow={1}
      px={1.5}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: { xs: 160, md: 140 },
        mb: { xs: 2, md: 0 },
        py: 1,
        width: "256px",
      }}
    >
      {isFetching ? (
        <Loader />
      ) : ploomSalesKPIs.length > 0 ? (
        <>
          <Typography alignItems="center" display="flex" variant="gray14Bold">
            {ts("totalDevice.title")}
          </Typography>
          <Box display="flex" flexDirection="column">
            <Typography variant="main42bold">{formatNumbers(totalDeviceSales)}</Typography>
          </Box>
          <Typography alignItems="center" display="flex" variant="gray14Bold">
            {ts("totalAccessories.title")}
          </Typography>
          <Typography variant="main42bold">{formatNumbers(totalAccessoriesSales)}</Typography>
        </>
      ) : (
        <>
          <Typography alignItems="center" display="flex" variant="gray14Bold">
            {ts("totalDeviceAndAccessories.title")}
          </Typography>
          <NoDataFound kpi={ts("totalDeviceAndAccessories.title").toLocaleLowerCase()} />
        </>
      )}
    </Box>
  );
};
