import { api } from "@app/redux/api";

export const filesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation<{ file: string }, FormData>({
      query: (body) => ({
        url: "/files/upload",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useUploadFileMutation } = filesApi;
export const filesApiReducer = filesApi.reducer;
export const filesApiMiddleware = filesApi.middleware;
