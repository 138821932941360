import { FunctionComponent, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import { Bullets } from "@/components";
import {
  kpiPlaceholder,
  festivalKpiTiles,
  nonFestivalKpiTiles,
  ploomFestivalKpiTiles,
} from "@/app/constants";
import { useGetFeaturesQuery } from "@/pages/Private/redux/features.api";
import { eventsSelector } from "@/pages/Private/redux";
import { useAppSelector } from "@/app/redux/hooks";

import { KpiType } from "../../../../event.schema";
import { KpiCard } from "./KpiCard";
import { EventTypes, KpiTypeEnum } from "../../../../types";

interface KpiSwiperProps {
  kpis: KpiType[];
  isFiltration?: boolean;
  isLoading?: boolean;
}

export const KpiSwiper: FunctionComponent<KpiSwiperProps> = ({ kpis, isFiltration, isLoading }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const { data: features } = useGetFeaturesQuery();
  const { eventDetails } = useAppSelector(eventsSelector);
  const isPloomEvent = eventDetails?.eventType !== EventTypes.FESTIVAL;
  const isPloomFestivalEvent = eventDetails?.eventType === EventTypes.PLOOM_FESTIVAL;

  const kpiTilesArray = useMemo(() => {
    switch (eventDetails?.eventType) {
      case EventTypes.FESTIVAL:
        return festivalKpiTiles;
      case EventTypes.PLOOM_FESTIVAL:
        return ploomFestivalKpiTiles;
      case EventTypes.MOBILE:
      case EventTypes.STATIONARY:
        return nonFestivalKpiTiles;

      default:
        return [];
    }
  }, [eventDetails]);

  const activeFeatures = features?.filter((feat) => feat.active).map((feat) => feat.feature);
  const kpisWithActiveFeatureStatus = festivalKpiTiles.filter((kpi) =>
    activeFeatures?.includes(kpi.featureFlagName)
  );

  return kpisWithActiveFeatureStatus.length < 1 ? (
    <></>
  ) : (
    <Box mb={3}>
      <Swiper
        breakpoints={{
          900: {
            slidesPerView: isPloomFestivalEvent ? 6 : 5,
          },
        }}
        slidesPerView={1.35}
        spaceBetween={8}
        onActiveIndexChange={(swiper) => setActiveIndex(swiper.realIndex)}
      >
        {kpiTilesArray.map((kpi) => {
          const kpiData = kpis.find((k) => k.name === kpi.name);

          return (
            <SwiperSlide key={`slide-${kpi.name}`}>
              <KpiCard
                isFiltration={isFiltration}
                isLoading={isLoading}
                kpi={kpiData || kpiPlaceholder}
                kpiName={
                  isPloomEvent && kpi.name === KpiTypeEnum.NEWSLETTER_DATA
                    ? KpiTypeEnum.NEWSLETTER_DATA_WITH_DEVICE_ID
                    : kpi.name
                }
                showOverall={kpi.showOverall}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Bullets count={kpiTilesArray.length} selectedIndex={activeIndex} />
      </Box>
    </Box>
  );
};
