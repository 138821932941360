import { forwardRef, HTMLInputTypeAttribute, ReactNode } from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";

import { UseFormRegisterReturn } from "react-hook-form";

interface InputStyledProps {
  classes?: string;
  hasError?: boolean;
  border?: boolean;
  smallSize?: boolean;
  fixedWidth?: boolean;
}

export type CustomInputProps = {
  value?: string | number;
  label?: string | React.ReactNode;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  icon?: ReactNode;
  handleIconClick?: () => void;
  autocompleteValue?: string;
} & Partial<UseFormRegisterReturn> &
  InputStyledProps;

const Input = styled.input<InputStyledProps>`
  font-size: 14px;
  line-height: 17px;
  outline: none;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  ${(props) =>
    props.border ? "border: 1px solid #E2E2E2; border-radius: 4px; box-shadow: none;" : ""}
  ${(props) =>
    props.smallSize ? "height: 16px; padding: 5.2px 12px;" : "height: 18px; padding: 13px 16px;"}
  ${(props) => (props.fixedWidth ? "width: 105px;" : "width: calc(100% - 28px);")}
	${(props) => props.classes}
	${(props) => (props.hasError ? "border: 1px solid #d76067;" : "")}
	&::placeholder {
    color: #919eab;
  }
`;

export const InputField = forwardRef<HTMLInputElement, CustomInputProps>(function CustomInput(
  {
    value,
    label,
    hasError,
    classes,
    autocompleteValue,
    placeholder,
    icon,
    handleIconClick,
    type = "text",
    border,
    smallSize,
    ...rest
  },
  ref
) {
  return (
    <>
      {label && (
        <Box
          color="gray900.main"
          component="label"
          display="block"
          fontSize={16}
          fontWeight={600}
          htmlFor={rest.name}
          mb={1}
        >
          {label}
        </Box>
      )}
      <Input
        autoComplete={autocompleteValue}
        id={rest.name}
        placeholder={placeholder}
        type={type}
        value={value}
        {...rest}
        ref={ref}
        border={border}
        classes={classes}
        hasError={hasError}
        smallSize={smallSize}
      />

      {icon && (
        <Box
          sx={{ position: "absolute", cursor: "pointer", top: 16, right: 16 }}
          onClick={handleIconClick}
        >
          {icon}
        </Box>
      )}
    </>
  );
});
