import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";

import { ServerError } from "@/types/error";
import { ERROR_TYPE, translateError, notify } from "@/app/utils";
import {
  ConfirmationModal,
  LoadingOverlay,
  TableColumn,
  TableComponent,
  TextBadge,
} from "@/components";
import { User, UserTable } from "@/pages/Public/pages/Login/schema/login";
import { useDeleteUserMutation } from "@/pages/Private/redux";
import { useWindowSize } from "@/app/hooks/useWindowSize";
import { MetaData } from "@/schemas/meta-data";
import { Features } from "@/pages/Private/pages/Companies/enums";
import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";
import { useHasUserPermission } from "@/app/hooks/useHasUserPermission";
import { PermissionRoles } from "@/enum";

import { EditUserModal } from "./EditUserModal";

interface UsersTableProps {
  users: User[];
  page: number;
  handleFetchPage: (page: number) => void;
  meta?: MetaData;
  isLoading?: boolean;
}

export const UsersTable: FunctionComponent<UsersTableProps> = ({
  users,
  page,
  handleFetchPage,
  meta,
  isLoading,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`users.${key}`);
  const tk = (key: string) => t(`users.modals.deleteUser.${key}`);

  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [deleteUser] = useDeleteUserMutation();
  const windowSize = useWindowSize();

  const isMultiTenancyActive = useIsFeatureActive(Features.MULTI_TENANCY);
  const isPermittedToGetCompanies = useHasUserPermission(PermissionRoles.GET_COMPANY);

  const handleEditUser = (user: User) => {
    setSelectedUser(user);
    setShowEditModal(true);
  };

  const handleDeleteModal = (user: User) => {
    setSelectedUser(user);
    setShowConfirmationModal(true);
  };

  const handleDeleteUser = async () => {
    try {
      await deleteUser(selectedUser?.id ?? 0).unwrap();
      notify({ text: t("notifications.deletedUser") });

      setShowConfirmationModal(false);
    } catch (err) {
      notify({
        text: translateError[(err as ServerError).data.message as ERROR_TYPE],
        type: "error",
      });
    }
  };

  const handleCloseModals = () => {
    setSelectedUser(null);
    setShowEditModal(false);
    setShowConfirmationModal(false);
  };

  const columnsDesktop: TableColumn<UserTable>[] = [
    {
      title: ts("userInfo.userName"),
      width: isPermittedToGetCompanies ? "25%" : "30%",
      render: (user) => {
        return (
          <>
            <Typography
              sx={{ display: "inline-flex", color: "gray900.main", fontWeight: 500 }}
              variant="main14"
            >
              {user.firstName} {user.lastName}
            </Typography>{" "}
          </>
        );
      },
    },
    {
      title: ts("userInfo.email"),
      width: isMultiTenancyActive && isPermittedToGetCompanies ? "30%" : "40%",
      render: (user) => (
        <>
          <Typography
            sx={{ display: "inline-flex", color: "gray900.main", fontWeight: 500 }}
            variant="main14"
          >
            {user.email}
          </Typography>{" "}
        </>
      ),
    },
    {
      title: ts("userInfo.role"),
      width: isMultiTenancyActive && isPermittedToGetCompanies ? "25%" : "30%",
      render: (user) => (
        <>
          {user.roles.map((role, index) => (
            <TextBadge key={`user-role-${index}`} role={role} />
          ))}
        </>
      ),
    },
    ...(isMultiTenancyActive && isPermittedToGetCompanies
      ? [
          {
            title: ts("userInfo.company"),
            width: "20%",
            render: (user: UserTable) => (
              <>
                <Typography
                  sx={{ display: "inline-flex", color: "gray900.main", fontWeight: 500 }}
                  variant="main14"
                ></Typography>{" "}
                <>{user?.company?.name}</>
              </>
            ),
          },
        ]
      : []),
    {
      title: ts("actions"),
      width: 60,
      render: (user) => (
        <>
          <DeleteOutline
            sx={{
              color: "gray600.main",
              marginRight: 1,
              "&:hover": {
                opacity: 0.5,
                cursor: "pointer",
              },
            }}
            onClick={() => handleDeleteModal(user)}
          />
          <EditOutlined
            sx={{
              color: "gray600.main",
              "&:hover": {
                opacity: 0.5,
                cursor: "pointer",
              },
            }}
            onClick={() => handleEditUser(user)}
          />
        </>
      ),
    },
  ];

  const columnsMobile: TableColumn<User>[] = [
    {
      title: ts("details"),
      width: 150,
      render: (user) => (
        <>
          <Typography
            sx={{ display: "inline-flex", color: "gray900.main", fontWeight: 500 }}
            variant="main14"
          >{`${user.firstName} ${user.lastName}`}</Typography>{" "}
          <>
            {user.roles.map((role, index) => (
              <TextBadge key={`user-role-${index}`} role={role} />
            ))}
          </>
          <br />
          <Typography component="p" variant="main14">
            {user.email}
          </Typography>
        </>
      ),
    },
    {
      title: ts("actions"),
      width: 60,
      render: (user) => (
        <>
          <DeleteOutline
            sx={{
              color: "gray600.main",
              marginRight: 1,
              "&:hover": {
                opacity: 0.5,
                cursor: "pointer",
              },
            }}
            onClick={() => handleDeleteModal(user)}
          />
          <EditOutlined
            sx={{
              color: "gray600.main",
              "&:hover": {
                opacity: 0.5,
                cursor: "pointer",
              },
            }}
            onClick={() => handleEditUser(user)}
          />
        </>
      ),
    },
  ];

  return !users ? (
    <LoadingOverlay />
  ) : (
    <>
      <TableComponent
        columns={windowSize.width > 600 ? columnsDesktop : columnsMobile}
        data={users}
        handleFetchPage={handleFetchPage}
        isLoading={isLoading}
        page={page}
        totalPages={meta?.pageCount ?? 1}
      />

      <EditUserModal handleClose={handleCloseModals} show={showEditModal} user={selectedUser} />
      <ConfirmationModal
        isDeletion
        confirmButton={t("basics.delete")}
        description={tk("description")}
        email={`${t("basics.email")}: ${selectedUser?.email}`}
        handleClose={handleCloseModals}
        handleConfirm={handleDeleteUser}
        isShown={showConfirmationModal}
        name={`${t("basics.name")}: ${selectedUser?.firstName} ${selectedUser?.lastName}`}
        role={`${t("basics.role")}: ${selectedUser?.roles.map((role) => role.name)}`}
        title={tk("title")}
      />
    </>
  );
};
