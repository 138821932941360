import React from "react";
import ReactDOM from "react-dom/client";

import { worker as serverWorker } from "@/app/mocks/browser";

import "./index.css";
import { PARSED_ENV } from "@/app/constants/common";

import App from "./App";

if (PARSED_ENV.REACT_APP_NODE_ENV === "development") {
  serverWorker.start();
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
