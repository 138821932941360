import { addDays, format } from "date-fns";
import { utcToZonedTime, toDate } from "date-fns-tz";

import { EventDatesRequestBodyType } from "@/pages/Private/pages/Events/event.schema";

export const timeZone = "Europe/Berlin";

export const convertEventDates = (eventDates: EventDatesRequestBodyType[]) => {
  return eventDates?.map((dateTime) => {
    const fromDateTime = toDate(`${dateTime.date} ${dateTime.fromDateTime}`, { timeZone });
    const tillDateTime = toDate(`${dateTime.date} ${dateTime.tillDateTime}`, { timeZone });

    return {
      fromDateTime: fromDateTime.toString(),
      tillDateTime: dateTime.isAfterMidnight
        ? addDays(tillDateTime, 1).toString()
        : tillDateTime.toString(),
    };
  });
};

export const formatInTimeZone = (date: Date, fmt: string) =>
  format(utcToZonedTime(date, timeZone), fmt);
