import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Typography } from "@mui/material";

import { ERROR_TYPE, renderErrorMessages } from "@/app/utils/errors";
import { Button, InputField, PasswordInput } from "@/components";
import { ROUTE_CONFIG } from "@/app/routes/config";

import { LoginType, LoginSchema } from "./schema/login";
import { useLoginMutation } from "../../redux/auth.api";

export const LoginBsure: FunctionComponent = () => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`login.${key}`);

  const navigate = useNavigate();

  const [login, { isLoading, isError, error }] = useLoginMutation();

  const submissionError = (error as Record<string, Record<string, string>>) || null;
  const message = submissionError?.data?.message;

  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields },
  } = useForm<LoginType>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: zodResolver(LoginSchema),
  });

  const onSubmit = handleSubmit(async (values) => {
    try {
      await login(values).unwrap();
    } catch (err) {
      console.error(err);
    }
  });

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
      onSubmit={onSubmit}
    >
      <Typography mb={4.5} variant="h1">
        <>
          {ts("title.bsure")}
          <Typography color="gray700.main">{ts("description.bsure")}</Typography>
        </>
      </Typography>
      <Box mb={1} width="100%">
        <InputField
          autocompleteValue="username"
          hasError={touchedFields.email && !!errors.email}
          {...register("email")}
          placeholder={ts("emailPlaceholder")}
        />
      </Box>
      <Box mb={1} width="100%">
        <PasswordInput
          autocompleteValue="password"
          hasError={touchedFields.password && !!errors.password}
          placeholder={ts("passwordPlaceholder")}
          {...register("password")}
        />
      </Box>

      {isError
        ? renderErrorMessages([message] as ERROR_TYPE[])
        : Object.keys(errors).length
        ? renderErrorMessages(Object.values(errors).map((error) => error.message) as ERROR_TYPE[])
        : null}

      <Box mb={4.5} width="100%">
        <Typography
          component="p"
          textAlign="right"
          variant="link"
          onClick={() => navigate(ROUTE_CONFIG.FORGOT_PASSWORD)}
        >
          {ts("forgotPasswordCta")}
        </Typography>
      </Box>

      <Box width="100%">
        <Button isLoading={isLoading} title={ts("loginCta")} type="submit" />
      </Box>
    </Box>
  );
};
