import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Checkbox, Grid, Typography } from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { useAppSelector } from "@/app/redux/hooks";
import { eventsSelector } from "@/pages/Private";
import { Button, ButtonSize, InputField } from "@/components";
import { useUpdateEventMutation } from "@/pages/Private/redux";
import { formatNumbers, notify, removeThousandsSeparator } from "@/app/utils";

import { useHasUserPermission } from "@/app/hooks/useHasUserPermission";
import { PermissionRoles } from "@/enum";
import { Features } from "@/pages/Private/pages/Companies/enums";
import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";

import { EventGoalsType } from "../../../../event.schema";

export const KPIs: FunctionComponent = () => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.settings.kpis.${key}`);

  const [updateEvent, { isSuccess, isError, isLoading }] = useUpdateEventMutation();

  const { eventDetails } = useAppSelector(eventsSelector);
  const [updateKpiName, setUpdateKpiName] = useState("");
  const is2ndKpiFeatureActive = useIsFeatureActive(Features.SECONDARY_PLANNED_KPI); // TECH DEBT - BA-706

  const kpiHiddenPlanned = eventDetails?.brand?.company.config?.kpiHiddenPlanned;

  const initialValues = {
    kpis: eventDetails?.goals || [],
  };

  const { control, register, getValues, setValue, watch, handleSubmit } = useForm({
    defaultValues: initialValues,
    values: initialValues,
  });

  const { fields } = useFieldArray({
    control,
    name: "kpis",
  });

  const permittedToUnlock = useHasUserPermission(PermissionRoles.UNLOCK_KPI);
  const editKpiPermission = useHasUserPermission(PermissionRoles.UPDATE_EVENT_KPI);
  const permittedToEditPlanned2 = useHasUserPermission(PermissionRoles.EDIT_2ND_PLANNED_KPI); // TECH DEBT - BA-706

  const formatPlannedValue = (value: number | null) => {
    if (!value) {
      return "";
    }

    const formattedValue = value.toString();

    if (formattedValue.length > 1 && formattedValue[0] === "0") {
      return formattedValue
        .slice(1)
        .replaceAll(/\./g, "")
        .replaceAll(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    return formattedValue.replaceAll(/\./g, "").replaceAll(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const convertPlannedValue = (value: string | number | null): number | null => {
    return !value ? null : Number(value.toString().replaceAll(/\./g, ""));
  };

  const finalize = async (kpi: EventGoalsType) => {
    const currentKpis = getValues("kpis");

    const updatedKpis = currentKpis.reduce(
      (updatedEventGoals: EventGoalsType[], currentKpi: EventGoalsType) => {
        const initialKpi = initialValues.kpis.find((ikpi) => ikpi.type === currentKpi.type);

        kpi.completed = !initialKpi?.completed ? true : false;

        if (
          initialKpi &&
          (initialKpi.planned !== currentKpi.planned ||
            initialKpi.planned2 !== currentKpi.planned2 ||
            initialKpi.actual !== currentKpi.actual ||
            initialKpi.isActive !== currentKpi.isActive ||
            initialKpi.completed !== currentKpi.completed)
        ) {
          updatedEventGoals.push({
            ...currentKpi,
            planned: convertPlannedValue(currentKpi.planned),
            planned2: convertPlannedValue(currentKpi.planned2),
            actual: removeThousandsSeparator(currentKpi.actual),
            completed: currentKpi.completed || false,
          });
        }

        return updatedEventGoals;
      },
      []
    );

    if (updatedKpis.length > 0) {
      const body = {
        goals: updatedKpis,
      };

      setUpdateKpiName(kpi.type);
      await updateEvent({ id: eventDetails!.id, body });
    }
  };

  const onSubmit = async (values: { kpis: EventGoalsType[] }) => {
    const goals = values.kpis.length
      ? values.kpis.reduce((updatedKpiGoals: EventGoalsType[], kpi: EventGoalsType) => {
          kpi.planned2 = convertPlannedValue(kpi.planned2); // TECH DEBT - BA-706
          kpi.planned = convertPlannedValue(kpi.planned);
          kpi.actual = removeThousandsSeparator(kpi.actual);
          const oldKpiValues = eventDetails?.goals.find((g) => g.type === kpi.type);

          if (
            oldKpiValues?.planned !== kpi.planned ||
            oldKpiValues?.planned2 !== kpi.planned2 || // TECH DEBT - BA-706
            oldKpiValues?.isActive !== kpi.isActive ||
            (kpi.isActualEditable && oldKpiValues?.actual !== kpi.actual)
          ) {
            updatedKpiGoals.push(kpi);
          }

          return updatedKpiGoals;
        }, [])
      : [];

    if (goals.length) {
      const body = { goals };

      await updateEvent({ id: eventDetails!.id, body });
    }
  };

  useEffect(() => {
    initialValues?.kpis.forEach((kpi, i) => {
      if (kpi.completed) {
        setValue(`kpis.${i}.isActive`, kpi.isActive);
      }
    });
  }, [initialValues?.kpis, setValue]);

  useEffect(() => {
    if (isSuccess) {
      notify({ text: t("notifications.eventUpdated") });
    }

    if (isError) {
      notify({ text: t("notifications.wentWrong"), type: "error" });
    }
  }, [isSuccess, isError, t]);

  return (
    <Box
      bgcolor="white"
      borderRadius={2}
      boxShadow={1}
      maxWidth={is2ndKpiFeatureActive ? "1084px" : "844px"} // TECH DEBT - BA-706
      mt={4}
      p={2}
      width="fit-content"
    >
      <Box borderBottom="1px solid" borderColor="gray400.main" mb={2} pb={2}>
        <Typography color="gray900.main" fontSize={18} fontWeight={600} mb={1.5}>
          {ts("title")}
        </Typography>
        <Typography color="gray900.main" fontSize={14} fontWeight={400}>
          {ts("description")}
        </Typography>
      </Box>
      <Box borderBottom="1px solid" borderColor="gray400.main" mb={2} pb={2}>
        <Grid container rowGap={1}>
          <Grid
            container
            item
            columnSpacing={3}
            columns={is2ndKpiFeatureActive ? 5 : 4} // TECH DEBT - BA-706
            flexWrap="nowrap"
          >
            <Grid item xs={1.1}>
              <Typography variant="mainBold">{ts("kpi")}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="mainBold">{ts("active")}</Typography>
            </Grid>
            {is2ndKpiFeatureActive && ( // TECH DEBT - BA-706
              <Grid item xs={1.1}>
                <Typography variant="mainBold">{ts("planned2")}</Typography>
              </Grid>
            )}
            <Grid item xs={1.1}>
              <Typography variant="mainBold">{ts("planned")}</Typography>
            </Grid>
            <Grid item xs={1.1}>
              <Typography variant="mainBold">{ts("actual")}</Typography>
            </Grid>
            <Grid item xs={0.7}></Grid>
          </Grid>
          {fields?.map((kpi, i) => (
            <Grid
              key={`event-days-${kpi.id}`}
              container
              item
              alignItems="center"
              columnSpacing={3}
              columns={is2ndKpiFeatureActive ? 5 : 4} // TECH DEBT - BA-706
              flexWrap="nowrap"
            >
              <Grid item xs={1.1}>
                <InputField border disabled smallSize value={t(`events.kpis.${kpi.type}`)} />
              </Grid>
              <Grid item>
                <Controller
                  control={control}
                  name={`kpis.${i}.isActive`}
                  render={({ field: isActive }) => (
                    <Checkbox
                      {...isActive}
                      disableRipple
                      checked={isActive.value}
                      disabled={kpi.completed}
                      sx={{
                        color: "#E2E2E2",
                        "&.MuiButtonBase-root": {
                          paddingY: 0,
                        },
                      }}
                      onChange={(e) => {
                        isActive.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
              </Grid>
              {is2ndKpiFeatureActive && ( // TECH DEBT - BA-706
                <Grid item xs={1.1}>
                  {!kpiHiddenPlanned?.includes(kpi.type) && (
                    <InputField
                      {...register(`kpis.${i}.planned2`)}
                      border
                      smallSize
                      disabled={!permittedToEditPlanned2}
                      value={formatPlannedValue(watch(`kpis.${i}.planned2`))}
                    />
                  )}
                </Grid>
              )}
              <Grid item xs={1.1}>
                {!kpiHiddenPlanned?.includes(kpi.type) && (
                  <InputField
                    {...register(`kpis.${i}.planned`)}
                    border
                    smallSize
                    disabled={kpi.completed || !editKpiPermission}
                    value={formatPlannedValue(watch(`kpis.${i}.planned`))}
                  />
                )}
              </Grid>
              <Grid item xs={1.1}>
                <InputField
                  {...register(`kpis.${i}.actual`)}
                  border
                  smallSize
                  disabled={!kpi.isActualEditable || !editKpiPermission}
                  value={formatNumbers(watch(`kpis.${i}.actual`))}
                />
              </Grid>
              {editKpiPermission && (
                <Grid item xs={0.7}>
                  <Button
                    handleClick={() => finalize(getValues(`kpis.${i}`))}
                    isDisabled={kpi.completed && !permittedToUnlock}
                    isLoading={isLoading && updateKpiName === kpi.type}
                    size={ButtonSize.S}
                    title={t(
                      !kpi.completed
                        ? "basics.finalize"
                        : permittedToUnlock
                        ? "basics.unlock"
                        : "basics.completed"
                    )}
                  />
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box maxWidth={128}>
        <Button
          handleClick={handleSubmit(onSubmit)}
          isDisabled={!editKpiPermission}
          isLoading={isLoading}
          size={ButtonSize.M}
          title={t("basics.save")}
        />
      </Box>
    </Box>
  );
};
