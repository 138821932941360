import { Paper, Stack, Switch, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useAppSelector } from "@/app/redux/hooks";
import { eventsSelector, switchEventsKpiTarget2Type } from "@/pages/Private/redux";

export const KpiTargetTypeSwitch: FunctionComponent = () => {
  // TECH DEBT - BA-706 - The whole component is created for the particular feature!
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { kpiTarget2TypeSwitchedOn } = useAppSelector(eventsSelector);

  const switchLabel = { inputProps: { "aria-label": "Switch between KPI type" } };

  return (
    <Paper sx={{ paddingX: 1 }}>
      <Typography variant="main14">{t("events.kpiSwitch")}</Typography>
      <Stack alignItems="center" direction="row" spacing={1} sx={{ whiteSpace: "nowrap" }}>
        <Typography variant="gray12">{t("events.plannedKPI")}</Typography>
        <Switch
          checked={kpiTarget2TypeSwitchedOn}
          onChange={() => dispatch(switchEventsKpiTarget2Type())}
          {...switchLabel}
          size="small"
          sx={{
            "& .MuiSwitch-switchBase": {
              color: "gray400.main",
              "&.Mui-checked": {
                color: "main",
              },
            },
          }}
        />
        <Typography variant="gray12">{t("events.jtiKPI")}</Typography>
      </Stack>
    </Paper>
  );
};
