import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { getDay } from "date-fns";

import { toPercent } from "@/app/utils";
import { DonutChart, Loader } from "@/components";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";

interface ActivationsByDayDonutChartProps {
  isFetching: boolean;
  kpis: KpiType[];
}
export const ActivationsByDayDonutChart: FunctionComponent<ActivationsByDayDonutChartProps> = ({
  isFetching,
  kpis,
}) => {
  const { t } = useTranslation();

  const activationsByDayLabels = useMemo(() => {
    const activationsByDayKPIs = kpis
      .find((kpi) => kpi.name === KpiTypeEnum.ACTIVATIONS)
      ?.data.at(0);

    return (
      activationsByDayKPIs?.date?.reduce((weekDays: string[], el) => {
        const currDateArr = el.date.split("-");
        const currDayWeekIndex = getDay(
          new Date(+currDateArr[0], +currDateArr[1] - 1, +currDateArr[2])
        );
        const currWeekDayName = t(`weekDays.${currDayWeekIndex}`);

        weekDays.push(currWeekDayName);

        return weekDays;
      }, []) || []
    );
  }, [kpis, t]);

  const activationsByDayData = useMemo(() => {
    const activationsByDayKPIs = kpis
      .find((kpi) => kpi.name === KpiTypeEnum.ACTIVATIONS)
      ?.data.at(0);

    const activationsByDayValues = activationsByDayKPIs?.date?.map((day) => day.value) || [];
    const total = activationsByDayValues.reduce((a, b) => a + b, 0);
    const percents = activationsByDayValues.map((v) => Math.round(toPercent({ number: v, total })));

    return percents;
  }, [kpis]);

  return (
    <Box
      sx={{
        borderStyle: "solid",
        borderWidth: { xs: "1px 0 0 0", md: "0 0 0 1px" },
        borderColor: "gray400.main",
        paddingLeft: { xs: 0, md: 1 },
        paddingTop: { xs: 1, md: 0 },
      }}
    >
      <Typography component="p" mb={1} variant="mainBold">
        {t("events.donutActivationsByDay")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : (
        <DonutChart
          kpi={t("events.donutActivationsByDay")}
          labels={activationsByDayLabels}
          series={activationsByDayData}
        />
      )}
    </Box>
  );
};
