import { FunctionComponent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import { bSureLogo, jtiLogo } from "@/assets";
import { ROUTE_CONFIG } from "@/app/routes/config";
import { useProductVersion } from "@/app/hooks/useProductVersion";
import { ProductVersion } from "@/types";

export const AuthLayout: FunctionComponent<{ children: JSX.Element }> = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isLogin = pathname === ROUTE_CONFIG.LOGIN;
  const isBsureLogin = pathname === ROUTE_CONFIG.LOGIN_BSURE;

  const currentProductVersion = useProductVersion();

  return (
    <Box bgcolor="gray100.main" height="100vh" overflow="auto" width="100vw">
      <Box padding={2}>
        <Box marginY={isLogin ? 10 : isBsureLogin ? 10 : 4}>
          {currentProductVersion === ProductVersion.JTI ? (
            <img
              alt=""
              src={isBsureLogin ? bSureLogo : jtiLogo}
              style={{
                width: isLogin ? 96 : isBsureLogin ? 150 : 48,
                height: "auto",
                display: "block",
                margin: "0 auto",
              }}
              onClick={() => navigate(ROUTE_CONFIG.LOGIN)}
            />
          ) : (
            <img
              alt=""
              src={bSureLogo}
              style={{
                width: isLogin ? 150 : 72,
                height: "auto",
                display: "block",
                margin: "0 auto",
              }}
              onClick={() => navigate(ROUTE_CONFIG.LOGIN)}
            />
          )}
        </Box>
        <Box maxWidth={400} mx="auto">
          {children}
        </Box>
      </Box>
    </Box>
  );
};
