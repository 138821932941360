import { FunctionComponent, useState } from "react";
import { Box, Typography, LinearProgress, DialogContent } from "@mui/material";

import { useTranslation } from "react-i18next";

import { NoDataFound, Modal, Loader } from "@/components";
import { removeThousandsSeparator } from "@/app/utils";
import { Dashboard } from "@/enum";
import { useAppSelector } from "@/app/redux/hooks";
import { eventsSelector } from "@/pages/Private/redux";

import { EventTypes, TopTenTableData } from "../../../../types";

interface TopTenTableProps {
  isFetching: boolean;
  dataToPass: TopTenTableData[];
  namingPath: string;
  promoterTableType?: string;
}

export const TopTenTable: FunctionComponent<TopTenTableProps> = ({
  isFetching,
  dataToPass,
  namingPath,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.topTenTables.${namingPath}.${key}`);

  const { eventDetails } = useAppSelector(eventsSelector);
  const isPloomEvent = eventDetails?.eventType !== EventTypes.FESTIVAL;

  const topTenData =
    dataToPass.length > 10
      ? namingPath.split(".")[0] === Dashboard.PROMOTER
        ? dataToPass.slice(0, 30)
        : dataToPass.slice(0, 10)
      : dataToPass;

  const totalAmounts = dataToPass.reduce((total, item) => {
    total += removeThousandsSeparator(item.quantity);

    return Math.round(total);
  }, 0);

  const [showPopup, setShowPopup] = useState(false);

  const handleBoxClick = () => {
    setShowPopup(!showPopup);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <Box
      bgcolor="white"
      borderRadius={2}
      boxShadow={1}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "fit-content",
        mb: { xs: 2, md: 0 },
        px: 1.5,
        py: 2,
        width: { md: "30%" },
        gap: 0.5,
      }}
    >
      <Box display="flex" flexDirection="column" mb={0.5}>
        <Typography component="h3" variant="mainBold">
          {namingPath.includes("promoter")
            ? ts(`title.${isPloomEvent ? "withDevice" : "withoutDevice"}`)
            : ts("title")}
        </Typography>
        {(namingPath === "product" || namingPath === "module") && (
          <Typography variant="gray12">{t("events.twentySticks")}</Typography>
        )}
      </Box>
      {isFetching ? (
        <Loader />
      ) : !topTenData || !dataToPass || topTenData.length <= 0 || dataToPass.length <= 0 ? (
        <NoDataFound
          height="150px"
          kpi={
            namingPath.includes("promoter")
              ? ts(`title.${isPloomEvent ? "withDevice" : "withoutDevice"}`)
              : ts("title")
          }
        />
      ) : (
        <>
          {topTenData.map((el, i) => (
            <Box key={i} alignItems="end" display="flex">
              <Typography
                sx={{
                  borderBottom: "1px solid #E3E3E3",
                  width: { xs: "70%", md: "65%" },
                }}
                variant="main12"
              >
                {el.name}
              </Typography>
              <Box
                sx={{
                  textAlign: "right",
                  width: { xs: "30%", md: "35%" },
                }}
              >
                <Typography variant="main12">{el.quantity}</Typography>
                <Typography variant="gray12">({Math.round(el.percentage)}%)</Typography>
                <LinearProgress
                  sx={{
                    borderRadius: 1,
                    height: 3,
                    width: "100%",
                  }}
                  value={el.percentage}
                  variant="determinate"
                />
              </Box>
            </Box>
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mb: 4,
              mt: 0.5,
            }}
          >
            <Typography variant="main14Bold">{ts("totals")}</Typography>
            <Typography variant="main14Bold">{totalAmounts}</Typography>
          </Box>
          <Typography
            style={{ cursor: "pointer" }}
            textAlign="end"
            variant="gray14Bold"
            onClick={handleBoxClick}
          >
            {ts("viewAll")}
          </Typography>
        </>
      )}
      {/* All data modal */}
      <Modal
        setShow={closePopup}
        show={showPopup}
        title={ts("popupTitle") + ` (${dataToPass.length} ${ts("type")})`}
      >
        <DialogContent
          dividers
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            border: "none",
            padding: 1.5,
            paddingTop: 0,
          }}
        >
          {!dataToPass || dataToPass.length <= 0 ? (
            <NoDataFound kpi={ts("title")} />
          ) : (
            dataToPass.map((el, i) => (
              <Box key={i} alignItems="end" display="flex">
                <Typography
                  sx={{
                    borderBottom: "1px solid #E3E3E3",
                    width: { xs: "75%", md: "65%" },
                  }}
                  variant="main12"
                >
                  {el.name}
                </Typography>
                <Box
                  sx={{
                    textAlign: "right",
                    width: { xs: "25%", md: "35%" },
                  }}
                >
                  <Typography variant="main12">{el.quantity}</Typography>
                  <Typography variant="gray12">({Math.round(el.percentage)}%)</Typography>
                  <LinearProgress
                    sx={{
                      borderRadius: 1,
                      height: 3,
                      width: "100%",
                    }}
                    value={el.percentage}
                    variant="determinate"
                  />
                </Box>
              </Box>
            ))
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mt: 0.5,
            }}
          >
            <Typography variant="main14Bold">{ts("totals")}</Typography>
            <Typography variant="main14Bold">{totalAmounts}</Typography>
          </Box>
        </DialogContent>
      </Modal>
    </Box>
  );
};
