import { FunctionComponent } from "react";

import { kpiPlaceholder } from "@/app/constants";

import { KpiType } from "../../../../event.schema";
import { KpiCard } from "../../OverviewDashboard/components/KpiCard";
import { KpiTypeEnum } from "../../../../types";

interface DoiKpiCardProps {
  kpis: KpiType[];
  isFiltration?: boolean;
  isFetching?: boolean;
  isDoiDashboard?: boolean;
}

export const DoiDistributionKpiCard: FunctionComponent<DoiKpiCardProps> = ({
  kpis,
  isFiltration,
  isFetching,
  isDoiDashboard,
}) => {
  const newsletterData = kpis.find((kpi) => kpi.name === KpiTypeEnum.NEWSLETTER_DATA);

  return (
    <KpiCard
      dashboardHasKpiCard={isDoiDashboard}
      isFiltration={isFiltration}
      isLoading={isFetching}
      kpi={newsletterData || kpiPlaceholder}
      kpiName={newsletterData?.name || KpiTypeEnum.NEWSLETTER_DATA}
      showOverall={!!newsletterData}
    />
  );
};
