import { FunctionComponent, useMemo } from "react";
import { Box } from "@mui/material";
import { utcToZonedTime } from "date-fns-tz";

import { eventsSelector } from "@/pages/Private/redux";
import { LoadingOverlay } from "@/components";
import { timeZone } from "@/app/utils";
import { useAppSelector } from "@/app/redux/hooks";
import { Dashboard } from "@/enum";
import { useWindowSize } from "@/app/hooks/useWindowSize";

import { DoiTotalRegistrations } from "./components/DoiTotalRegistrations";
import { DoiDistributionDonutChart } from "./components/DoiDistributionDonutChart";
import { DoiByGenderDonutChart } from "./components/DoiByGenderDonutChart";
import { DoiByAgeGroupsDonutChart } from "./components/DoiByAgeDonutChart";
import { DoiByAgegroupByGenderHeatmap } from "./components/DoiByAgegroupByGenderHeatmap";
import { DoiDistributionByCityTable } from "./components/DoiDistributionByCityTable";
import { DoiDistributionKpiCard } from "./components/DoiDistributionKpiCard";
import { DoiByDayDonutChart } from "./components/DoiByDayDonutChart";
import { EventTypes, KpiTypeEnum } from "../../../types";
import { HeatmapByDayAndHour } from "../OverviewDashboard/components/HeatmapByDayAndHour";
import { Timeline } from "../OverviewDashboard/components/Timeline";

interface EventDoiDashboardProps {
  eventDateId: number | null;
  selectedDashboard: string;
  isFetching: boolean;
}
export const EventDoiDashboard: FunctionComponent<EventDoiDashboardProps> = ({
  eventDateId,
  selectedDashboard,
  isFetching,
}) => {
  const { width } = useWindowSize();
  const { eventDetails } = useAppSelector(eventsSelector);
  const isPloomEvent = eventDetails?.eventType !== EventTypes.FESTIVAL;
  const isDoiDashboard = selectedDashboard === Dashboard.DOI;

  const { selectedEvent: event } = useAppSelector(eventsSelector);

  const timeLineDates = useMemo(() => {
    if (eventDateId) {
      const eventDate = event?.eventDates.find((date) => date.id === eventDateId);

      return {
        // start: eventDate?.fromDateTime ?? "",
        // end: eventDate?.tillDateTime ?? "",
        start: utcToZonedTime(new Date(eventDate?.fromDateTime ?? ""), timeZone),
        end: utcToZonedTime(new Date(eventDate?.tillDateTime ?? ""), timeZone),
      };
    }

    const startDate = event?.eventDates.map((date) => date.fromDateTime)[0] ?? "";
    const endDate =
      event?.eventDates.map((date) => date.tillDateTime)[event?.eventDates.length - 1] ?? "";
    const start = utcToZonedTime(new Date(startDate), timeZone);
    const end = utcToZonedTime(new Date(endDate), timeZone);

    return { start, end };
  }, [event, eventDateId]);

  return !event ? (
    <LoadingOverlay />
  ) : (
    <>
      <Box
        sx={{
          mb: { xs: 2, md: 1 },
          display: "flex",
          flexDirection: width < 1880 ? "column" : "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          bgcolor="white"
          borderRadius={2}
          boxShadow={1}
          p={1.5}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            pb: { xs: 1.5, md: 1 },
            mb: { xs: 2, md: 1 },
            minWidth: { xs: 266, md: 500 },
            maxWidth: { xs: 266, md: 500 },
          }}
        >
          <DoiTotalRegistrations isFetching={isFetching} kpis={event?.data} />
          <DoiDistributionKpiCard
            isDoiDashboard={isDoiDashboard}
            isFetching={isFetching}
            isFiltration={!!eventDateId}
            kpis={event?.data}
          />
        </Box>
        <Box
          bgcolor="white"
          borderRadius={2}
          boxShadow={1}
          p={1.5}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
            pb: { xs: 1.5, md: 1 },
          }}
        >
          <DoiDistributionDonutChart
            isFetching={isFetching}
            isFiltration={!!eventDateId}
            kpis={event?.data}
          />
          <DoiByGenderDonutChart
            isFetching={isFetching}
            isFiltration={!!eventDateId}
            kpis={event?.data}
          />
          <DoiByAgeGroupsDonutChart
            isFetching={isFetching}
            isFiltration={!!eventDateId}
            kpis={event?.data}
          />
          {!eventDateId && !isPloomEvent && (
            <DoiByDayDonutChart isFetching={isFetching} kpis={event?.data} />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          gap: 1,
          display: { md: "flex" },
          mb: { xs: 2, md: 1 },
        }}
      >
        {isPloomEvent ? (
          <HeatmapByDayAndHour
            defaultTab={KpiTypeEnum.NEWSLETTER_DATA}
            isFiltration={!!eventDateId}
            isLoading={isFetching}
            kpis={event.data}
          />
        ) : (
          <Timeline
            dates={timeLineDates}
            isFiltration={!!eventDateId}
            isLoading={isFetching}
            kpis={event?.data}
          />
        )}
        <DoiDistributionByCityTable
          doiKPIs={event?.data}
          isFetching={isFetching}
          isFiltration={!!eventDateId}
        />
      </Box>
      {!isPloomEvent && (
        <DoiByAgegroupByGenderHeatmap isFiltration={!!eventDateId} kpis={event?.data} />
      )}
    </>
  );
};
