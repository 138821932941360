import { useTranslation } from "react-i18next";
import { FunctionComponent, useState } from "react";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import { LoadingOverlay, TableColumn, TableComponent } from "@/components";
import { MetaData } from "@/schemas/meta-data";

import { CreateOrEditBrandModal } from "./CreateOrEditBrandModal";
import { BrandType } from "../schema/brand.schema";

interface BrandsTableProps {
  brands: BrandType[];
  page: number;
  handleFetchPage: (page: number) => void;
  meta?: MetaData;
  isLoading?: boolean;
}

export const BrandsTable: FunctionComponent<BrandsTableProps> = ({
  brands,
  page,
  handleFetchPage,
  meta,
  isLoading,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`brands.${key}`);

  const [selectedBrand, setSelectedBrand] = useState<BrandType | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleEditBrand = (row: BrandType) => {
    setShowEditModal(true);
    setSelectedBrand(row);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedBrand(null);
  };

  const columns: TableColumn<BrandType>[] = [
    {
      title: ts("brand"),
      render: (brand) => (
        <>
          <Box alignItems="center" display="flex">
            {brand.image ? (
              <Box height={28} mr={1} width={28}>
                <img
                  alt="#"
                  src={brand.image}
                  style={{ width: "100%", height: "100%", borderRadius: "100%" }}
                />
              </Box>
            ) : (
              <Box bgcolor="main" borderRadius="100%" height={28} mr={1} width={28}></Box>
            )}
            <Typography variant="main14">{brand?.name}</Typography>
          </Box>
        </>
      ),
    },
    {
      title: ts("actions"),
      render: (row) => (
        <>
          <DeleteOutline
            sx={{
              color: "gray600.main",
              marginRight: 1,
              "&:hover": {
                opacity: 0.5,
                cursor: "pointer",
              },
            }}
            // onClick={() => handleDeleteModal(row)}
          />
          <EditOutlined
            sx={{
              color: "gray600.main",
              "&:hover": {
                opacity: 0.5,
                cursor: "pointer",
              },
            }}
            onClick={() => handleEditBrand(row)}
          />
        </>
      ),
      width: 60,
    },
  ];

  return !brands ? (
    <LoadingOverlay />
  ) : (
    <>
      <TableComponent
        columns={columns}
        data={brands}
        handleFetchPage={handleFetchPage}
        isLoading={isLoading}
        page={page}
        totalPages={meta?.pageCount ?? 1}
      />

      {showEditModal && (
        <CreateOrEditBrandModal
          brand={selectedBrand}
          handleClose={handleCloseEditModal}
          isEditing={showEditModal}
          isOpen={showEditModal}
          setIsOpen={setShowEditModal}
        />
      )}
    </>
  );
};
