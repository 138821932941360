import { Box } from "@mui/material";
import { FunctionComponent } from "react";

export const Bullets: FunctionComponent<{ count: number; selectedIndex: number }> = ({
  count,
  selectedIndex,
}) => {
  const renderBullets = () => {
    const rows = [];

    for (let i = 0; i < count; i++) {
      rows.push(
        <Box
          key={`pagination-${i}`}
          bgcolor={selectedIndex === i ? "main" : "gray400.main"}
          borderRadius="100%"
          height={8}
          ml={1}
          width={8}
        ></Box>
      );
    }

    return rows;
  };

  return (
    <Box display="flex" justifyContent="center" mt={2}>
      {renderBullets()}
    </Box>
  );
};
