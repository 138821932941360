import { FunctionComponent, useMemo } from "react";
import { Box } from "@mui/material";

import { sidebarSelector } from "@/pages/Private";
import { useAppSelector } from "@/app/redux/hooks";
import { ActiveBarEnum } from "@/types";
import { EventFilter } from "@/pages/Private/pages/Events/pages/EventsOverview/components";
import { ModulesFilter } from "@/pages/Private/pages/Modules/pages/ModulesOverview/components/ModulesFilter";

import { Navigation } from "../Layouts/Navigation/Navigation";

export const SideBar: FunctionComponent = () => {
  const { activeBar } = useAppSelector(sidebarSelector);

  const renderChild = useMemo(() => {
    switch (activeBar) {
      case ActiveBarEnum.MENU:
        return <Navigation />;
      case ActiveBarEnum.EVENTS_FILTER:
        return <EventFilter isForMobile />;
      case ActiveBarEnum.MODULES_FILTER:
        return <ModulesFilter isForMobile />;

      default:
        break;
    }
  }, [activeBar]);

  return (
    <Box
      sx={{
        position: "absolute",
        height: "calc(100% - 90px)",
        width: "100vw",
        zIndex: 20,
        bgcolor: "gray100.main",
        transitionDuration: "200ms",
        transform: activeBar ? "translateX(0px)" : "translateX(-100%);",
        transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
      }}
    >
      <Box sx={{ padding: "24px 16px", height: "calc(100% - 48px)" }}>{renderChild}</Box>
    </Box>
  );
};
