import { FunctionComponent } from "react";
import { default as Chart } from "react-apexcharts";

import { useProductVersion } from "@/app/hooks/useProductVersion";
import { ProductVersion } from "@/types";

import { NoDataFound } from "../NoDataFound/NoDataFound";

interface HeatmapChartProps {
  series: {
    name: string;
    data: {
      x: string;
      y: number;
    }[];
  }[];
  labels: string[];
  kpi: string;
  padding?: boolean;
}

export const HeatmapChart: FunctionComponent<HeatmapChartProps> = ({
  series,
  labels,
  kpi,
  padding,
}) => {
  const isWithoutData = !series.length;
  const currentProductVersion = useProductVersion();

  // Find the longest label length
  const maxLabelLength = labels.reduce((maxLength, label) => Math.max(maxLength, label.length), 0);

  // Check if labels should be rotated based on longest label length and amount of columns
  const rotateLabels = maxLabelLength > 20 && labels.length > 15;

  // Dynamically set the offsetY based on the label length (multiplier can be adjusted to fit layout needs).
  const dynamicOffsetY = maxLabelLength * 4;

  return isWithoutData ? (
    <NoDataFound height="100%" kpi={kpi} />
  ) : (
    <Chart
      height={40 * series.length + 40}
      options={{
        noData: {
          text: "No data found",
        },
        labels,
        chart: {
          type: "heatmap",
          toolbar: {
            show: false,
          },
          redrawOnParentResize: true,
        },
        legend: {
          show: false,
        },
        plotOptions: {
          heatmap: {
            radius: 4,
            shadeIntensity: 0.9,
            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: 0,
                  name: "low",
                  color: "#ebf9f8",
                },
              ],
            },
          },
        },
        colors: currentProductVersion === ProductVersion.BSURE ? ["#D6B60E"] : ["#00B2A9"],
        dataLabels: {
          enabled: true,
          style: { colors: ["#2B3845"] },
        },
        grid: {
          show: false,
          padding: {
            top: 0,
            right: padding ? 0 : -15,
            bottom: -25,
            left: 5,
          },
        },
        xaxis: {
          position: "top",
          labels: {
            rotate: 45,
            offsetX: rotateLabels ? 5 : 0,
            maxHeight: rotateLabels ? dynamicOffsetY + 10 : undefined,
            offsetY: rotateLabels ? dynamicOffsetY : 0,
            style: {
              colors: currentProductVersion === ProductVersion.BSURE ? "#2B3845" : "#919EAB",
              fontSize: "10px",
              fontWeight: 600,
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            maxWidth: 280,
            align: "right",
            style: {
              colors: currentProductVersion === ProductVersion.BSURE ? "#2B3845" : "#919EAB",
              fontSize: "10px",
              fontWeight: 700,
            },
          },
        },
      }}
      series={series}
      type="heatmap"
      width="100%"
    />
  );
};
