import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { BackLink, LoadingOverlay } from "@/components";
import { useAppSelector } from "@/app/redux/hooks";
import { companiesSelector } from "@/pages/Private/redux/companies.slice";
import {
  useGetCompanyByIdQuery,
  useUpdateCompanyMutation,
} from "@/pages/Private/redux/companies.api";
import { useUploadImage } from "@/pages/Private/helpers/useUploadImage";
import { ERROR_TYPE, notify, translateError } from "@/app/utils";
import { ServerError } from "@/types/error";

import { CompanyRequestBodyType, CompanyType } from "../../schema/companies.schema";
import { CompanySettingsOverview } from "../components/CompanySettingsOverview";

export const EditCompanyDetails: FunctionComponent = () => {
  const { id = 0 } = useParams();
  const { t } = useTranslation();
  const ts = (key: string) => t(`companies.edit.${key}`);
  const { companyDetails } = useAppSelector(companiesSelector);

  const { isFetching } = useGetCompanyByIdQuery(Number(id), { refetchOnMountOrArgChange: true });
  const [updateCompany, { isSuccess, isError, isLoading }] = useUpdateCompanyMutation();
  const { uploadImage, isLoading: isImageLoading } = useUploadImage();

  const [company, setCompany] = useState<CompanyType>();

  const initialValues: CompanyRequestBodyType = {
    name: company?.name ?? "",
    code: company?.code ?? "",
    logo: company?.logo ?? "",
  };

  const onSubmit = async (values: CompanyRequestBodyType) => {
    try {
      const body: CompanyRequestBodyType = {};

      for (const key in values) {
        const initialValue = initialValues[key as keyof CompanyRequestBodyType];
        const currentValue = values[key as keyof CompanyRequestBodyType];

        if (currentValue !== initialValue) {
          body[key as keyof CompanyRequestBodyType] = currentValue;
        }
      }

      if (Object.entries(body).length > 0) {
        if (body.logo) {
          const imageUrl = await uploadImage(body.logo);

          body.logo = imageUrl;
        }

        await updateCompany({ id: company?.id ?? 0, body }).unwrap();
      }
    } catch (err) {
      notify({
        text: translateError[(err as ServerError).data.message as ERROR_TYPE],
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      notify({ text: t("companies.notifications.companyUpdated") });
    }

    if (isError) {
      notify({ text: t("companies.notifications.wentWrong"), type: "error" });
    }
  }, [isSuccess, isError, t]);

  useEffect(() => {
    if (companyDetails) {
      setCompany(companyDetails);
    }
  }, [companyDetails]);

  return isFetching ? (
    <LoadingOverlay />
  ) : (
    <Box pt={0.5}>
      <BackLink text={t("companies.backToCompanies")} />
      <Typography color="gray900.main" component="h1" fontSize={24} fontWeight={600} my={3}>
        {ts("title")}
      </Typography>
      <CompanySettingsOverview
        initialValues={initialValues}
        isEditing={true}
        isLoading={isLoading || isImageLoading}
        onSubmit={onSubmit}
      />
    </Box>
  );
};
