import { SpeedRounded } from "@mui/icons-material";
import { Box } from "@mui/material";
import { FunctionComponent } from "react";

import { renderEventColor } from "@/app/utils/helpers";

import { EventStatus } from "../../types";

interface EventStatusBoxProps {
  status: EventStatus;
  smaller?: boolean;
}

export const EventStatusBox: FunctionComponent<EventStatusBoxProps> = ({ status, smaller }) => {
  return (
    <Box
      sx={{
        bgcolor: renderEventColor(status)?.light,
        borderRadius: "100%",
        width: smaller ? "24px" : "31px",
        height: smaller ? "24px" : "31px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          bgcolor: renderEventColor(status)?.medium,
          borderRadius: "100%",
          width: smaller ? "17px" : "23px",
          height: smaller ? "17px" : "23px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SpeedRounded
          sx={{
            color: renderEventColor(status)?.dark,
            width: smaller ? 12 : 17,
            height: smaller ? 12 : 17,
          }}
        />
      </Box>
    </Box>
  );
};
