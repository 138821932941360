import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import { BackLink } from "@/components";
import { ROUTE_CONFIG } from "@/app/routes/config";
import { useWindowSize } from "@/app/hooks/useWindowSize";

import { RibbonMenu } from "../../../../../../../components/RibbonMenu/RibbonMenu";

interface SettingsSectionProps {
  selectedDashboard: string | null;
}

export const SettingsSection: FunctionComponent<SettingsSectionProps> = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  return (
    <>
      {width <= 600 && <RibbonMenu isProjectReporting={true} />}
      <Box alignItems="center" display="flex" mb={1.5}>
        <Box sx={{ justifyItems: "left" }}>
          <BackLink
            destination={ROUTE_CONFIG.PROJECT_REPORTING}
            text={t("navigation.projectReporting")}
          />
        </Box>
        <Box sx={{ margin: "auto" }}>{width > 600 && <RibbonMenu isProjectReporting={true} />}</Box>
      </Box>
    </>
  );
};
