import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { useCreateCompanyMutation } from "@/pages/Private/redux/companies.api";
import { useUploadImage } from "@/pages/Private/helpers/useUploadImage";
import { BackLink } from "@/components";
import { notify } from "@/app/utils";

import { CompanyRequestBodyType, CompanyRequestType } from "../../schema/companies.schema";
import { CompanySettingsOverview } from "../components/CompanySettingsOverview";

export const CreateCompany: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const ts = (key: string) => t(`companies.create.${key}`);

  const [createCompany, { isLoading, isSuccess, isError }] = useCreateCompanyMutation();
  const { uploadImage, isLoading: isImageLoading } = useUploadImage();

  const initialValues: CompanyRequestBodyType = {
    name: "",
    logo: "",
    code: "",
  };

  const onSubmit = async (values: CompanyRequestBodyType) => {
    const { name, logo, code } = values;

    const body: CompanyRequestType = {
      name,
      logo,
      code,
    };

    if (logo) {
      const uploadedImage = await uploadImage(logo);

      body.logo = uploadedImage;
    }

    await createCompany(body);
  };

  useEffect(() => {
    if (isSuccess) {
      notify({ text: t("companies.notifications.companyCreated") });
      navigate(-1);
    }

    if (isError) {
      notify({ text: t("companies.notifications.wentWrong"), type: "error" });
    }
  }, [isSuccess, isError, navigate, t]);

  return (
    <Box pt={0.5}>
      <BackLink text={t("companies.backToCompanies")} />
      <Typography color="gray900.main" component="h1" fontSize={24} fontWeight={600} my={3}>
        {ts("title")}
      </Typography>
      <CompanySettingsOverview
        initialValues={initialValues}
        isLoading={isLoading || isImageLoading}
        onSubmit={onSubmit}
      />
    </Box>
  );
};
