import { useTranslation } from "react-i18next";
import { FunctionComponent, useCallback, useMemo } from "react";
import { Box, Typography } from "@mui/material";

import { DonutChart, Loader } from "@/components";
import { toPercent } from "@/app/utils";

import { KpiType } from "../../../../event.schema";
import {
  KpiTypeEnum,
  SalesPloomProductCategories,
  SalesPloomProductTypes,
} from "../../../../types";

interface SalesByDeviceAndPacksProps {
  isFiltration?: boolean;
  isFetching: boolean;
  ploomSalesKPIs: KpiType[];
}

export const SalesByDeviceAndPacksDonutChart: FunctionComponent<SalesByDeviceAndPacksProps> = ({
  isFetching,
  isFiltration,
  ploomSalesKPIs,
}) => {
  const { t } = useTranslation();

  /** Additional function to calculate the overall of sales by category
   * @param kpi KpiType with products
   * @returns The total sum of the kpi products overall values
   */
  const salesByProductCategory = useCallback(
    (kpi: KpiType) => {
      return kpi.data.reduce((totalKpiSales, product) => {
        totalKpiSales += (isFiltration ? product.date?.at(0)?.value : product.overall?.value) ?? 0;

        return totalKpiSales;
      }, 0);
    },
    [isFiltration]
  );

  // Sales split by Device & Packs donut chart labels names
  const salesByDeviceAndPacksLabels = useMemo(() => {
    const salesByDeviceAndPacksKPIs =
      ploomSalesKPIs
        .find((kpi) => kpi.name === KpiTypeEnum.SALES_BY_PRODUCT_TYPE_BY_CATEGORY)
        ?.data.filter(
          (type) =>
            type.specific === SalesPloomProductTypes.CIGARETTES ||
            type.specific === SalesPloomProductTypes.DEVICE
        ) || [];

    return (
      salesByDeviceAndPacksKPIs.map(
        (kpi) =>
          SalesPloomProductCategories[
            kpi.overall?.dim2 as keyof typeof SalesPloomProductCategories
          ] || kpi.specific
      ) || []
    );
  }, [ploomSalesKPIs]);

  // Sales split by Device & Packs donut chart data
  const salesByDeviceAndPacksData = useMemo(() => {
    const deviceAndPacksKpis = ploomSalesKPIs.filter(
      (kpi) =>
        kpi.name === KpiTypeEnum.SALES_BY_PRODUCT_BY_HEATERDEVICE ||
        kpi.name === KpiTypeEnum.SALES_BY_PRODUCT_BY_CIGARETTESTYPE_ECSCATEGORY
    );

    const totalSales = deviceAndPacksKpis.reduce((kpisOverallSum, kpi) => {
      kpisOverallSum += salesByProductCategory(kpi);

      return kpisOverallSum;
    }, 0);

    return deviceAndPacksKpis.map((kpi) =>
      Math.round(toPercent({ number: salesByProductCategory(kpi), total: totalSales }))
    );
  }, [ploomSalesKPIs, salesByProductCategory]);

  return (
    <Box
      sx={{
        pl: { xs: 0, md: 1 },
        pt: { xs: 1, md: 0 },
      }}
    >
      <Typography component="p" mb={1} variant="mainBold">
        {t("events.donutSalesByDeviceAndPacks")}
      </Typography>
      {isFetching ? (
        <Loader />
      ) : (
        <DonutChart
          kpi={t("events.donutSalesByDeviceAndPacks")}
          labels={salesByDeviceAndPacksLabels}
          series={salesByDeviceAndPacksData}
        />
      )}
    </Box>
  );
};
