import { FunctionComponent } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface NoDataFoundIf {
  kpi: string;
  align?: "left" | "right";
  height?: number | string;
}

export const NoDataFound: FunctionComponent<NoDataFoundIf> = ({ kpi, align, height = "100%" }) => {
  const { t } = useTranslation();

  return (
    <Box alignItems="center" display="flex" flexGrow={1} height={height} justifyContent="center">
      <Typography
        alignContent="center"
        color="gray700.main"
        fontSize={14}
        fontWeight={500}
        maxWidth={270}
        minHeight={150}
        textAlign={align ?? "center"}
      >
        {t("events.noData", { kpi })}
      </Typography>
    </Box>
  );
};
